/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import '../App.css';
// import Chat from 'layouts/pages/bot-ai/chat';
import VuiBox from 'components/VuiBox';
// import conversationData from 'layouts/pages/conversation-tables/data/conversationData';
import { Button, Card, Tooltip } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import annyang from 'annyang';
import StopIcon from '@mui/icons-material/Stop';
import VuiInput from 'components/VuiInput';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import VuiTypography from 'components/VuiTypography';
import typing from '../Typing GIF.gif';
import { createStyles, makeStyles } from '@mui/styles';
import { talk } from './streaming-client-api';
import { useVisionUIController } from "context";
import {initializeVideo} from './streaming-client-api';
import axios from 'axios';
import BASE_URL from '../context/api';

const useStyles = makeStyles(() =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageLightBlue: {
      position: "relative",
      minWidth:"23%",
      maxWidth: "80%",
      padding: "10px",
      backgroundColor: "#0175FF",
      // width: "60%",
      //height: "50px",
      textAlign: "left",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #0175FF",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "-1px",
        left: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #0175FF",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px",
      },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      color: "#fff",
      font: "Plus Jakarta Display",
    },
    messageTimeStampLeft: {
      fontSize: "0.75em",
      textAlign: "left",
      paddingTop: "5px",
      color: "#bfbfbf",
    },
    messageTimeStampRight: {
      fontSize: "0.75em",
      color: "#bfbfbf",
      textAlign: "right",
      paddingTop: "5px",
    },
    lightBlue: {
      color: "#FFF",
      width: "150%",
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

const useRightStyles = makeStyles(() =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageLightBlue: {
      position: "relative",
      minWidth:"23%",
      maxWidth: "47.5%",
      padding: "10px",
      backgroundColor: "#0175FF",
      // width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "200 .8em 'Plus Jakarta Display', sans-serif",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #0175FF",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "-1px",
        left: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #0175FF",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px",
      },
    },
    messageDeepBlue: {
      // right: "3%",
      position: "relative",
      alignSelf: "right",
      maxWidth: "60%",
      padding: "10px",
      backgroundColor: "transparent",
      //height: "50px",
      textAlign: "left",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid transparent",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #0175FF",
        borderLeft: "17px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px",
      },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      color: "#fff",
      font: "Plus Jakarta Display",
    },
    messageTimeStampLeft: {
      fontSize: "0.75em",
      textAlign: "left",
      paddingTop: "5px",
      color: "#bfbfbf",
    },
    messageTimeStampRight: {
      fontSize: "0.75em",
      color: "#bfbfbf",
      textAlign: "right",
      paddingTop: "5px",
    },
    deepBlue: {
      color: "#FFF",
      width: "150%",
    },
    lightBlue: {
      color: "#FFF",
      width: "150%",
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

const ChatWindow = ({ open, position, onClose, onCloseWindow }) => {
  //eslint-disable-next-line
  const suggestions = ['text to video', 'video personalization', 'conversational video chatbot', 'Schedule a call'];
  const options = ['I have a query', 'File a claim'];
  // const texttovideo = ['redirect to page', 'back to the menu']
  const [isListening, setIsListening] = useState(false);
  const [text, setText] = useState('');
  const videos =['https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/8cd_welcome.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/55a_first.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/ad3_texttovideo.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/0ca_personalized.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/250_conersational.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/a8e_contact.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/1c8_thanks.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/941_sorry.mp4'];
  const [videoIndex, setVideoIndex] = useState(0);
  const [messages, setMessages] = useState(() => {
    const url = window.location.pathname;
    if (url === '/chatbot-flashaid') {
      return [{ message: "Hello, I'm your personal assistant here to help you through the claims process.", type: 'incoming' }];
    } else if (url === '/botai') {
      return [{ message: "Welcome to QuickVideo, How can i assist you today ?", type: 'incoming' }];
    }
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const messagesContainerRef = useRef(null);
  const [ controller, dispatch ] = useVisionUIController();
  const { botAiAvatar, userDetails, botDetails } = controller;
  const {user_id} = userDetails;
  const [currentURL, setCurrentUrl] = useState('');

  useEffect(() => {
    const url = window.location.pathname;
    setCurrentUrl(url);
    url === '/chatbot-flashaid' ? setMessages([{message: "Hello, I'm your personal assistant here to help you through the claims process.",type: 'incoming'}]) : url === '/genpact' ? setMessages([{message: "Welcome to Genpact, How can i assist you today ?",type: 'incoming'}]) : setMessages([{message: botDetails.wMessage, type: 'incoming'}])
  }, [])

  const videoRef = useRef();
  
  useEffect(() => {
    if (videoRef.current) {
      setIsVideoLoaded(false);

      videoRef.current.load();
      videoRef.current.play().then(() => {
        setIsVideoLoaded(true);
      });
    }
  }, [botAiAvatar.preview_url]);

  useEffect(() => {
    window.location.pathname === '/genpact' ? initializeVideo(videoRef, 'https://quickvideo.blob.core.windows.net/quickvideo/presenters/preview/646efc.mp4', 'en-US-JennyNeural') : initializeVideo(videoRef, botAiAvatar, "en-US-JasonNeural");
    // messages && talk(messages[messages.length-1].message, user_id, 'clips', false);
  }, [])

  const makeAPIRequest = async (prompt) => {
    try {
      const res = await axios.post(`${BASE_URL}/generate_ai_text`, {
        prompt_text: prompt
      })
      
      const newAvailableText = res.data.generated_text;
      return newAvailableText.trim();
    } catch (err) {
      console.log(err);
    }
  }

  const chatBoxMessage = async (input) => {
    try {
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          Authorization: 'Bearer 61c6c73d-5a92-4212-8ecf-51b71b9c04ff'
        },
        body: JSON.stringify({
          messages: [
            {
              role: 'user',
              newKey: 'New Value',
              content: input,
              created_at: createChatData.created,
              'newKey-1': 'New Value',
            }
          ],
          streamId: sessionStorage.getItem('streamId'),
          sessionId: sessionStorage.getItem('sessionId'),
          newKey: 'New Value',
          chatId: createChatData.id,
        })
      };
      
      fetch('https://quick.quickvideo.ai/v1/assistant_chat', options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));
    } catch (error) {
      console.log(error);
    }
  } 

  const generateReply = async (input) => {
    const regex = /FAS\d+-\d+/i;
    const hnRegex = /.*hospital/i;
    const pinCodeRegex = /\b\d{6}\b/;
    const dateRegex = /\b\d{1,2}-\d{1,2}-\d{2,4}\b/;
    const billRegex = /\b\d{3,5}\b/;

    if(currentURL === '/botai') {
      const reply = await makeAPIRequest(input);
      return reply;
    } else if(currentURL === '/genpact') {
      const reply = await chatBoxMessage(input);
      return reply;
    } else if(currentURL === '/chatbot-flashaid') {
      const text = input.toLowerCase();

      if (text === 'hi' || text === 'hey' || text === 'hello' || text === 'hii' || text === 'heyy' || text === 'helloo' || text === 'helo' || text === 'heloo') {
          return options;
      } else if (text === 'file a claim') {
          return "Before we get started, we'd like to thank you for choosing our service. To begin, please provide your FlashAid Claim number.";
      } else if (regex.test(text)) {
          return "Thank you. it's great to have you here. Your FlashAid Claim number helps us tailor our assistance to your specific needs. Now, let's dive in.\n To proceed, we'll need some information about the hospital where you received treatment.\n Provide me hospital name";
      } else if (hnRegex.test(text)) {
          return "Provide me hospital city pin code";
      } else if (pinCodeRegex.test(text)) {
          return "Provide hospital admission date";
      } else if (dateRegex.test(text)) {
        return "Next, please provide us with the specific details of your illness or medical condition";
      } else if (text === 'heart attack') {
          return "To assist you better, we require an estimate of your hospital bill.";
      } else if (billRegex.test(text)) {
          return "Your claim request has been successfully registered. Here is your claim ticket number: 2001";
      } else if (text === "thank you" || text === "thank you for your help") {
          return "We'll keep you updated on the status of your claim. Our team will be in touch with you soon to guide you through the process and address any queries you may have. Thank you for choosing FlashAid. We're here to make your claim experience as smooth as possible. If you have any further questions or need assistance, please don't hesitate to reach out. Wishing you speedy recovery and a hassle-free claim process."
      } else {
          return "Sorry, I am not getting your query."
      }
    }
    // if (!isNaN(input)) {
    //   setVideoIndex(6);
    //   return 'thanks for providing, we will get back to you soon.';
    // }

    // switch (text) {
    //   case 'hi':
    //     setVideoIndex(1);
    //     return suggestions;

    //   case 'hey':
    //     setVideoIndex(1);
    //     return suggestions;

    //   case 'hello':
    //     setVideoIndex(1);
    //     return suggestions;

    //   case 'text to video':
    //     setVideoIndex(2);
    //     return 'It is a smart Text-to-Video generator tool that simplifies the creation of personalized and professional Ai-generated Videos for a better customer experience.';

    //   case 'video personalization':
    //     setVideoIndex(3);
    //     return 'Create and share personalized videos with a human-like element to encourage the receiver to engage.';

    //   case 'conversational video chatbot':
    //     setVideoIndex(4);
    //     return 'Your business interactions now have a human touch, fully automated. Hold natural conversations, resolve customer queries and so much more!';

    //   case 'schedule a call':
    //     setVideoIndex(5);
    //     return 'may i know your contact details ?';

    //   case 'show me menu':
    //     setVideoIndex(1);
    //     return suggestions;
      
    //   case 'show me menu again please':
    //     setVideoIndex(1);
    //     return suggestions;
    
    //   default:
    //     setVideoIndex(7);
    //     return 'Sorry, I am not getting your query.';
    // }
  };
  
  const sendMessage = async () => {
    const newOutgoingMessage = { message: text, type: 'outgoing' };
    const newMessages = [...messages, newOutgoingMessage];
    text !== '' && setMessages(newMessages);

    setText('');
    setIsLoading(true);

    let reply = await generateReply(text.toLowerCase());
    
    if (Array.isArray(reply)) {
      if (reply.length > 0) {
        const selectedMessage =  `Choose one of the below options ${reply[0]} or ${reply[1]}`;
        await talk(selectedMessage, user_id, 'clips', false);
      }
    } else if(window.location.pathname === '/aiwidget') {
      await talk(text, user_id, 'clips', true);
    } else {
      await talk(reply, user_id, 'clips', false);
    }

    setTimeout(() => {
      const newReceivedMessage = { message: reply, type: 'incoming' };
      setMessages([...newMessages, newReceivedMessage]);
      setIsLoading(false);
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      }
    }, 4000);
  };

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages])
  

  const handleButton = (index, item) => {
    setText(item);
  }

  const dialogStyle = {
    position: 'fixed',
    bottom:  window.innerWidth < 768 ? '70px' : position.bottom,
    right:  window.innerWidth < 768 ? 'auto' : position.right,
    zIndex: 1001,
    // background: 'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)',
    background: 'rgb(8,8,54)',
    transition: 'right 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    width:  window.innerWidth < 768 ? 300 : 360,
    minHeight:  window.innerWidth < 768 ? 300 : 450,
    height:  window.innerWidth < 768 ? 450 : 600,
    border: '1px solid skyblue',
    borderBottom: 'none'
  };

  useEffect(() => {
    annyang.addCallback('result', handleResult);

    return () => {
      annyang.removeCallback('result', handleResult);
    };
  }, []);

  const [createChatData, setCreateChatData] = useState();

  const handleResult = (phrases) => {
    setText(phrases[0]);
  };

  // const toggleRecognition = () => {
  //   if (isListening) {
  //     setIsListening(false); 
  //     annyang.abort();
  //   } else {
  //     setIsListening(true);
  //     annyang.start();
  //   }
  // };
  const startRecognition = () => {
    setIsListening(true);
    annyang.start();
    // const newTimeoutId = setTimeout(stopRecognition, 3000);
    // setTimeoutId(newTimeoutId);
  };

  const stopRecognition = () => {
    setIsListening(false);
    annyang.abort();
    window.location.pathname === '/genpact' && sendMessage();
    // clearTimeout(timeoutId);
  };

  const classes = useStyles();
  const rightClasses = useRightStyles();
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: dialogStyle }}
      className="chat-window"
    >
      <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
        <Tooltip title='Minimize chat window' placement='top'>
          <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px 5px 0', background: 'rgb(8,8,54)', cursor: 'pointer'}} onClick={onCloseWindow}>
            <CloseFullscreenIcon size={18} color='white' />
          </div>
        </Tooltip>
        <Tooltip title='Close session' placement='top'>
          <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px 5px 0', background: 'rgb(8,8,54)', cursor: 'pointer'}} onClick={onClose}>
            <ClearIcon size={18} color='white' />
          </div>
        </Tooltip>
      </VuiBox>
      <Card style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', background: 'rgb(8,8,54)', paddingTop: '0px'}}>
        <VuiBox
          style={{
            backgroundSize: "contain",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundPosition: 'center',
            position: "relative",
            objectFit: "contain",
            // margin: "auto",
            padding: '0 5px',
            paddingTop: 0
          }}
        >
          <VuiTypography style={{color: 'whitesmoke'}}>{currentURL === '/chatbot-flashaid' ? "Casey from FlashAid" : `${botDetails.name} from ${botDetails.companyName}`}</VuiTypography>
          <div className={`video-container ${isVideoLoaded ? 'loaded' : ''}`}>
            {window.location.pathname === '/genpact' ? 
            <video ref={videoRef} id="my-video" playsInline autoPlay loop>
              <source src='https://quickvideo.blob.core.windows.net/quickvideo/presenters/preview/646efc.mp4' type="video/mp4" />
            </video>
            :
            <video ref={videoRef} id="my-video" playsInline autoPlay>
              <source src={botAiAvatar} type="video/mp4" />
            </video>
            }
          </div>
        </VuiBox>
        
        {/* <Chat conversation={activeConversation.msg_data} /> */}
        {/* {inputSuggestion && <VuiInput value={inputSuggestion} />}
        {(!inputSuggestion && transcription) && <VuiInput multiline minRows={2} maxRows={5} sx={{textAlign: 'center'}} value={transcription} />} */}
      </Card>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: 'auto', marginBottom: '25px', flexDirection: 'column'}}>
        <div style={{maxHeight: window.innerWidth < 768 ? 150 : 220, overflowY: 'auto', width: '100%', padding: '5px 25px'}} ref={messagesContainerRef}>
          {messages && messages.map(({message, type}, index) => (
            type === 'incoming' ? 
              Array.isArray(message) ? 
              <div key={index} style={{color: 'whitesmoke', marginBottom: 25, marginRight: 'auto', padding: '5px 10px'}}>
                {message.map((item, index) => (
                  <Button variant='contained' key={index} sx={{width: '100px', color: 'whitesmoke', marginRight: '5px', marginBottom: '5px'}} onClick={()=>handleButton(index, item)}>{item}</Button>
                ))}
              </div> 
              : 
              window.location.pathname !== '/genpact' && <div key={index} className={classes.messageLightBlue} style={{color: 'whitesmoke', marginBottom: 25, marginRight: 'auto', padding: '5px 10px'}}>
                <h6 style={{fontSize: window.innerWidth < 768 ? '12px' : ''}}>{message}</h6>
              </div>
            :
            <div key={index} className={rightClasses.messageDeepBlue} style={{color: 'whitesmoke', marginBottom: 15, marginLeft: 'auto', padding: '5px 10px',}}>
              <h6 style={{fontSize: window.innerWidth < 768 ? '12px' : ''}}>{message}</h6>
            </div>
          ))}
          {isLoading && <img src={typing} alt="typing" width={50} />}
        </div>
        <div style={{display:'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <VuiInput sx={{width: window.innerWidth < 768 ? 'auto !important' : '200px !important', marginRight: '5px'}} value={text} onChange={e => {setText(e.target.value)}} onKeyDown={(e) => {if (e.key === 'Enter') {e.preventDefault(); sendMessage(); }}} />
          <SendIcon size={16} color="white" style={{margin: '0px 5px', cursor: 'pointer'}} onClick={sendMessage} />
          {isListening ? 
            <Button variant='contained' sx={{margin: window.innerWidth < 768 ? 'auto' : '0 5px'}} onClick={stopRecognition}>
             <StopIcon size={22} color='white' /> 
            </Button>
            :  
            <Button variant='contained' sx={{margin: window.innerWidth < 768 ? 'auto' : '0 5px'}} onClick={startRecognition}>
             <MicIcon size={22} color='white' /> 
            </Button>
          }
        </div>
      </div>
    </Dialog>
  );
};

export default ChatWindow;
