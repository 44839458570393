import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

function Builder() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
          mt={2}
          ml={1}
        >
          <VuiTypography variant="lg" color="white">
            Videos
          </VuiTypography>
        </VuiBox>
      </Grid>

      {/* <Grid container spacing={1}> */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <NavLink to="/create">
                <PlaceholderCard title={{ variant: "h6", text: "Add New" }} />
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <NavLink to="/create">
                <PlaceholderCard title={{ variant: "h6", text: "Add New" }} />
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <NavLink to="/create">
                <PlaceholderCard title={{ variant: "h6", text: "Add New" }} />
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <NavLink to="/create">
                <PlaceholderCard title={{ variant: "h6", text: "Add New" }} />
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <NavLink to="/create">
                <PlaceholderCard title={{ variant: "h6", text: "Add New" }} />
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <NavLink to="/create">
                <PlaceholderCard title={{ variant: "h6", text: "Add New" }} />
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <NavLink to="/create">
                <PlaceholderCard title={{ variant: "h6", text: "Add New" }} />
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <NavLink to="/create">
                <PlaceholderCard title={{ variant: "h6", text: "Add New" }} />
            </NavLink>
          </Grid>
      {/* </Grid> */}
    </Grid>
  );
}
export default Builder;
