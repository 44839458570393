 /* eslint-disable no-unused-vars */

 const Welcome = () => {
   return (
     <div style={{textAlign: 'center'}}>
       <p><strong>Welcome</strong></p>
       <p>Let's help you get started, in less than a minute.</p>
     </div>
   );
 }
 
 export const createSteps = [
   {
     selector: '#presenters',
     content: "Choose a presenter from our list."
   },
   {
     selector: '#upload',
     content: "Or upload your image to make a custom avatar."
   },
   {
     selector: '#backgroundColor',
     content: "Choose a background color."
   },
   {
     selector: '#videoName',
     content: "Next, name the video."
   },
   {
     selector: '#scriptTab',
     content: "Now you can choose to add a script for your avatar"
   },
   {
     selector: '#scriptTabInput',
     content: "You can directly add a script here"
   },
   {
     selector: '#scriptTabAiInput',
     content: "Or ask our AI assistant to generate one from a prompt"
   },
   {
     selector: '#scriptTabOption',
     content: "Then select the language, voice, and style for the avatar"
   },
   {
     selector: '#audioTab',
     content: "Alternatively, you can directly upload audio for the video"
   },
   {
     selector: '#cloneTab',
     content: "Or create a custom voice clone to use with a script"
   },
 ];