/*eslint-disable*/

import A from "assets/images/A.png";
import B from "assets/images/B.png";
import C from "assets/images/C.png";
import D from "assets/images/D.png";
import E from "assets/images/E.jpeg";
import F from "assets/images/F.png";
import G from "assets/images/G.jpeg";
import H from "assets/images/H.jpeg";
import I from "assets/images/I.jpeg";
import J from "assets/images/J.png";
import K from "assets/images/K.jpeg";
import L from "assets/images/L.png";
import M from "assets/images/M.jpeg";
import N from "assets/images/N.jpeg";
import O from "assets/images/O.png";
import P from "assets/images/P.png";
import Q from "assets/images/Q.jpeg";
import R from "assets/images/R.jpeg";
import S from "assets/images/S.jpeg";
import T from "assets/images/T.jpeg";
import U from "assets/images/U.png";
import V from "assets/images/V.jpeg";
import W from "assets/images/W.png";
import X from "assets/images/X.jpeg";

export default [
  {
    image: D,
    name: "Tom M",
    color: "info",
    width: "380px"
  },
  {
    image: E,
    name: "Nicole N",
    color: "info",
    width: "380px"
  },
  {
    image: F,
    name: "Maria P",
    color: "info",
    width: "380px"
  },
  {
    image: G,
    name: "Burce M",
    color: "info",
    width: "380px"
  },
  {
    image: H,
    name: "Sandra A",
    color: "info",
    width: "380px"
  },
  {
    image: I,
    name: "Katty L",
    color: "info",
    width: "380px"
  },
  {
    image: J,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: K,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: L,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: M,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: N,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: O,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: P,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: Q,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: R,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: S,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: T,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: U,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: V,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: W,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
  {
    image: X,
    name: "Emma Q",
    color: "info",
    width: "380px"
  },
];
