/*eslint-disable no-unused-vars */

import React from "react";
import QuickVideoIcon from "../QuickVideoIcon.png";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import VuiButton from "components/VuiButton";
import { Box, Paper } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
// import Card from "assets/theme/components/card";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Shop } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function Second() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [Size, setSize] = React.useState(0);

  const handleSize = (event, newSize) => {
    setSize(newSize);
  };
  const [Role, setRole] = React.useState(0);

  const handleRole = (event, newRole) => {
    setRole(newRole);
  };

  return (
    <VuiBox>
      <VuiBox style={{ marginTop: "25px" }}>
        <VuiTypography
          variant="button"
          textGradient={true}
          color="logo"
          fontSize={14}
          letterSpacing={2}
          fontWeight="medium"
        >
          <img src={QuickVideoIcon} alt="quickvideoicon" height="30px" />
        </VuiTypography>
      </VuiBox>

      <Grid>
        <VuiBox style={{ display: "flex", justifyContent: "center" }}>
          <VuiTypography
            variant="h2"
            color="info"
            textGradient
            verticalAlign="middle"
            fontWeight="regular"
          >
            Tell us more about your company to customize your experience.
          </VuiTypography>
        </VuiBox>

        <VuiBox
          style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}
        >
          <VuiTypography
            style={{ display: "block", justifyContent: "center" }}
            variant="h4"
            textGradient
            verticalAlign="middle"
            fontWeight="false"
            color="secondary"
          >
            Vertical
          </VuiTypography>
        </VuiBox>
        <VuiBox
          style={{ display: "flex", justifyContent: "center", marginTop: "1%" }}
        >
          <Tabs value={value} onChange={handleChange} centered>
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  Ecommerce
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  Saas
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  Agency
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  Education
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  Other
                </Typography>
              }
            />
          </Tabs>
        </VuiBox>

        <VuiBox
          style={{ display: "flex", justifyContent: "center", marginTop: "2%" }}
        >
          <VuiTypography
            style={{ display: "block", justifyContent: "center" }}
            variant="h4"
            textGradient
            verticalAlign="middle"
            fontWeight="false"
            color="secondary"
          >
            Company size
          </VuiTypography>
        </VuiBox>
        <VuiBox
          style={{ display: "flex", justifyContent: "center", marginTop: "1%" }}
        >
          <Tabs value={Size} onChange={handleSize} centered>
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  1-20
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  21-50
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  51-100
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  101-1000
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  1001+
                </Typography>
              }
            />
          </Tabs>
        </VuiBox>

        <VuiBox
          style={{ display: "flex", justifyContent: "center", marginTop: "2%" }}
        >
          <VuiTypography
            style={{ display: "block", justifyContent: "center" }}
            variant="h4"
            textGradient
            verticalAlign="middle"
            fontWeight="false"
            color="secondary"
          >
            Role
          </VuiTypography>
        </VuiBox>
        <VuiBox
          style={{ display: "flex", justifyContent: "center", marginTop: "1%" }}
        >
          <Tabs value={Role} onChange={handleRole} centered>
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  Marketing
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  Sales
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  Product
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  Engineering
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  Support
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  Opperations
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  HR
                </Typography>
              }
            />
            <Tab
              iconPosition="start"
              label={
                <Typography color={"whitesmoke"} variant="h6">
                  Other
                </Typography>
              }
            />
          </Tabs>
        </VuiBox>
      </Grid>

      <VuiBox sx={{ flex: 1, justifyContent: "flex-end" }}>
        <VuiBox mt={4} mb={1}>
          <VuiTypography
            component={Link}
            to="/create"
            variant="button"
            color="white"
            fontWeight="medium"
          >
            <Button
              style={{ position: "fixed", bottom: 20, right: 180 }}
              variant="outlined"
            >
              Skip
            </Button>
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={4} mb={1}>
          <VuiTypography
            component={Link}
            to="/create"
            variant="button"
            color="white"
            fontWeight="medium"
          >
            <Button
              style={{ position: "fixed", bottom: 20, right: 60 }}
              variant="outlined"
            >
              Continue
            </Button>
          </VuiTypography>
        </VuiBox>

      </VuiBox>
    </VuiBox>
  );
}

export default Second;
