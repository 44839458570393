/*eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from "react";
import './App.css';
// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard PRO React example components
import Sidenav from "components/Sidenav";
// import Configurator from "examples/Configurator";

// Vision UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Vision UI Dashboard PRO React routes
import routes from "routes";

// Vision UI Dashboard PRO React contexts
import {
  useVisionUIController,
  //eslint-disable-next-line
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Plugins custom css
import "assets/theme/base/plugins.css";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import success from "layouts/authentication/success";
import ForgotPassword from "layouts/authentication/ForgotPassword";
import ChatButton from "./video-streaming/ChatButton";
import VuiTypography from "components/VuiTypography";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import mixpanel from "mixpanel-browser";

mixpanel.init("785e0f35653bd38294092d072e829815", {
  debug: true,
  track_pageview: false,
  persistence: "localStorage",
});

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor, isLoggedIn, userDetails, widget } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  // const handleOnMouseEnter = () => {
  //   if (miniSidenav && !onMouseEnter) {
  //     setMiniSidenav(dispatch, false);
  //     setOnMouseEnter(true);
  //   }
  // };

  // // Close sidenav when mouse leave mini sidenav
  // const handleOnMouseLeave = () => {
  //   if (onMouseEnter) {
  //     setMiniSidenav(dispatch, true);
  //     setOnMouseEnter(false);
  //   }
  // };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            component={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <VuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="white"
      sx={({ palette: { info } }) => ({
        cursor: "pointer",
        backgroundColor: info.main,
      })}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </VuiBox>
  );

  if (process.env.NODE_ENV === "production") {
    console.error = () => function () {};
    console.log = () => function () {};
    console.debug = () => function () {};
    console.info = () => function () {};
    console.warn = () => function () {};
  }

  return (
      <div className="app">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {isLoggedIn ? 
            (
              <>
                {layout === "dashboard" && pathname !== '/videoeditor' && pathname !== '/saastutorial/main' && 
                <Sidenav
                  color={sidenavColor}
                  brandName="QuickVideo.ai"
                  routes={routes}
                />}
                <div className="layout">
                  {/* <VuiBox sx={{background: 'white', borderRadius: '10px', padding: '5px 50px', textAlign: 'center', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto'}}>
                    <VuiTypography variant='h6' sx={{color: 'white', fontWeight: 500, display: 'flex', alignItems: 'center'}}>
                      <WarningAmberIcon color="warning" sx={{marginRight: '10px'}} /> Oops, it looks like you have only 5 credits left, please upgrade your plan!
                    </VuiTypography>
                  </VuiBox> */}
                  <Switch>
                    {getRoutes(routes)}
                    <Redirect from="*" to="/create" />
                    {/* <Redirect from="*" to={userDetails.org_info ? "/create" : window.innerWidth < 768 ? "/create" : "/authentication/First/basic"} /> */}
                  </Switch>
                </div> 
                {widget && <ChatButton />}
              </>
          ) : (
            <>
              <Switch>
                <Route exact path="/signin" component={SignInBasic} />
                <Route exact path="/signup" component={SignUpBasic} />
                <Route path="/activate/:token" component={success} />
                <Route path="/reset_password/:token" component={ForgotPassword} />
                <Redirect to="/signin" />
              </Switch>
            </>
          )}
        </ThemeProvider>
      </div>
  )
}
