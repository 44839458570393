import { Button, FormControlLabel, Radio, RadioGroup, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import VuiBox from 'components/VuiBox';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import sl1 from './../images/caption_type_1.svg';
import sl2 from './../images/caption_type_3.svg'
import dl1 from './../images/caption_type_2 (1).svg';
import dl2 from './../images/caption_type_4 (1).svg';
import VuiSelect from 'components/VuiSelect';

const Subtitle = ({alignment, setAlignment, value, setValue, fonts, setFonts, selectedFont, setSelectedFont, selectedFontSize, setSelectedFontSize, fontSize, setFontSize}) => {
  // const [alignment, setAlignment] = useState('sl');
  // const [value, setValue] = useState('one');
  // const [fonts, setFonts] = useState(['Noto sans', 'Caveat-VariableFont_wght', 'Montserrat-Bold', 'Montserrat-Italic', 'Montserrat-Regular', 'Poppins-Bold', 'Poppins-Italic', 'Poppins-Regular', 'SourceSerifPro-Bold', 'SourceSerifPro-Italic', 'SourceSerifPro-Regular', 'AbrilFatface-Regular', 'AlfaSlabOne-Regular', 'Blinker-Bold', 'Blinker-Regular', 'Orbitron-VariableFont_wght', 'PinyonScript-Regular']);
  // const [selectedFont, setSelectedFont] = useState('Noto sans');
  // const [fontSize, setFontSize] = useState(['12', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32']);
  // const [selectedFontSize, setSelectedFontSize] = useState('12');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleFontChange = (e) => {
    setSelectedFont(e.value);
  };

  const handleFontSizeChange = (e) => {
    setSelectedFontSize(e.value);
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const CustomLabel = ({ label, imageSrc, ...rest }) => (
    <VuiBox sx={{display: 'flex', alignItems: 'center'}}>
      <FormControlLabel {...rest} />
      <img src={imageSrc} alt="Img" style={{ marginRight: '8px', width: '200px' }} />
      {/* {label} */}
    </VuiBox>
  );

  return (
    <VuiBox>
      <Typography>Subtitle</Typography>
      <VuiBox sx={{display: 'flex', flexDirection: 'column'}}>
        <Button variant='contained' sx={{color: 'whitesmoke', marginTop: '15px'}}>Generate Subtitle</Button>
        <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column' , backgroundColor: 'transparent', marginTop: '10px', minHeight: '100px', padding: '0 10px', border: '1px solid gray', borderRadius: '15px'}}>
          <VuiBox sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
            <textarea rows='5' style={{background: 'none', outline: 'none', border: 'none', margin: '5px', height: '60px', overflow: 'auto', width: '250px', color: 'whitesmoke'}} />
            <DeleteIcon color='gray' sx={{marginTop: '10px'}} />
          </VuiBox>
          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px'}}>
              <Typography variant='h6'>Start</Typography>
              <input type='text' value={0.0} style={{width: '50px', marginLeft: '5px', textAlign: 'center'}} />
            </VuiBox>
            <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px'}}>
              <Typography variant='h6'>End</Typography>
              <input type='text' value={1.0} style={{width: '50px', marginLeft: '5px', textAlign: 'center'}} />
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
      <VuiBox sx={{display: 'flex', flexDirection: 'column', marginTop: '15px', alignItems: 'center'}}>
        <Typography sx={{marginBottom: '5px'}}>Subtitle style</Typography>
        <ToggleButtonGroup
          color="white"
          value={alignment}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="sl" sx={{color: 'gray'}}>Single line</ToggleButton>
          <ToggleButton value="dl" sx={{color: 'gray'}}>Double line</ToggleButton>
        </ToggleButtonGroup>
      </VuiBox>
      {alignment === 'sl' && (
        <VuiBox sx={{margin: '10px'}}>
          <RadioGroup
            value={value}
            onChange={handleRadioChange}
          >
            <CustomLabel
              value="one"
              control={<Radio />}
              imageSrc={sl1}
            />
            <CustomLabel
              value="two"
              control={<Radio />}
              imageSrc={sl2}
            />
          </RadioGroup>
        </VuiBox>
      )}
      {alignment === 'dl' && (
        <VuiBox sx={{margin: '10px'}}>
          <RadioGroup
            value={value}
            onChange={handleRadioChange}
          >
            <CustomLabel
              value="three"
              control={<Radio />}
              imageSrc={dl1}
            />
            <CustomLabel
              value="four"
              control={<Radio />}
              imageSrc={dl2}
            />
          </RadioGroup>
        </VuiBox>
      )}
      <VuiBox>
        <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Font</Typography>
        <VuiSelect
          options={fonts.map((entry) => ({ value: entry, label: entry }))}
          onChange={(e)=>handleFontChange(e)}
        />
      </VuiBox>
      <VuiBox>
        <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Font Size</Typography>
        <VuiSelect
          options={fontSize.map((entry) => ({ value: entry, label: entry }))}
          onChange={(e)=>handleFontSizeChange(e)}
        />
      </VuiBox>
    </VuiBox>
  )
}

export default Subtitle;