import { Button, Checkbox, CircularProgress, Divider, Modal } from '@mui/material';
import React from 'react';
import './index.css';
import VuiTypography from 'components/VuiTypography';
import warningIcon from './warning_595067.png';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

const ConfirmModal = ({confirmModal, setConfirmModal, handleGenerate, credits, creditsloader, setCreditsloader, setAsk, videoLength, creditsBurn}) => {

  const handleClose = () => {
    setConfirmModal(false);
    setCreditsloader(false);
  }

  const handleCheck = (event) => {
    setAsk(event.target.checked);
  }

  return (
    <div>
      <Modal open={confirmModal} onClose={handleClose}>
        <div className='confirm_modal' style={{boxShadow: "rgb(54, 68, 97) 0px 0px 30px 2px"}}>
          <div>
            {window.innerWidth < 768 ? <h4 style={{padding: "5px", fontWeight: 400, textAlign: 'center'}}>Confirm your action</h4> : <h3 style={{padding: "5px", fontWeight: 400, textAlign: 'center'}}>Confirm your action</h3>}
          </div>
          <Divider light />
          <h4 style={{fontWeight: 400, textAlign: 'center'}}>Are you sure you want to generate video with provided details ?</h4>
          <h5 style={{marginTop: '20px', marginBottom:'10px', fontWeight: 400, textAlign: 'left'}}>Credits Policy</h5>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <ul style={{fontSize: '14px'}}>
              <li>1 credit will be deducted on successful video generation of video duration 0-15 sec.</li>
              <span style={{color: 'gray'}}>e.g. Suppose generated video total duration is <b>1.15 min</b>, then <b>5</b> credits will be deducted.</span>
            </ul>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: '15px'}}>
            <img src={warningIcon} alt="warning" width={20} style={{marginRight: '10px', marginTop: '3px'}} />
            <VuiTypography variant='h6'>Below shown video length and credits burn is approximate. It may vary after successful video generation.</VuiTypography>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px', flexDirection: window.innerWidth < 768 ? 'column' : 'row'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '200px'}}>
              <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <VuiTypography variant='h6'>Video Length</VuiTypography>
                <h6 style={{marginLeft: '5px'}}>{`${videoLength} - ${videoLength + 1} sec`}</h6>
              </div>
              <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <VuiTypography variant='h6'>Credits</VuiTypography>
                <h6 style={{marginLeft: '5px'}}>{creditsloader ? <CircularProgress size={16} color="white" /> : `${creditsBurn} (${credits} left)`}</h6>
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '10px'}}>
              <Button 
                variant='contained' 
                sx={{
                  color: "whitesmoke", 
                  background: "#344767",
                  margin: "5px",
                  ":hover": {
                    bgcolor: "#344767",
                    color: "white"
                  }
                }} 
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button 
                variant='contained' 
                sx={{
                  color: "whitesmoke", 
                  background: "#344767",
                  margin: "5px",
                  ":hover": {
                    bgcolor: "#344767",
                    color: "white"
                  },
                  ":disabled": {
                    bgcolor: "#344762",
                    color: "gray",
                  }
                }} 
                onClick={handleGenerate} 
                disabled={credits < creditsBurn + 2}
              >
                Generate
              </Button>
            </div>
          </div>

          {(credits < creditsBurn + 2) && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px'}}>
            <VuiTypography variant='h6' color='error'>More credits are required to create video, upgrade your plan <NavLink to='/gopremium' style={{textDecoration: 'underline', borderBottom: '1px solid red', color: 'red'}}>here</NavLink>.</VuiTypography>
          </div>}
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px'}}>
            <Checkbox sx={{color: 'whitesmoke'}} onChange={handleCheck} />
            <VuiTypography variant='h6'>Don't ask again.</VuiTypography>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ConfirmModal;