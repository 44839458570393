import { AppBar, Button, ButtonGroup, CircularProgress, Grid, IconButton, Slider, Tab, Tabs, Typography } from '@mui/material'
import VuiBox from 'components/VuiBox'
import VuiTypography from 'components/VuiTypography'
import React, { useEffect, useState } from 'react'
import RecordScreen from './RecordScreen'
import VuiInput from 'components/VuiInput'
import OutroSettings from './OutroSettings'
import axios from 'axios'
import { AddCircleOutline, Cancel } from '@mui/icons-material'

const Outro = ({uploadedVideo, setUploadedVideo, stockVideos, setStockVideos, videoQuery, setVideoQuery, videoPage, videoTotalPages, setVideoPage, lastVideoRef, loading, videoObserver, scenes, updateSceneKey, currentSceneIndex, openSettings, setOpenSettings, setScenes, setUploadedBackground, loadingModal, setLoadingModal, addScene, outro, setOutro, uploadedOutroVideos, setUploadedOutroVideos, setShowIO}) => {
  const [videoTabValue, setVideoTabValue] = useState(0);

  const tabHandler = (event, newValue) => {
    setVideoTabValue(newValue);
    setOpenSettings({
      ...openSettings,
      outro: false,
    })
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const videoObject = {
          video: e.target.result,
          // Other properties as needed
        };
        setUploadedOutroVideos((prevVideos) => [...prevVideos, videoObject]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddVideo = (videoObject) => {
    const newVideoObject = {
      ...videoObject,
      zIndex: 10,
      opacity: 1,
      x: 170,
      y: 20,
      size: {
        width: 300,
        height: 200,
      },
      handle: false,
      fullscreen: false,
      recorded: false,
    };

    // updateSceneKey(currentSceneIndex, 'selectedOutro', newVideoObject);
    setOutro(newVideoObject);
    setShowIO(prevIO => ({
      ...prevIO,
      outro: true
    }));
    setOpenSettings({
      ...openSettings,
      outro: newVideoObject,
    });
  };

  const handleRemoveVideo = (index) => {
    setUploadedOutroVideos((prevVideos) => prevVideos.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (lastVideoRef.current) {
      videoObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !loading) {
          setVideoPage((prevPage) => prevPage + 1);
        }
      });

      videoObserver.current.observe(lastVideoRef.current);
    }

    return () => {
      if (videoObserver.current) {
        videoObserver.current.disconnect();
      }
    };
    //eslint-disable-next-line
  }, [loading, videoTotalPages, videoPage]);

  return (
    <VuiBox>
      <Grid container alignItems={"center"}>
        <AppBar
          position="static"
          sx={{ display: "flex", mb: 0, alignItems: "center"}}
        >
          <Tabs value={videoTabValue} onChange={tabHandler} sx={{overflow: 'auto', width: '100%' }}>
            <Tab
              style={{minWidth:"100%"}}
              label={
                <VuiTypography color="light" variant="body2">
                  Outro Video
                </VuiTypography>
              }
            />
          </Tabs>
        </AppBar>
      </Grid>
      {videoTabValue === 0 && 
        <VuiBox sx={{marginTop: '15px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          {openSettings.outro === false ? 
            <>
              <label htmlFor="video-file" style={{cursor: 'pointer'}}>
                <Typography variant='h6' sx={{color: '#000', background: 'lightgray', padding: '10px 25px', borderRadius: '15px'}}>
                  Click here to Upload
                  <input
                    accept="video/mp4, video/webm"
                    style={{ display: 'none' }}
                    type="file"
                    id="video-file"
                    name="video-file"
                    onChange={handleFileUpload}
                  />
                </Typography>
              </label>
              <div style={{ marginTop: '20px' }}>
                {uploadedOutroVideos.map((videoObject, index) => (
                  <VuiBox key={index} sx={{position:"relative", display:"inline-block", margin:'5px', border: '2px solid gray', borderRadius: '5px'}}>
                    <video src={videoObject.video} width="180px" height='130px' controls style={{ display: 'block' }} />
                    <IconButton
                      size="small"
                      color="white"
                      onClick={() => handleAddVideo(videoObject)}
                      style={{ position: 'absolute', top: 0, left: 0, background: 'black' }}
                    >
                      <AddCircleOutline />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="white"
                      onClick={() => handleRemoveVideo(index)}
                      style={{ position: 'absolute', top: 0, right: 0, background: 'black' }}
                    >
                      <Cancel />
                    </IconButton>
                  </VuiBox>
                ))}
              </div>
            </>
            :
            <OutroSettings scenes={scenes} setScenes={setScenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} openSettings={openSettings} setOpenSettings={setOpenSettings} setUploadedBackground={setUploadedBackground} setOutro={setOutro} setShowIO={setShowIO} />}
        </VuiBox>
      }
    </VuiBox>
  )
}

export default Outro