import { Button, ButtonGroup, Slider, Typography } from '@mui/material';
import VuiBox from 'components/VuiBox';
import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const ModelSettings = ({setOpenSettings, openSettings, updateSceneKey, scenes, currentSceneIndex}) => {
  const [scale, setScale] = useState(openSettings.model.scale);
  const [zIndex, setZIndex] = useState(openSettings.model.zIndex);
  const [opacity, setOpacity] = useState(openSettings.model.opacity);
  const [top, setTop] = useState(openSettings.model.marginTop);
  const [left, setLeft] = useState(openSettings.model.marginLeft);

  const handleScale = (event, newValue) => {
    setScale(newValue);

    if (openSettings.model) {
      const updatedModel = { ...openSettings.model };
      updatedModel.scale = newValue;
  
      updateSceneKey(currentSceneIndex, 'selectedModel', updatedModel);
    }
  }

  const handleLeftMinus = () => {
    setLeft(prevLeft => prevLeft - 1);
    if (openSettings.model) {
      const updatedModel = { ...openSettings.model };
      updatedModel.marginLeft = left;
  
      updateSceneKey(currentSceneIndex, 'selectedModel', updatedModel);
    }
  }
  
  const handleLeftPlus = () => {
    setLeft(prevLeft => prevLeft + 1);
    if (scenes[currentSceneIndex].selectedModel) {
      const updatedModel = { ...scenes[currentSceneIndex].selectedModel };
      updatedModel.marginLeft = left;
  
      updateSceneKey(currentSceneIndex, 'selectedModel', updatedModel);
    }
  }

  const handleTopMinus = () => {
    setTop(prevTop => prevTop - 1);
    if (scenes[currentSceneIndex].selectedModel) {
      const updatedModel = { ...scenes[currentSceneIndex].selectedModel };
      updatedModel.marginTop = top;
  
      updateSceneKey(currentSceneIndex, 'selectedModel', updatedModel);
    }
  }
  
  const handleTopPlus = () => {
    setTop(prevTop => prevTop + 1);
    if (scenes[currentSceneIndex].selectedModel) {
      const updatedModel = { ...scenes[currentSceneIndex].selectedModel };
      updatedModel.marginTop = top;
  
      updateSceneKey(currentSceneIndex, 'selectedModel', updatedModel);
    }
  }

  const handleZdecrease = () => {
    setZIndex(prevZ => {
      const newZIndex = prevZ - 10;
  
      if (scenes[currentSceneIndex].selectedModel) {
        const updatedModel = { ...scenes[currentSceneIndex].selectedModel, zIndex: newZIndex };
        updateSceneKey(currentSceneIndex, 'selectedModel', updatedModel);
      }
  
      return newZIndex;
    });
  };  

  const handleZincrease = () => {
    setZIndex(prevZ => {
      const newZIndex = prevZ + 10;
  
      if (scenes[currentSceneIndex].selectedModel) {
        const updatedModel = { ...scenes[currentSceneIndex].selectedModel, zIndex: newZIndex };
        updateSceneKey(currentSceneIndex, 'selectedModel', updatedModel);
      }
  
      return newZIndex;
    });
  };
  

  const handleOpacity = (event, newValue) => {
    setOpacity(newValue);
    if (scenes[currentSceneIndex].selectedModel) {
      const updatedModel = { ...scenes[currentSceneIndex].selectedModel };
      updatedModel.opacity = newValue;
  
      updateSceneKey(currentSceneIndex, 'selectedModel', updatedModel);
    }
  }

  const handleDelete = () => {
    if (scenes[currentSceneIndex].selectedModel) {
      let updatedModel = {...scenes[currentSceneIndex].selectedModel };
      updatedModel = null;

      updateSceneKey(currentSceneIndex,'selectedModel', updatedModel);
      updateSceneKey(currentSceneIndex, 'avatarVideo', false);
      setOpenSettings({...openSettings, model: false});
    } else {
      setOpenSettings({...openSettings, model: false});
    }
  }

  return (
    <VuiBox sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
      <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '5px'}}>
        <ArrowBackIcon color="white" sx={{cursor: 'pointer'}} onClick={()=>setOpenSettings({...openSettings, model: false})} />
        <DeleteOutlineIcon color='white' sx={{cursor: 'pointer'}} onClick={handleDelete} />
      </VuiBox>
      <VuiBox sx={{background: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center'}} mt={1}>
        {openSettings.model && 
          <img alt='uploaded' src={openSettings.model.model} style={{ maxWidth: '100%', maxHeight: '20vh' }} />  
        }
      </VuiBox>
      <VuiBox sx={{marginTop: '10px'}}>
        {/* <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Scale</Typography>
          <Slider value={scale} onChange={handleScale} min={0} max={100}  color="secondary" sx={{width: '100px'}} />
          <VuiBox sx={{color: 'whitesmoke', border: '1px solid whitesmoke', borderRadius: '10px', padding: '0px 20px', fontSize: '14px'}}>{scale === '' ? "0%" : `${scale}%`}</VuiBox>
        </VuiBox> */}
        <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
          {/* <Typography variant='h6' sx={{marginTop: '10px'}}>Position</Typography>
          <VuiBox sx={{display: 'flex', justifyContent: 'space-between'}}>
            <VuiBox sx={{display: 'flex' ,flexDirection: 'column'}}>
              <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>X</Typography>
              <ButtonGroup variant='outlined' aria-label="outlined button group" sx={{width: '150px'}}>
                <Button sx={{padding: '5px'}} onClick={handleLeftMinus}>-</Button>
                <Button sx={{padding: '5px'}}>{left}</Button>
                <Button sx={{padding: '5px'}} onClick={handleLeftPlus}>+</Button>
              </ButtonGroup>
            </VuiBox>
            <VuiBox sx={{display: 'flex' ,flexDirection: 'column'}}>
              <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Y</Typography>
              <ButtonGroup variant='outlined' aria-label="outlined button group" sx={{width: '150px'}}>
                <Button sx={{padding: '5px'}} onClick={handleTopMinus}>-</Button>
                <Button sx={{padding: '5px'}}>{top}</Button>
                <Button sx={{padding: '5px'}} onClick={handleTopPlus}>+</Button>
              </ButtonGroup>
            </VuiBox>
          </VuiBox> */}

          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px auto'}}>
            <ButtonGroup variant="contained">
              <Button sx={{color: 'whitesmoke', '&:disabled': {color: 'gray' }}} onClick={handleZdecrease} disabled={zIndex < 1}>move back</Button>
              <Button sx={{color: 'whitesmoke'}} onClick={handleZincrease}>move front</Button>
            </ButtonGroup>
          </VuiBox>

          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
            <Typography variant='h6' color='whitesmoke'>Opacity</Typography>
            <Slider min={0} max={1} step={0.01} color="secondary" sx={{width: '100px'}} value={opacity} onChange={handleOpacity} />
            <VuiBox sx={{color: 'whitesmoke', border: '1px solid whitesmoke', borderRadius: '10px', padding: '0px 20px', fontSize: '14px'}}>{opacity}</VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </VuiBox>
  )
}

export default ModelSettings