/*eslint-disable no-unused-vars */
import { TextField } from "@mui/material";
import React from "react";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function Tik(){
    return(
        <Grid  style={{float: 'right'}}>
          <Typography style={{ fontWeightMedium: 500,fontSizeMD: 16, bottom:"50px", color: "whitesmoke", marginBottom: '10px'}}> 
          Import From TikTok
          </Typography>
          <VuiBox  style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
            {/* <Card style={{ width:'60%',marginLeft:5,marginTop:25,marginRight:5, float: 'left'}}> */}
            <Card style={{  width:'45rem', justifySelf:'center', float: 'left', marginBottom: '10px'}}>
              <VuiBox sx={{ display: 'flex' }}>
                <VuiInput style={{width: 300, marginRight: '5px'}}
                  placeholder="Please paste your tiktok video link here.."
                />
                <VuiButton variant="outlined" color="info">
                  Import
                </VuiButton>
              </VuiBox>    
              <Card style={{width:'50%',height:200, justifySelf:'center', backgroundColor: 'whitesmoke', left: '25%', marginTop: '15px'}}>
              </Card>
            </Card>
          </VuiBox>
          {/* <FormControlLabel style={{color:"whitesmoke",display:'block',marginRight:'40px',marginLeft:'5px'}} */}
          <FormControlLabel 
            style={{color:"whitesmoke",display:'block',justifyContent:'center',margin:'auto'}}
            value="start"
            control={<Checkbox style ={{
              color: "#00e676",
            }}/>}
            label ={
              <Typography variant="p" style={{ color: 'whitesmoke',fontSize:'10px' }}>
                By using this feature and importing content to Tosltoy (content), you acknowledge and agree that you may only import Content which you own 
                or otherwise have the right to import to Tolstoy. You are solely responsible for ensuring that your use of this functionality complies with applicable 
                laws, including, without limitation, privacy laws, and that you have any and all rights and consents necessary to make use of this functionality.
              </Typography>}
            labelPlacement="start"
          />
        </Grid>
    );
}

export default Tik;