import { Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

const TicketModal = ({ticketModal, setTicketModal, closeTicket}) => {
    
  const handleClose = async () => {
    setTicketModal(false);
  };

  return (
    <div>
      <Modal open={ticketModal} onClose={handleClose}>
        <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 100%, rgba(10, 14, 35, 0.49) 76.65%)",
            outline: "none",
            padding: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            minHeight: "30vh",
            minWidth: "50vw",
            boxShadow: "rgb(54, 68, 97) 0px 0px 10px 20px 2px",
            borderRadius: '5px'
          }}>
            <Close color='white' sx={{position: 'absolute', right: 20, top: 20, cursor: 'pointer'}} onClick={handleClose} />
            <VuiBox sx={{display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
              <VuiBox sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid skyblue'}}>
                <h3 style={{color: 'white'}}>{ticketModal.issue}</h3>
                <VuiTypography color='white' variant='h6'>
                  {ticketModal.state === 'active' ?
                    <VuiBox sx={{display: 'flex', alignItems: 'center', fontSize: '12px', color: 'green'}}><VuiBox sx={{borderRadius: '100%', background: 'green', width: '10px', height: '10px'}} />&nbsp; Active</VuiBox> : ticketModal.state === 'pending' ? <VuiBox sx={{display: 'flex', alignItems: 'center', fontSize: '12px', color: 'red'}}><VuiBox sx={{borderRadius: '100%', background: 'red', width: '10px', height: '10px'}} />&nbsp; Pending</VuiBox> : <VuiBox sx={{display: 'flex', alignItems: 'center', fontSize: '12px', color: 'gray'}}><VuiBox sx={{borderRadius: '100%', background: 'gray', width: '10px', height: '10px'}} />&nbsp; Completed</VuiBox>
                  }
                </VuiTypography>
                <h6 style={{color: 'white'}}>{ticketModal.created_at}</h6>
              </VuiBox>
              <VuiTypography variant='h6' sx={{color: 'white', marginTop: '10px'}}>
                {ticketModal.description}
              </VuiTypography>
            </VuiBox>
            <Button variant='contained' sx={{position: 'absolute', bottom: 15, right: 15, color: 'whitesmoke'}} onClick={closeTicket}>Close ticket</Button>
        </div> 
      </Modal>
    </div>
  )
}

export default TicketModal;