/** 
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

/* eslint-disable no-unused-vars */
import Default from "layouts/dashboards/default";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import AllVideos from "layouts/pages/all-videos";
import Reports from "layouts/pages/users/reports";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import Alerts from "layouts/pages/alerts";
import PricingPage from "layouts/pages/pricing-page";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import ConversationTables from "layouts/pages/conversation-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import SignUpCover from "layouts/authentication/sign-up/cover";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import Plugins from "layouts/pages/plugins";
import FirstBasic from "layouts/authentication/First/basic";
import SecondBasic from "layouts/authentication/Second/basic";
import Feed from "layouts/pages/feed";
import Create from "layouts/pages/createvideo";
import BotAi from "layouts/pages/bot-ai";

import {
  MdVideoLibrary,
  MdMoveToInbox,
  MdHistory,
  MdNotifications,
  MdSettings,
  MdExtension,
  MdHelp,
  MdAccountCircle,
  MdDashboard,
  MdAddBox,
} from "react-icons/md";
import { IoStatsChart } from "react-icons/io5";
import { IoDiamondSharp } from "react-icons/io5";
import { AiOutlineApi } from 'react-icons/ai';
import {AiOutlineHistory} from "react-icons/ai"
import { BiBot } from "react-icons/bi"
import { MdOutlineVideoSettings } from "react-icons/md";
import { MdOutlineChat } from "react-icons/md";
import { IoReceiptSharp } from "react-icons/io5"
import { FaMagic } from "react-icons/fa";
import { MdOutlineVideoStable } from "react-icons/md";

// React icons
import { IoDocument } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoDocuments } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import Setting from "layouts/pages/account/settings/indexx";
import { HiTemplate } from "react-icons/hi";
import Catalogue from "layouts/pages/catalogue";
import Product from "layouts/pages/catalogue/product";
import Profile from "./examples/Navbars/DashboardNavbar/profile";
import Wishlist from "./examples/Navbars/DashboardNavbar/Wishlist";
import Chatbot from "layouts/pages/chatbot";
import { SiChatbot } from "react-icons/si";
import Premium from "layouts/pages/account/settings/components/Plans/plans";
import Success from "layouts/pages/account/settings/components/Plans/success";
import Personalization from "layouts/pages/personalization";
import PersonalizationOriginal from "layouts/pages/personalization-original/personalization";
import VideoEditor from "layouts/pages/video-editor";
import ApiDetails from "layouts/pages/api-details/ApiDetails";
import FirstStep from "layouts/pages/catalogue/components/FirstStep";
import StreamingLogs from "layouts/pages/streamingLogs";
import SingleDetail from "layouts/pages/streamingLogs/singleDetail";
import AiWidget from "layouts/pages/ai-widget";
import SaasTutorial from "layouts/pages/Saas-tutorial";
import MainSaas from "layouts/pages/Saas-tutorial/main";

// // const routes = [
// //   {
// //     type: "link",
// //     name: "Dashboard",
// //     key: "dashboard",
// //     route: "/dashboard",
// //     icon: <IoHome size="15px" color="inherit" />,
// //     component: Default,
// //     noCollapse: true,
// // collapse: false
// //   },
// //   {
// //     type: "link",
// //     name: "Tables",
// //     key: "tables",
// //     route: "/tables",
// //     icon: <IoStatsChart size="15px" color="inherit" />,
// //     component: Tables,
// //     noCollapse: true,
// collapse: false
// //   },
// //   {
// //     type: "link",
// //     name: "Billing",
// //     key: "billing",
// //     route: "/billing",
// //     icon: <BsCreditCardFill size="15px" color="inherit" />,
// //     component: Billing,
// //     noCollapse: true,
// collapse: false
// //   },
// //   {
// //     type: "link",
// //     name: "RTL",
// //     key: "rtl",
// //     route: "/rtl",
// //     icon: <IoBuild size="15px" color="inherit" />,
// //     component: RTL,
// //     noCollapse: true,
// collapse: false
// //   },
// //   { type: "title", title: "Account Pages", key: "account-pages" },
// //   {
// //     type: "link",
// //     name: "Profile",
// //     key: "profile",
// //     route: "/profile",
// //     icon: <BsFillPersonFill size="15px" color="inherit" />,
// //     component: Profile,
// //     noCollapse: true,
// collapse: false
// //   },
// //   {
// //     type: "link",
// //     name: "Sign In",
// //     key: "sign-in",
// //     route: "/authentication/sign-in",
// //     icon: <IoIosDocument size="15px" color="inherit" />,
// //     component: SignIn,
// //     noCollapse: true,
// collapse: false
// //   },
// //   {
// //     type: "link",
// //     name: "Sign Up",
// //     key: "sign-up",
// //     route: "/authentication/sign-up",
// //     icon: <IoRocketSharp size="15px" color="inherit" />,
// //     component: SignUp,
// //     noCollapse: true,
// collapse: false
// //   },
// // ];

const routes = [
  
  // {
  //   type: "link",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   route: "/dashboard",
  //   icon: <MdDashboard size="15px" color="inherit" />,
  //   component: Default,
  //   noCollapse: true,
  //   collapse: false,
  //   id: "dashboard",
  // },
  {
    type: "link",
    name: "Create Video",
    key: "create",
    route: "/create",
    icon: <MdAddBox size="15px" color="inherit" />,
    component: Create,
    noCollapse: true,
    collapse: false,
    id: "create"
  },
  {
    type: "link",
    name: "Video Library",
    key: "library",
    route: "/library",
    icon: <MdVideoLibrary size="15px" color="inherit" />,
    component: AllVideos,
    noCollapse: true,
    collapse: false,
    id: "library",
  },
  {
    type: "link",
    name: "Video Editor",
    key: "videoeditor",
    route: "/videoeditor",
    icon: <FaMagic size="15px" color="inherit" />,
    component: VideoEditor,
    noCollapse: true,
    collapse: false,
    id: "editor",
    iconNext: false,
  },
  {
    type: "link",
    name: "Video Personalization",
    key: "videopersonalization",
    route: "/videopersonalization",
    icon: <MdOutlineVideoSettings size="15px" color="inherit" />,
    component: PersonalizationOriginal,
    noCollapse: true,
    collapse: false,
    id: "personalization",
    iconNext: false,
  },
  // {
  //   type: "link",
  //   name: "SaaS Tutorial",
  //   key: "saastutorial",
  //   route: "/saastutorial",
  //   icon: <MdOutlineVideoStable size="15px" color="inherit" />,
  //   component: SaasTutorial,
  //   noCollapse: true,
  //   collapse: false,
  //   id: "saastutorial",
  //   iconNext: false,
  // },
  // {
  //   type: "",
  //   name: "saastutorial",
  //   key: "saastutorial",
  //   route: "/saastutorial/main",
  //   component: MainSaas,
  // },
 
  // {
  //   type: "link",
  //   name: "Chatbot",
  //   key: "chatbot",
  //   route: "/chatbot",
  //   icon: <SiChatbot size="15px" color="inherit" />,
  //   component: Chatbot,
  //   noCollapse: true,
  //   collapse: false,
  //   id: "chatbot",
  //   premium: true,
  // },
  // {
  //   type: "link",
  //   name: "Inbox",
  //   key: "inbox",
  //   route: "/inbox",
  //   icon: <MdMoveToInbox size="15px" color="inherit" />,
  //   component: ConversationTables,
  //   noCollapse: true,
  //   collapse: false,
  //   id: "inbox",
  // },
   {
    type: "link",
    name: "Bot AI",
    key: "botai",
    route: "/botai",
    icon: <BiBot size="15px" color="inherit" />,
    component: BotAi,
    noCollapse: true,
    collapse: false,
    id: "botai",
    iconNext: false,
  },
  {
    type: "link",
    name: "Usage",
    key: "usage",
    route: "/usage",
    icon: <AiOutlineHistory size="15px" color="inherit" />,
    component: Billing,
    noCollapse: true,
    collapse: false,
    id: "history",
  },
  // {
  //   type: "link",
  //   name: "AI Widget",
  //   key: "aiwidget",
  //   route: "/aiwidget",
  //   icon: <MdOutlineChat size="15px" color="inherit" />,
  //   component: AiWidget,
  //   noCollapse: true,
  //   collapse: false,
  //   id: "aiwidget",
  //   iconNext: false,
  // },
  // {
  //   type: "link",
  //   name: "Catalogue",
  //   key: "catalogue",
  //   route: "/catalogue",
  //   icon: <HiTemplate size="15px" color="inherit" />,
  //   component: Catalogue,
  //   noCollapse: true,
  //   collapse: false,
  //   id: "catalogue",
  //   iconNext: false,
  // },
  // { type: "title", title: "Account Pages", key: "account-pages" },
  // {
  //   type: "link",
  //   name: "Profile",
  //   key: "profile",
  //   route: "/settings?activePane=profile",
  //   icon: <MdAccountCircle size="15px" color="inherit" />,
  //   id: 'profile',
    // collapse: [
    //   {
    //     name: "My Profile",
    //     key: "profile",
    //     pathname: "/settings?activePane=profile",
    //     // href: "/settings?activePane=profile",
    //     route: "/settings?activePane=profile",
    //     stateChanger: Settings.changeSettingsPane,
    //   },
      // {
      //   name: "My Workspace",
      //   key: "workspace",
      //   pathname: "/settings?activePane=workspace",
      //   // href: "/settings?activePane=workspace",
      //   route: "/settings?activePane=workspace",
      //   stateChanger: Settings.changeSettingsPane,
      // },
      // {
      //   name: "Team",
      //   key: "team",
      //   pathname: "/settings?activePane=team",
      //   // href: "/settings?activePane=team",
      //   route: "/settings?activePane=team",
      //   stateChanger: Settings.changeSettingsPane,
      // },
      // {
      //   name: "Logout",
      //   key: "logout",
      //   route: "/logout",
      //   component: Default,
      //   // route: "/logout",
      // },
    //],
  // },
  // {
  //   type: "link",
  //   name: "Notifications",
  //   key: "notifications",
  //   route: "/notifications",
  //   icon: <MdNotifications size="15px" color="inherit" />,
  //   component: Alerts,
  //   noCollapse: true,
  //   collapse: false,
  //   id: 'notifications',
  // },
  // {
  //   type: "link",
  //   name: "Plugins",
  //   key: "plugins",
  //   route: "/plugins",
  //   icon: <MdExtension size="15px" color="inherit" />,
  //   component: Plugins,
  //   noCollapse: true,
  //   collapse: false,
  //   id: "plugins",
  // },
  {
    type: "link",
    name: "Upgrade",
    key: "upgrade",
    route: "/upgrade",
    icon: <IoDiamondSharp size="15px" color="inherit" />,
    component: Premium,
    noCollapse: true,
    collapse: false,
    id: "plans",
    iconNext: false,
  },
  {
    type: "link",
    name: "API",
    key: "apidetails",
    route: "/apidetails",
    icon: <AiOutlineApi size="15px" color="inherit" />,
    component: ApiDetails,
    noCollapse: true,
    collapse: false,
    id: "api",
    iconNext: false,
  },
  // {
  //   type: "link",
  //   name: "Documentation",
  //   key: "documentation",
  //   route: "/documentation",
  //   icon: <MdHelp size="15px" color="inherit" />,
  //   component: SignInCover,
  //   noCollapse: true,
  //   collapse: false,
  //   id: "documentation"
  // },
  // {
  //   type: "link",
  //   name: "Settings",
  //   key: "settings",
  //   route: "/settings",
  //   icon: <MdSettings size="15px" color="inherit" />,
  //   component: Setting,
  //   noCollapse: true,
  //   collapse: false,
  //   id: "settings",
  // },
  
  {
    type: "",
    name: "Dashboards",
    key: "dashboards",
    icon: <IoHome size="15px" color="inherit" />,
    collapse: [
      {
        name: "Default",
        key: "default",
        route: "/dashboards/default",
        component: Default,
      },
      { name: "CRM", key: "crm", route: "/dashboards/crm", component: CRM },
    ],
  },


  { type: "", title: "Pages", key: "title-pages" },
  {
    type: "",
    name: "Pages",
    key: "pages",
    icon: <IoDocument size="15px" color="inherit" />,
    collapse: [
      {
        name: "Profile",
        key: "profile",
        collapse: [
          {
            name: "Profile Overview",
            key: "profile-overview",
            route: "/pages/profile/profile-overview",
            component: ProfileOverview,
          },
          {
            name: "Teams",
            key: "teams",
            route: "/pages/profile/teams",
            component: Teams,
          },
          {
            name: "All Projects",
            key: "all-projects",
            route: "/pages/profile/all-projects",
            component: AllProjects,
          },
        ],
      },
      {
        name: "Users",
        key: "users",
        collapse: [
          {
            name: "Reports",
            key: "reports",
            route: "/pages/users/reports",
            component: Reports,
          },
          {
            name: "New User",
            key: "new-user",
            route: "/pages/users/new-user",
            component: NewUser,
          },
        ],
      },
      {
        name: "Account",
        key: "account",
        collapse: [
          {
            name: "Settings",
            key: "settings",
            route: "/pages/account/settings",
            component: Settings,
          },
          {
            name: "Billing",
            key: "billing",
            route: "/pages/account/billing",
            component: Billing,
          },
          {
            name: "Invoice",
            key: "invoice",
            route: "/pages/account/invoice",
            component: Invoice,
          },
        ],
      },
      {
        name: "Projects",
        key: "projects",
        collapse: [
          {
            name: "General",
            key: "general",
            route: "/pages/projects/general",
            component: General,
          },
          {
            name: "Timeline",
            key: "timeline",
            route: "/pages/projects/timeline",
            component: Timeline,
          },
        ],
      },
      {
        name: "Pricing Page",
        key: "pricing-page",
        route: "/pages/pricing-page",
        component: PricingPage,
      },
      { name: "RTL", key: "rtl", route: "/pages/rtl", component: RTL },
      {
        name: "Widgets",
        key: "widgets",
        route: "/pages/widgets",
        component: Widgets,
      },
      {
        name: "Charts",
        key: "charts",
        route: "/pages/charts",
        component: Charts,
      },
      {
        name: "Alerts",
        key: "alerts",
        route: "/pages/alerts",
        component: Alerts,
      },
    ],
  },
  {
    type: "",
    name: "Applications",
    key: "applications",
    icon: <IoBuild size="15px" color="inherit" />,
    collapse: [
      {
        name: "Kanban",
        key: "kanban",
        route: "/applications/kanban",
        component: Kanban,
      },
      {
        name: "Wizard",
        key: "wizard",
        route: "/applications/wizard",
        component: Wizard,
      },
      {
        name: "Data Tables",
        key: "data-tables",
        route: "/applications/data-tables",
        component: DataTables,
      },
      {
        name: "Calendar",
        key: "calendar",
        route: "/applications/calendar",
        component: Calendar,
      },
    ],
  },
  {
    type: "",
    name: "Ecommerce",
    key: "ecommerce",
    icon: <FaShoppingCart size="15px" color="inherit" />,
    collapse: [
      {
        name: "Products",
        key: "products",
        collapse: [
          {
            name: "New Product",
            key: "new-product",
            route: "/ecommerce/products/new-product",
            component: NewProduct,
          },
          {
            name: "Edit Product",
            key: "edit-product",
            route: "/ecommerce/products/edit-product",
            component: EditProduct,
          },
          {
            name: "Product Page",
            key: "product-page",
            route: "/ecommerce/products/product-page",
            component: ProductPage,
          },
        ],
      },
      {
        name: "Orders",
        key: "orders",
        collapse: [
          {
            name: "Order List",
            key: "order-list",
            route: "/ecommerce/orders/order-list",
            component: OrderList,
          },
          {
            name: "Order Details",
            key: "order-details",
            route: "/ecommerce/orders/order-details",
            component: OrderDetails,
          },
        ],
      },
    ],
  },
  {
    type: "",
    name: "Authentication",
    key: "authentication",
    icon: <IoDocuments size="15px" color="inherit" />,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: SignInBasic,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in/cover",
            component: SignInCover,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-in/illustration",
            component: SignInIllustration,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-up/basic",
            component: SignUpBasic,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up/cover",
            component: SignUpCover,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-up/illustration",
            component: SignUpIllustration,
          },
        ],
      },
      {
        name: "First",
        key: "First",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/First/basic",
            component: FirstBasic,
            noCollapse: true,
            collapse: false,
          },
        ],
      },
      {
        name: "Second",
        key: "Second",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/Second/basic",
            component: SecondBasic,
          },
        ],
      },
    ],
  },
  {
    type: "",
    name: "Feed",
    key: "feed",
    route: "/feed",
    icon: <MdVideoLibrary size="15px" color="inherit" />,
    component: Feed,
    noCollapse: true,
    collapse: false,
    id: "feed"
  },
  {
    type: "",
    name: "Catalogue",
    key: "catalogue",
    route: "/catalogue/products",
    component: FirstStep,
  },
  {
    type: "",
    name: "Catalogue",
    key: "catalogue",
    route: "/catalogue/products/singleProduct",
    component: Product,
  },
  {
    type: "",
    name: "Streaming ",
    key: "streaming ",
    route: "/streaminglogs",
    component: StreamingLogs,
  },
  {
    type: "",
    name: "Streaming ",
    key: "streaming ",
    route: "/streaminglogs/:id",
    component: SingleDetail,
  },
  {
    type: "",
    name: "Profile",
    key: "profile",
    route: "/profile",
    component: Profile,
  },
  {
    type: "",
    name: "Wishlist",
    key: "wishlist",
    route: "/wishlist",
    component: Wishlist,
  },
  {
    type: "",
    name: "success",
    key: "success",
    route: "/success",
    component: Success
  },
  {
    type: "",
    name: "chatbot-flashaid",
    key: "chatbot-flashaid",
    route: "/chatbot-flashaid",
    component: BotAi
  },
  {
    type: "",
    name: "genpact",
    key: "genpact",
    route: "/genpact",
    component: BotAi
  },
  {
    type: "",
    name: "notifications",
    key: "notifications",
    route: "/notifications",
    component: Alerts
  },
  {
    type: "",
    name: "aiwidget",
    key: "aiwidget",
    route: "/aiwidget",
    component: AiWidget
  },
  {
    type: "",
    name: "personalization-original",
    key: "personalization-original",
    route: "/personalization-original",
    component: Personalization
  },
  {
    type: "",
    name: "saastutorial",
    key: "saastutorial",
    route: "/saastutorial/main",
    component: MainSaas,
  },
];

export default routes;