import React, { useState } from 'react';
import { Button, Modal} from '@mui/material';
import './index.css';
import { TbHandRock } from "react-icons/tb";

const ModalComponent = ({onClick, name, setName, chatBotList, setChatBotList}) => {
  const [open, setOpen] = useState(true);
  // useEffect(() => {
  //   console.log(chatBotList);
  // })
  //eslint-disable-next-line
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClick();
  };

  const handleNameChange = (event) => {
    const newName = event.target.value;

    // Update the name state
    setName(newName);

    // Update the chatBotList state
    setChatBotList((prevChatBotList) => {
      const firstObjectKey = Object.keys(prevChatBotList)[0];
      const firstObjectValue = prevChatBotList[firstObjectKey];

      const updatedChatBotList = {
        [newName]: firstObjectValue,
        ...prevChatBotList,
      };

      // Remove the old key if the name has changed
      if (newName !== firstObjectKey) {
        delete updatedChatBotList[firstObjectKey];
      }

      return updatedChatBotList;
    });
  };

  const handleCreateChatbot = () => {
    console.log(`Creating video chatbot with name: ${name}`);
    handleClose();
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className='modal' style={{boxShadow: "rgb(54, 68, 97) 0px 0px 30px 2px"}}>
          <div className='heading'>
            <h2 style={{padding: "5px"}}>Ok, Let's get started</h2>
            <TbHandRock /> 
          </div>
          <input placeholder='Give your chatbot name' onChange={handleNameChange} fullWidth className='textfield' />
          <Button 
            variant='contained' 
            sx={{
              color: "whitesmoke", 
              background: "#344767",
              marginTop: "5px",
              ":hover": {
                bgcolor: "#344767",
                color: "white"
              }
            }} 
            onClick={handleCreateChatbot} 
            disabled={!name} 
          >
            Create Video Chatbot
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalComponent;
