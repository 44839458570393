/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { Link, NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import SingleDetail from "../singleDetail";

function DataTableBodyCell({ noBorder, align, children }) {
  //eslint-disable-next-line
  const { light, grey } = colors;
  const { size } = typography;
  const { borderWidth } = borders;

  return (
    <VuiBox
      component="td"
      textAlign={align}
      fontSize={size.sm}
      borderBottom={noBorder ? "none" : `${borderWidth[1]} solid ${grey[700]}`}
      py={1.5}
      px={3}
    >
      <VuiBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle"}}
      >
        <Link to={{ 
          pathname: `/streaminglogs/${children.props.cell.row.original.id}`, 
          state: {
            id: children.props.cell.row.original.id,
            stream_id: children.props.cell.row.original.stream_id,
            session_start: children.props.cell.row.original.session_start,
            is_active: children.props.cell.row.original.is_active,
            source_url: children.props.cell.row.original.source_url.props.src,
            session_converse: children.props.cell.row.original.session_converse,
          } 
        }}>
          <p style={{color: 'lightgray'}}>{children}</p>
        </Link>
      </VuiBox>
    </VuiBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
