/*eslint-disable*/

import AA from "assets/images/AA.jpeg";
import BB from "assets/images/BB.jpeg";
import CC from "assets/images/CC.jpeg";
import DD from "assets/images/DD.jpeg";
import EE from "assets/images/EE.jpeg";
import FF from "assets/images/FF.jpeg";
import GG from "assets/images/GG.jpeg";
import HH from "assets/images/HH.jpeg";
import JJ from "assets/images/JJ.jpeg";
import KK from "assets/images/KK.jpeg";
import LL from "assets/images/LL.jpeg";

export default [
  {
    image: AA,
    name: "Abbie W",
    color: "info",
  },
  {
    image: BB,
    name: "Abbie W",
    color: "info",
  },
  {
    image: CC,
    name: "Abbie W",
    color: "info",
  },
  {
    image: DD,
    name: "Abbie W",
    color: "info",
  },
  {
    image: EE,
    name: "Abbie W",
    color: "info",
  },
  {
    image: FF,
    name: "Abbie W",
    color: "info",
  },
  {
    image: GG,
    name: "Abbie W",
    color: "info",
  },
  {
    image: HH,
    name: "Abbie W",
    color: "info",
  },
  {
    image: JJ,
    name: "Abbie W",
    color: "info",
  },
  {
    image: KK,
    name: "Abbie W",
    color: "info",
  },
  {
    image: LL,
    name: "Abbie W",
    color: "info",
  },
];