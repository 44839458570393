import VuiBox from 'components/VuiBox';
import VuiInput from 'components/VuiInput';
import VuiSelect from 'components/VuiSelect';
import VuiTypography from 'components/VuiTypography';
import React, { useState } from 'react'

const Avatar = ({presenters, setWidgetDetails, widgetDetails, voices, selectedAgent}) => {
  const [selectedPresenterId, setSelectedPresenterId] = useState(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

  const handlePlayPauseClick = (voiceId, isPlaying) => {
    if (isPlaying) {
      setCurrentlyPlaying(voiceId);
    } else {
      setCurrentlyPlaying(null);
    }
  };

  const handleAvatarClick = (avatar) => {
    setWidgetDetails((prevDetails) => ({
      ...prevDetails,
      selectedAvatar: avatar.image_url,
      selectedAvatarPreview: avatar.preview_url,
      presenterId: avatar.presenter_id,
    }));
    setSelectedPresenterId(null);
  }

  const uniquePresenters = {};

  presenters.forEach(presenter => {
      const presenterId = presenter.presenter_id.split('-')[0];
      if (!uniquePresenters[presenterId]) {
          uniquePresenters[presenterId] = {
              presenter_id: presenterId,
              preview_url: presenter.preview_url,
              image_url: presenter.image_url
          };
      }
  });

  const uniquePresentersArray = Object.values(uniquePresenters);

  const handleDetails = (e, section) => {
    const domainArray = e.target.value.split(/\s*,\s*/);

    setWidgetDetails((prevDetails) => ({
      ...prevDetails,
      [section]: section === 'domains' ? domainArray : e.target.value,
    }));
  }

  const handleAvailableVoiceSelection = (selectedOption) => {
    setWidgetDetails((prevDetails) => ({
      ...prevDetails,
      selectedVoice: selectedOption.value,
    }));
  }

  // const handleModelSelection = (selectedOption) => {
  //   setWidgetDetails((prevDetails) => ({
  //     ...prevDetails,
  //     selectedLanguage: selectedOption.value,
  //   }));
  // }

  return (
    <VuiBox sx={{display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
      {selectedAgent && <VuiTypography color='error' textAlign='center'>Details cannot be updated</VuiTypography>}
      {selectedAgent && <VuiTypography color='error' textAlign='center' variant='h6'>It is view only</VuiTypography>}
      <VuiBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
        <VuiBox sx={{display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
          <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
            <VuiTypography color={selectedAgent ? "gray" : 'white'}>Name your Bot AI avatar</VuiTypography>
            <VuiInput
              placeholder="name your avatar"
              onChange={(e) => handleDetails(e, 'avatarName')}
              value={widgetDetails.avatarName}
              // disabled={textLoading || availableVoiceLoading}
              // onBlur={handleAvailableVoiceBlur}
              // error={availableVoiceError}
              sx={{marginTop:'10px'}}
              disabled={selectedAgent}
            />
          </VuiBox>
          <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', marginTop: '30px'}}>
            <VuiTypography color={selectedAgent ? "gray" : 'white'}>What is your company name ?</VuiTypography>
            <VuiInput
              placeholder="enter your company name"
              onChange={(e) => handleDetails(e, 'companyName')}
              value={widgetDetails.companyName}
              // disabled={textLoading || availableVoiceLoading}
              // onBlur={handleAvailableVoiceBlur}
              // error={availableVoiceError}
              sx={{marginTop:'10px'}}
              disabled={selectedAgent}
            />
          </VuiBox>
        </VuiBox>
        <VuiBox sx={{display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
          <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
            <VuiTypography color={selectedAgent ? "gray" : 'white'}>Write your welcome message</VuiTypography>
            <VuiInput
              placeholder="write your welcome message here .."
              onChange={(e) => handleDetails(e, 'wMessage')}
              value={widgetDetails.wMessage}
              // disabled={textLoading || availableVoiceLoading}
              // onBlur={handleAvailableVoiceBlur}
              // error={availableVoiceError}
              sx={{marginTop:'10px'}}
              disabled={selectedAgent}
            />
          </VuiBox>
          <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center', marginTop: '30px'}}>
            <VuiTypography color={selectedAgent ? "gray" : 'white'} sx={{marginBottom:'10px'}}>Available Voices</VuiTypography>
            <VuiSelect
              placeholder="None"
              options={voices}
              onChange={handleAvailableVoiceSelection}
              disabled={selectedAgent}
              enableAudio={true}
              currentlyPlaying={currentlyPlaying}
              onPlayPauseClick={handlePlayPauseClick}
            />
          </VuiBox>
          {/* <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center', marginTop: '30px'}}>
            <VuiTypography color='white'>Select language</VuiTypography>
            <VuiSelect
              placeholder = "None"
              options={[
                { value: "eleven_monolingual_v1", label: "English" },
                { value: "eleven_multilingual_v1", label: "German" },
                { value: "eleven_multilingual_v1", label: "Polish" },
                { value: "eleven_multilingual_v1", label: "Spanish" },
                { value: "eleven_multilingual_v1", label: "Italian" },
                { value: "eleven_multilingual_v1", label: "French" },
                { value: "eleven_multilingual_v1", label: "Portugeese" },
                { value: "eleven_multilingual_v1", label: "Hindi" },
              ]}
              onChange={handleModelSelection}
            />
          </VuiBox> */}
        </VuiBox>
      </VuiBox>
      <VuiTypography color={selectedAgent ? "gray" : 'white'} textAlign='center' variant='h4' mt='30px'>Choose your widget AI avatar</VuiTypography>
      <VuiBox sx={{display: 'flex', flexWrap: 'wrap', maxHeight: '380px', overflowY: 'auto', margin: '10px auto', alignItems: 'center', justifyContent: 'center', pointerEvents: selectedAgent &&"none", opacity: selectedAgent && 0.5, cursor: selectedAgent && "not-allowed"}}>
        {uniquePresentersArray.map((avatar, index) => (
          <img
            key={index}
            src={avatar.image_url}
            alt='avatar'
            style={{
              borderRadius: "100%",
              width: "67px",
              height: "67px",
              background: "rgb(241, 242, 246)",
              objectFit: "cover",
              margin: '3px',
              cursor: 'pointer',
              opacity: selectedPresenterId && selectedPresenterId.split('-')[0] !== avatar.presenter_id ? 0.3 : 1,
              transition: 'opacity 0.3s',
            }}
            onClick={()=>setSelectedPresenterId(avatar.presenter_id.split('-')[0])}
            onMouseOver={(e) => {
              e.target.style.border = "3px solid rgb(47, 57, 156)";
            }}
            onMouseOut={(e) => {
              e.target.style.border = "none";
            }}
          />
        ))}
        {selectedPresenterId && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexWrap: 'wrap',
          background: 'rgba(255, 255, 255, 0.9)',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '8px',
          zIndex: 10,
        }}>
          {presenters.filter(presenter => presenter.presenter_id.startsWith(selectedPresenterId)).map((presenter, index) => (
            <img
              key={index}
              src={presenter.image_url}
              alt='related avatar'
              style={{
                borderRadius: "100%",
                width: "67px",
                height: "67px",
                background: "rgb(241, 242, 246)",
                objectFit: "cover",
                margin: '3px',
                cursor: 'pointer',
              }}
              onClick={()=>handleAvatarClick(presenter)}
              onMouseOver={(e) => {
                e.currentTarget.style.border = "3px solid rgb(47, 57, 156)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.border = "none";
              }}
            />
          ))}
        </div>
      )}
      </VuiBox>
    </VuiBox>
  )
}

export default Avatar