import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import React from 'react';
import DataTable from "examples/Tables/DataTable";
import tableData from "../tableData";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

function FirstStep() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox lineHeight={1}>
        <VuiTypography variant="h5" fontWeight="medium" color="white">
          Video Products
        </VuiTypography>
        <DataTable table={tableData} />
      </VuiBox>
    </DashboardLayout>
  )
}

export default FirstStep;