/*eslint-disable no-unused-vars */

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VideoThumbnail from 'react-video-thumbnail'; 
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  FiCard,
  FiCardActionArea,
  FiCardActions,
  FiCardContent,
  FiCardMedia
} from "./FullImageCard";

const styles = {
  paperContainer: {
      backgroundImage: `url(${Image})`
  }
};

// Custom styles for ComplexProjectCard
function VideoThumbnailCard(props) {
  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
        maxHeight: "100%",
        minHeight: "30vh",
        padding: "0",
        flexDirection: "column-reverse",
        cursor: 'pointer'
      }}
      style={{ 
        backgroundImage: `url(${props.thumbnail})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      onClick={props.onClick}
    >
      <VuiBox sx={{ 
        display: "flex", 
        flexDirection: "column", 
        height: "100%", 
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) )`, 
        padding: '0.2rem', borderRadius: "0px 0px 10px 10px"}} 
      >
        <Grid container spacing={1} direction="row">
          <Grid item xs={12}>
            <VuiBox display="flex" alignItems="center">
              <VuiBox ml={2} lineHeight={0}>
                <VuiBox mb={1} lineHeight={0}>
                  <VuiTypography
                    variant="h6"
                    color="white"
                    textTransform="capitalize"
                    fontWeight="medium"
                    marginTop="0.5rem"
                  >
                    {props.title}
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
            </VuiBox>
          </Grid>
          <Grid item xs={1}>
            {props.dropdown && (
              <VuiTypography
                color="secondary"
                onClick={props.dropdown.action}
                sx={{
                  ml: "auto",
                  alignSelf: "flex-start",
                  py: 1.25,
                }}
              >
                <Icon fontSize="default" sx={{ cursor: "pointer", color: "#fff" }}>
                  more_vert
                </Icon>
              </VuiTypography>
            )}
            {props.dropdown.menu}
          </Grid>
        </Grid>
      </VuiBox>
    </Card>
  );
}

// Setting default values for the props of ComplexProjectCard
VideoThumbnailCard.defaultProps = {
  color: "dark",
  dateTime: "",
  members: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
VideoThumbnailCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default VideoThumbnailCard;
