import { Card, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProductImages from "layouts/ecommerce/products/product-page/components/ProductImages";
import ProductInfo from "layouts/ecommerce/products/product-page/components/ProductInfo";
import React from 'react'

function Product() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={1}>
        <Card
          sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
            overflow: "visible",
            background: linearGradient(
              gradients.cardDark.main,
              gradients.cardDark.state,
              gradients.cardDark.deg
            ),
          })}
        >
          <VuiBox p={3}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6} xl={5}>
                <ProductImages />
              </Grid>
              <Grid item xs={12} lg={6} sx={{ mx: "auto" }}>
                <ProductInfo />
              </Grid>
            </Grid>
          </VuiBox>
        </Card>
      </VuiBox>
    </DashboardLayout>
  )
}

export default Product;