/*eslint-disable no-unused-vars */

// icons
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import BASE_URL from "context/api";
import { useSnackbar } from "notistack";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// Images

// Vision UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Vision UI Dashboard React example components
import { useCallback, useEffect, useRef, useState } from "react";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { AutoAwesome, Person, Videocam } from "@mui/icons-material";
import { IoMdClipboard, IoMdColorWand, IoMdPerson } from "react-icons/io";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormGroup,
  Input,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import VuiInput from "components/VuiInput";
import VuiAvatar from "components/VuiAvatar";

// Vision UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

import { AiOutlinePlus } from "react-icons/ai";

// Vision UI Dashboard PRO React data variables
import storiesData from "layouts/pages/profile/teams/components/Stories/data/storiesData";
import AiAvatar from "../profile/teams/components/Stories/data/AiAvatar";
import Sidediv from "./Sidediv";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { IoPencilOutline } from "react-icons/io5";
import VuiSelect from "components/VuiSelect";
import axios from "axios";
import { setLoading, setPostLoading, useVisionUIController, setOpenPricingModal, setSelectedImageName, setPresenterId, setImage } from "context";
import ConfirmModal from "./ConfirmModal";
import { useDropzone } from "react-dropzone";
import Zoom from '@mui/material/Zoom';
import './index.css';
import LoadingModal from "./loadingModal";
import CheckNote from './clipboard_6289203.png';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import UploadIcon from '@mui/icons-material/Upload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import html2canvas from 'html2canvas';
import EditIcon from '@mui/icons-material/Edit';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import Tour from 'reactour';
import { createSteps } from "createSteps";
import ClearIcon from '@mui/icons-material/Clear';
import LockIcon from '@mui/icons-material/Lock';
import AiMan from './ai-generated-8578267_640.jpg';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import mixpanel from "mixpanel-browser";
import { setFetchLogs } from "context";

function Create() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [bgColor, setBgColor] = useState('#ffffff');
  const [selectedOrientation, setSelectedOrientation] = useState(null);
  const [text, setText] = useState("");
  const [textEmojiError, setTextEmojiError] = useState('');
  const [availableVoiceText, setAvailableVoiceText] = useState("");
  const [cloneText, setCloneText] = useState("");
  const [cloneName, setCloneName] = useState("");
  const [style, setStyle] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [recordedFile, setRecordedFile] = useState(null);
  const [type, setType] = useState('text');
  const [voiceId, setVoiceId] = useState("en-US-JacobNeural");
  const [avatars, setAvatars] = useState(storiesData);
  // const [preAvatars, setPreAvatars] = useState([]);
  const [uniquePresentersArray, setUniquePresentersArray] = useState([]);
  const [selectedPresenterId, setSelectedPresenterId] = useState(null);
  const [presenterDetails, setPresenterDetails] = useState();
  const [uploadedAvatars, setUploadedAvatars] = useState([]);
  const [videoName, setVideoName] = useState("");
  const [videoNameError, setVideoNameError] = useState('');
  const [touched, setTouched] = useState(false);
  const [scriptTouched, setScriptTouched] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [chatgptModal, setChatgptModal] = useState(false);
  const [credits, setCredits] = useState(null);
  const [creditsloader, setCreditsloader] = useState(false);
  const [ask, setAsk] = useState(false);
  const [controller, dispatch] = useVisionUIController();
  const { userDetails, loading, selectedImageName, presenterId, image, preAvatars} = controller;
  const { user_id, access_token, plan } = userDetails;
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [customBackground, setCustomBackground] = useState(null);
  const [audioResponse, setAudioResponse] = useState('');
  const cloneScriptCharacterCount = cloneText && cloneText.length;
  const voiceScriptCharacterCount = availableVoiceText && availableVoiceText.length;
  const scriptCharacterCount = text && text.length;
  const [videoLength, setVideoLength] = useState(0);
  const [creditsBurn, setCreditsBurn] = useState(0);
  const [selectAudio, setSelectAudio] = useState(false);
  const [selectedAudioFiles, setSelectedAudioFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [snapshot, setSnapshot] = useState(null);
  const componentRef = useRef(null);
  const [mLeft, setMLeft] = useState('27%');
  const [activeImage, setActiveImage] = useState(null);
  const [highlightedImage, setHighlightedImage] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tourOpen, setTourOpen] = useState(false); 

  const handleTourToggle = () => {
    setTourOpen(!tourOpen);
  };

  const handleVideoLength = () => {
    const inputLength = scriptCharacterCount;
    const charactersPerVideoLength = 15;
    const secondsPerVideoLength = 1;
    const secondsPerCredit = 15;

    const videoLen = Math.ceil(inputLength / charactersPerVideoLength) * secondsPerVideoLength;
    type === 'text' && setVideoLength(videoLen);

    const credits = Math.ceil(videoLen / secondsPerCredit);
    type === 'text' && setCreditsBurn(credits);
  }

  const uniquePresenters = {};

  useEffect(() => {
    setPresenterDetails({
      driver_id: "vmcS2q23Kk",
      gender: "male",
      image_url: "https://quickvideo.blob.core.windows.net/quickvideo/presenters/image/139315.png",
      presenter_id: "rian-pbMoTzs7an",
      preview_url: "https://quickvideo.blob.core.windows.net/quickvideo/presenters/preview/50bafc.mp4",
      thumbnail_url: "https://quickvideo.blob.core.windows.net/quickvideo/presenters/thumbnail/cf8ead.png"
    });
  }, []);

  const planLimits = {
    Trial: 7,
    Lite: Infinity,
    Pro: Infinity,
    Business: Infinity,
    Enterprise: Infinity
  };

  const getUnlockedIndexLimit = (plan) => {
    switch (plan) {
      case 'Trial':
        return 1;
      case 'Lite':
        return 1;
      case 'Pro':
        return 2;
      case 'Business':
        return Infinity; 
      case 'Enterprise':
        return Infinity;
      default:
        return 0;
    }
  };

  useEffect(() => {
    preAvatars && preAvatars.forEach(presenter => {
      const presenterId = presenter.presenter_id.split('-')[0];
      if (!uniquePresenters[presenterId]) {
          uniquePresenters[presenterId] = {
              presenter_id: presenterId,
              preview_url: presenter.preview_url,
              image_url: presenter.image_url
          };
      }
    });
  
    setUniquePresentersArray(Object.values(uniquePresenters));
    setAvatars((preAvatar)=> [...Object.values(uniquePresenters), ...preAvatar]);
  }, [preAvatars]);

  // useEffect(() => {
  //   axios.get(`${BASE_URL}/presenters`, {
  //     params: {
  //       user_id
  //     },
  //   })
  //   .then(response => {
  //     const newAvatars = response.data.presenters;
  //     setPreAvatars(newAvatars);
  //     newAvatars.forEach(presenter => {
  //       const presenterId = presenter.presenter_id.split('-')[0];
  //       if (!uniquePresenters[presenterId]) {
  //           uniquePresenters[presenterId] = {
  //               presenter_id: presenterId,
  //               preview_url: presenter.preview_url,
  //               image_url: presenter.image_url
  //           };
  //       }
  //     });
    
  //     const avatarLimit = getAvatarLimit(plan);
  //     setUniquePresentersArray(Object.values(uniquePresenters));
  //     setAvatars((preAvatar)=> [...Object.values(uniquePresenters), ...preAvatar]);
  //   })
  //   .catch(error => console.error(error));
  //   }, [user_id]);

  const removePresenterModel = () => {
    setSelectedPresenterId(null);
  }

  const callUploadImage = async () => {
    // setAvatarLoading(true);
    try {
      const res = await axios.get(`${BASE_URL}/generate_image`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id
        },
      });
      setAvatarLoading(false);
      const uploadedAvatars = res.data.uploaded_avatars;

      if (uploadedAvatars.length > 0) {
        const imageFiles = uploadedAvatars.map((file) => ({
          id: generateUniqueId(),
          imageUrl: file,
        }));

        setUploadedAvatars((prevFiles) => [...imageFiles, ...prevFiles]);
      } else {
        setUploadedAvatars([]);
      }
    } catch (error) {
      setAvatarLoading(false);
      enqueueSnackbar(error.message || 'Something went wrong', {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } 
  }

  useEffect(() => {
    callUploadImage();
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleVideoName = (e) => {
    setVideoName(e.target.value);
    // const nameRegex = /^[a-zA-Z0-9\s]*$/;
    // if (!nameRegex.test(e.target.value)) {
    //   setVideoNameError("Special characters are not allowed for video file name");
    // } else {
    //   setVideoNameError("");
    // }
  }

  const handleDiscard = () => {
    setText("");
    setVideoName("");
    setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/D.png');
    setStyle(null);
    setSelectedOrientation(null);
    setBgColor('#ffffff');
    setSelectedImageName(dispatch, 0);
    setSelectedUploadImage(0);
    setSelectedImageName1(null);
    setSelectedLanguage(null);
    setSelectedFile(null);
    setRecordedFile(null);
    setTouched(false);
    setScriptTouched(false);
    setTextEmojiError('');
    setPresenterDetails({
      driver_id: "vmcS2q23Kk",
      gender: "male",
      image_url: "https://quickvideo.blob.core.windows.net/quickvideo/presenters/image/139315.png",
      presenter_id: "rian-pbMoTzs7an",
      preview_url: "https://quickvideo.blob.core.windows.net/quickvideo/presenters/preview/50bafc.mp4",
      thumbnail_url: "https://quickvideo.blob.core.windows.net/quickvideo/presenters/thumbnail/cf8ead.png"
    });
  }
  
  const fetchData = async () => {
    try {
      setCreditsloader(true);
      const response = await axios.get(`${BASE_URL}/credits`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id
        },
      });
      if(response.data) {
        setCredits(response.data.credits.Total);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setCreditsloader(false);
    }
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [credits])

  const handleModal = () => {
    handleVideoLength();
    !ask && setConfirmModal(true);
    ask && handleGenerate();
  }

  const handleGenerate = () => {
    setConfirmModal(false);
    let url;
    const data = {
      script: {
        type: type,
        provider: { type: 'microsoft', voice_id: voiceId, voice_config: {rate: '1'} },
        input: type === 'text' ? text : audioResponse ? audioResponse : selectedFile ? selectedFile : recordedFile
      },
      user_id: user_id,
      file_name: videoName,
      style: style, 
      // fileType: recordedFile ? 'recorded' : 'uploaded'
    };

    if (!selectedUploadToggle && selectedImageName < uniquePresentersArray.length) {
      if(customBackground !== null){
        data.snapshot = snapshot;
      } else {
        data.presenter_id = presenterId;
        data.background = { color: bgColor };
      }
    } else {
      data.source_url = image;
    }
    
    const headers = {
      accept: 'application/json',
      'content-type': type === "text" ? 'application/json' : 'multipart/form-data',
      'Authorization': `Bearer ${access_token}`,
    };

    if (!selectedUploadToggle && selectedImageName < uniquePresentersArray.length) {
      if(customBackground !== null){
        url = `${BASE_URL}/talks`;
      } else {
        url = `${BASE_URL}/clips`;
      }
    } else {
      url = `${BASE_URL}/talks`;
    }
    
    const options = {
      method: 'POST',
      url: url,
      headers: headers,
      data: type === "text" ? JSON.stringify(data) : data
    };

    setLoading(dispatch, true); 
    setLoadingModal(true);
    setPostLoading(dispatch, true);
    setTimeout(() => {
      history.push('/library', {Presenter: true});
    }, 3000);
    
    axios(options)
      .then(response => {
        setLoading(dispatch, false);
        setLoadingModal(false);
        setPostLoading(dispatch, false);
        setFetchLogs(dispatch, true);
        enqueueSnackbar("AI Video generated successfully.", {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        mixpanel.track('Video Creation', {
          'video name': videoName,
          'type': 'Presenter Video'
        });
      })
      .catch(error => {
        console.error(error);
        setLoading(dispatch, false);
        setLoadingModal(false);
        setPostLoading(dispatch, false);
        enqueueSnackbar(error.response.statusText || 'Something went wrong', {
          variant: "warning",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
  }

  const tabHandler = (event, newValue) => setTabValue(newValue);

  const [selectedUploadImage, setSelectedUploadImage] = useState(0);
  const [selectedUploadToggle, setSelectedUploadToggle] = useState(false);
  const [selectedImageName1, setSelectedImageName1] = useState(null);

  const isImageSizeValid = (file) => {
    const maxSize = 10 * 1024 * 1024;
    return file.size <= maxSize;
  };

  const handleCustomBackground = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (!isImageSizeValid(file)) {
      alert("File size exceeds the limit of 10MB");
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      setCustomBackground(reader.result);
      captureSnapshot();
    };

    reader.readAsDataURL(file); 
  };

  const removeCustomBackground = () => {
    setCustomBackground(null);
    const fileInput = document.getElementById('custom-image-upload');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  useEffect(() => {
    captureSnapshot();
  }, [image, mLeft])

  const captureSnapshot = () => {
    if (componentRef.current) {
      html2canvas(componentRef.current, { useCORS: true }).then((canvas) => {
        const dataURL = canvas.toDataURL();
        setSnapshot(dataURL);
      });
    }
  };

  const handleActionClick = (actionName) => {
    switch (actionName) {
      case 'Left':
        setMLeft(prevLeft => (parseInt(prevLeft) + 1) + '%');
        break;
      case 'Right':
        setMLeft(prevLeft => (parseInt(prevLeft) - 1) + '%');
        break;
      default:
        break;
    }
  };

  const actions = [
    { icon: <ChevronRightIcon />, name: 'Move avtar right' },
    { icon: <ChevronLeftIcon />, name: 'Move avtar left' },
  ];

  const handleAvatarClick = (item, index) => {
    setSelectedImageName(dispatch, index);
    setSelectedUploadToggle(false);
    setActiveImage(index);
    if (index < uniquePresentersArray.length) {
      setSelectedPresenterId(uniquePresentersArray[index].presenter_id.split('-')[0]);
    } else {
      handleThumbnailClick(item, index);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
  const handleThumbnailClick = (presenter, index) => {
    setSelectedPresenterId(null);
    if (index < uniquePresentersArray.length) {
      setPresenterDetails(presenter);
      setPresenterId(dispatch, presenter.presenter_id);
      setImage(dispatch, presenter.image_url);
    } else {
      setPresenterDetails(presenter);
      switch(index) {
        case 25:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/D.png');
        case 26:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/E.jpeg');
        case 27:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/F.png');
        case 28:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/G.jpeg');
        case 29:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/H.jpeg');
        case 30:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/I.jpeg');
        case 31:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/J.png');
        case 32:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/K.jpeg');
        case 33:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/L.png');
        case 34:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/M.jpeg');
        case 35:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/N.jpeg');
        case 36:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/O.png');
        case 37:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/P.png');
        case 38:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/Q.jpeg');
        case 39:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/R.jpeg');
        case 40:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/S.jpeg');
        case 41:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/T.jpeg');
        case 42:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/U.png');
        case 43:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/V.jpeg');
        case 44:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/W.png');
        case 45:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/X.jpeg');
        default:
          return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/D.png');
      }
    }
  };

  const handleThumbnailClick1 = (index1) => {
    setSelectedImageName1(index1);
  };

  const handleModalClose = () => {
    setSelectedImageName(dispatch, null);
  };

  function Presenter() {
    const { borderWidth } = borders;

    const renderPresenter = storiesData.map(({ image, name, color }) => (
      <Grid
        key={name}
        item
        xs={4}
        sm={3}
        md={2}
        lg={1}
        sx={{ flex: "0 0 100%" }}
        onClick={() => handleThumbnailClick(name)}
      >
        <VuiBox
          borderRadius="50%"
          width="3.625rem"
          height="3.625rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="white"
          mx="auto"
          border={`${borderWidth[2]} solid ${colors[color].main}`}
          sx={{ cursor: "pointer" }}
        >
          <VuiAvatar size={"xl"} src={image} alt={name} />
        </VuiBox>
      </Grid>
    ));

    return (
      <Grid container>
        <Grid
          item
          xs={4}
          sm={3}
          md={2}
          lg={1}
          sx={{ flex: "0 0 100%" }}
          justifyContent="space-between"
          wrap="nowrap"
        >
          <VuiBox
            bgColor="info"
            borderRadius="50%"
            width="3.625rem"
            height="3.625rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="white"
            mx="auto"
            sx={{ cursor: "pointer" }}
          >
            <AiOutlinePlus color="white" size="24px" />
            <VuiTypography fontSize={12} color="text" fontWeight="regular">
              Add
            </VuiTypography>
          </VuiBox>
        </Grid>
        {/* {renderPresenter} */}
      </Grid>
    );
  }

  function Header() {
    return (
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-between"
        sx={({ breakpoints }) => ({
          [breakpoints.up("xs")]: {
            gap: "16px",
          },
          [breakpoints.up("xs")]: {
            gap: "0px",
          },
          [breakpoints.up("xl")]: {
            gap: "0px",
          },
        })}
      >
        <VuiTypography
          variant="body1"
          color="white"
          style={{ marginLeft: "20px" }}
        >
          Create Video
        </VuiTypography>
      </Grid>
    );
  }

  const { borderWidth } = borders;

  const handleBgColorChange = (e) => {
    setBgColor(e.target.value);
  };
  
  const handleDoubleClick = () => {
    setBgColor('#00ff40');
  };

  const handleOrientationChange = (option) => {
    setSelectedOrientation(option.value);
  };

  const handleImageSelection = (imageName) => {
    setSelectedImageName(dispatch, imageName);
  };

  const getImageStyle = () => {
    let imageStyle = {
      width: "100%",
      height: window.innerWidth < 768 ? "200px" : "380px",
      backgroundColor: bgColor,
      backgroundImage: customBackground ? `url(${customBackground})` : 'none',
      marginLeft: "auto",
      marginRight: "auto",
      backgroundPosition: "center",
      backgroundSize: 'cover',
    };

    if (selectedOrientation === "Square") {
      imageStyle.width = "380px";
      imageStyle.height = "380px";
    } else if (selectedOrientation === "Wide") {
      imageStyle.width = "100%";
      imageStyle.height = "380px";
    } else if (selectedOrientation === "Vertical") {
      imageStyle.width = "230px";
      imageStyle.height = "380px";
    }

    return imageStyle;
  };

  const handleBlur = () => {
    setTouched(true);
  };

  const error = touched && videoName === '';

  const handleUploadImage = (index) => {
    setSelectedUploadToggle(true);
    setSelectedUploadImage(index);
    setImage(dispatch, uploadedAvatars[index].imageUrl);
  }

  const isImageFile = (file) => {
    return file.type.startsWith('image/');
  };

  const isFileSizeValid = (file) => {
    const maxSize = 4 * 1024 * 1024;
    return file.size <= maxSize;
  };

  const generateUniqueId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!isFileSizeValid(file)) {
      enqueueSnackbar("File size exceeds the limit of 10MB", {
        variant: "Warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }

    setAvatarLoading(true);

    try {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("user_id", user_id);
  
      const response = await axios.post(`${BASE_URL}/generate_image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${access_token}`,
        },
      });
  
      // const imageFiles = event.map((file) => ({
      //   id: generateUniqueId(),
      //   url: URL.createObjectURL(file),
      //   imageUrl: response.data.image_url,
      // }));

      const imageFile = {
        id: generateUniqueId(),
        url: URL.createObjectURL(file),
        imageUrl: response.data.image_url,
      };

      setAvatarLoading(false);
      setUploadedAvatars((prevFiles) => [imageFile, ...prevFiles]);
      mixpanel.track('Image avatar uploaded', {
        'Image link': response.data.image_url,
      });
    } catch (error) {
      setAvatarLoading(false);
      enqueueSnackbar(error.message || 'Something went wrong', {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  const deleteImageUpload = async (index) => {
    setAvatarLoading(true);
    try {
      await axios.delete(`${BASE_URL}/generate_image`, {
        params: {
          image_url: uploadedAvatars[index].imageUrl,
          user_id
        },
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        setUploadedAvatars([]);
        callUploadImage();
        setAvatarLoading(false);
      })
      .catch(err => {
        console.log(err);
        setAvatarLoading(false);
      })
    } catch (error) {
      setAvatarLoading(false);
      enqueueSnackbar(error.message || 'Something went wrong', {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    multiple: false,
  });

  // const handleTooltipOpen = () => {
  //   // if (isMissingFields) {
  //     if (text === '' && videoName === '') {
  //       setTextEmojiError('write your script');
  //     } else if(videoName === '') {
  //       setTouched(true);
  //     } else {
  //       setTextEmojiError('');
  //       setTouched(false);
  //     }
  //   // } 
  //   setTooltipOpen(true);
  // };

  // const handleTooltipClose = () => {
  //   setTextEmojiError('');
  //   setTooltipOpen(false);
  //   setTouched(false);
  // };

  // const isMissingFields = type === 'text' ? (text === '' || (selectedLanguage === '' || selectedVoice === '' || style === '') || videoName === "" || loading || (credits < creditsBurn + 2) || videoNameError !== '') : ((selectedFile === null && selectAudio === false) || videoName === "" || loading || (credits < creditsBurn + 2) || videoNameError !== '');

  // const tooltipContent = (
  //   <div style={{display: 'flex', alignItems: 'center'}}>
  //     <img src={CheckNote} alt="checkbox" width={30} />
  //     {type === "text" ? 
  //       text === '' ? 'script is missing' : videoName === '' ? 'Please enter video name' : videoNameError !== '' ? videoNameError : 'language and voice fields required' :  'language and voice fields required'
  //     }
  //     {type === "audio" ? videoNameError !== '' ? videoNameError : 'Audio File or Video Name is missing' : ''}
  //   </div>
  // );

  const determineError = () => {
    if (type === 'text') {
      if (text === '') return 'script is missing';
      if (videoName === '') return 'Please enter video name';
      if (!selectedLanguage || !selectedVoice || !style) return 'language, voice and style fields required';
    } else if (type === 'audio') {
      if (videoName === '') return 'Please enter video name';
      if (selectedFile === null && !selectAudio) return 'Audio File or Video Name is missing';
    }
    return '';
  };

  const errorMessage = determineError();

  const isMissingFields = 
    type === 'text' 
      ? (text === '' || !selectedLanguage || !selectedVoice || !style || videoName === "" || loading || credits < creditsBurn + 2 || videoNameError !== '') 
      : ((selectedFile === null && !selectAudio) || videoName === "" || loading || credits < creditsBurn + 2 || videoNameError !== '');

  const tooltipContent = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={CheckNote} alt="checkbox" width={30} />
      {errorMessage}
    </div>
  );

  const handleTooltipOpen = () => {
    if (text === '') {
      setTextEmojiError('write your script');
    } else if(videoName === '') {
      setTouched(true);
    } else {
      setTextEmojiError('');
      setTouched(false);
    }
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTextEmojiError('');
    setTooltipOpen(false);
    setTouched(false);
  };

  const handleUpgradeClick = () => {
    history.push('/upgrade');
  };

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    return '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Tour
        steps={createSteps}
        isOpen={tourOpen}
        onRequestClose={() => setTourOpen(!tourOpen)}
        rounded={10}
        accentColor="#060b27"
        nextButton="Next"
        prevButton="Back"
        lastStepNextButton="Finish"
        // showNumber={true}
        showButtons={true}
        showNavigation={true}
        disableInteraction={false}
        disableDotsNavigation={false}
        disableKeyboardNavigation={false}
        disableFocusLock={false}
        scrollSmooth={true}
      />
      {/* <Header /> */}
      <Card
        sx={{
          minHeight: "1000px",
          maxWidth: "100%",
        }}
      >
        <Grid container spacing={1} direction={window.innerWidth < 768 ? 'column' : 'row'}>
          <Grid item xs={8} width='100%'>
            {tabValue === 0 && (
              <div style={{ position: "relative", margin: 0 }}>
                <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: window.innerWidth < 768 ? "column" : "row",}} mb={2}>
                  <VuiBox maxWidth={250} id="videoName">
                    <VuiInput
                      onBlur={handleBlur}
                      error={error}
                      icon={{
                        component: <IoPencilOutline />,
                        direction: "left",
                      }}
                      data-clarity-mask="true"
                      value={videoName}
                      placeholder="Name your video"
                      onChange={(e) => handleVideoName(e)}
                      disabled={loading}
                      sx={({ breakpoints }) => ({
                        [breakpoints.down("sm")]: {
                          maxWidth: "150px",
                        },
                        [breakpoints.only("sm")]: {
                          maxWidth: "150px",
                        },
                        backgroundColor: "info.main !important",
                      })}
                    />
                  </VuiBox>
                  <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: window.innerWidth < 768 ? "10px" : ""}}>
                    <>
                      <Tooltip title='Click to view tutorial'>
                        <InfoIcon sx={{color: 'whitesmoke', cursor: 'pointer'}} onClick={handleTourToggle} />
                      </Tooltip>
                      <VuiBox sx={{margin: '0px 5px'}}>
                        <Button style={{minWidth: window.innerWidth < 768 ? "100px" : "150px", color: '#fff', background: "transparent", outline: "none", padding: "12px 10px", borderRadius: "11px", border: "1px solid #fff"}} onClick={handleDiscard} 
                        disabled={type === 'text' ? (text === '' || (selectedLanguage === '' || selectedVoice === '' || style === '') || videoName === "" || loading) : ((selectedFile === null && selectAudio === false) || videoName === "" ||loading)}
                        sx={{
                          "&.Mui-disabled": {
                            opacity: 0.5,
                            cursor: "not-allowed"
                          }
                        }}
                        >
                          {window.innerWidth <= 768 ? "Discard" : "Discard Video"}
                        </Button>
                      </VuiBox>
                      <VuiBox sx={{margin: '0px 5px'}}>
                      <Tooltip 
                        title={isMissingFields ? tooltipContent : ''} 
                        placement="top" 
                        // open={tooltipOpen && isMissingFields}
                        onOpen={handleTooltipOpen}
                        onClose={handleTooltipClose}
                      >
                        <span>
                          <Button onClick={handleModal} style={{minWidth: window.innerWidth < 768 ? "100px" : "150px", color: 'black', background: "white", outline: "none", padding: "12px 10px", borderRadius: "11px"}} 
                          disabled={type === 'text' ? (text === '' || textEmojiError !== ''|| (selectedLanguage === null || selectedVoice === null || style === null) || videoName === "" || loading || videoNameError !== '') : ((selectedFile === null && selectAudio === false) || videoName === "" || loading || videoNameError !== '')}
                          sx={{
                            "&.Mui-disabled": {
                              opacity: 0.5,
                              cursor: "not-allowed"
                            }
                          }}>
                          {loading ? (
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress size={16} color="black" />
                              </div>
                            ) : (
                              <>
                                <Videocam />
                                &nbsp;
                                {window.innerWidth <= 768 ? "Generate" : "Generate Video"}
                              </>
                            )
                          }  
                          </Button>
                        </span>
                      </Tooltip>
                      </VuiBox>
                    </>
                  </VuiBox>
                </VuiBox>
                <VuiBox>
                  <VuiBox
                    ref={componentRef}
                    style={ selectedImageName < uniquePresentersArray.length && !selectedUploadToggle ? getImageStyle() : {
                      backgroundColor: "#fff",
                      backgroundSize: "contain",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                      backgroundPosition: 'center',
                      //backgroundImage: selectedUploadToggle ? `url(${customBackground})` : 'none',
                      position: "relative",
                      objectFit: "contain",
                      margin: "auto"
                    }}
                  >
                    {selectedUploadToggle ? (
                      <img 
                        src={uploadedAvatars[selectedUploadImage]?.imageUrl}
                        alt='avatar' 
                        maxWidth="100%"
                        height={window.innerWidth < 480 ? "200px" : "380px"}
                      />
                    ) : 
                      <>
                        {selectedPresenterId && 
                        <div style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: window.innerWidth <= 768 ? 'translate(-50%, -10%)' : 'translate(-50%, -50%)',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexWrap: 'wrap',
                          background: 'rgba(0, 0, 0, 0.85)',
                          width: window.innerWidth <=768 && '280px',
                          border: '1px solid #000',
                          padding:  window.innerWidth <= 768 ? "10px" : '20px',
                          borderRadius: '8px',
                          zIndex: 10,
                          boxShadow: '0px 0px 50px 47px rgba(0,0,0,0.31)',
                        }}>
                          <ClearIcon
                            sx={{
                              color: 'whitesmoke',
                              borderRadius: '100%',
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '7px',
                              right: '7px',
                              zIndex: 5
                            }}
                            onClick={removePresenterModel}
                          />
                          <VuiTypography variant='h6' color='white'>Choose one of the below costume</VuiTypography>
                          <VuiBox sx={{ borderTop: '1px solid white', paddingTop: '5px' }}>
                            {preAvatars.filter(presenter => presenter.presenter_id.startsWith(selectedPresenterId)).map((presenter, index) => {
                              const unlockedIndexLimit = getUnlockedIndexLimit(plan);
                              const isLocked = index >= unlockedIndexLimit;
                              
                              return (
                                <div key={presenter.presenter_id} style={{ position: 'relative', display: 'inline-block', margin: '3px' }}>
                                  <img
                                    src={presenter.image_url}
                                    alt={presenter.presenter_id.split('-')[0]}
                                    style={{
                                      borderRadius: '100%',
                                      width: window.innerWidth <= 768 ? '67px' : '80px',
                                      height: window.innerWidth <= 768 ? '67px' : '80px',
                                      background: 'rgb(241, 242, 246)',
                                      objectFit: 'cover',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => handleThumbnailClick(presenter, index)}
                                    onMouseOver={(e) => {
                                      e.currentTarget.style.border = '3px solid rgb(47, 57, 156)';
                                    }}
                                    onMouseOut={(e) => {
                                      e.currentTarget.style.border = 'none';
                                    }}
                                  />
                                  {isLocked && (
                                    <Tooltip TransitionComponent={Zoom} placement="top"
                                      PopperProps={{
                                        sx: {
                                          "& .MuiTooltip-tooltip": {
                                            color: "#fff",
                                            maxWidth: '500px',
                                            height: '100px',
                                            backdropFilter: 'blur(100px)', 
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                                            opacity: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'space-around'
                                          }
                                        }
                                      }}
                                      title={
                                        <>
                                          <VuiTypography variant='h6' color="inherit">Upgrade your plan to unlock costume</VuiTypography>
                                          <Button variant="contained" sx={{color: 'whitesmoke'}} onClick={handleUpgradeClick}><LockOpenIcon sx={{marginRight: '3px'}} />Upgrade plan</Button>
                                        </>
                                      }
                                    >
                                      <div
                                        style={{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          width: '80px',
                                          height: '80px',
                                          borderRadius: '100%',
                                          background: 'rgba(0, 0, 0, 0.5)',
                                          // backdropFilter: 'blur(5px)',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          color: 'white',
                                          fontSize: '24px',
                                        }}
                                      >
                                        <LockIcon />
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                              );
                            })}
                          </VuiBox>
                        </div>}
                        {selectedImageName !== null && presenterDetails && (selectedImageName < uniquePresentersArray.length) ? (
                          <img
                            src={presenterDetails?.image_url}
                            alt='presenter'
                            // width={customBackground ? "45%" : (window.innerWidth < 768 && window.innerWidth > 450) ? "30%" : window.innerWidth > 1200 ? "45%" : "60%"}
                            height={window.innerWidth < 768 ? "160px" : customBackground ? "280px" : "380px"}
                            style={{
                              position: "absolute", 
                              clipPath: selectedOrientation === "Vertical" ? "inset(0px 23.5% 0px 23.5%)" : "",
                              boxShadow: highlightedImage ? 'rgba(255, 255, 255, 0.4) 0px -20px 50px 0px' : 'none',
                              transform: highlightedImage ? 'scale(1.03)' : 'translate(-50%, -50%)',
                              top: window.innerWidth < 768 ? "73.75%" : '56.5%',
                              left: '50%',
                              // transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                              borderTopLeftRadius: highlightedImage ? '5%' : '',
                              borderTopRightRadius: highlightedImage ? '5%' : '',
                            }}
                          />
                        ) : (
                          <img
                            src={presenterDetails?.image}
                            alt={presenterDetails?.name}
                            width={window.innerWidth < 768 ? "200px" : avatars[selectedImageName]?.width}
                          />
                        )}
                      </>
                  }
                  </VuiBox>
                  {/* <Button onClick={handleRemoveBackground}>Remove bg</Button> */}
                  {!selectedUploadToggle && selectedImageName < uniquePresentersArray.length ? 
                    <VuiBox sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end", position: "absolute", right: window.innerWidth < 768 ? "2px" : "5px", top: window.innerWidth < 768 ? "102px" : "60px"}}>
                      {/* <VuiSelect
                        placeholder= {selectedOrientation ? selectedOrientation : "Orientation"}
                        options={[
                          { value: "Wide", label: "Wide  600x380" },
                          { value: "Square", label: "Square  380x380" },
                          { value: "Vertical", label: "Vertical  230x380" },
                        ]}
                        onChange={ selectedImageName < uniquePresentersArray.length ? handleOrientationChange : null}
                      /> */}
                      {!customBackground && <VuiBox sx={{display: "flex", alignItems: "center", marginTop: "5px", marginRight: '3px', width: "45px", backgroundColor: '#0f1535', borderRadius: "50%"}} id='backgroundColor' onDoubleClick={handleDoubleClick}>
                        {/* <VuiTypography sx={{margin: "2px 2px 2px 8px", fontSize: "0.8rem", fontWeight: 400, flex: "0.7", color: "#545d77"}}>
                          Background
                        </VuiTypography> */}
                        <Tooltip title='Change background color' placement="left">
                          <VuiInput
                            type="color"
                            sx={{border: "none", cursor: "pointer", borderRadius: '50%'}}
                            value={bgColor}
                            onChange={(e)=>handleBgColorChange(e)}
                          />
                        </Tooltip>
                      </VuiBox>}
                      {/* <VuiBox sx={{display: 'flex', width: '100px', justifyContent: 'flex-end'}}>
                        <Tooltip title='Upload background' placement="left">
                          <VuiBox sx={{display: "flex", alignItems: "center", marginTop: '5px', marginRight: '3px', padding: "5px 10px 0px 10px", backgroundColor: '#0f1535', borderRadius: "50%"}}>
                            <label htmlFor="custom-image-upload" style={{cursor: 'pointer'}}>
                              <UploadIcon color="white" fontSize="medium" />
                              <input
                                accept="image/jpeg, image/png, image/jpg"
                                style={{ display: 'none' }}
                                type="file"
                                id="custom-image-upload"
                                name="custom-image-upload"
                                onChange={handleCustomBackground}
                              />
                            </label>
                          </VuiBox>
                        </Tooltip>
                        {customBackground !==null && <Tooltip title='Remove Backgrund'>
                          <VuiBox sx={{display: "flex", alignItems: "center", marginTop: '5px', padding: "0px 10px", backgroundColor: '#0f1535', borderRadius: "0.7375rem", cursor: 'pointer'}} onClick={removeCustomBackground}>
                            <HighlightOffIcon color="white" fontSize="medium" />
                          </VuiBox>
                        </Tooltip>}
                      </VuiBox> */}
                      {customBackground && <VuiBox sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
                        <Tooltip title='Change Position'>
                          <SpeedDial
                            ariaLabel="SpeedDial openIcon example"
                            sx={{ position: 'absolute', bottom: window.innerWidth < 768 ? "170px" : 0, right: window.innerWidth < 768 ? 1 : 2, '& .MuiFab-primary': { backgroundColor: '#0f1535', width: 50, height: 50, '&:hover': {backgroundColor: '#fff', color: '#0f1535'} }}}
                            icon={
                              <EditIcon 
                                openIcon={<CloseIcon />}
                              />
                            }
                            onMouseEnter={() => setHighlightedImage(true)}
                            onMouseLeave={() => setHighlightedImage(false)}
                          >
                            {actions.map((action) => (
                              <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={() => handleActionClick(action.name)}
                              />
                            ))}
                          </SpeedDial>
                        </Tooltip>
                      </VuiBox>}
                    </VuiBox> : (<></>)
                  }
                </VuiBox>
              </div>
            )}

            {tabValue === 1 && (
              <Grid
                container
                direction="column"
                // alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <VuiTypography variant={"body1"} color="white" align="center">
                    Generate an AI Presenter
                  </VuiTypography>
                </Grid>

                <Grid item mt={2}>
                  <ButtonGroup fullWidth>
                    <VuiButton
                      variant="outlined"
                      color="white"
                      disabled
                      sx={{ border: "1px solid", width: "12rem" }}
                    >
                      A portrait of
                    </VuiButton>
                    <VuiInput
                      placeholder="Click here for inspiration, or describe the presenter you'd like to..."
                      variant="outlined"
                      sx={{ borderRadius: "0px" }}
                    />
                    <VuiButton
                      variant="contained"
                      color="white"
                      sx={{ width: "12rem" }}
                      // disabled
                    >
                      Generate
                    </VuiButton>
                  </ButtonGroup>
                </Grid>

                {selectedImageName1 !== null && (
                  <Grid item xs={12} md={5} mt={2} mb={3}>
                    <VuiBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        src={AiAvatar[selectedImageName1].image}
                        alt={AiAvatar[selectedImageName1].name}
                        style={{ width: "40%", borderRadius: "15%", height: "250px" }}
                      />
                    </VuiBox>
                  </Grid>
                )}
              </Grid>
            )}

            <Grid container mt={1} mb={1}>
              <AppBar position="static" sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                <Tabs value={tabValue} onChange={tabHandler}>
                  {/* <Tab
                    icon={<IoMdPerson color="white" size="25px" />}
                    label={
                      <VuiTypography color="light" variant="body2">
                        Choose a presenter
                      </VuiTypography>
                    }
                  /> */}
                  {/* <Tab
                    icon={<IoMdColorWand color="white" size="25px" />}
                    label={
                      <VuiTypography color="light" variant="body2">
                        Generate AI Presenter
                      </VuiTypography>
                    }
                  /> */}
                </Tabs>
              </AppBar>
            </Grid>

            {tabValue === 0 && (
              <Grid
                container
                spacing={0}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "auto",
                  maxHeight: window.innerWidth < 768 ? "25vh" : "72vh"
                  // justifyContent: "center",
                }}
              >
                {avatarLoading ? 
                  <VuiBox
                      mt={1}
                      bgColor="white"
                      borderRadius="50%"
                      width="100px"
                      height="100px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color="white"
                      mx="auto"
                    >
                      <CircularProgress size={30} color="black" />
                    </VuiBox>
                  : 
                  <>
                    <Tooltip TransitionComponent={Zoom} placement="top"
                      PopperProps={{
                        sx: {
                          "& .MuiTooltip-tooltip": {
                            color: "#fff",
                            maxWidth: '500px',
                            backgroundColor: 'rgb(7, 11, 36)',
                            opacity: 1,
                            border: '1px solid gray'
                          }
                        }
                      }}
                      title={
                        <>
                          <VuiTypography variant='h6' color="inherit">Upload your own image</VuiTypography>
                          <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', borderTop: '1px solid #fff', marginTop: '5px'}}>
                            <img src={AiMan} alt='tooltip' height='160px' style={{filter: 'drop-shadow(0px 0px 100px skyblue)', borderRadius: '5px'}} />
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: '3px'}}>
                              <h3 style={{marginBottom: '3px'}}>Requirements</h3>
                              <p>&#10004; Front facing</p>
                              <p>&#10004; Centered</p>
                              <p>&#10004; Neutral expression</p>
                              <p>&#10004; Good lighting</p>
                              <p>&#10004; Recommended size: 512x512 px</p>
                              <p>&#10008; No face obstructions</p>
                            </div>
                          </div>
                        </>
                      }
                    >
                      <label htmlFor="image-upload" style={{cursor: 'pointer', marginLeft: 'auto', marginRight: 'auto'}}>
                        <VuiBox
                          mt={1}
                          bgColor="info"
                          borderRadius="50%"
                          // width="3.625rem"
                          // height="3.625rem"
                          width={window.innerWidth < 768 ? "50px" : "100px"}
                          height={window.innerWidth < 768 ? "50px" : "100px"}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          color="white"
                          mx="auto"
                          sx={{ cursor: "pointer" }}
                          // onClick={() => alert("Add functionality")}
                          // {...getRootProps()}
                        >
                          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} id='upload'>
                            {/* <AiOutlinePlus color="white" size="14px" /> */}
                            <VuiTypography
                              fontSize={window.innerWidth < 768 ? 12 : 14}
                              color="white"
                              fontWeight="regular"
                              sx={{'&:hover': window.innerWidth > 768 && {fontSize: '18px'}, textAlign: 'center'}}
                            >
                              {/* <input {...getInputProps()} accept="image/*" /> */}
                                &#43; 
                                Upload
                                <input
                                  accept="image/*"
                                  style={{ display: 'none' }}
                                  type="file"
                                  id="image-upload"
                                  name="image-upload"
                                  onChange={handleFileUpload}
                                />
                            </VuiTypography>
                          </VuiBox>
                        </VuiBox>
                      </label>
                    </Tooltip>
                    {uploadedAvatars && uploadedAvatars.map((item, index) => (
                      <Grid
                        key={index}
                        item
                        mt={1}
                        // columns={4}
                        // xs={1}
                        // sm={1}
                        // md={1}
                        // lg={1}
                        sx={{
                          flex: "0 0 20%",
                          alignItems: "center",
                          justifyContent: "center",
                          position: 'relative'
                        }}
                      >
                        <VuiBox
                          borderRadius="100%"
                          // // width="3.625rem"
                          width={window.innerWidth < 768 ? "50px" : "100px"}
                          height={window.innerWidth < 768 ? "50px" : "100px"}
                          // // height="3.625rem"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          color="white"
                          mx="auto"
                          border={`${borderWidth[2]} solid`}
                          // sx={{ cursor: "pointer" }}
                        >
                          <img
                            src={item.imageUrl}
                            alt={item.name}
                            onClick={() => window.innerWidth < 768  && handleUploadImage(index, item.id)}
                            style={{
                              borderRadius: "100%",
                              width: window.innerWidth < 768 ? "47px" : "97px",
                              height: window.innerWidth < 768 ? "47px" : "97px",
                              background: "rgb(241, 242, 246)",
                              objectFit: "cover"
                            }}
                            onMouseOver={(e) => {
                              e.target.style.border = "3px solid darkblue";
                            }}
                            onMouseOut={(e) => {
                              e.target.style.border = "none";
                            }}
                          />
                          {window.innerWidth > 768 && <VuiBox
                            position="absolute"
                            top={0}
                            right='auto'
                            borderRadius="100%"
                            width={window.innerWidth < 768 ? "50px" : "100px"}
                            height={window.innerWidth < 768 ? "50px" : "100px"}
                            display="flex"
                            justifyContent="space-evenly"
                            alignItems="center"
                            sx={{
                              cursor: "pointer",
                              backgroundColor: "rgba(0, 0, 0, 0.8)",
                              opacity: 0,
                              border: '1px solid #fff',
                              transition: "opacity 0.3s ease",
                              "&:hover": {
                                opacity: 1,
                              },
                            }}
                          >
                            <CheckIcon color="white" sx={{cursor: 'pointer', "&:hover": {transform: window.innerWidth < 768 ? '' : 'scale(1.5)' },}} onClick={() => handleUploadImage(index, item.id)} />
                            <DeleteOutlineIcon color="white" sx={{cursor: 'pointer', "&:hover": {transform: window.innerWidth < 768 ? '' : 'scale(1.5)' },}} onClick={()=>deleteImageUpload(index)} />
                          </VuiBox>}
                          {window.innerWidth < 768 && <VuiBox
                            position="absolute"
                            top={-5}
                            right={0}
                            borderRadius="100%"
                            width="25px"
                            height="25px"
                            display="flex"
                            justifyContent="space-evenly"
                            alignItems="center"
                            sx={{
                              cursor: "pointer",
                              backgroundColor: "rgba(0, 0, 0, 0.8)",
                              border: '1px solid #fff',
                            }}
                            onClick={()=>deleteImageUpload(index)}
                          >
                            <DeleteOutlineIcon color="white" sx={{cursor: 'pointer', "&:hover": {transform: window.innerWidth < 768 ? '' : 'scale(1.5)' },}} />
                          </VuiBox>}
                        </VuiBox>
                      </Grid>
                    ))}
                    {avatars.map((item, index) => (
                      <Grid
                        key={index}
                        item
                        mt={1}
                        sx={{
                          flex: "0 0 20%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        id='presenters'
                      >
                        <VuiBox
                          borderRadius="100%"
                          // // width="3.625rem"
                          width={window.innerWidth < 768 ? "50px" : "100px"}
                          height={window.innerWidth < 768 ? "50px" : "100px"}
                          // // height="3.625rem"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          color="white"
                          mx="auto"
                          border={`${borderWidth[1]} solid`}
                          sx={{ cursor: "pointer", background: "rgb(241, 242, 246)", "&:hover": {background: "none"}, position: 'relative' }}
                        >
                          <img
                            src={index < uniquePresentersArray.length ? item.image_url : item.image}
                            alt={index < uniquePresentersArray.length ? item.id : item.name}
                            style={{
                              borderRadius: "100%",
                              width: window.innerWidth < 768 ? "47px" : "97px",
                              height: window.innerWidth < 768 ? "47px" : "97px",
                              // background: "rgb(241, 242, 246)",
                              objectFit: "cover",
                              border: activeImage === index ? "3px solid darkblue" : "none",
                              // transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                            }}
                            onMouseOver={(e) => {
                              e.target.style.transform = "scale(1.1)";
                              e.target.style.boxShadow = "0px 0px 20px 0px rgba(255,255,255,0.5)"
                            }}
                            onMouseOut={(e) => {
                              e.target.style.transform = "none";
                              e.target.style.boxShadow = "none";
                            }}
                            onClick={() => handleAvatarClick(item, index)}
                          />
                          {plan === 'Trial' && ((index > 7 && index < 25) || (index >= 33)) && (
                            <Tooltip placement="top"
                              PopperProps={{
                                sx: {
                                  "& .MuiTooltip-tooltip": {
                                    color: "#fff",
                                    maxWidth: '500px',
                                    height: '100px',
                                    backdropFilter: 'blur(100px)', 
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                                    opacity: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    border: '1px solid gray'
                                  }
                                }
                              }}
                              title={
                                <>
                                  <VuiTypography variant='h6' color="inherit">Upgrade your plan to unlock more presenters</VuiTypography>
                                  <Button variant="contained" sx={{color: 'whitesmoke'}} onClick={handleUpgradeClick}><LockOpenIcon sx={{marginRight: '3px'}} />Upgrade plan</Button>
                                </>
                              }
                            >
                              <div
                                style={{
                                  position: 'absolute',
                                  top: -2,
                                  left: -2,
                                  width: window.innerWidth < 768 ? "52px" : "102px",
                                  height: window.innerWidth < 768 ? "52px" : "102px",
                                  borderRadius: '100%',
                                  background: 'rgba(0, 0, 0, 0.5)',
                                  // backdropFilter: 'blur(1px)',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: 'white',
                                  fontSize: window.innerWidth < 768 ? '20px' : '24px',
                                }}
                              >
                                <LockIcon />
                              </div>
                            </Tooltip>
                          )}
                        </VuiBox>
                      </Grid>
                    ))}
                  </>
                }
              </Grid>
            )}
{/* 
            {tabValue === 1 && (
              <Grid
                container
                spacing={0}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "auto",
                  // justifyContent: "center",
                }}
              >
                <VuiBox
                  mt={1}
                  bgColor="info"
                  borderRadius="50%"
                  // width="3.625rem"
                  // height="3.625rem"
                  width="100px"
                  height="100px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                  mx="auto"
                  sx={{ cursor: "pointer" }}
                  onClick={() => alert("Add functionality")}
                  {...getRootProps()}
                >
                  <AiOutlinePlus color="white" size="20px" />
                  <VuiTypography
                    fontSize={12}
                    color="white"
                    fontWeight="regular"
                  >
                    <input {...getInputProps()} />
                    Create
                  </VuiTypography>
                </VuiBox>

                {AiAvatar.map((item1, index1) => (
                  <Grid
                    key={index1}
                    item
                    mt={1}
                    // columns={4}
                    // xs={1}
                    // sm={1}
                    // md={1}
                    // lg={1}
                    sx={{
                      flex: "0 0 20%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => handleThumbnailClick1(index1)}
                  >
                    <VuiBox
                      borderRadius="100%"
                      // // width="3.625rem"
                      width="100px"
                      height="100px"
                      // // height="3.625rem"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color="white"
                      mx="auto"
                      border={`${borderWidth[2]} solid`}
                      sx={{ cursor: "pointer" }}
                    >
                      <img
                        src={item1.image}
                        alt={item1.name}
                        style={{
                          borderRadius: "100%",
                          width: "97px",
                          height: "97px",
                          objectFit: "cover"
                        }}
                        onMouseOver={(e) => {
                          e.target.style.border = "3px solid darkblue";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.border = "none";
                        }}
                      />
                    </VuiBox>
                  </Grid>
                ))}
              </Grid>
            )} */}
          </Grid>

          <Grid item xs={4} alignContent={"center"} width='100%'>
            <Sidediv 
              text={text} 
              setText={setText} 
              textEmojiError={textEmojiError}
              setTextEmojiError={setTextEmojiError}
              style={style} 
              setStyle={setStyle} 
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
              selectedVoice={selectedVoice}
              setSelectedVoice={setSelectedVoice}
              setVoiceId={setVoiceId}
              loading={loading}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              recordedFile={recordedFile}
              setRecordedFile={setRecordedFile}
              setType={setType}
              availableVoiceText={availableVoiceText}
              setAvailableVoiceText={setAvailableVoiceText}
              cloneText={cloneText}
              setCloneText={setCloneText}
              cloneName={cloneName}
              setCloneName={setCloneName}
              audioResponse={audioResponse}
              setAudioResponse={setAudioResponse}
              scriptCharacterCount={scriptCharacterCount}
              voiceScriptCharacterCount={voiceScriptCharacterCount}
              cloneScriptCharacterCount={cloneScriptCharacterCount}
              setVideoLength={setVideoLength}
              setCreditsBurn={setCreditsBurn}
              videoLength={videoLength}
              selectAudio={selectAudio}
              setSelectAudio={setSelectAudio}
              selectedAudioFiles={selectedAudioFiles}
              setSelectedAudioFiles={setSelectedAudioFiles}
              currentFileIndex={currentFileIndex}
              setCurrentFileIndex={setCurrentFileIndex}
              scriptTouched={scriptTouched}
              setScriptTouched={setScriptTouched}
              chatgptModal={chatgptModal}
              setChatgptModal={setChatgptModal}
            />
          </Grid>
        </Grid>
      </Card>
      {confirmModal && 
        <ConfirmModal 
          confirmModal={confirmModal} 
          setConfirmModal={setConfirmModal} 
          handleGenerate={handleGenerate}
          credits={credits}
          creditsloader={creditsloader}
          setCreditsloader={setCreditsloader}
          setAsk={setAsk}
          videoLength={videoLength}
          creditsBurn={creditsBurn}
        />
      }
      {loadingModal &&
        <LoadingModal 
          loadingModal={loadingModal}
          setLoadingModal={setLoadingModal}
        />
      }
    </DashboardLayout>
  );
}

export default Create;
