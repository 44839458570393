import { Button } from "@mui/material";
import React, { useState, useRef } from "react";

const AudioRecorder = ({setRecordedFile}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioData, setAudioData] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [stream, setStream] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  //eslint-disable-next-line
  const [timeLimitReached, setTimeLimitReached] = useState(false);
  const [totalTime] = useState(120);
  const timerIntervalRef = useRef(null);
  const audioPlayerRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  const checkTimeLimit = () => {
    if (elapsedTime >= totalTime) {
      stopRecording();
      setTimeLimitReached(true);
    }
  };

  const startRecording = () => {
    setIsRecording(true);
    setElapsedTime(0);
    chunksRef.current = [];
    setTimeLimitReached(false); // Reset the time limit state

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setStream(stream);
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
        mediaRecorderRef.current.addEventListener("stop", handleRecordingAvailable);
        mediaRecorderRef.current.start();

        setTimeout(() => {
          stopRecording();
          setTimeLimitReached(true);
        }, totalTime * 1000); 
        startTimer();
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
        setIsRecording(false);
      });
  };

  const stopRecording = () => {
    setIsRecording(false);
    mediaRecorderRef.current.stop();
    stopTimer();

    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }
  };

  const handleDataAvailable = (event) => {
    chunksRef.current.push(event.data);
  };

  const startTimer = () => {
    timerIntervalRef.current = setInterval(() => {
      setElapsedTime((prevElapsedTime) => {
        const newElapsedTime = prevElapsedTime + 1;
        checkTimeLimit();
        return newElapsedTime;
      });
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerIntervalRef.current);
  };

  const playAudio = () => {
    setIsPlaying(true);
    audioPlayerRef.current.play();
  };

  const pauseAudio = () => {
    setIsPlaying(false);
    audioPlayerRef.current.pause();
  };

  const resetAudio = () => {
    setIsPlaying(false);
    setIsRecording(false);
    setAudioData(null);
    setElapsedTime(0);
    audioPlayerRef.current.currentTime = 0;
    chunksRef.current = [];
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60).toString().padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const handleTimeUpdate = () => {
    if (!isPlaying && audioPlayerRef.current.currentTime === audioPlayerRef.current.duration) {
      setIsPlaying(false);
    }
    if (isPlaying) {
      setElapsedTime(Math.floor(audioPlayerRef.current.currentTime));
    }
  };

  const handleEnded = () => {
    setIsPlaying(false);
    audioPlayerRef.current.currentTime = 0;
  };

  const handleRecordingAvailable = () => {
    const audioBlob = new Blob(chunksRef.current, { type: "audio/mp3" });
    const audioUrl = URL.createObjectURL(audioBlob);
    setAudioData(audioUrl);
  };

  const handleFileUpload = () => {
    const audioBlob = new Blob(chunksRef.current, { type: "audio/mp3" });
    const audioFile = new File([audioBlob], "recorded_audio.mp3", { type: "audio/mp3" });
    setRecordedFile(audioFile);
    setUploaded(true);
  };

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
        <Button style={{minWidth: "70px", color: 'black', background: "white", outline: "none", padding: "12px 10px", borderRadius: "11px"}} onClick={isRecording ? stopRecording : startRecording} disabled={uploaded || audioData}>
          {isRecording ? "Stop" : "Start"}
        </Button>
        <Button style={{minWidth: "70px", color: 'black', background: "white", outline: "none", padding: "12px 10px", borderRadius: "11px"}} onClick={isPlaying ? pauseAudio : playAudio} disabled={!audioData}>
          {isPlaying ? "Pause" : "Play"}
        </Button>
        <Button style={{minWidth: "70px", color: 'black', background: "white", outline: "none", padding: "12px 10px", borderRadius: "11px"}} onClick={resetAudio} disabled={!audioData}>
          Reset
        </Button>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'column', marginTop: '10px'}}>
        <div style={{color: 'whitesmoke'}}>{`${formatTime(elapsedTime)} / ${formatTime(totalTime)}`}</div>
        <div>
          {audioData && (
            <audio ref={audioPlayerRef} src={audioData} onTimeUpdate={handleTimeUpdate} onEnded={handleEnded} />
          )}
        </div>
        {audioData && (
          <Button style={{minWidth: "70px", color: 'black', background: "white", outline: "none", padding: "12px 10px", borderRadius: "11px"}} onClick={handleFileUpload} disabled={isRecording || isPlaying || uploaded}>
            {uploaded ? "Uploaded" : "Upload Audio"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default AudioRecorder;
