import VuiBox from 'components/VuiBox';
import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, IconButton, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import VuiSelect from 'components/VuiSelect';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VuiInput from 'components/VuiInput';
import { IoPencilOutline } from 'react-icons/io5';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Sidediv from './Sidediv';
import ThreeDotsMenu from './components/ThreeDotsMenu';
import Draggable from 'react-draggable';
import html2canvas from 'html2canvas';
import VideoFooter from './components/VideoFooter';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {Resizable} from 're-resizable';
import Logo from './images/Logo 1.svg';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import './editor.css';
import LoadingModal from './components/loadingModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IoMdColorWand } from 'react-icons/io';
import ChatGptModal from './chatGptModal';
import BASE_URL from "context/api";
import logo from './QuickVideoIcon.png';
import VuiTypography from 'components/VuiTypography';

const EditingSection = ({plan, setCreateVideo, setSelectBreak, setSelectSpeed, changeInput, setChangeInput, videoName, setVideoName, setSelectLanguage, setSelectVoice, voiceOption, setVoiceOption, touched, setTouched, alignment, setAlignment, value, setValue, fonts, setFonts, selectedFont, setSelectedFont, selectedFontSize, setSelectedFontSize, fontSize, setFontSize, selectedFile, setSelectedFile, stockImages, setStockImages, imageQuery, setImageQuery, page, setPage, imageObserver, videoObserver, lastImageRef, loading, selectedBackground, setSelectedBackground, uploadedBackground, setUploadedBackground, backgroundTabValue, setBackgroundTabValue, uploadedVideo, setUploadedVideo, stockVideos, setStockVideos, videoQuery, setVideoQuery, videoPage, setVideoPage, lastVideoRef, uploadedAudio, setUploadedAudio, models, setModels, imageTotalPages, videoTotalPages, items, setItems, selectedAudio, setSelectedAudio, faceModels, scenes, setScenes, updateSceneKey, addScene, deleteScene, handleBoxClick, currentSceneIndex, handleSubmit, componentRef, openSettings, setOpenSettings, stockShapes, setStockShapes, shapesQuery, setShapesQuery, indexToStore, setIndexToStore, loadingModal, setLoadingModal, handleLeaveClick, calculateDimensions, confirmModal, setConfirmModal, handleFinalSubmit, intro, setIntro, outro, setOutro, uploadedIntroVideos, setUploadedIntroVideos, uploadedOutroVideos, setUploadedOutroVideos, showIO, setShowIO}) => {

  // console.log(scenes);
  const [isValid, setIsValid] = useState(false);
  const [validateError, setValidateError] = useState('');
  const [chatgptModal, setChatgptModal] = useState(false);
  const [chatgptText, setChatgptText] = useState('');
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

  const scriptCharacterCount = scenes[currentSceneIndex].script && scenes[currentSceneIndex].script.length;

  const handleChatgptText = () => {
    setChatgptModal(true);
  };

  const makeAPIRequest = async (prompt) => {
    try {
      const res = await axios.post(`${BASE_URL}/generate_ai_text`, {
        prompt_text: prompt
      })
      
      const newAvailableText = res.data.generated_text;
      if(newAvailableText) {
        updateSceneKey(currentSceneIndex, 'script', newAvailableText);
        return newAvailableText.trim();
      } else {
        console.log(res.data.error);
      }
    } catch (err) {
      console.log(err.response.data.error);
    }
  }

  const validateScenes = () => {
    for (let scene of scenes) {
      if (scene.script === '' || scene.script.length < 3) {
        setValidateError('Enter your script');
        return false;
      }
      if (scene.audio.selectVoice === '') {
        setValidateError('Select voice for your video');
        return false;
      }
      if (scene.selectedImage.length === 0 && scene.selectedVideo === null && scene.selectedModel === null) {
        setValidateError("Select one of the options Avatar, Image or Video");
        return false;
      }
    }
    setValidateError('');
    return true;
  };

  useEffect(() => {
    if (validateScenes(scenes)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [scenes]);

  const handleArrayResize = (e, dir, ref, d, item, itemIndex, elementType) => {
    let width = item.size.width + d.width;
    let height = item.size.height + d.height;

    setScenes((prevScenes) => {
      return prevScenes.map((scene, index) => {
        if (index === currentSceneIndex) {
          const updatedArray = scene[elementType].map((item, i) => {
            if (i === itemIndex) {
              return {
                ...item,
                size: {
                  width: width,
                  height: height,
                },
              };
            }
            return item;
          });
  
          return {
            ...scene,
            [elementType]: updatedArray,
          };
        }
        return scene;
      });
    });
  };
  
  // const handleTextResize = (e, dir, ref, d, item, itemIndex, elementType, innerElementType) => {
  //   let width = item.width + d.width;
  //   let height = item.height + d.height;

  //   setScenes((prevScenes) => {
  //     return prevScenes.map((scene, index) => {
  //       if (index === currentSceneIndex) {
  //         const updatedArray = scene[elementType][innerElementType].map((item, i) => {
  //           if (i === itemIndex) {
  //             return {
  //               ...item,
  //               size: {
  //                 x: width,
  //                 y: height,
  //               },
  //             };
  //           }
  //           return item;
  //         });
  
  //         return {
  //           ...scene,
  //           [elementType]: {
  //             ...scene[elementType],
  //             [innerElementType]: updatedArray,
  //           },
  //         };
  //       }
  //       return scene;
  //     });
  //   });
  // };

  const handleModelResize = (e, dir, ref, d) => {
    let width = scenes[currentSceneIndex].selectedModel.size.width + d.width;
    let height = scenes[currentSceneIndex].selectedModel.size.height  + d.height;

    setScenes((prevScenes) => {
      return prevScenes.map((scene, index) => {
        if (index === currentSceneIndex) {
          const updatedElement = {
            ...scene['selectedModel'],
            size: {
              width,
              height
            },
          };
  
          return {
            ...scene,
            selectedModel: updatedElement,
          };
        }
        return scene;
      });
    });
  }
  
  const handleVideoResize = (e, dir, ref, d) => {
    let width = scenes[currentSceneIndex].selectedVideo.size.width + d.width;
    let height = scenes[currentSceneIndex].selectedVideo.size.height  + d.height;

    setScenes((prevScenes) => {
      return prevScenes.map((scene, index) => {
        if (index === currentSceneIndex) {
          const updatedElement = {
            ...scene['selectedVideo'],
            size: {
              width,
              height
            },
          };
  
          return {
            ...scene,
            selectedVideo: updatedElement,
          };
        }
        return scene;
      });
    });
  }

  const handleModelMouseEnter = () => {
    setScenes((prevScenes) => {
      return prevScenes.map((scene, index) => {
        if (index === currentSceneIndex) {
          const updatedElement = {
            ...scene['selectedModel'],
            handle: true,
          };
          return {
            ...scene,
            selectedModel: updatedElement,
          };
        }
        return scene;
      });
    });
  };

  const handleModelMouseLeave = () => {
    setScenes((prevScenes) => {
      return prevScenes.map((scene, index) => {
        if (index === currentSceneIndex) {
          const updatedElement = {
            ...scene['selectedModel'],
            handle: false,
          };
          return {
            ...scene,
            selectedModel: updatedElement,
          };
        }
        return scene;
      });
    });
  };
  
  const handleVideoMouseEnter = () => {
    setScenes((prevScenes) => {
      return prevScenes.map((scene, index) => {
        if (index === currentSceneIndex) {
          const updatedElement = {
            ...scene['selectedVideo'],
            handle: true,
          };
          return {
            ...scene,
            selectedVideo: updatedElement,
          };
        }
        return scene;
      });
    });
  };

  const handleVideoMouseLeave = () => {
    setScenes((prevScenes) => {
      return prevScenes.map((scene, index) => {
        if (index === currentSceneIndex) {
          const updatedElement = {
            ...scene['selectedVideo'],
            handle: false,
          };
          return {
            ...scene,
            selectedVideo: updatedElement,
          };
        }
        return scene;
      });
    });
  };

  const handleMouseEnter = (itemIndex, elementType) => {
    setScenes((prevScenes) => {
      return prevScenes.map((scene, index) => {
        if (index === currentSceneIndex) {
          const updatedArray = scene[elementType].map((item, i) => {
            if (i === itemIndex) {
              return {
                ...item,
                handle: true,
              };
            }
            return item;
          });
  
          return {
            ...scene,
            [elementType]: updatedArray,
          };
        }
        return scene;
      });
    });
  };
  
  const handleMouseLeave = (itemIndex, elementType) => {
    setScenes((prevScenes) => {
      return prevScenes.map((scene, index) => {
        if (index === currentSceneIndex) {
          const updatedArray = scene[elementType].map((item, i) => {
            if (i === itemIndex) {
              return {
                ...item,
                handle: false,
              };
            }
            return item;
          });
  
          return {
            ...scene,
            [elementType]: updatedArray,
          };
        }
        return scene;
      });
    });
  };
  
  // const handleTextMouseEnter = (itemIndex, elementType, innerElementType) => {
  //   setScenes((prevScenes) => {
  //     return prevScenes.map((scene, index) => {
  //       if (index === currentSceneIndex) {
  //         const updatedArray = scene[elementType][innerElementType].map((item, i) => {
  //           if (i === itemIndex) {
  //             return {
  //               ...item,
  //               handle: true,
  //             };
  //           }
  //           return item;
  //         });
  
  //         return {
  //           ...scene,
  //           [elementType]: {
  //             ...scene[elementType],
  //             [innerElementType]: updatedArray,
  //           },
  //         };
  //       }
  //       return scene;
  //     });
  //   });
  // };
  
  // const handleTextMouseLeave = (itemIndex, elementType, innerElementType) => {
  //   setScenes((prevScenes) => {
  //     return prevScenes.map((scene, index) => {
  //       if (index === currentSceneIndex) {
  //         const updatedArray = scene[elementType][innerElementType].map((item, i) => {
  //           if (i === itemIndex) {
  //             return {
  //               ...item,
  //               handle: false,
  //             };
  //           }
  //           return item;
  //         });
  
  //         return {
  //           ...scene,
  //           [elementType]: {
  //             ...scene[elementType],
  //             [innerElementType]: updatedArray,
  //           },
  //         };
  //       }
  //       return scene;
  //     });
  //   });
  // };

  const handleModelStyle = {
    bottomRight: { display: scenes[currentSceneIndex]?.['selectedModel']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '7px', height: '7px', bottom: '-4px', right: '-4px' },
    bottomLeft: { display: scenes[currentSceneIndex]?.['selectedModel']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '7px', height: '7px', bottom: '-4px', left: '-4px' },
    topRight: { display: scenes[currentSceneIndex]?.['selectedModel']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '7px', height: '7px', top: '-4px', right: '-4px' },
    topLeft: { display: scenes[currentSceneIndex]?.['selectedModel']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '7px', height: '7px', top: '-4px', left: '-4px' },
    right: { display: scenes[currentSceneIndex]?.['selectedModel']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '2px', height: '30px', top: '50%', right: '-1px', transform: 'translateY(-50%)' },
    left: { display: scenes[currentSceneIndex]?.['selectedModel']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '2px', height: '30px', top: '50%', left: '-1px', transform: 'translateY(-50%)' },
    top: { display: scenes[currentSceneIndex]?.['selectedModel']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '30px', height: '2px', left: '50%', top: '-1px', transform: 'translateX(-50%)' },
    bottom: { display: scenes[currentSceneIndex]?.['selectedModel']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '30px', height: '2px', left: '50%', bottom: '-1px', transform: 'translateX(-50%)' },
  };
  
  const handleVideoStyle = {
    bottomRight: { display: scenes[currentSceneIndex]?.['selectedVideo']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '7px', height: '7px', bottom: '-4px', right: '-4px' },
    bottomLeft: { display: scenes[currentSceneIndex]?.['selectedVideo']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '7px', height: '7px', bottom: '-4px', left: '-4px' },
    topRight: { display: scenes[currentSceneIndex]?.['selectedVideo']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '7px', height: '7px', top: '-4px', right: '-4px' },
    topLeft: { display: scenes[currentSceneIndex]?.['selectedVideo']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '7px', height: '7px', top: '-4px', left: '-4px' },
    right: { display: scenes[currentSceneIndex]?.['selectedVideo']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '2px', height: '30px', top: '50%', right: '-1px', transform: 'translateY(-50%)' },
    left: { display: scenes[currentSceneIndex]?.['selectedVideo']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '2px', height: '30px', top: '50%', left: '-1px', transform: 'translateY(-50%)' },
    top: { display: scenes[currentSceneIndex]?.['selectedVideo']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '30px', height: '2px', left: '50%', top: '-1px', transform: 'translateX(-50%)' },
    bottom: { display: scenes[currentSceneIndex]?.['selectedVideo']?.handle ? 'block' : 'none', backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid', width: '30px', height: '2px', left: '50%', bottom: '-1px', transform: 'translateX(-50%)' },
  };

  const handleStyle = (index, type) => {
    return {
      bottomRight: {
        display: scenes[currentSceneIndex]?.[type][index]?.handle ? 'block' : 'none',
        backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', 
        border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid',
        width: '7px',
        height: '7px',
        bottom: '-4px',
        right: '-4px',
      },
      bottomLeft: {
        display: scenes[currentSceneIndex]?.[type][index]?.handle ? 'block' : 'none',
        backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', 
        border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid',
        width: '7px',
        height: '7px',
        bottom: '-4px',
        left: '-4px',
      },
      topRight: {
        display: scenes[currentSceneIndex]?.[type][index]?.handle ? 'block' : 'none',
        backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', 
        border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid',
        width: '7px',
        height: '7px',
        top: '-4px',
        right: '-4px',
      },
      topLeft: {
        display: scenes[currentSceneIndex]?.[type][index]?.handle ? 'block' : 'none',
        backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', 
        border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid',
        width: '7px',
        height: '7px',
        top: '-4px',
        left: '-4px',
      },
      right: {
        display: scenes[currentSceneIndex]?.[type][index]?.handle ? 'block' : 'none',
        backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', 
        border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid',
        width: '2px',
        height: '30px',
        top: '50%',
        right: '-1px',
        transform: 'translateY(-50%)',
      },
      left: {
        display: scenes[currentSceneIndex]?.[type][index]?.handle ? 'block' : 'none',
        backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', 
        border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid',
        width: '2px',
        height: '30px',
        top: '50%',
        left: '-1px',
        transform: 'translateY(-50%)',
      },
      top: {
        display: scenes[currentSceneIndex]?.[type][index]?.handle ? 'block' : 'none',
        backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', 
        border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid',
        width: '30px',
        height: '2px',
        left: '50%',
        top: '-1px',
        transform: 'translateX(-50%)',
      },
      bottom: {
        display: scenes[currentSceneIndex]?.[type][index]?.handle ? 'block' : 'none',
        backgroundColor: selectedBackground === '#000000' ? 'white' : 'black', 
        border: selectedBackground === '#000000' ? '1px solid white' : 'black 1px solid',
        width: '30px',
        height: '2px',
        left: '50%',
        bottom: '-1px',
        transform: 'translateX(-50%)',
      },
    };
  };
  
  // const handleTextStyle = (index, type, innerType) => {
  //   return {
  //     bottomRight: {
  //       display: scenes[currentSceneIndex]?.[type][innerType][index]?.handle ? 'block' : 'none',
  //       backgroundColor: 'white',
  //       border: '1px solid white',
  //       width: '7px',
  //       height: '7px',
  //       bottom: '-4px',
  //       right: '-4px',
  //     },
  //     bottomLeft: {
  //       display: scenes[currentSceneIndex]?.[type][innerType][index]?.handle ? 'block' : 'none',
  //       backgroundColor: 'white',
  //       border: '1px solid white',
  //       width: '7px',
  //       height: '7px',
  //       bottom: '-4px',
  //       left: '-4px',
  //     },
  //     topRight: {
  //       display: scenes[currentSceneIndex]?.[type][innerType][index]?.handle ? 'block' : 'none',
  //       backgroundColor: 'white',
  //       border: '1px solid white',
  //       width: '7px',
  //       height: '7px',
  //       top: '-4px',
  //       right: '-4px',
  //     },
  //     topLeft: {
  //       display: scenes[currentSceneIndex]?.[type][innerType][index]?.handle ? 'block' : 'none',
  //       backgroundColor: 'white',
  //       border: '1px solid white',
  //       width: '7px',
  //       height: '7px',
  //       top: '-4px',
  //       left: '-4px',
  //     },
  //     right: {
  //       display: scenes[currentSceneIndex]?.[type][innerType][index]?.handle ? 'block' : 'none',
  //       backgroundColor: 'white',
  //       border: '1px solid white',
  //       width: '2px',
  //       height: '30px',
  //       top: '50%',
  //       right: '-1px',
  //       transform: 'translateY(-50%)',
  //     },
  //     left: {
  //       display: scenes[currentSceneIndex]?.[type][innerType][index]?.handle ? 'block' : 'none',
  //       backgroundColor: 'white',
  //       border: '1px solid white',
  //       width: '2px',
  //       height: '30px',
  //       top: '50%',
  //       left: '-1px',
  //       transform: 'translateY(-50%)',
  //     },
  //     top: {
  //       display: scenes[currentSceneIndex]?.[type][innerType][index]?.handle ? 'block' : 'none',
  //       backgroundColor: 'white',
  //       border: '1px solid white',
  //       width: '30px',
  //       height: '2px',
  //       left: '50%',
  //       top: '-1px',
  //       transform: 'translateX(-50%)',
  //     },
  //     bottom: {
  //       display: scenes[currentSceneIndex]?.[type][innerType][index]?.handle ? 'block' : 'none',
  //       backgroundColor: 'white',
  //       border: '1px solid white',
  //       width: '30px',
  //       height: '2px',
  //       left: '50%',
  //       bottom: '-1px',
  //       transform: 'translateX(-50%)',
  //     },
  //   };
  // };

  const captureSnapshot = async (index) => {
    try {
      const canvas = await html2canvas(componentRef.current, { useCORS: true, allowTaint: true, scale: 2 });
      const dataURL = canvas.toDataURL();
      setScenes((prevScenes) => {
        const updatedScenes = [...prevScenes];
        if (index < updatedScenes.length) {
          updatedScenes[index].snapshot = dataURL;
        }
        return updatedScenes;
      });
    } catch (error) {
      console.error('Error capturing snapshot:', error);
    }
  };

  useEffect(() => {
    captureSnapshot(currentSceneIndex);
    const captureInterval = setInterval(() => captureSnapshot(currentSceneIndex), 3000);

    return () => {
      clearInterval(captureInterval);
    };
    //eslint-disable-next-line
  }, [currentSceneIndex]);


  const [bgTouch, setBgTouch] = useState(false);

  const apiKey = '39ac8d95df8d5d45639e30ca0cbcdfbb';

  const fetchAvailableVoices = async () => {
    const url = 'https://api.elevenlabs.io/v1/voices';
    const headers = {
      Accept: 'application/json',
      'xi-api-key': apiKey,
    };

    try {
      const response = await axios.get(url, { headers });
      return response.data.voices;
    } catch (error) {
      console.error('Error fetching available voices:', error);
      return [];
    }
  };

  // const handleDrag = (e, ui) => {
  //   const { x, y } = ui;
  //   const updatedScenes = scenes.map((scene, index) => {
  //     if (index === currentSceneIndex && scene.selectedModel) {
  //       return {
  //         ...scene,
  //         selectedModel: {
  //           ...scene.selectedModel,
  //           x,
  //           y,
  //         },
  //       };
  //     }
  //     return scene;
  //   });
  //   setScenes(updatedScenes);
  // }

  const handleDrag = (event, ui, sceneIndex, elementType, itemIndex) => {
    const { x, y } = ui;
  
    if (Array.isArray(scenes[sceneIndex][elementType])) {
      const updatedArray = scenes[sceneIndex][elementType].map((item, index) => {
        if (index === itemIndex) {
          return {
            ...item,
            x,
            y,
          };
        }
        return item;
      });

      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === sceneIndex) {
            return {
              ...scene,
              [elementType]: updatedArray,
            };
          }
          return scene;
        });
      });
    } else {
      const updatedElement = {
        ...scenes[sceneIndex][elementType],
        x,
        y,
      };

      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === sceneIndex) {
            return {
              ...scene,
              [elementType]: updatedElement,
            };
          }
          return scene;
        });
      });
    }
  };

  const handleTextDrag = (event, ui, sceneIndex, textArrayKey, itemIndex) => {
    const { x, y } = ui;
  
    setScenes((prevScenes) => {
      return prevScenes.map((scene, index) => {
        if (index === sceneIndex) {
          const updatedTextArray = {
            ...scene.textArrays[textArrayKey][itemIndex],
            x,
            y,
          };
  
          const updatedTextArrays = [...scene.textArrays[textArrayKey]];
          updatedTextArrays[itemIndex] = updatedTextArray;
  
          return {
            ...scene,
            textArrays: {
              ...scene.textArrays,
              [textArrayKey]: updatedTextArrays,
            },
          };
        }
        return scene;
      });
    });
  }; 

  const fetchData = async () => {
    const availableVoices = await fetchAvailableVoices();

    const premadeVoices = availableVoices.filter(option => option.category === 'premade');
    const combinedOptions = [
      ...premadeVoices.map(option => ({ value: option.voice_id, label: option.name, audioUrl: option.preview_url })),
    ];

    setVoiceOption(combinedOptions);
  };

  const handlePlayPauseClick = (voiceId, isPlaying) => {
    if (isPlaying) {
      setCurrentlyPlaying(voiceId);
    } else {
      setCurrentlyPlaying(null);
    }
  };


  useEffect(() => {
    // const interval = setInterval(() => {
    //     setCurrentDate(new Date());
    // }, 1000);

    // return () => {
    //     clearInterval(interval);
    // };
    // setCurrentDate(new Date());
    fetchData();
    setVideoName('untitled');
    //eslint-disable-next-line
  }, []);

  const handleVideoName = (e) => {
    setVideoName(e.target.value);
  }

  const breakOptions = [{value: '0.5s', label: '0.5s'}, {value: '1s', label: '1s'}, {value: '1.5s', label: '1.5s'}];
  const speedOptions = [
    {value: '0.5x', label: '0.5x'}, 
    {value: '0.6x', label: '0.6x'}, 
    {value: '0.7x', label: '0.7x'}, 
    {value: '0.8x', label: '0.8x'}, 
    {value: '0.9x', label: '0.9x'}, 
    {value: '1.0x', label: '1.0x'}, 
    {value: '1.5x', label: '1.5x'}, 
    {value: '2.0x', label: '2.0x'}, 
    {value: '3.0x', label: '3.0x'}, 
    {value: '4.0x', label: '4.0x'}, 
    {value: '5.0x', label: '5.0x'}, 
  ]
  
  const resolutionOptions = [
    { value: '144p (4:3)', label: '144p (4:3)', aspectRatio: '4:3', expectedSize: {width: 256, height: 144}, },
    { value: '240p (4:3)', label: '240p (4:3)', aspectRatio: '4:3', expectedSize: {width: 320, height: 240}, },
    { value: '360p (4:3)', label: '360p (4:3)', aspectRatio: '4:3', expectedSize: {width: 640, height: 360}, },
    { value: '720p (16:9)', label: '720p (16:9)', aspectRatio: '16:9', expectedSize: {width: 1280, height: 720}, },
    { value: '1080p (16:9)', label: '1080p (16:9)', aspectRatio: '16:9', expectedSize: {width: 1920, height: 1080}, },
  ]

  const languageOptions = [
    { value: "eleven_monolingual_v1", label: "English" },
    { value: "eleven_multilingual_v1", label: "German" },
    { value: "eleven_multilingual_v1", label: "Polish" },
    { value: "eleven_multilingual_v1", label: "Spanish" },
    { value: "eleven_multilingual_v1", label: "Italian" },
    { value: "eleven_multilingual_v1", label: "French" },
    { value: "eleven_multilingual_v1", label: "Portugeese" },
    { value: "eleven_multilingual_v1", label: "Hindi" },
  ]

  const handleBreakOptionChange = (selectedOption) => {
    setScenes((prevScenes) => {
      const newScenes = [...prevScenes];
      newScenes[currentSceneIndex] = {
        ...newScenes[currentSceneIndex],
        audio: {
          ...newScenes[currentSceneIndex].audio,
          selectBreak: selectedOption.value,
        },
      };
      return newScenes;
    });
  };
  
  const handleSpeedOptionChange = (selectedOption) => {
    setScenes((prevScenes) => {
      const newScenes = [...prevScenes];
      newScenes[currentSceneIndex] = {
        ...newScenes[currentSceneIndex],
        audio: {
          ...newScenes[currentSceneIndex].audio,
          selectSpeed: selectedOption.value,
        },
      };
      return newScenes;
    });
  };
  
  // const handleResolutionOptionChange = (selectedOption) => {
  //   let newRes = {};

  //   switch(selectedOption.value) {
  //     case '144p (4:3)':
  //       newRes = {width: 256, height: 144, type: selectedOption.value};
  //       break;
  //     case '240p (4:3)':
  //       newRes = {width: 320, height: 240, type: selectedOption.value};
  //       break;
  //     case '360p (4:3)':
  //       newRes = {width: 640, height: 360, type: selectedOption.value};
  //       break;
  //     case '720p (16:9)':
  //       newRes = {width: 1280, height: 720, type: selectedOption.value};
  //       break;
  //     case '1080p (16:9)':
  //       newRes = {width: 1920, height: 1080, type: selectedOption.value};
  //       break;
  //     default: 
  //       newRes = {width: 640, height: 360, type: "360p (4:3)"};
  //       break;
  //   }

  //   setScenes((prevScenes) => {
  //     const newScenes = [...prevScenes];
  //     newScenes[currentSceneIndex] = {
  //       ...newScenes[currentSceneIndex],
  //       resolution: newRes,
  //     };
  //     return newScenes;
  //   });
  // };

  const handleResolutionOptionChange = (selectedOption) => {
    const baseHeight = 360; 
    const newRes = calculateDimensions(selectedOption.aspectRatio, baseHeight);

    setScenes((prevScenes) => {
      const newScenes = [...prevScenes];
      newScenes[currentSceneIndex] = {
        ...newScenes[currentSceneIndex],
        resolution: {
          ...newRes,
          type: selectedOption.value,
          expectedSize: selectedOption.expectedSize,
        },
      };
      return newScenes;
    });
  };
  
  const handleLanguageOptionChange = (selectedOption) => {
    setScenes((prevScenes) => {
      const newScenes = [...prevScenes];
      newScenes[currentSceneIndex] = {
        ...newScenes[currentSceneIndex],
        audio: {
          ...newScenes[currentSceneIndex].audio,
          selectLanguage: selectedOption.label,
        },
      };
      return newScenes;
    });
  };

  const handleVoiceOptionChange = (selectedOption) => {
    setScenes((prevScenes) => {
      const newScenes = [...prevScenes];
      newScenes[currentSceneIndex] = {
        ...newScenes[currentSceneIndex],
        audio: {
          ...newScenes[currentSceneIndex].audio,
          selectVoice: {
            value: selectedOption.value,
            label: selectedOption.label,
          },
        },
      };
      return newScenes;
    });
  };

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  const handleOpen = (variant) => {
    const updatedText = {};

    for (const key in scenes[currentSceneIndex].textToggle) {
      updatedText[key] = false;
    }
    updatedText[variant] = true;
    updateSceneKey(currentSceneIndex, 'textToggle', updatedText);
  }

  const handleReOpenText = (variant, index) => {
    const updatedText = {};

    for (const key in scenes[currentSceneIndex].textToggle) {
      updatedText[key] = false;
    }
    updatedText[variant] = scenes[currentSceneIndex].textArrays[variant][index];
    updateSceneKey(currentSceneIndex, 'textToggle', updatedText);
  }

  const handleClick = (prop, variant) => {
    const updatedInnerTouched = {};

    for (const key in scenes[currentSceneIndex].textToggle) {
      updatedInnerTouched[key] = false;
    }
    updateSceneKey(currentSceneIndex, 'textToggle', updatedInnerTouched);

    setOpenSettings({
      model: false,
      text: false,
      image: false,
      video: false,
      audio: false,
      shape: false,
      intro: false,
      outro: false,
    })
    const updatedTouched = {};

    for (const key in touched) {
      updatedTouched[key] = false;
    }
    updatedTouched[prop] = true;
    setTouched(updatedTouched);

  }

  const handleReOpen = (prop, variant, index) => {

    const updatedTouched = {};

    for (const key in touched) {
      updatedTouched[key] = false;
    }
    updatedTouched[prop] = true;
    setTouched(updatedTouched);

    prop === 'text' && handleReOpenText(variant, index);

    switch(prop) {
      case 'model':
        setOpenSettings({
          ...openSettings,
          model: scenes[currentSceneIndex].selectedModel,
        })
        break;
      case 'image':
        setOpenSettings({
          ...openSettings,
          image: scenes[currentSceneIndex].selectedImage[variant],
        })
        break;
      case 'video':
        setOpenSettings({
          ...openSettings,
          video: scenes[currentSceneIndex].selectedVideo,
        })
        break;
      case 'intro':
        setOpenSettings({
          ...openSettings,
          intro: intro,
        })
        break;
      case 'outro':
        setOpenSettings({
          ...openSettings,
          outro: outro,
        })
        break;
      case 'shapes':
        setOpenSettings({
          ...openSettings,
          shape: scenes[currentSceneIndex].selectedShape[variant],
        })
        break;
      // case 'text':
      //   setOpenSettings({
      //     ...openSettings,
      //     text: scenes[currentSceneIndex].textArrays[variant][index],
      //   })
      //   break;
      default: 
        break;
    }
  }

  useEffect(() => {
    uploadedBackground !== null ? updateSceneKey(currentSceneIndex, 'background', uploadedBackground) : updateSceneKey(currentSceneIndex, 'background', selectedBackground)
    //eslint-disable-next-line
  }, [selectedBackground, uploadedBackground])

  const handleTouch = (name, variant) => {
    setBgTouch(!bgTouch);
    handleClick(name, variant);
  }

  function isImage(file) {
    if (file.type && file.type.startsWith('image/')) {
      return true;
    }
  
    if (file.type && file.type.startsWith('blob:')) {
      const extension = file.name.split('.').pop().toLowerCase();
      return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension);
    }
  
    return false;
  }

  const getBackgroundImageStyle = (background) => {
    if (typeof background === 'string') {
      if (background.includes('image')) {
        return `url(${background}) no-repeat center center / cover`;
      } else {
        return background;
      }
    } else {
      return background;
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
  }

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const handleSeek = (event) => {
    const seekTime = event.target.value;
    videoRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  const history = useHistory();

  function handleLogoClick() {
    history.push("/create");
  }

  const handleShowIO = (type) => {
    setShowIO(prevIO => ({
      ...prevIO,
      [type]: true,
    }))
  }

  const handleOpenIO = (prop) => {
    const updatedTouched = {};

    for (const key in touched) {
      updatedTouched[key] = false;
    }
    updatedTouched[prop] = true;
    setTouched(updatedTouched);
  }

  const handleCloseIO = () => {
    setShowIO({
      intro: false,
      outro: false,
    });
    setTouched({
      template: false,
      model: true,
      text: false,
      subtitle: false,
      image: false,
      background: false,
      video: false,
      audio: false,
      shapes: false,
      animation: false,
    })
  }

  const isAnyShowIOTrue = () => {
    return Object.values(showIO).some(value => value === true);
  };

  return (
    <VuiBox sx={{display: 'flex', marginTop: '20px', flexDirection: 'column', padding: '0 20px'}}  className={plan==='Trial' && "blurred-content"}>
      <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px'}}>
        <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between',}}>
          {/* <ArrowBackIcon color="white" onClick={() => setCreateVideo(false)} sx={{cursor: 'pointer'}} /> */}
          <ArrowBackIcon color="white" onClick={handleLeaveClick} sx={{cursor: 'pointer'}} />
        </VuiBox>
        <div style={{position: 'absolute', top: '3%', cursor: 'pointer', zIndex: 10}} onClick={handleLogoClick}>
          <img src={Logo} alt="logo" width='200px' />
        </div>
        {!changeInput && <Tooltip title='click here to change the name of project'><Typography sx={{cursor: 'pointer', borderBottom: '1px solid'}} onClick={() => setChangeInput(true)}>{videoName}</Typography></Tooltip>}
        {changeInput && 
          <VuiBox sx={{display: 'flex', alignItems: 'center'}}>
            <VuiInput 
              value={videoName} 
              onChange={(e) => handleVideoName(e)} 
              icon={{
                component: <IoPencilOutline />,
                direction: "right",
              }}
            />
            <SaveIcon sx={{marginLeft: '10px', cursor: 'pointer'}} onClick={() => setChangeInput(false)} color='white' />
          </VuiBox>
        }
        <Tooltip title={validateError} placement='left'>
          <span>
            <Button variant='contained' sx={{color: 'whitesmoke', '&:disabled': {background: 'gray'}}} onClick={handleSubmit}>Export</Button>
          </span>
        </Tooltip>
      </VuiBox>
      <VuiBox sx={{display: 'flex', alignItems: 'flex-start'}}>
        <VuiBox sx={{flex: 0.2, display: 'flex', flexDirection: 'column'}}>
          <Typography variant='h6' color='whitesmoke' textAlign='left'>Slides</Typography>
          <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px', overflow: 'auto', maxHeight: '70vh'}}>

            {intro ? 
              <VuiBox sx={{margin: '20px 0px'}}>
                <VuiBox sx={{height: '13vh', width: '12vw', background: 'transparent', border: '1px dotted white', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', textAlign: 'center', position: 'relative'}} onClick={()=>{handleShowIO('intro'); handleReOpen('intro')}}>
                  <video src={intro.video} width='100%' height='100%'></video>
                </VuiBox> 
                <VuiTypography variant='h6'>Intro Video</VuiTypography>
              </VuiBox>
            : 
              <VuiBox sx={{minHeight: '12vh', maxHeight: '16vh', minWidth: '12vw', background: 'transparent', border: '1px dotted white', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', textAlign: 'center', position: 'relative', margin: '20px 0px'}} onClick={()=>handleOpenIO('intro')}>
                <VuiTypography variant='h6'>Intro Video</VuiTypography>
              </VuiBox>}

            {scenes.map((scene, index) => (
              <VuiBox key={index} sx={{width: '12vw', position: 'relative', cursor: 'pointer'}} onClick={()=>{handleBoxClick(index); handleCloseIO();}}>
                <img src={scene.snapshot} alt={`Slide ${index + 1}`} width='100%' />
                {scenes.length > 1 && <DeleteOutlineIcon color='white' sx={{position: 'absolute', top: '5px', right: '5px', cursor: 'pointer'}} onClick={()=>deleteScene(index)} />}
              </VuiBox>
            ))}

            {outro ? 
              <VuiBox sx={{margin: '20px 0px'}}>
                <VuiBox sx={{height: '13vh', width: '12vw', background: 'transparent', border: '1px dotted white', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', textAlign: 'center', position: 'relative'}} onClick={()=>{handleShowIO('outro'); handleReOpen('outro')}}>
                  <video src={outro.video} width='100%' height='100%'></video>
                </VuiBox> 
                <VuiTypography variant='h6'>Outro Video</VuiTypography>
              </VuiBox>
            : 
              <VuiBox sx={{minHeight: '12vh', maxHeight: '16vh', minWidth: '12vw', background: 'transparent', border: '1px dotted white', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', textAlign: 'center', position: 'relative', margin: '20px 0px'}} onClick={()=>handleOpenIO('outro')}>
                <VuiTypography variant='h6'>Outro Video</VuiTypography>
              </VuiBox>}
            {/* <VuiBox sx={{minHeight: '12vh', maxHeight: '16vh', minWidth: '12vw', background: 'transparent', border: '1px dotted white', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', textAlign: 'center', position: 'relative'}} onClick={addScene}>
              <AddCircleOutlineIcon color='white' sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} />
            </VuiBox> */}
          </VuiBox>
        </VuiBox>
        <VuiBox sx={{flex: 0.6, display: 'flex', flexDirection: 'column', width: '100%'}}>
          {isAnyShowIOTrue() ?
            <VuiBox sx={{height: `${scenes[currentSceneIndex].resolution.height}px`, width: `${scenes[currentSceneIndex].resolution.width}px`, background: 'white', borderRadius: '0px', position: 'relative', overflow: 'hidden', userSelect: 'none', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px', marginLeft: 'auto', marginRight: 'auto', border: '1px solid skyblue'}}>
              {showIO.intro ? <video controls alt='bg' src={intro.video} width='100%' height='100%' onClick={()=>handleReOpen('intro')} style={{cursor: 'pointer', position: 'absolute'}} /> : <video controls alt='bg' src={outro.video} width='100%' height='100%' onClick={()=>handleReOpen('outro')} style={{cursor: 'pointer', position: 'absolute'}} />}
            </VuiBox> 
            :
          <VuiBox sx={{height: `${scenes[currentSceneIndex].resolution.height}px`, width: `${scenes[currentSceneIndex].resolution.width}px`, background: uploadedBackground !== null ? `url(${uploadedBackground})` : selectedBackground, backgroundRepeat: uploadedBackground && "no-repeat", backgroundSize: uploadedBackground && "cover", backgroundPosition: uploadedBackground && "center", borderRadius: '0px', position: 'relative', overflow: 'hidden', userSelect: 'none', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px', marginLeft: 'auto', marginRight: 'auto'}} ref={componentRef} className="grandparent" key={currentSceneIndex} onContextMenu={handleContextMenu}>
          
            {scenes[currentSceneIndex].selectedModel !== null && <VuiBox sx={{width: '100%'}}>
              <Draggable bounds=".grandparent" onDrag={(event, ui) => handleDrag(event, ui, currentSceneIndex, 'selectedModel')} position={{x: scenes[currentSceneIndex].selectedModel.x, y: scenes[currentSceneIndex].selectedModel.y}}>
                <Resizable
                  lockAspectRatio={true}
                  size={scenes[currentSceneIndex].selectedModel.size}
                  onResizeStop={(e, dir, ref, d)=>handleModelResize(e, dir, ref, d)}
                  style={{cursor: 'pointer', position: 'absolute', scale: `${scenes[currentSceneIndex].selectedModel.scale}%`, zIndex: scenes[currentSceneIndex].selectedModel.zIndex, opacity: scenes[currentSceneIndex].selectedModel.opacity, marginTop: `${scenes[currentSceneIndex].selectedModel.marginTop}%`, marginLeft: `${scenes[currentSceneIndex].selectedModel.marginLeft}%`}}
                  handleStyles={handleModelStyle}
                  onMouseEnter={handleModelMouseEnter}
                  onMouseLeave={handleModelMouseLeave}
                  bounds='.grandparent'
                >
                  <img alt='bg' src={scenes[currentSceneIndex].selectedModel.model} width='100%' height='100%' onClick={()=>handleReOpen('model')} draggable={false} />
                </Resizable>
              </Draggable>
            </VuiBox>}

            {scenes[currentSceneIndex].selectedImage && <VuiBox sx={{width: '100%'}}>
              {scenes[currentSceneIndex].selectedImage.map((item, index) => (
                <Draggable key={index} bounds=".grandparent" onDrag={(event, ui) => handleDrag(event, ui, currentSceneIndex, 'selectedImage', index)} position={{x: item.x, y: item.y}}>
                  <Resizable
                    size={item.size}
                    onResizeStop={(e, dir, ref, d)=>handleArrayResize(e, dir, ref, d, item, index, 'selectedImage')}
                    style={{cursor: 'pointer', position: 'absolute', zIndex: item.zIndex, opacity: item.opacity, marginTop: `${item.marginTop}%`, marginLeft: `${item.marginLeft}%`}}
                    handleStyles={handleStyle(index, 'selectedImage')}
                    onMouseEnter={()=>handleMouseEnter(index, 'selectedImage')}
                    onMouseLeave={()=>handleMouseLeave(index, 'selectedImage')}
                    bounds='.grandparent'
                  >
                    <img alt='bg' width='100%' height='100%' src={item.image} onClick={()=>handleReOpen('image', index)} draggable={false}
                    // style={{ transform: `scale(${item.scale / 100})` }} 
                    />
                  </Resizable>
                </Draggable>
              ))}
            </VuiBox>}

            {scenes[currentSceneIndex].selectedShape && <VuiBox sx={{width: '100%'}}>
              {/* {bgTouch && <div style={{position: 'absolute', margin: '10px'}}>
                <ThreeDotsMenu />
              </div>} */}
              {scenes[currentSceneIndex].selectedShape.map((item, index) => (
                <Draggable key={index} bounds=".grandparent" onDrag={(event, ui) => handleDrag(event, ui, currentSceneIndex, 'selectedShape', index)} position={{x: item.x, y: item.y}}>
                  <Resizable
                    lockAspectRatio={true}
                    size={item.size}
                    onResizeStop={(e, dir, ref, d)=>handleArrayResize(e, dir, ref, d, item, index, 'selectedShape')}
                    style={{cursor: 'pointer', position: 'absolute', zIndex: item.zIndex, opacity: item.opacity, marginTop: `${item.marginTop}%`, marginLeft: `${item.marginLeft}%`}}
                    handleStyles={handleStyle(index, 'selectedShape')}
                    onMouseEnter={()=>handleMouseEnter(index, 'selectedShape')}
                    onMouseLeave={()=>handleMouseLeave(index, 'selectedShape')}
                    bounds='.grandparent'
                  >
                    <img alt='bg' width='100%' height='100%' src={item.image} onClick={()=>handleReOpen('shapes', index)} draggable={false}
                    
                    // style={{ transform: `scale(${item.scale / 100})` }} 
                    />
                  </Resizable>
                </Draggable>
              ))}
            </VuiBox>}

            {scenes[currentSceneIndex].selectedVideo && <VuiBox sx={{width: '100%', height: '100%'}}>
              <video crossOrigin="anonymous" controls alt='bg' src={scenes[currentSceneIndex].selectedVideo.video} width='100%' height='100%' />
            </VuiBox>}

            {scenes[currentSceneIndex].textArrays.header.length > 0 && <VuiBox sx={{width: '100%'}}>
              {/* {bgTouch && <div style={{position: 'absolute', margin: '10px'}}>
                <ThreeDotsMenu />
              </div>} */}
              {scenes[currentSceneIndex].textArrays.header.map((item, index) => 
                <Draggable key={index} bounds=".grandparent" onDrag={(event, ui) => handleTextDrag(event, ui, currentSceneIndex, 'header', index)} position={{x: item.x, y: item.y}}>
                  {/* <Resizable
                    size={item.size}
                    onResizeStop={(e, dir, ref, d)=>handleTextResize(e, dir, ref, d, item.size, index, 'textArrays', 'header')}
                    style={{outline: `${item.border}px solid ${item.borderColor}`, padding: `${item.padding}px`, cursor: 'pointer', position: 'absolute', background: item.background, scale: `${item.scale}%`, zIndex: item.zIndex + 1, opacity: item.opacity, marginTop: `${item.marginTop}%`, marginLeft: `${item.marginLeft}%`, fontFamily: item.font}}
                    handleStyles={handleTextStyle(index, 'textArrays', 'header')}
                    onMouseEnter={()=>handleTextMouseEnter(index, 'textArrays', 'header')}
                    onMouseLeave={()=>handleTextMouseLeave(index, 'textArrays', 'header')}
                    bounds='.grandparent'
                  > */}
                    <VuiBox sx={{outline: `${item.border}px solid ${item.borderColor}`, padding: `${item.padding}px`, cursor: 'pointer', position: 'absolute', width: 'fit-content', maxWidth: '100%',  background: item.background, scale: `${item.scale}%`, zIndex: item.zIndex + 1, opacity: item.opacity, marginTop: `${item.marginTop}%`, marginLeft: `${item.marginLeft}%`, fontFamily: item.font}} onClick={()=>handleReOpen('text', 'header', index)}>
                      <p style={{color: item.color, fontSize: `${item.fontSize}px`, wordBreak: 'break-word'}}>{item.text}</p>
                    </VuiBox>
                  {/* </Resizable> */}
                </Draggable>
              )}
            </VuiBox>}

            {scenes[currentSceneIndex].textArrays.title.length > 0 && <VuiBox>
              {/* {bgTouch && <div style={{position: 'absolute', margin: '10px'}}>
                <ThreeDotsMenu />
              </div>} */}
              {scenes[currentSceneIndex].textArrays.title.map((item, index) => 
                <Draggable key={index} bounds=".grandparent" onDrag={(event, ui) => handleTextDrag(event, ui, currentSceneIndex, 'title', index)} position={{x: item.x, y: item.y}}>
                  <VuiBox sx={{border: `${item.border}px solid ${item.borderColor}`, color: 'white', width: 'fit-content', padding: `${item.padding}px`, cursor: 'pointer', position: 'absolute', maxWidth: '100%', background: item.background, scale: `${item.scale}%`, zIndex: item.zIndex, opacity: item.opacity, marginTop: `${item.marginTop}px`, marginLeft: `${item.marginLeft}px`, fontFamily: item.font}} onClick={()=>handleReOpen('text', 'title', index)}>
                    <p style={{color: item.color, fontSize: `${item.fontSize}px`, wordBreak: 'break-word'}}>{item.text}</p>
                  </VuiBox>
                </Draggable>
              )}
            </VuiBox>}

            {scenes[currentSceneIndex].textArrays.main_text.length > 0 && <VuiBox>
              {/* {bgTouch && <div style={{position: 'absolute', margin: '10px'}}>
                <ThreeDotsMenu />
              </div>} */}
              {scenes[currentSceneIndex].textArrays.main_text.map((item, index) => 
                <Draggable key={index} bounds=".grandparent" onDrag={(event, ui) => handleTextDrag(event, ui, currentSceneIndex, 'main_text', index)} position={{x: item.x, y: item.y}}>
                  <VuiBox sx={{border: `${item.border}px solid ${item.borderColor}`, color: 'white', width: 'fit-content', padding: `${item.padding}px`, cursor: 'pointer', position: 'absolute', maxWidth: '100%', background: item.background, scale: `${item.scale}%`, zIndex: item.zIndex, opacity: item.opacity, marginTop: `${item.marginTop}px`, marginLeft: `${item.marginLeft}px`, fontFamily: item.font}} onClick={()=>handleReOpen('text', 'main_text', index)}>
                    <p style={{color: item.color, fontSize: `${item.fontSize}px`, wordBreak: 'break-word'}}>{item.text}</p>
                  </VuiBox>
                </Draggable>
              )}
            </VuiBox>}
            
            {scenes[currentSceneIndex].textArrays.caption.length > 0 && <VuiBox>
              {/* {bgTouch && <div style={{position: 'absolute', margin: '10px'}}>
                <ThreeDotsMenu />
              </div>} */}
              {scenes[currentSceneIndex].textArrays.caption.map((item, index) => 
                <Draggable key={index} bounds=".grandparent" onDrag={(event, ui) => handleTextDrag(event, ui, currentSceneIndex, 'caption', index)} position={{x: item.x, y: item.y}}>
                  <VuiBox sx={{border: `${item.border}px solid ${item.borderColor}`, color: 'white', width: 'fit-content', padding: `${item.padding}px`, cursor: 'pointer', position: 'absolute', maxWidth: '100%', background: item.background, scale: `${item.scale}%`, zIndex: item.zIndex, opacity: item.opacity, marginTop: `${item.marginTop}px`, marginLeft: `${item.marginLeft}px`, fontFamily: item.font}} onClick={()=>handleReOpen('text', 'caption', index)}>
                    <p style={{color: item.color, fontSize: `${item.fontSize}px`, wordBreak: 'break-word'}}>{item.text}</p>
                  </VuiBox>
                </Draggable>
              )}
            </VuiBox>}
            {plan === 'Lite' && <Tooltip title='Upgrade your plan to remove watermark'>
              <VuiBox sx={{position: 'absolute', bottom: 5, left: 5, zIndex: 1000, background: 'black', padding: '0px 10px', borderRadius: '10px', cursor: 'pointer'}}>
                <img src={logo} alt="watermark" width={100} style={{mixBlendMode: 'difference'}} />
              </VuiBox>
            </Tooltip>}
          </VuiBox>}
          {/* {scenes[currentSceneIndex].selectedVideo.length > 0 && <VuiBox className="custom-seek-bar-container">
            {isPlaying ? <PauseCircleIcon fontSize='large' color='white' onClick={handlePlayPause} sx={{marginRight: '10px'}} /> : <PlayCircleIcon fontSize='large' color='white' onClick={handlePlayPause} sx={{marginRight: '10px'}} />}
            <input
              className="custom-seek-bar"
              type="range"
              style={{width: videoRef?.current?.duration * 20}}
              value={currentTime}
              max={videoRef.current && videoRef.current.duration}
              onChange={handleSeek}
              orient='vertical'
            />
          </VuiBox>} */}
          <VuiBox sx={{display: 'flex', justifyContent: 'space-between', marginTop: '10px', opacity: isAnyShowIOTrue() && 0.5, pointerEvents: isAnyShowIOTrue() && 'none' }}>
            <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
              <VuiSelect 
                placeholder="English"
                options={languageOptions}
                onChange={handleLanguageOptionChange}
                value={{label: scenes[currentSceneIndex].audio.selectLanguage}}
              />
              <VuiSelect 
                placeholder="Voice"
                options={voiceOption}
                onChange={handleVoiceOptionChange}
                value={{label: scenes[currentSceneIndex].audio.selectVoice.label}}
                enableAudio={true}
                currentlyPlaying={currentlyPlaying}
                onPlayPauseClick={handlePlayPauseClick}
              />
              {/* <VuiSelect 
                placeholder="1.0x"
                options={speedOptions}
                onChange={handleSpeedOptionChange}
                value={{label: scenes[currentSceneIndex].audio.selectSpeed}}
              /> */}
              <VuiSelect 
                placeholder="1.0x"
                options={resolutionOptions}
                onChange={handleResolutionOptionChange}
                value={{label: scenes[currentSceneIndex].resolution.type}}
              />
            </VuiBox>
          </VuiBox>
          <VuiBox sx={{display: 'flex', marginTop: '20px', justifyContent: 'space-between', flexDirection: 'column', opacity: isAnyShowIOTrue() && 0.5, pointerEvents: isAnyShowIOTrue() && 'none'}}>
            <>
              <Typography variant='h6' color='whitesmoke' textAlign='left'>Script</Typography>
              <VuiInput 
                placeholder="Type your script here..."
                multiline
                minRows={6}
                maxRows={10} 
                sx={{marginTop: '10px'}}
                value={scenes[currentSceneIndex].script}
                onChange={(e)=>{updateSceneKey(currentSceneIndex, 'script', e.target.value); setChatgptText(e.target.value)}}
              />
              <VuiBox
                sx={{
                  width: "600px",
                  borderRadius: "5px",
                  marginTop: "2%",
                  height: "48px",
                  display: "flex",
                  overflow: "hidden",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Character count" placement="top">
                  <Button sx={{border: '1px solid gray', color: 'whitesmoke', fontSize: '16px'}}>{scriptCharacterCount ? scriptCharacterCount : 0}</Button>
                </Tooltip>
                <Tooltip title="Continue your text using AI" placement="top">
                  <Button variant='contained' sx={{margin: '5px', color: 'whitesmoke'}} onClick={()=>handleChatgptText()}>
                    Text using AI <IoMdColorWand sx={{margin: '5px'}} />
                  </Button>
                </Tooltip>
              </VuiBox>
            </>
          </VuiBox>
        </VuiBox>
        <VuiBox sx={{flex: 0.4}}>
          <Sidediv 
            touched={touched} 
            setTouched={setTouched}
            alignment={alignment}
            setAlignment={setAlignment}
            value={value}
            setValue={setValue}
            fonts={fonts}
            setFonts={setFonts}
            selectedFont={selectedFont}
            setSelectedFont={setSelectedFont}
            selectedFontSize={selectedFontSize}
            setSelectedFontSize={setSelectedFontSize}
            fontSize={fontSize}
            setFontSize={setFontSize}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            stockImages={stockImages}
            setStockImages={setStockImages}
            imageQuery={imageQuery}
            setImageQuery={setImageQuery}
            page={page}
            setPage={setPage}
            imageObserver={imageObserver}
            videoObserver={videoObserver}
            lastImageRef={lastImageRef}
            loading={loading}
            selectedBackground={selectedBackground}
            setSelectedBackground={setSelectedBackground}
            uploadedBackground={uploadedBackground}
            setUploadedBackground={setUploadedBackground}
            backgroundTabValue={backgroundTabValue}
            setBackgroundTabValue={setBackgroundTabValue}
            uploadedVideo={uploadedVideo}
            setUploadedVideo={setUploadedVideo}
            stockVideos={stockVideos}
            setStockVideos={setStockVideos}
            videoQuery={videoQuery}
            setVideoQuery={setVideoQuery}
            videoPage={videoPage}
            setVideoPage={setVideoPage}
            lastVideoRef={lastVideoRef}
            uploadedAudio={uploadedAudio}
            setUploadedAudio={setUploadedAudio}
            models={models}
            setModels={setModels}
            imageTotalPages={imageTotalPages}
            videoTotalPages={videoTotalPages}
            handleClick={handleClick}
            selectedAudio={selectedAudio}
            setSelectedAudio={setSelectedAudio}
            faceModels={faceModels}
            scenes={scenes}
            setScenes={setScenes}
            updateSceneKey={updateSceneKey}
            currentSceneIndex={currentSceneIndex}
            openSettings={openSettings}
            setOpenSettings={setOpenSettings}
            stockShapes={stockShapes}
            setStockShapes={setStockShapes}
            shapesQuery={shapesQuery}
            setShapesQuery={setShapesQuery}
            indexToStore={indexToStore}
            setIndexToStore={setIndexToStore}
            loadingModal={loadingModal}
            setLoadingModal={setLoadingModal}
            addScene={addScene}
            intro={intro}
            setIntro={setIntro}
            outro={outro}
            setOutro={setOutro}
            uploadedIntroVideos={uploadedIntroVideos}
            setUploadedIntroVideos={setUploadedIntroVideos}
            uploadedOutroVideos={uploadedOutroVideos}
            setUploadedOutroVideos={setUploadedOutroVideos}
            setShowIO={setShowIO}
          />
          {loadingModal &&
            <LoadingModal 
              loadingModal={loadingModal}
              setLoadingModal={setLoadingModal}
            />
          }
        </VuiBox>
      </VuiBox>
      {chatgptModal && 
        <ChatGptModal open={chatgptModal} setOpen={setChatgptModal} makeAPIRequest={makeAPIRequest} chatgptText={chatgptText} setChatgptText={setChatgptText} />
      }
    </VuiBox>
  )
}

export default EditingSection