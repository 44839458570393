// icons
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

//components
import File from "./File";
import Tik from "./Tik";
import Lib from "./library";
import Stock from "./stock";
import Templat from "./template/templat";
// Images



// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React icons

// Vision UI Dashboard React example components
import { useState } from "react";

import { IoCloudUploadOutline, IoFolderOpenOutline, IoLogoTiktok, IoLogoYoutube, IoStar, IoTvOutline, IoVideocamOutline } from "react-icons/io5";
import VuiTypography from "components/VuiTypography";
import RecordScreen from "./RecordScreen";
import VideoRecording from "./VideoRecording";

function Upload() {
  //eslint-disable-next-line
  const [tabsOrientation, setTabsOrientation] = useState("vertical");
  const [tabValue, setTabValue] = useState("upload");

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          px: "auto",
          // mb: 2,
          // maxHeight: "500px",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={({ breakpoints }) => ({
            [breakpoints.up("xs")]: {
              gap: "20px",
            },
            [breakpoints.up("xs")]: {
              gap: "5px",
            },
            [breakpoints.up("xl")]: {
              gap: "5px",
            },
          })}
        >
          <Grid item>
          <AppBar position="static">
              <Tabs
                alignItems="left"
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{
                  background: "transparent",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Tab
                  sx={{ justifyContent: "left" }}
                  labelPlacement="start"
                  icon={<IoCloudUploadOutline color="white" size="18px" />}
                  iconPosition="start"
                  label={<VuiTypography variant="caption" color="light">Upload File</VuiTypography>}
                  value="upload"
                />
                <Tab
                  sx={{ justifyContent: "left" }}
                  labelPlacement="start"
                  icon={<IoLogoTiktok color="white" size="18px"/>}
                  iconPosition="start"
                  label={<VuiTypography variant="caption" color="light">Import from TikTok</VuiTypography>}
                  
                  value="TikTok"
                />
                <Tab
                sx={{ justifyContent: "left" }}
                labelPlacement="start"
                  
                  icon={<IoVideocamOutline color="white" size="18px"/>}
                  iconPosition="start"
                  
                  label={<VuiTypography variant="caption" color="light">Record Video</VuiTypography>}
                  value="video"
                />
                <Tab
                  sx={{ justifyContent: "left" }}
                  labelPlacement="start"
                  
                  icon={<IoTvOutline color="white" size="18px"/>}
                  iconPosition="start"
                  label={<VuiTypography variant="caption" color="light">Record Screen</VuiTypography>}
                  
                  value="screen"
                />
                <Tab
                sx={{ justifyContent: "left" }} labelPlacement="start" icon={<IoFolderOpenOutline color="white" size="18px"/>} iconPosition="start" 
                label={<VuiTypography variant="caption" color="light">Library</VuiTypography>}
                value="library" />
                <Tab
                sx={{ justifyContent: "left" }}
                labelPlacement="start"
                  icon={<IoLogoYoutube color="white" size="18px"/>}
                  iconPosition="start"
                  label={<VuiTypography variant="caption" color="light">Stock videos</VuiTypography>}
                  // label="Stock videos"
                  value="stock"
                />
                <Tab
                sx={{ justifyContent: "left" }}
                  icon={<IoStar color="white" size="12px"/>}
                  iconPosition="start"
                  label={<VuiTypography variant="caption" color="light">Start from Template</VuiTypography>}
                  value="template"
                />
                
                
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>

      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          maxWidth: "lg",
          // maxHeight: 1000,
          // mb: 2,
          px: "auto",
          minHeight: 500,
          width: "80%",
          minWidth: "800px",
          marginLeft: 2,
          overflow: "scroll",
        }}
      >
        {tabValue === "upload" && <File></File>}
        {tabValue === "TikTok" && <Tik></Tik>}
        {tabValue === "video" && <VideoRecording></VideoRecording>}
        {tabValue === "screen" && <RecordScreen></RecordScreen>}
        {tabValue === "library" && <Lib></Lib>}
        {tabValue === "stock" && <Stock></Stock>}
        {tabValue === "template" && <Templat></Templat>}
      </Card>
    </VuiBox>
  );
}

export default Upload;