import { Card } from '@mui/material';
import VuiTypography from 'components/VuiTypography';
import React, { useEffect, useRef, useState } from 'react';
import BackupIcon from "@mui/icons-material/Backup";
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from "notistack";
import axios from 'axios';
import { useVisionUIController } from 'context';
import BASE_URL from 'context/api';

const Upload = ({uploadFile, setUploadFile, setManualSubtitles, setLoading, timestamp, setTimeStamp}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [controller] = useVisionUIController();
  const { userDetails } = controller;
  const { user_id } = userDetails; 

  const removeFile = (event) => {
    event.stopPropagation();
    setUploadFile(null);
  }

  const isFileSizeValid = (file) => {
    const maxSize = 25 * 1024 * 1024;
    return file.size <= maxSize;
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    // if (!isFileSizeValid(file)) {
    //   enqueueSnackbar("File size exceeds the limit of 25MB", {
    //     variant: "Warning",
    //     autoHideDuration: 3000,
    //     anchorOrigin: {
    //       vertical: "top",
    //       horizontal: "right",
    //     },
    //   });
    //   return;
    // }

    setUploadFile(file);

    const formData = new FormData();
    formData.append('video_file', file);
    formData.append('userId', user_id);

    try {
      setLoading(true);
      const response = await axios.post(`${BASE_URL}/video_transcribe`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      setManualSubtitles(response.data);
    } catch (error) {
      console.error('Error transcribing audio:', error);
      setLoading(false);
    }
  };

  return (
    <>
      {uploadFile ? (
        <div style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto'}}>
          <ClearIcon sx={{color: 'whitesmoke', boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)", borderRadius: '100%', position: "absolute", right: 15, top: 15, cursor: 'pointer'}} onClick={removeFile} />
          <video controls style={{ width: "100%", height: "100%", marginTop: '20px' }}>
            <source src={URL.createObjectURL(uploadFile)} type="video/mp4" />
          </video>
          <VuiTypography textAlign='center'>{`${uploadFile.name.slice(0, 20)}...`}</VuiTypography>
        </div>
      ) : (
        <label htmlFor="video-file" style={{cursor: 'pointer'}}>
          <VuiTypography variant="h5" color="white" textAlign= 'center'>
            Upload the video to be personalized
          </VuiTypography>
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "fit-content",
              color: "whitesmoke",
              border: "1px dashed gray",
              cursor: "pointer",
              borderRadius: "15px",
              overflow: "hidden",
              padding: "40px",
              // minWidth: "500px",
              maxWidth: "700px",
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '20px'
            }}
          >
            <input
              accept="video/mp4"
              style={{ display: 'none' }}
              type="file"
              id="video-file"
              name="video-file"
              value={uploadFile}
              onChange={handleFileUpload}
            />
            <BackupIcon sx={{ width: "3em", height: "3em" }} />
            <h3 style={{fontWeight: 300}}>Drag and drop files here</h3>
            <h4>or</h4>
            <h4
              style={{
                padding: "5px 15px",
                marginTop: "5px",
                border: "1px solid gray",
                borderRadius: "5px",
                fontWeight: 400
              }}
            >
              Browse Files
            </h4>
          </Card>
        </label>
      )}
    </>
  )
}

export default Upload;