/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";

function Bill({ name, subscription, date, time, days, active }) {
  const { gradients } = colors;
  const { bill } = gradients;

  return (
    <VuiBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ background: linearGradient(bill.main, bill.state, bill.deg) }}
      borderRadius="lg"
      p="24px"
      // mb={noGutter ? "0px" : "24px"}
      mt="20px"
    >
      <VuiBox width="100%" display="flex" flexDirection="column">
        
        <VuiBox mb={1} lineHeight={0}>
          {/* <VuiTypography variant="caption" color="text">
            Plan:&nbsp;&nbsp;&nbsp;
            
          </VuiTypography> */}
          <VuiTypography
            variant="button"
            color="white"
            fontWeight="medium"
            textTransform="capitalize"
            sx={{fontSize: '18px'}}
          >
            {subscription}
          </VuiTypography>
        </VuiBox>
        <VuiBox mb={1} lineHeight={0}>
          <VuiTypography variant="caption" color="text">
            Subscribed on:&nbsp;&nbsp;&nbsp;
            <VuiTypography variant="caption" fontWeight="regular" color="text">
              {date} | {time}
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        { active === true && 
          <VuiBox mb={1} lineHeight={0}>
            <VuiTypography variant="caption" color="text">
              Days left:&nbsp;&nbsp;&nbsp;
              <VuiTypography variant="caption" fontWeight="regular" color="text">
                {days}
              </VuiTypography>
            </VuiTypography>
          </VuiBox>
        }
        <VuiBox mb={1} lineHeight={0}>
          <VuiTypography variant="caption" color="text">
            {/* Active:&nbsp;&nbsp;&nbsp; */}
            <VuiTypography variant="h5" fontWeight="regular" color="green" sx={{color: 'green'}}>
              {active === true ? 'ACTIVE' : 'INACTIVE'}
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
      <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb="5px"
        >
          {/* <VuiTypography
            variant="button"
            color="white"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {name}
          </VuiTypography> */}

          {/* <VuiBox
            display="flex"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
            sx={({ breakpoints }) => ({
              [breakpoints.only("sm")]: {
                flexDirection: "column",
              },
            })}
          >
            <VuiButton variant="text" color="text">
              <a href="mailto:support@quickvideo.ai" style={{color: 'white'}}>Help</a>
            </VuiButton>
          </VuiBox> */}
        </VuiBox>
    </VuiBox>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  subscription: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export default Bill;
