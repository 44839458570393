import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import Upload from "./Upload";
import Script from "./Script";
import Variables from "./Variables";
import Dataset from "./Dataset";
import Voice from "./Voice";
import Preview from "./Preview";
import Details from "./Details";
import Papa from "papaparse";
import axios from "axios";
import { useVisionUIController } from "context";
import BASE_URL from "context/api";
import { useSnackbar } from "notistack";
import SamplePreview from "./SamplePreview";
import mixpanel from "mixpanel-browser";
import { setFetchLogs } from "context";
// import { CircularProgress } from "@mui/material";

function Steps({
  uploadFile, setUploadFile, manualSubtitles, setManualSubtitles, timestamp, setTimeStamp, loading, setLoading, selectedWords, selectedIndices, setSelectedWords, setSelectedIndices, selectedVariablesName, setSelectedVariablesName, project, setProject, buttonCount, setButtonCount, uploadCSV, setUploadCSV, parsedData, setParsedData, tableRows, setTableRows, values, setValues, selectedFile, setSelectedFile, audioResponse, setAudioResponse, setNewProject, sampleVideoPreview, setSampleVideoPreview, selectVideoPreview, setSelectVideoPreview, allProjects, projectNameError, setProjectNameError, availableVoiceSelect,setAvailableVoiceSelect
}) {
  const isProjectEmpty = Object.values(project).some(value => value === null);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleClose = () => setNewProject(false);
  const [controller, dispatch] = useVisionUIController();
  const { userDetails } = controller;
  const { user_id, access_token } = userDetails;
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    const data = {
      CSV_file: uploadCSV,
      video_file: uploadFile,
      audio: audioResponse,
      project_details: project,
      script: manualSubtitles,
      userId: user_id,
      selected_words: selectedWords,
      timestamp: timestamp
    };
    
    const headers = {
      accept: 'application/json',
      'content-type': 'multipart/form-data',
      'Authorization': `Bearer ${access_token}`,
    };
    
    const options = {
      method: 'POST',
      url: `${BASE_URL}/video_personalize`,
      headers: headers,
      data: data
    };
    setActiveStep(activeStep + 1);
    setLoading(true); 
    
    axios(options)
      .then(response => {
        setLoading(false);
        setFetchLogs(dispatch, true);
        mixpanel.track('Video Creation', {
          'video name': data.project_details,
          'type': 'Personalisation Video'
        });
        enqueueSnackbar("AI Video generated successfully.", {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        enqueueSnackbar(error.response.statusText, {
          variant: "warning",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
  }

  const discardPreviewVideo = () => {
    // handleBack();
    // setSampleVideoPreview(null);
    setProject({
      ...project,
      variableText: '',
    });
  }

  const handleSelectPreviewVideo = () => {
    setSelectVideoPreview(true);
  }

  const handlePreviewVideo = () => {
    const data = {
      video_file: uploadFile,
      project_details: project,
      script: manualSubtitles,
      userId: user_id,
      audio: audioResponse,
    };
    
    const headers = {
      accept: 'application/json',
      'content-type': 'multipart/form-data',
      'Authorization': `Bearer ${access_token}`,
    };
    
    const options = {
      method: 'POST',
      url: `${BASE_URL}/sample_video_personalize`,
      headers: headers,
      data: data
    };
    setActiveStep(activeStep + 1);
    setLoading(true); 
    
    axios(options)
      .then(response => {
        setLoading(false);
        setSampleVideoPreview(response.data);
        mixpanel.track('Video Creation', {
          'video name': data.project_details,
          'type': 'Sample personalization Video'
        });
        enqueueSnackbar("AI Video generated successfully.", {
          variant: "success",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        enqueueSnackbar(error.response.data.description, {
          variant: "warning",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
  }

  function getSteps() {
    return ["Project Details", "Upload Video", "Confirm Script", "Select Variables", "Voice Sample", "Sample Preview", "Upload Dataset", "Preview"];
  }
  
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Details project={project} setProject={setProject} allProjects={allProjects} projectNameError={projectNameError} setProjectNameError={setProjectNameError} />;
      case 1:
        return <Upload uploadFile={uploadFile} setUploadFile={setUploadFile} manualSubtitles={manualSubtitles} setManualSubtitles={setManualSubtitles} setLoading={setLoading} timestamp={timestamp} setTimeStamp={setTimeStamp} />;
      case 2:
        return <Script uploadFile={uploadFile} manualSubtitles={manualSubtitles} setManualSubtitles={setManualSubtitles} loading={loading} />;
      case 3:
        return <Variables manualSubtitles={manualSubtitles} loading={loading} selectedIndices={selectedIndices} selectedWords={selectedWords} setSelectedIndices={setSelectedIndices} setSelectedWords={setSelectedWords} selectedVariablesName={selectedVariablesName} setSelectedVariablesName={setSelectedVariablesName} project={project} setProject={setProject} />;
      case 4:
        return <Voice selectedFile={selectedFile} setSelectedFile={setSelectedFile} audioResponse={audioResponse} setAudioResponse={setAudioResponse} availableVoiceSelect={availableVoiceSelect} setAvailableVoiceSelect={setAvailableVoiceSelect} />;
      case 5:
        return <SamplePreview sampleVideoPreview={sampleVideoPreview} loading={loading} discardPreviewVideo={discardPreviewVideo} handleSelectPreviewVideo={handleSelectPreviewVideo} selectVideoPreview={selectVideoPreview} />;
      case 6:
        return <Dataset selectedWords={selectedWords} downloadCSV={downloadCSV} buttonCount={buttonCount} uploadCSV={uploadCSV} setUploadCSV={setUploadCSV} handleCSVUpload={handleCSVUpload} parsedData={parsedData} setParsedData={setParsedData} tableRows={tableRows} setTableRows={setTableRows} values={values} setValues={setValues} project={project} />;
      case 7:
        return <Preview />;
      default:
        return null;
    }
  }

  // const downloadCSV = () => {
  //   const rows = selectedWords.length + 6; // Number of rows (header + selected words)
  //   let csvContent = '';
  
  //   // Add header row with variable names
  //   csvContent += selectedVariablesName.join(',') + '\n';
  //   // Add selected words as values for each column
  //   for (let row = 1; row < rows; row++) {
  //     // csvContent += ',';
  //     for (let col = 0; col < selectedWords.length; col++) {
  //       if (col > 0) {
  //         csvContent += ',';
  //       }
  //       csvContent += row === 1 ? selectedWords[col] : ''; // Leave first row empty
  //     }
  //     csvContent += '\n';
  //   }
  
  //   const blob = new Blob([csvContent], { type: 'text/csv' });
  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'selected_words.csv';
  //   a.click();
  //   URL.revokeObjectURL(url);
  //   setButtonCount(1);
  // };

  const downloadCSV = () => {
    const rows = 4;
    let csvContent = '';
  
    // Add header row with personalizationVariableType values
    csvContent += project.personalizationVariableType + '\n';
  
    // Add variableText as the first data row
    csvContent += project.variableText + '\n';
  
    // Add empty rows (excluding the first row) with placeholders
    for (let row = 2; row <= rows; row++) {
      for (let col = 0; col < 1; col++) {
        if (col > 0) {
          csvContent += ',';
        }
        csvContent += ''; // Leave empty for the remaining rows
      }
      csvContent += '\n';
    }
  
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'selected_words.csv';
    a.click();
    URL.revokeObjectURL(url);
    setButtonCount(1);
  };

  const handleCSVUpload = (event) => {
    setUploadCSV(event.target.files[0]);
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        // Parsed Data Response in array format
        setParsedData(results.data);

        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        setValues(valuesArray);
      },
    });
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    return '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, []);

  return (
      <VuiBox>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card>
              <VuiBox>
                <VuiBox>
                  {getStepContent(activeStep)}
                  <VuiBox mt={2} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 || activeStep === 7 ? (
                      <VuiBox />
                    ) : (
                      <VuiButton
                        variant="gradient"
                        sx={{ minWidth: "100px" }}
                        color="light"
                        onClick={handleBack}
                        // disabled={selectVideoPreview}
                      >
                        prev
                      </VuiButton>
                    )}
                    <VuiButton
                      sx={{ minWidth: "100px" }}
                      color="info"
                      onClick={activeStep === 4 ? handlePreviewVideo : activeStep === 6 ? handleSubmit : isLastStep ? handleClose : handleNext}
                      disabled={activeStep === 0 ? (isProjectEmpty || projectNameError) : activeStep === 1 ? !uploadFile : activeStep === 2 ? manualSubtitles === '' : activeStep === 3 ? project.variableText === '' : activeStep === 4 ? audioResponse === null : activeStep === 5 ? sampleVideoPreview === null : activeStep === 6 ? !uploadCSV : false}
                    >
                      {isLastStep ? "good to go!" : activeStep === 6 ? "submit" : activeStep === 4 ? "generate sample video ➜" :  "next"}
                    </VuiButton>
                  </VuiBox>
                </VuiBox>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
  );
}

export default Steps;
