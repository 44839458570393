/*eslint-disable no-unused-vars */

import { useEffect, useState } from "react";
// Vision UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import { Card, Skeleton, Stack } from "@mui/material";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";

// React icons
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdBrowsers,
  IoMdPerson,
  IoMdPhonePortrait,
} from "react-icons/io";
import { IoMdNotifications } from "react-icons/io";

// function Collapsed({ icon, title, open, children, ...rest }) {
//   // const [collapse, setCollapse] = useState(false);
//   return (
//     <VuiBox mt={0} mb={1}>
//       <Card>
//         <VuiBox
//           {...rest}
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           sx={{ cursor: "pointer" }}
//         >
//           <VuiButton color="info">{icon}</VuiButton>
//           <VuiTypography variant="lg" color="white" sx={{ userSelect: "none" }}>
//             {title}
//           </VuiTypography>

//           <VuiBox color="white">
//             {open ? (
//               <IoIosArrowUp color="dark" />
//             ) : (
//               <IoIosArrowDown color="white" />
//             )}
//           </VuiBox>
//         </VuiBox>

//         <Collapse timeout={400} in={open}>
//           <VuiBox lineHeight={1}>
//             <VuiTypography
//               variant="button"
//               color="white"
//               opacity={0.8}
//               fontWeight="regular"
//             >
//               {children}
//             </VuiTypography>
//           </VuiBox>
//         </Collapse>
//       </Card>
//     </VuiBox>
//   );
// }

function Collapsed({ icon, title, open, children, ...rest }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  // const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("12")
        : setTabsOrientation("4");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  // const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const [collapse, setCollapse] = useState(false);
  return (
    <Grid container>
      <Grid item xs={tabsOrientation}>
        <Card>
          <VuiBox
            {...rest}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ cursor: "pointer" }}
          >
            <VuiButton color="info">{icon}</VuiButton>
            <VuiTypography
              variant="lg"
              color="white"
              sx={{ userSelect: "none" }}
            >
              {title}
            </VuiTypography>

            <VuiBox color="white">
              {open ? (
                <IoIosArrowUp color="dark" />
              ) : (
                <IoIosArrowDown color="white" />
              )}
            </VuiBox>
          </VuiBox>

          <Collapse timeout={400} in={open}>
            <VuiBox lineHeight={1}>
              <Divider light />
              <VuiTypography
                variant="button"
                color="white"
                opacity={0.8}
                fontWeight="regular"
              >
                {children}
              </VuiTypography>
            </VuiBox>
          </Collapse>
        </Card>
      </Grid>
    </Grid>
  );
}

function Pages() {
  const [Pagescollapse, PagessetCollapse] = useState(false);
  return (
    <Collapsed
      icon={<IoMdBrowsers color="white" size="22px" />}
      title="Pages"
      open={Pagescollapse === 1}
      onClick={() =>
        Pagescollapse === 1 ? PagessetCollapse(false) : PagessetCollapse(1)
      }
    >
      <VuiBox
        mb={1}
        mt={1}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <VuiTypography variant="body2" color="white">
          Set where to display or hide the widget
        </VuiTypography>
        <VuiButton size="small" color="error" onClick="">
          <Icon
            sx={{
              fontWeight: "bold",
              color: ({ palette: { white } }) => white.main,
            }}
          >
            add
          </Icon>
          Add rule
        </VuiButton>
      </VuiBox>
    </Collapsed>
  );
}

function Event() {
  const [Eventcollapse, EventsetCollapse] = useState(false);
  //Event buttons
  const [but1, setBut1] = useState(false);
  const handleSetBut1 = () => setBut1(!but1);
  const [but2, setBut2] = useState(false);
  const handleSetBut2 = () => setBut2(!but2);
  const [but3, setBut3] = useState(false);
  const handleSetBut3 = () => setBut3(!but3);
  return (
    <Collapsed
      icon={<IoMdNotifications color="white" size="22px" />}
      title="Event Triggers"
      open={Eventcollapse === 1}
      onClick={() =>
        Eventcollapse === 1 ? EventsetCollapse(false) : EventsetCollapse(1)
      }
    >
      <VuiBox mt={2}>
        <VuiTypography variant="body2" color="white" fontWeight="medium">
          Triggers
        </VuiTypography>

        <VuiBox mt={1} mb={1} ml={1}>
          <VuiBox display="flex" alignItems="center">
            <VuiSwitch color="info" checked={but1} onChange={handleSetBut1} />
            <VuiTypography
              variant="caption"
              color="white"
              fontWeight="medium"
              onClick={handleSetBut1}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Exit intent
            </VuiTypography>
          </VuiBox>
          <VuiBox display="flex" alignItems="center">
            <VuiSwitch color="info" checked={but2} onChange={handleSetBut2} />
            <VuiTypography variant="caption" color="white" fontWeight="medium">
              &nbsp;&nbsp;&nbsp;&nbsp;Show after &nbsp;
            </VuiTypography>
            <VuiBox>
              <VuiInput />
            </VuiBox>
            <VuiTypography variant="caption" color="white" fontWeight="medium">
              &nbsp;&nbsp;&nbsp;&nbsp;seconds
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        {/* <VuiTypography variant="body2" color="white" fontWeight="medium">
                Restrictions
              </VuiTypography> */}

        <VuiBox display="flex" justifyContent="space-between">
          <VuiTypography variant="body2" color="white" fontWeight="medium">
            Restrictions
          </VuiTypography>
          <VuiSwitch color="info" checked={but3} onChange={handleSetBut3} />
        </VuiBox>
      </VuiBox>
    </Collapsed>
  );
}

function Audience() {
  const [Audcollapse, AudsetCollapse] = useState(false);
  return (
    <Collapsed
      icon={<IoMdPerson color="white" size="22px" />}
      title="Audience"
      open={Audcollapse === 1}
      onClick={() =>
        Audcollapse === 1 ? AudsetCollapse(false) : AudsetCollapse(1)
      }
    ></Collapsed>
  );
}

function Device() {
  const [Devicecollapse, DevicesetCollapse] = useState(false);
  //Device buttons
  const [active, setActive] = useState(false);
  const handleSetActive = () => setActive(!active);
  const [active1, setActive1] = useState(false);
  const handleSetActive1 = () => setActive1(!active1);
  return (
    <Collapsed
      icon={<IoMdPhonePortrait color="white" size="22px" />}
      title="Devices"
      open={Devicecollapse === 1}
      onClick={() =>
        Devicecollapse === 1 ? DevicesetCollapse(false) : DevicesetCollapse(1)
      }
    >
      <VuiBox mt={2}>
        <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="info" checked={active} onChange={handleSetActive} />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetActive}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Display on mobile
          </VuiTypography>
        </VuiBox>
        <VuiBox display="flex" alignItems="center">
          <VuiSwitch
            color="info"
            checked={active1}
            onChange={handleSetActive1}
          />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetActive1}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Display on desktop
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </Collapsed>
  );
}

function Rules() {
  return (
    <Grid container spacing={1} mt={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <VuiBox mt={1} ml={1}>
              <VuiTypography variant="h5" color="white">
                Widget Rules
              </VuiTypography>
              <VuiTypography style={{ fontSize: "small" }} mt={2}>
                <span style={{ color: "white" }}>
                  {`Rules let you define exactly where and when your widget appears, and to whom. Need some help? Check out our `}
                </span>
                <a href="https://support.example.com">help article.</a>
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <VuiTypography variant="h6" color="white">
                Decide when and how to show your feed!
              </VuiTypography>
              <VuiTypography variant="caption" color="white" mt={2}>
                You can set any rules you'd like to make your feed appear to
                specific users on specific pages, etc.
              </VuiTypography>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Pages />
          </Grid>
          <Grid item xs={12}>
            <Event />
          </Grid>
          <Grid item xs={12}>
            <Audience />
          </Grid>
          <Grid item xs={12}>
            <Device />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Rules;
