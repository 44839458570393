import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../editor.css';
import loading from '../images/White Loading.gif';
import GIF from '../images/film-editing_4035076.png';
import VuiTypography from 'components/VuiTypography';

const LoadingModal = ({loadingModal, setLoadingModal}) => {
  
  // const handleClose = () => {
  //   setLoadingModal(false);
  // }
  const arr = ["Indexing", "Splitting", "Uploading", "Transcribing"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState(arr[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the index and word
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % arr.length);
      setCurrentWord(arr[currentWordIndex]);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentWordIndex, arr]);

  return (
    <div>
      <Modal open={loadingModal}>
        <div className='loading_modal' style={{boxShadow: "rgb(54, 68, 97) 0px 0px 30px 2px"}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {/* <img src={loading} alt="loading" width={200} style={{position: 'absolute', marginTop: '40%', opacity: 0.1}} /> */}
            <div style={{display: 'flex', flexDirection: window.innerWidth < 768 ? 'column' : '', alignItems: 'center', justifyContent: 'space-around'}}>
              <img src={GIF} alt="gif" width={150} />
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '0px 20px'}}>
                {/* <VuiTypography variant='h4' style={{color: 'white', textAlign: window.innerWidth < 768 && 'center'}}>Sit, Back and Relax.</VuiTypography> */}
                <VuiTypography style={{color: 'lightgray', textAlign: window.innerWidth < 768 && 'center'}}>Your video will be split up in multiple scenes </VuiTypography>
                <VuiTypography variant='h4' style={{color: 'lightgray', textAlign: window.innerWidth < 768 && 'center'}}>{currentWord} ..</VuiTypography>
                <img src={loading} alt="loading" width={40} />
              </div>
            </div>
          </div>
        </div> 
      </Modal>
    </div>
  )
}

export default LoadingModal;