import { AppBar, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material'
import VuiBox from 'components/VuiBox'
import VuiInput from 'components/VuiInput';
import VuiTypography from 'components/VuiTypography';
import React, { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';

const Background = ({backgroundTabValue, setBackgroundTabValue, stockImages, setStockImages, imageQuery, setImageQuery, page, setPage, imageObserver, videoObserver, lastImageRef, loading, stockVideos, setStockVideos, videoQuery, setVideoQuery, videoPage, setVideoPage, lastVideoRef, currentSceneIndex, updateSceneKey}) => {
  const [selectedBackground, setSelectedBackground] = useState('#fff');
  const [uploadedBackground, setUploadedBackground] = useState(null);

  useEffect(() => {
    uploadedBackground !== null ? updateSceneKey(currentSceneIndex, 'background', uploadedBackground) : updateSceneKey(currentSceneIndex, 'background', selectedBackground)
    //eslint-disable-next-line
  }, [selectedBackground, uploadedBackground]);

  const tabHandler = (event, newValue) => {
    setBackgroundTabValue(newValue);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
  
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedBackground(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangeBackgroundColor = (color) => {
    setUploadedBackground(null);
    setSelectedBackground(color.hex);
  }

  const handleSearchInputChange = (e) => {
    setStockImages([]);
    setImageQuery(e.target.value);
  };

  const filteredImages = stockImages && stockImages.filter((image) => image.alt.toLowerCase().includes(imageQuery.toLowerCase()));

  const handleStockBackground = (image) => {
    setUploadedBackground(image.src.original);
  }

  useEffect(() => {
    if (lastImageRef.current) {
      imageObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !loading) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      imageObserver.current.observe(lastImageRef.current);
    }

    return () => {
      if (imageObserver.current) {
        imageObserver.current.disconnect();
      }
    };
    //eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (lastVideoRef.current) {
      videoObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !loading) {
          setVideoPage((prevPage) => prevPage + 1);
        }
      });

      videoObserver.current.observe(lastVideoRef.current);
    }

    return () => {
      if (videoObserver.current) {
        videoObserver.current.disconnect();
      }
    };
    //eslint-disable-next-line
  }, [loading]);

  return (
    <VuiBox>
      <Typography>Background</Typography>
      {uploadedBackground && <VuiBox sx={{maxHeight: '30vh', maxWidth: '100%', height: '20vh', width: '100%', background: '#000', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img alt='bg' src={uploadedBackground} style={{ maxWidth: '100%', maxHeight: '18vh' }} />
      </VuiBox>}
      {!uploadedBackground && <VuiBox sx={{maxHeight: '30vh', maxWidth: '100%', height: '18vh', width: '100%', background: selectedBackground, marginTop: '10px'}}>
      </VuiBox>}
      <Grid container alignItems={"center"}>
        <AppBar
          position="static"
          sx={{ display: "flex", mb: 0, alignItems: "center", marginTop: '5px'}}
        >
          <Tabs value={backgroundTabValue} onChange={tabHandler} sx={{overflow: 'auto', width: '100%' }}>
            <Tab
              style={{minWidth:"33%"}}
              label={
                <VuiTypography color="light" variant="body2">
                  Color
                </VuiTypography>
              }
            />
            <Tab
              style={{minWidth:"33%"}}
              label={
                <VuiTypography color="light" variant="body2">
                  Upload
                </VuiTypography>
              }
            />
            <Tab
              style={{minWidth:"33%"}}
              label={
                <VuiTypography color="light" variant="body2">
                  Stock
                </VuiTypography>
              }
            />
          </Tabs>
        </AppBar>
      </Grid>
      {backgroundTabValue === 0 && <VuiBox sx={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
        <ChromePicker color={selectedBackground} onChange={handleChangeBackgroundColor} width='100%' disableAlpha={true} />
      </VuiBox>}

      {backgroundTabValue === 1 && 
        <VuiBox sx={{marginTop: '15px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <label htmlFor="image-file" style={{cursor: 'pointer'}}>
            <Typography variant='h6' sx={{color: '#000', background: 'lightgray', padding: '10px 25px', borderRadius: '15px'}}>
              Click here to Upload
              <input
                accept="image/jpeg, image/png, image/jpg"
                style={{ display: 'none' }}
                type="file"
                id="image-file"
                name="image-file"
                onChange={handleFileUpload}
              />
            </Typography>
          </label>
        </VuiBox>
      }

      {/* {backgroundTabValue === 2 && (<Grid container alignItems={"center"}>
        <AppBar
          position="static"
          sx={{ display: "flex", mb: 0, alignItems: "center", marginTop: '5px'}}
        >
          <Tabs value={backgroundInnerTabValue} onChange={innerTabHandler} sx={{overflow: 'auto', width: '100%' }}>
            <Tab
              style={{minWidth:"50%"}}
              label={
                <VuiTypography color="light" variant="body2">
                  Images
                </VuiTypography>
              }
            />
            <Tab
              style={{minWidth:"50%"}}
              label={
                <VuiTypography color="light" variant="body2">
                  Videos
                </VuiTypography>
              }
            />
          </Tabs>
        </AppBar>
      </Grid>
      )} */}

      {backgroundTabValue === 2 && <VuiBox sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-evenly', margin: '5px 0'}}>
        <VuiInput placeholder='search here' value={imageQuery} onChange={handleSearchInputChange}  />
        {(filteredImages.length > 0 ? filteredImages : stockImages).map((image, index) => (
              <img 
              key={image.id} 
              src={image.src.large2x} 
              alt={image.alt} 
              width='130px' 
              style={{margin: '5px 0', cursor: 'pointer'}} 
              ref={index === ((filteredImages ? filteredImages.length - 1 : stockImages.length - 1)) ? lastImageRef : null} 
              onClick={() => handleStockBackground(image)} 
              />
            ))}
        {loading && <CircularProgress color='white' size={20} />}
      </VuiBox>}

      {/* {(backgroundTabValue === 2 && backgroundInnerTabValue === 1) && 
      <VuiBox sx={{marginTop: '5px'}}>
        <VuiInput placeholder='search here' value={videoQuery} onChange={(e)=>setVideoQuery(e.target.value)} sx={{marginBottom: '10px'}}  />
        <VuiBox sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-evenly', margin: '5px 0', overflow: 'auto', maxHeight: '100%'}}>
          {stockVideos.map((video, index) => (
            <div key={index}>
              <video
                src={video.video_files[0].link}
                alt={video.id}
                ref={index === stockVideos.length - 1 ? lastVideoRef : null}
                width='130px'
                style={{cursor: 'pointer', margin: '5px 0'}} 
                onClick={() => handleStockVideoBackground(video.video_files[0].link)}
              />
            </div>
          ))}
          {loading && <CircularProgress color='white' size={20} />}
        </VuiBox>
      </VuiBox>} */}
    </VuiBox>
  )
}

export default Background 