import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import VuiBox from 'components/VuiBox';
import React, { useState } from 'react';
import { useRef } from 'react';

const RecordScreen = () => {
  const [mediaStream, setMediaStream] = useState(null);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const videoRef = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      const recorder = new MediaRecorder(stream, {
        mimeType: 'video/webm',
        videoBitsPerSecond: 100000000,
      });

      recorder.ondataavailable = (e) => {
        setRecordedChunks((prevChunks) => [...prevChunks, e.data]);
      };

      recorder.start();
      setMediaStream(stream);
      setMediaRecorder(recorder);
      setRecording(true);
      videoRef.current.srcObject = stream;
    } catch (err) {
      console.error('Error capturing screen:', err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaStream) {
      mediaRecorder.stop();
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaRecorder(null);
      setMediaStream(null);
      setRecording(false);
    }
  };

  const downloadVideo = () => {
    if (recordedChunks.length) {
      const videoBlob = new Blob(recordedChunks, { type: 'video/webm' });
      const videoUrl = URL.createObjectURL(videoBlob);
      const downloadLink = document.createElement('a');
      downloadLink.href = videoUrl;
      downloadLink.download = 'recorded-screen.webm';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const showScreen = mediaStream !== null;

  return (
    <>
        {showScreen && <video ref={videoRef} style={{ maxWidth: '100%' }} autoPlay />}
        <VuiBox sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
        {recording ? (
          <Button onClick={stopRecording}>Stop Recording</Button>
          ) : (
          <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'}}>
            <Typography style={{ fontWeightMedium: 500,fontSizeMD: 16, color: "whitesmoke", marginBottom: '10px'}}> 
                Click on below button to start your screen recording
            </Typography>
            <VuiBox sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
              <Button onClick={startRecording}>Start Recording</Button>
              {recordedChunks.length > 0 && <Button onClick={downloadVideo}>Download Video</Button>}
            </VuiBox>
          </VuiBox>
          )}
          
        </VuiBox>
    </>
  );
};

export default RecordScreen;
