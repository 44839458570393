/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/
/*eslint-disable */
/**
 * The base breakpoints for the Vision UI Dashboard PRO Material.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire Vision UI Dashboard PRO Material using thie file.
 */

export default {
  values: {
    xs: 375,
    sm: 425,
    md: 768,
    lg: 1024,
    xl: 1440,
    xxl: 1680,
  },
};
