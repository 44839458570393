import { Modal } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageLightBlue: {
      position: "relative",
      minWidth:"fit-content",
      maxWidth: "80%",
      padding: "10px",
      backgroundColor: "#0175FF",
      // width: "60%",
      //height: "50px",
      textAlign: "left",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      // "&:after": {
      //   content: "''",
      //   position: "absolute",
      //   width: "0",
      //   height: "0",
      //   borderTop: "15px solid #0175FF",
      //   borderLeft: "15px solid transparent",
      //   borderRight: "15px solid transparent",
      //   top: "-1px",
      //   left: "-15px",
      // },
      // "&:before": {
      //   content: "''",
      //   position: "absolute",
      //   width: "0",
      //   height: "0",
      //   borderTop: "17px solid #0175FF",
      //   borderLeft: "16px solid transparent",
      //   borderRight: "16px solid transparent",
      //   top: "-1px",
      //   left: "-17px",
      // },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      color: "#fff",
      font: "Plus Jakarta Display",
    },
    messageTimeStampLeft: {
      fontSize: "0.75em",
      textAlign: "left",
      paddingTop: "5px",
      color: "#bfbfbf",
    },
    messageTimeStampRight: {
      fontSize: "0.75em",
      color: "#bfbfbf",
      textAlign: "right",
      paddingTop: "5px",
    },
    lightBlue: {
      color: "#FFF",
      width: "150%",
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

const useRightStyles = makeStyles(() =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageLightBlue: {
      position: "relative",
      minWidth:"23%",
      maxWidth: "47.5%",
      padding: "10px",
      backgroundColor: "#0175FF",
      // width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "200 .8em 'Plus Jakarta Display', sans-serif",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #0175FF",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "-1px",
        left: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #0175FF",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px",
      },
    },
    messageDeepBlue: {
      // right: "3%",
      position: "relative",
      // alignSelf: "right",
      minWidth: "fit-content",
      maxWidth: "80%",
      padding: "10px",
      backgroundColor: "transparent",
      //height: "50px",
      textAlign: "left",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      // "&:after": {
      //   content: "''",
      //   position: "absolute",
      //   width: "0",
      //   height: "0",
      //   borderTop: "15px solid transparent",
      //   borderLeft: "15px solid transparent",
      //   borderRight: "15px solid transparent",
      //   top: "0",
      //   right: "-15px",
      // },
      // "&:before": {
      //   content: "''",
      //   position: "absolute",
      //   width: "0",
      //   height: "0",
      //   borderTop: "17px solid #0175FF",
      //   borderLeft: "17px solid transparent",
      //   borderRight: "16px solid transparent",
      //   top: "-1px",
      //   right: "-17px",
      // },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      color: "#fff",
      font: "Plus Jakarta Display",
    },
    messageTimeStampLeft: {
      fontSize: "0.75em",
      textAlign: "left",
      paddingTop: "5px",
      color: "#bfbfbf",
    },
    messageTimeStampRight: {
      fontSize: "0.75em",
      color: "#bfbfbf",
      textAlign: "right",
      paddingTop: "5px",
    },
    deepBlue: {
      color: "#FFF",
      width: "150%",
    },
    lightBlue: {
      color: "#FFF",
      width: "150%",
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

const ChatModal = ({chatModal, setChatModal}) => {
  const session_start =  "15:41, 19/08/2024";
  const source_url =  "rian-pbMoTzs7an";

  const [isPlaying, setIsPlaying] = useState(new Array(chatModal.chats?.length).fill(false));
  const audioRefs = useRef([]);

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    return `${dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  };

  const togglePlayPause = (index) => {
    const newIsPlaying = [...isPlaying];
    newIsPlaying[index] = !isPlaying[index];

    if (newIsPlaying[index]) {
      audioRefs.current[index].play();
    } else {
      audioRefs.current[index].pause();
    }

    setIsPlaying(newIsPlaying);
  };

  const classes = useStyles();
  const rightClasses = useRightStyles();

  const date = session_start.split(',');
    
  const handleClose = () => {
    setChatModal(false);
  };

  return (
    <div>
      <Modal open={chatModal} onClose={handleClose}>
        <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 100%, rgba(10, 14, 35, 0.49) 76.65%)",
            outline: "none",
            padding: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            minHeight: "fit-content",
            minWidth: "fit-content",
            boxShadow: "rgb(54, 68, 97) 0px 0px 10px 20px 2px",
            borderRadius: '5px'
          }}>
            <Close color='white' sx={{position: 'absolute', right: 20, top: 20, cursor: 'pointer'}} onClick={handleClose} />
            <VuiBox sx={{display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
              <VuiBox sx={{width: '400px', height: '600px', objectFit: 'cover', borderRadius: '5%', boxShadow: 'whitesmoke 0px 0px 40px -15px', padding: '10px', border: '1px solid lightblue'}} >
                <VuiTypography sx={{color: 'whitesmoke', textAlign: 'center'}} variant='h6'>{date[1]}</VuiTypography>
                <div style={{maxHeight: '550px', overflowY: 'auto', width: '100%', padding: '5px'}}>
                  {chatModal.chats === null ? <VuiBox sx={{textAlign: 'center'}}>No chats captured</VuiBox> : chatModal.chats.map((item, index) => (
                    item.speaker === 'Avatar' ? 
                      <VuiBox sx={{display: 'flex' ,alignItems: 'flex-start', paddingBottom: '5px'}}>
                        <img src={source_url} alt="" style={{width: '30px', height: '30px', objectFit: 'cover', borderRadius: '100%', background: 'white'}} />
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: 'auto'}}>
                          <div key={index} className={classes.messageLightBlue} style={{color: 'whitesmoke', marginTop: '5px', marginRight: 'auto', padding: '5px 10px', marginLeft: '5px'}}>
                            {item.type === 'audio' ? 
                              <div key={index}>
                                <audio ref={(element) => (audioRefs.current[index] = element)} style={{ display: 'none' }}>
                                  <source src={item.chat} type="audio/mpeg" />
                                </audio> 
                                <div style={{background: 'transparent', cursor: 'pointer'}}>
                                  {isPlaying[index] ? <PauseCircleIcon onClick={() => togglePlayPause(index)} fontSize='large' /> : <PlayCircleIcon onClick={() => togglePlayPause(index)} fontSize='large' />}
                                </div>
                              </div>
                            : 
                            <h6 style={{fontSize: window.innerWidth < 768 ? '12px' : ''}}>{item.chat}</h6>}
                            </div>
                          <VuiBox sx={{display: 'flex', alignItems: 'center'}}>
                            <h6 style={{color: 'lightgray', padding: '0px 5px', fontSize: '12px'}}>{formatDateTime(item.timestamp)}</h6>
                            {item.credits_used && <h6 style={{color: 'red', padding: '0px 5px', fontSize: '12px'}}> {item.credits_used.toFixed(2)} credits</h6>}
                          </VuiBox>
                        </div>
                      </VuiBox>
                    :
                      <VuiBox sx={{display: 'flex' ,alignItems: 'flex-start', paddingBottom: '5px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: 'auto'}}>
                          <div key={index} className={rightClasses.messageDeepBlue} style={{color: 'whitesmoke', marginTop: '5px', marginLeft: 'auto', padding: '5px 10px', marginRight: '5px'}}>
                            <h6 style={{fontSize: window.innerWidth < 768 ? '12px' : ''}}>{item.chat}</h6>
                          </div>
                          <h6 style={{color: 'lightgray', padding: '0px 5px', fontSize: '12px'}}>{formatDateTime(item.timestamp)}</h6>
                        </div>
                        <AccountCircleIcon color='white' fontSize='large' />
                      </VuiBox>
                  ))}
                </div>
              </VuiBox>
            </VuiBox>
        </div> 
      </Modal>
    </div>
  )
}

export default ChatModal;