import { Button, ButtonGroup, Slider, Typography } from '@mui/material'
import VuiBox from 'components/VuiBox'
import React, { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VuiSwitch from 'components/VuiSwitch';
import VuiTypography from 'components/VuiTypography';

const VideoSettings = ({selectedVideo, setSelectedVideo, openSettings, setOpenSettings, scenes, updateSceneKey, currentSceneIndex, setScenes, setUploadedBackground}) => {
  const [scale, setScale] = useState(openSettings.video.scale);
  const [zIndex, setZIndex] = useState(openSettings.video.zIndex);
  const [opacity, setOpacity] = useState(openSettings.video.opacity);
  const [top, setTop] = useState(openSettings.video.marginTop);
  const [left, setLeft] = useState(openSettings.video.marginLeft);
  const [fullButton, setFullButton] = useState(openSettings.video.fullscreen);

  const handleFullButton = () => {
    setFullButton((prevFullButton) => {
      const newFullButton = !prevFullButton;
  
      if (openSettings.video) {
        const updatedVideoSettings = { ...openSettings.video };
        updatedVideoSettings.fullscreen = newFullButton;
        updatedVideoSettings.x = 0;
        updatedVideoSettings.y = 0;
        if (newFullButton) {
          updatedVideoSettings.size.width = scenes[currentSceneIndex].resolution.expectedSize.width;
          updatedVideoSettings.size.height = scenes[currentSceneIndex].resolution.expectedSize.height;
        } else {
          updatedVideoSettings.size.width = 300;
          updatedVideoSettings.size.height = 200;
        }
  
        setOpenSettings({
          ...openSettings,
          video: updatedVideoSettings,
        });
  
        setScenes((prevScenes) => {
          return prevScenes.map((scene, index) => {
            if (index === currentSceneIndex) {
              return {
                ...scene,
                selectedVideo: updatedVideoSettings,
              };
            }
            return scene;
          });
        });
      }
  
      return newFullButton;
    });
  };
  
  const handleScale = (event, newValue) => {
    setScale(newValue);
    if (openSettings.video) {
      const updatedVideoSettings = { ...openSettings.video };
      updatedVideoSettings.scale = newValue;
  
      setOpenSettings({
        ...openSettings,
        video: updatedVideoSettings,
      })
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedVideo: updatedVideoSettings,
            };
          }
          return scene;
        });
      });
    }
  }

  const handleLeftMinus = () => {
    setLeft(prevLeft => prevLeft - 1);
    if (openSettings.video) {
      const updatedVideoSettings = { ...openSettings.video };
      updatedVideoSettings.marginLeft = left;
  
      setOpenSettings({
        ...openSettings,
        video: updatedVideoSettings,
      })
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedVideo: updatedVideoSettings,
            };
          }
          return scene;
        });
      });
    }
  }
  
  const handleLeftPlus = () => {
    setLeft(prevLeft => prevLeft + 1);
    if (openSettings.video) {
      const updatedVideoSettings = { ...openSettings.video };
      updatedVideoSettings.marginLeft = left;
  
      setOpenSettings({
        ...openSettings,
        video: updatedVideoSettings,
      })
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedVideo: updatedVideoSettings,
            };
          }
          return scene;
        });
      });
    }
  }

  const handleTopMinus = () => {
    setTop(prevTop => prevTop - 1);
    if (openSettings.video) {
      const updatedVideoSettings = { ...openSettings.video };
      updatedVideoSettings.marginTop = top;
  
      setOpenSettings({
        ...openSettings,
        video: updatedVideoSettings,
      })
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedVideo: updatedVideoSettings,
            };
          }
          return scene;
        });
      });
    }
  }
  
  const handleTopPlus = () => {
    setTop(prevTop => prevTop + 1);
    if (openSettings.video) {
      const updatedVideoSettings = { ...openSettings.video };
      updatedVideoSettings.marginTop = top;
  
      setOpenSettings({
        ...openSettings,
        video: updatedVideoSettings,
      })
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedVideo: updatedVideoSettings,
            };
          }
          return scene;
        });
      });
    }
  }

  const handleZdecrease = () => {
    setZIndex(prevZ => {
      const newZIndex = prevZ - 10;
  
      if (openSettings.video) {
        const updatedVideoSettings = { ...openSettings.video, zIndex: newZIndex };
  
        setOpenSettings(prevSettings => ({
          ...prevSettings,
          video: updatedVideoSettings,
        }));
  
        setScenes(prevScenes =>
          prevScenes.map((scene, index) => {
            if (index === currentSceneIndex) {
              return {
                ...scene,
                selectedVideo: updatedVideoSettings,
              };
            }
            return scene;
          })
        );
      }
  
      return newZIndex;
    });
  };  

  const handleZincrease = () => {
    setZIndex(prevZ => {
      const newZIndex = prevZ + 10;
  
      if (openSettings.video) {
        const updatedVideoSettings = { ...openSettings.video, zIndex: newZIndex };
  
        setOpenSettings(prevSettings => ({
          ...prevSettings,
          video: updatedVideoSettings,
        }));
  
        setScenes(prevScenes =>
          prevScenes.map((scene, index) => {
            if (index === currentSceneIndex) {
              return {
                ...scene,
                selectedVideo: updatedVideoSettings,
              };
            }
            return scene;
          })
        );
      }
  
      return newZIndex;
    });
  };
  

  const handleOpacity = (event, newValue) => {
    setOpacity(newValue);
    if (openSettings.video) {
      const updatedVideoSettings = { ...openSettings.video };
      updatedVideoSettings.opacity = newValue;
  
      setOpenSettings({
        ...openSettings,
        video: updatedVideoSettings,
      })
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedVideo: updatedVideoSettings,
            };
          }
          return scene;
        });
      });
    }
  }

  const handleDelete = () => {
    if (openSettings.video) {
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedVideo: null,
            };
          }
          return scene;
        });
      });
      setOpenSettings({...openSettings, video: false});
    } else {
      setOpenSettings({...openSettings, video: false});
    }
  }

  return (
    <VuiBox sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
      <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '5px'}}>
        <ArrowBackIcon color="white" sx={{cursor: 'pointer'}} onClick={()=>setOpenSettings({...openSettings, video: false})} />
        <DeleteOutlineIcon color='white' sx={{cursor: 'pointer'}} onClick={handleDelete} />
      </VuiBox>
      <VuiBox sx={{background: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center'}} mt={1}>
        {openSettings.video && 
          <video alt='uploaded' src={openSettings.video.video} style={{ maxWidth: '100%', maxHeight: '20vh' }} controls />  
        }
      </VuiBox>
      <VuiBox sx={{marginTop: '10px'}}>
        {/* <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Scale</Typography>
          <Slider value={scale} onChange={handleScale} min={0} max={500}  color="secondary" sx={{width: '100px'}} />
          <VuiBox sx={{color: 'whitesmoke', border: '1px solid whitesmoke', borderRadius: '10px', padding: '0px 20px', fontSize: '14px'}}>{scale === '' ? "0%" : `${scale}%`}</VuiBox>
        </VuiBox> */}
        <VuiBox
          mr={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <VuiSwitch
            color="info"
            checked={fullButton}
            onChange={handleFullButton}
          />
          <VuiTypography variant="body2" color={fullButton ? "white" : "text"}>
            &nbsp;&nbsp;&nbsp;{fullButton ? "Full screen mode on" : "Full screen mode off"}
          </VuiTypography>
        </VuiBox>
        <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
          {/* <Typography variant='h6' sx={{marginTop: '10px'}}>Position</Typography>
          <VuiBox sx={{display: 'flex', justifyContent: 'space-between'}}>
            <VuiBox sx={{display: 'flex' ,flexDirection: 'column'}}>
              <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>X</Typography>
              <ButtonGroup variant='outlined' aria-label="outlined button group" sx={{width: '150px'}}>
                <Button sx={{padding: '5px'}} onClick={handleLeftMinus}>-</Button>
                <Button sx={{padding: '5px'}}>{left}</Button>
                <Button sx={{padding: '5px'}} onClick={handleLeftPlus}>+</Button>
              </ButtonGroup>
            </VuiBox>
            <VuiBox sx={{display: 'flex' ,flexDirection: 'column'}}>
              <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Y</Typography>
              <ButtonGroup variant='outlined' aria-label="outlined button group" sx={{width: '150px'}}>
                <Button sx={{padding: '5px'}} onClick={handleTopMinus}>-</Button>
                <Button sx={{padding: '5px'}}>{top}</Button>
                <Button sx={{padding: '5px'}} onClick={handleTopPlus}>+</Button>
              </ButtonGroup>
            </VuiBox>
          </VuiBox> */}

          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px auto'}}>
            <ButtonGroup variant="contained">
              <Button sx={{color: 'whitesmoke', '&:disabled': {color: 'gray' }}} onClick={handleZdecrease} disabled={zIndex < 1}>move back</Button>
              <Button sx={{color: 'whitesmoke'}} onClick={handleZincrease}>move front</Button>
            </ButtonGroup>
          </VuiBox>

          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
            <Typography variant='h6' color='whitesmoke'>Opacity</Typography>
            <Slider min={0} max={1} step={0.01} color="secondary" sx={{width: '100px'}} value={opacity} onChange={handleOpacity} />
            <VuiBox sx={{color: 'whitesmoke', border: '1px solid whitesmoke', borderRadius: '10px', padding: '0px 20px', fontSize: '14px'}}>{opacity}</VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </VuiBox>
  )
}

export default VideoSettings