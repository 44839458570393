/*eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import { AttachMoney, AutoFixHigh, Check, Computer, ExitToApp, FileUpload,InsertDriveFile, Headphones, LaptopMac, Mail, MonetizationOn, People, Person, Quiz, Restore, Sell, Shop, Shop2, ShoppingBag, Star, YouTube, TaskAlt } from "@mui/icons-material";
import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import VuiBox from "components/VuiBox";
import { MdAdsClick } from "react-icons/md";
import { IoShirt } from "react-icons/io5";


function All(){
    return(
      <Grid container xs={6} md={5} lg={12} xl={12} justifyContent='center' mt='25px'>
        <Grid item xs={6} md={5} lg={3.5} xl={3} sx={{marginRight: '10px'}}>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Shoppable Videos", fontWeight: "regular" }}
              icon={<ShoppingBag color="white" size="22px" />}
            />
          </VuiBox>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Video Support", fontWeight: "regular" }}
              icon={<Headphones color="white" size="22px" />}
            />
          </VuiBox>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Personal Stylist", fontWeight: "regular" }}
              icon={<IoShirt color="white" size="22px" />}
            />
          </VuiBox>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Prospecting", fontWeight: "regular" }}
              icon={<MdAdsClick color="white" size="22px" />}
            />
          </VuiBox>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Lead Generation", fontWeight: "regular" }}
              icon={<MonetizationOn color="white" size="22px" />}
            />
          </VuiBox>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Pre-meeting Discovery", fontWeight: "regular" }}
              icon={<LaptopMac color="white" size="22px" />}
            />
          </VuiBox>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Video Recruiting", fontWeight: "regular" }}
              icon={<InsertDriveFile color="white" size="22px" />}
            />
          </VuiBox>
        </Grid>
        <Grid item xs={6} md={5} lg={3.5} xl={3} sx={{marginRight: '10px'}}>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Exit Intent", fontWeight: "regular" }}
              icon={<ExitToApp color="white" size="22px" />}
            />
          </VuiBox>
            <VuiBox mb ={3}>
              <MiniStatisticsCard 
                style={{width:"100"}}
                bgColor="info"
                title={{ text: "Welcome Visitors", fontWeight: "regular" }}
                icon={<Star color="white" size="22px" />}
              />
            </VuiBox>
            <VuiBox mb ={3}>
              <MiniStatisticsCard 
                style={{width:"100"}}
                bgColor="info"
                title={{ text: "Video Quiz", fontWeight: "regular" }}
                icon={<Quiz color="white" size="22px" />}
              />
            </VuiBox>
            <VuiBox mb ={3}>
              <MiniStatisticsCard 
                style={{width:"100"}}
                bgColor="info"
                title={{ text: "Product Pitch", fontWeight: "regular" }}
                icon={<AutoFixHigh color="white" size="22px" />}
              />
            </VuiBox>
            <VuiBox mb ={3}>
              <MiniStatisticsCard 
                style={{width:"100"}}
                bgColor="info"
                title={{ text: "Post Meeting follow up", fontWeight: "regular" }} 
                icon={<Restore color="white" size="22px" />}
              />
            </VuiBox>
            <VuiBox mb ={3}>
              <MiniStatisticsCard 
                style={{width:"100"}}
                bgColor="info"
                title={{ text: "Happiness Check in", fontWeight: "regular" }}
                icon={<TaskAlt color="white" size="22px" />}
              />
            </VuiBox>
        </Grid>
        <Grid item xs={6} md={5} lg={3.5} xl={3} sx={{marginRight: '10px'}}>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Email And SMS Campaigns", fontWeight: "regular" }}
              icon={<Mail color="white" size="22px" />}
            />
          </VuiBox>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Porduct Video", fontWeight: "regular" }}
              icon={<YouTube color="white" size="22px" />}
            />
          </VuiBox>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Unboxing", fontWeight: "regular" }}
              icon={<Box color="white" size="22px" />}
            />
          </VuiBox>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Personalized Outreach Blast", fontWeight: "regular" }}
              icon={<Sell color="white" size="22px" />}
            />
          </VuiBox>
          <VuiBox mb ={3}>
            <MiniStatisticsCard 
              style={{width:"100"}}
              bgColor="info"
              title={{ text: "Personal Introduction", fontWeight: "regular" }}
              icon={<Person color="white" size="22px" />}
            />
          </VuiBox>
        </Grid>
      </Grid>
    );
}

export default All;