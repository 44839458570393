// icons
// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
// Images

// Vision UI Dashboard React base styles
// import breakpoints from "assets/theme/base/breakpoints";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React icons

// Vision UI Dashboard React example components
import { useState } from "react";
import {
  IoAlertCircleOutline,
  IoArchiveOutline,
  IoCheckboxOutline,
  IoCheckmarkOutline,
  IoMailUnreadOutline,
} from "react-icons/io5";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

function Responses() {
  // const [tabsOrientation, setTabsOrientation] = useState("vertical");
  const [tabValue, setTabValue] = useState("all");
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <Grid container spacing={1} paddingTop={2}>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          // columns={1}
          sx={({ breakpoints }) => ({
            [breakpoints.up("xs")]: {
              gap: "20px",
            },
            [breakpoints.up("xs")]: {
              gap: "5px",
            },
            [breakpoints.up("xl")]: {
              gap: "5px",
            },
          })}
        >
          <Grid item xs={7}>
            <VuiTypography variant="Body1">Status</VuiTypography>
            <AppBar position="static">
              <Tabs
                alignItems="left"
                orientation="horizontal"
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{
                  background: "transparent",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Tab
                  sx={{ justifyContent: "left" }}
                  labelPlacement="start"
                  icon={<IoCheckmarkOutline color="white" size="22px" />}
                  iconPosition="start"
                  label="All"
                  value="all"
                />
                <Tab
                  sx={{ justifyContent: "left" }}
                  labelPlacement="start"
                  icon={<IoMailUnreadOutline color="white" size="22px" />}
                  iconPosition="start"
                  label="Unread"
                  value="unread"
                />
                <Tab
                  sx={{ justifyContent: "left" }}
                  labelPlacement="start"
                  icon={<IoCheckboxOutline color="white" size="22px" />}
                  iconPosition="start"
                  label="Replied"
                  value="replied"
                />
                <Tab
                  sx={{ justifyContent: "left" }}
                  labelPlacement="start"
                  icon={<IoArchiveOutline color="white" size="22px" />}
                  iconPosition="start"
                  label="Archive"
                  value="archive"
                />
                <Tab
                  sx={{ justifyContent: "left" }}
                  labelPlacement="start"
                  icon={<IoAlertCircleOutline color="white" size="22px" />}
                  iconPosition="start"
                  label="Spam"
                  value="spam"
                />
              </Tabs>
            </AppBar>
          </Grid>

          <Grid item xs={4}>
            <VuiTypography variant="Body1">Filter</VuiTypography>
            <FormGroup sx={{marginLeft:3,}}>
              <FormControlLabel control={<Checkbox />} label="Completed" />
              <FormControlLabel
                control={<Checkbox />}
                label="Email collected"
              />
              <FormControlLabel control={<Checkbox />} label="Video response" />
              <FormControlLabel control={<Checkbox />} label="Audio response" />
              <FormControlLabel control={<Checkbox />} label="Text response" />
            </FormGroup>
          </Grid>
        </Grid>

      <Grid item xs={9}
        style={{
          px: 3,
          maxHeight: "lg",
          minWidth: 600,
          maxWidth: 800,
          // position: 'absolute',
          // top: '25%',
          // marginLeft: 20,
          marginTop: "-12rem",
          // float: "left",
          overflow: "hidden",
        }}
      >
        {tabValue === "all" && (
          <VuiBox>
            <VuiTypography sx={{marginBottom: '8px'}}>All</VuiTypography>
            <VuiInput
              placeholder="Type here..."
              icon={{ component: "search", direction: "right" }}
              sx={({ breakpoints }) => ({
                [breakpoints.down("sm")]: {
                  maxWidth: "80px",
                },
                [breakpoints.only("sm")]: {
                  maxWidth: "80px",
                },
                backgroundColor: "info.main !important",
              })}
            />
          </VuiBox>
        )}

        {tabValue === "unread" && (
          <VuiBox>
            <VuiTypography sx={{marginBottom: '8px'}}>Unread</VuiTypography>
            <VuiInput
              placeholder="Type here..."
              icon={{ component: "search", direction: "right" }}
              sx={({ breakpoints }) => ({
                [breakpoints.down("sm")]: {
                  maxWidth: "80px",
                },
                [breakpoints.only("sm")]: {
                  maxWidth: "80px",
                },
                backgroundColor: "info.main !important",
              })}
            />
          </VuiBox>
        )}
        {tabValue === "replied" && (
          <VuiBox>
            <VuiTypography sx={{marginBottom: '8px'}}>Replied</VuiTypography>
            <VuiInput
              placeholder="Type here..."
              icon={{ component: "search", direction: "right" }}
              sx={({ breakpoints }) => ({
                [breakpoints.down("sm")]: {
                  maxWidth: "80px",
                },
                [breakpoints.only("sm")]: {
                  maxWidth: "80px",
                },
                backgroundColor: "info.main !important",
              })}
            />
          </VuiBox>
        )}
        {tabValue === "archive" && (
          <VuiBox>
            <VuiTypography sx={{marginBottom: '8px'}}>Archive</VuiTypography>
            <VuiInput
              placeholder="Type here..."
              icon={{ component: "search", direction: "right" }}
              sx={({ breakpoints }) => ({
                [breakpoints.down("sm")]: {
                  maxWidth: "80px",
                },
                [breakpoints.only("sm")]: {
                  maxWidth: "80px",
                },
                backgroundColor: "info.main !important",
              })}
            />
          </VuiBox>
        )}
        {tabValue === "spam" && (
          <VuiBox>
            <VuiTypography sx={{marginBottom: '8px'}}>Spam</VuiTypography>
            <VuiInput
              placeholder="Type here..."
              icon={{ component: "search", direction: "right" }}
              sx={({ breakpoints }) => ({
                [breakpoints.down("sm")]: {
                  maxWidth: "80px",
                },
                [breakpoints.only("sm")]: {
                  maxWidth: "80px",
                },
                backgroundColor: "info.main !important",
              })}
            />
          </VuiBox>
        )}
      </Grid>
    </Grid>
  );
}

export default Responses;
