import React, { useEffect, useState } from 'react';
import ChatWindow from './ChatWindow';
import '../App.css';
import { Fab } from '@mui/material';
import { useVisionUIController, setWidget } from "context";
import { destroy } from './streaming-client-api';
import { setSessionActive } from 'context';
import { enqueueSnackbar } from 'notistack';
import { setBotDetails } from 'context';

const ChatButton = () => {
  const [chatOpen, setChatOpen] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [controller, dispatch] = useVisionUIController();
  const { botAiAvatar, widget, userDetails } = controller;
  const [showAvatar, setShowAvatar] = useState('');

  useEffect(() => {
    setShowAvatar(botAiAvatar);
  }, [botAiAvatar])
  
  
  const handleChatToggle = () => {
    setChatOpen(!chatOpen);
    if (!chatOpen) {
      setPosition({ bottom: 100, right: 50 });
    }
  };

  const handleChatWindow = async () => {
    enqueueSnackbar('Closing current session.', { 
      variant: "warning",
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
    setWidget(dispatch, !widget);
    await destroy(userDetails.user_id);
    setSessionActive(dispatch, false);
    enqueueSnackbar('Current session is closed.', { 
      variant: "success",
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
    setBotDetails(dispatch, {
      name: '',
      companyName: '',
      wMessage: ''
    });
  }

  return (
    <div className="chat-button-container">
      <Fab
        variant="contained"
        color="primary"
        onClick={handleChatToggle}
        className="chat-button"
        sx={{width: '100px', height: '100px'}}
      >
        <video className="chat-video" autoPlay loop muted>
          <source src={window.location.pathname === '/genpact' ? 'https://quickvideo.blob.core.windows.net/quickvideo/presenters/preview/646efc.mp4' : showAvatar} type="video/mp4" style={{borderRadius:'100%'}} />
        </video>
      </Fab>
      <ChatWindow open={chatOpen} position={position} onClose={handleChatWindow} onCloseWindow={handleChatToggle} />
    </div>
  );
};

export default ChatButton;
