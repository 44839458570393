import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useEffect, useState } from 'react';
import { Button, Card, Grid, Typography } from '@mui/material';
import PlaceholderCard from 'examples/Cards/PlaceholderCard';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import Steps from './steps/steps';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import folderIcon from './folder_5082687.png';
import Projects from './projects';
import axios from 'axios';
import { useVisionUIController } from "context";
import BASE_URL from 'context/api';
import Skeleton from '@mui/material/Skeleton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './index.css';
import Lock from 'lock';
import Pagination from 'Pagination';

const Personalization = () => {
  const [newProject, setNewProject] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [manualSubtitles, setManualSubtitles] = useState("");
  const [timestamp, setTimeStamp] = useState([]);
  const [loading, setLoading] = useState('');
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [selectedWords, setSelectedWords] = useState([]);
  const [selectedVariablesName, setSelectedVariablesName] = useState([]);
  const [buttonCount, setButtonCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [projectLoading, setProjectLoading] = useState(false);
  const [projectNameError, setProjectNameError] = useState('');
  const [availableVoiceSelect, setAvailableVoiceSelect] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [project, setProject] = useState({
    name: '',
    use:'',
    description :'',
    // handler: '',
    variableType: null,
    personalizationVariableType: null,
    variableText: '',
  })
  const [sampleVideoPreview, setSampleVideoPreview] = useState(null);
  const [selectVideoPreview, setSelectVideoPreview] = useState(false);
  const [uploadCSV, setUploadCSV] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [audioResponse, setAudioResponse] = useState(null);
  const [allProjects, setAllProjects] = useState();
  const [detailView, setDetailView] = useState(false);
  const [projectDetails, setProjectDetails] = useState();
  const [controller] = useVisionUIController();
  const { userDetails } = controller;
  const { user_id, plan } = userDetails; 
  const history = useHistory();

  const itemsPerPage = 11;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedProjects = allProjects && allProjects.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleUpgradeClick = () => {
    history.push('/upgrade');
  };

  const handleOpen = (url) => {
    setOpenModal(true);
    setSelectedUrl(url);
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedUrl(null);
  };

  useEffect(() => {
    setProjectLoading(true);
    try {
      axios.get(`${BASE_URL}/project-info`, {
        params: {
          user_id
        },
      })
      .then(response =>
        {
          setAllProjects(response.data);
          setProjectLoading(false);
        }
      )
      .catch(err => {
        console.log(err);
        setProjectLoading(false);
      })
    } catch (error) {
      console.error('Error fetching data:', error);
      setProjectLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  const videoDetailsApi = async (selectedProject) => {
    try {
      await axios.get(`${BASE_URL}/video-details`, {
        params: {
          user_id,
          allProjects: selectedProject
        },
      })
      .then(response =>
        {
          setProjectDetails(response.data);
        }
      )
      .catch(err => {
        console.log(err);
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleClickOpen = () => {
    setNewProject(true);
  }

  const handleToggleProjectList = () => {
    const confirmLeave = window.confirm('Are you sure you want to leave this page, any changes made will be lost ?');
    if (confirmLeave) {
      setNewProject(false);
    }
  }

  const handleProjectClick = async (data) => {
    await videoDetailsApi(data);
    setDetailView(data);
  }

  const handleBack = () => {
    setDetailView(false); 
    setProjectDetails(null); 
    setSelectVideoPreview(false);
  }

  return (
    <DashboardLayout style={{height: '100%'}}>
      <DashboardNavbar />
      {(plan === '' || plan === '') && <VuiBox sx={{position: 'absolute', top: '100%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '2'}}>
        <Lock height={500} line="Video Personalization Feature" />
      </VuiBox>}
      {(!newProject && !detailView) && <VuiBox mt="0" mb="24px" className={plan==='' && "blurred-content"}>
        <Grid container>
          <Grid item xs={12}>
            <VuiBox mb={1}>
              <VuiTypography variant="lg" color="white" fontWeight="bold">
                Video personalization
              </VuiTypography>
            </VuiBox>
            <VuiBox mb="40px">
              <VuiTypography
                fontSize={16}
                color="text"
                fontWeight="regular"
              >
                You can personalize your video in just few simple steps.
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <PlaceholderCard
              title={{ variant: "h6", text: "Add Project" }}
              onClick={handleClickOpen}
            />
          </Grid>
          {displayedProjects && displayedProjects.map((v_data, index) => {
            return (
              projectLoading ? 
                <Skeleton
                  sx={{marginLeft: '20px', marginTop: '25px'}}
                  variant="square"
                  width={268}
                  height={180}
                /> : <Grid item xs={12} sm={6} md={4} lg={3} key={index} onClick={()=>handleProjectClick(v_data)}>
                <Card
                  sx={{cursor: "pointer", maxWidth: '268px', maxHeight: '180px'}}
                >
                  <img src={folderIcon} alt="folder" width='100%' />
                </Card>
                <Card
                  style={{
                    zIndex: "1",
                    marginTop: "-30px",
                    borderRadius: "0 0 20px 20px ",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    maxWidth: '268px',
                    cursor: 'pointer',
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: "1",
                    }}
                  >
                    <VuiTypography variant="body2" color="light">
                      {v_data.length > 15
                        ? v_data.slice(0, 15) + "..."
                        : v_data}
                    </VuiTypography>
                  </div>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        {allProjects && allProjects.length > 11 && <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(allProjects.length / itemsPerPage)}
          onPageChange={handlePageChange}
        />}
      </VuiBox>}

      {detailView && (
        <VuiBox>
          <ArrowBackIcon color="white" onClick={handleBack} sx={{cursor: 'pointer', marginBottom: '10px'}} />
          <Projects
            details={detailView}
            openModal={openModal}
            setOpenModal={setOpenModal}
            handleOpen={handleOpen}
            handleClose={handleClose}
            selectedUrl={selectedUrl}
            projectDetails={projectDetails}
          />
        </VuiBox>
      )}
      
      {newProject && (
        <>
          <ArrowBackIcon color="white" onClick={handleToggleProjectList} sx={{cursor: 'pointer', marginBottom: '10px'}} />
          <Steps
            allProjects={allProjects}
            projectNameError={projectNameError}
            setProjectNameError={setProjectNameError}
            setNewProject={setNewProject}
            uploadFile={uploadFile}
            setUploadFile={setUploadFile}
            manualSubtitles={manualSubtitles}
            setManualSubtitles={setManualSubtitles}
            timestamp={timestamp}
            setTimeStamp={setTimeStamp}
            loading={loading}
            setLoading={setLoading}
            selectedIndices={selectedIndices}
            setSelectedIndices={setSelectedIndices}
            selectedWords={selectedWords}
            setSelectedWords={setSelectedWords}
            selectedVariablesName={selectedVariablesName}
            setSelectedVariablesName={setSelectedVariablesName}
            project={project}
            setProject={setProject}
            buttonCount={buttonCount}
            setButtonCount={setButtonCount}
            uploadCSV={uploadCSV}
            setUploadCSV={setUploadCSV}
            parsedData={parsedData}
            setParsedData={setParsedData}
            tableRows={tableRows}
            setTableRows={setTableRows}
            values={values}
            setValues={setValues}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            audioResponse={audioResponse}
            setAudioResponse={setAudioResponse}
            sampleVideoPreview={sampleVideoPreview}
            setSampleVideoPreview={setSampleVideoPreview}
            selectVideoPreview={selectVideoPreview}
            setSelectVideoPreview={setSelectVideoPreview}
            availableVoiceSelect={availableVoiceSelect}
            setAvailableVoiceSelect={setAvailableVoiceSelect}
          />
        </>
      )}
    </DashboardLayout>
  )
}

export default Personalization