/*eslint-disable no-unused-vars */

import { useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiTagInput from "components/VuiTagInput";
import VuiAvatar from "components/VuiAvatar";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import VuiButton from "components/VuiButton";
import { CircularProgress } from "@mui/material";
import { useVisionUIController } from "context";
import axios from "axios";
import BASE_URL from "context/api";

const n_emp = [
  { value: "1-10", label: "1 - 10" },
  { value: "10-50", label: "10 - 50" },
  { value: "50-100", label: "50 - 100" },
  { value: "100-500", label: "100 - 500" },
  { value: "500-1000", label: "500 - 1000" },
  { value: "1000+", label: "1000+" }
];

const comp_vertical = [
  { value: "ecommerce", label: "E - Commerce" },
];

const comp_use_case = [
  { value: "video_marketing", label: "Video Marketing" },
];

function WorkspaceSettings(props) {
  const [number, setNumber] = useState('');
  const [vertical, setVertical] = useState('');
  const [usecase, setUsecase] = useState('');
  const [loading, setLoading] = useState(false);
  // const [skills, setSkills] = useState(["react", "angular"]);
  const [controller, dispatch] = useVisionUIController();
  const { userDetails } = controller;
  const { user_id } = userDetails;

  useEffect(() => {
    setLoading(true);
    try {
      axios.get(`${BASE_URL}/company_info`, {
        params: {
          user_id
        },
      }).then(res => {
        console.log(res.data.company_details)
        setLoading(false);
        setNumber(res.data.company_details.size)
        setVertical(res.data.company_details.value)
        setUsecase(res.data.company_details.role)
      })
      // if(response.data) {
      //   console.log(response);
      // }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [])

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <VuiBox mb="40px">
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Workspace Settings
        </VuiTypography>
      </VuiBox>
      <VuiBox mb="40px">
        <Grid container spacing={3} justifyContent="center">
        <VuiAvatar src={props.company_data.avatar_url} alt="Avatar" size="xxl" bgColor="primary"/>
        <Grid item>
          </Grid>
          <Grid item>
            <VuiTypography variant="lg" color="white" fontWeight="bold">
            {props.company_data.company_name}
            </VuiTypography>
            <br/>
            <VuiTypography variant="text" fontSize="16px" color="white">
            {props.company_data.company_url}
            </VuiTypography>
          </Grid>
        </Grid>
      </VuiBox>
      <VuiBox component="form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField label="Company name" placeholder={props.company_data.company_name} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Company URL" placeholder={props.company_data.company_url} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Number of Employees" value={loading ? 'loading...' : number} disabled />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Company vertical" value={loading ? 'loading...' : vertical} disabled />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Company use case" value={loading ? 'loading...' : usecase} disabled />
          </Grid>
        </Grid>
      </VuiBox>
    </Card>
  );
}

export default WorkspaceSettings;
