import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import React, { useEffect, useState } from 'react'
import DashboardNavbar from '.';
import axios from 'axios';
import { useVisionUIController } from "context";
import BASE_URL from "context/api";
import { Card, CircularProgress, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VideoPopup from 'layouts/pages/all-videos/components/VideoPopup';
import VuiTypography from 'components/VuiTypography';
import ReactPlayer from 'react-player';
import { useSnackbar } from "notistack";

const Wishlist = (props) => {
  let queryVid = props.location.search.replace("?queryVid=", "");
  //eslint-disable-next-line
  const [currentVid, setCurrentVid] = useState(queryVid ? queryVid : null);
  //eslint-disable-next-line
  const [controller, dispatch] = useVisionUIController();
  const [loading, setLoading] = useState(false);
  const [allVideos, setAllVideos] = useState([]);
  const [allEditorVideos, setAllEditorVideos] = useState([]);
  const [allSaasVideos, setAllSaasVideos] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentlyPlaying, setCurrentlyPlaying] = useState();
  const [apiCallCount, setApiCallCount] = useState(0);
  const { userDetails } = controller;
  const { user_id, access_token } = userDetails;
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async() => {
    setLoading(true);
    const userId = user_id;
    try {
      const response = await axios.get(`${BASE_URL}/ai_video_wishlist`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id: userId,
        },
      });
      setAllVideos(response.data.videos);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }
  
  const fetchEditorData = async() => {
    setLoading(true);
    const userId = user_id;
    try {
      const response = await axios.get(`${BASE_URL}/video_wishlist`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id: userId,
        },
      });
      if(response.data.videos.length > 0) {
        setAllEditorVideos(response.data.videos);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }
  
  const fetchSaasData = async() => {
    setLoading(true);
    const userId = user_id;
    try {
      const response = await axios.get(`${BASE_URL}/saas_video_wishlist`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id: userId,
        },
      });
      if(response.data.videos.length > 0) {
        setAllSaasVideos(response.data.videos);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (apiCallCount < 1) {
      fetchData();
      fetchEditorData();
      fetchSaasData();
      setApiCallCount(apiCallCount + 1);
    }
    //eslint-disable-next-line
  }, [user_id, allVideos, apiCallCount]);

  useEffect(() => {
    const mixVideos = allVideos.length > 0 && allEditorVideos.length > 0 && [...allVideos, ...allEditorVideos];
    if(mixVideos.length === 0) {
      enqueueSnackbar("No video wishlisted.", {
        variant: "default",
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (currentVid) {
      setCurrentlyPlaying(allVideos.find((vid) => vid.video_id === currentVid));
    }
    //eslint-disable-next-line
  }, []);

  function handleVideoClosed() {
    setCurrentlyPlaying(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {currentlyPlaying ? (
            <VuiBox width="100%">
              <VideoPopup
                video_data={currentlyPlaying}
                handleClose={handleVideoClosed}
              />
            </VuiBox>
          ) : (
            <VuiBox mt="0" mb="24px">
              <Grid container>
                <Grid item xs={12}>
                  <VuiBox mb={1}>
                    <VuiTypography variant="lg" color="white" fontWeight="bold">
                      Favourite Videos
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox mb="40px">
                    <VuiTypography
                      fontSize={16}
                      color="text"
                      fontWeight="regular"
                    >
                      All your Favourite videos will show up here.
                    </VuiTypography>
                  </VuiBox>
                </Grid>
              </Grid>
              <VuiBox mb={1}>
                {loading ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", marginTop: "10%" }}>
                    <CircularProgress size={60} color="white" />
                  </div>
                ) : (
                  <Grid container spacing={3}>
                    {allVideos.map((v_data, index) => {
                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3} onMouseEnter={() => setIsPlaying(true)} onMouseLeave={() => setIsPlaying(false)} key={v_data.video_id}>
                          <Card
                            onClick={() => setCurrentlyPlaying(v_data)}
                            sx={{cursor: "pointer"}}
                          >
                            <ReactPlayer url={v_data.file} muted width="100%" height="100%" playing={isPlaying} />
                          </Card>
                          <Card
                            style={{
                              zIndex: "1",
                              marginTop: "-30px",
                              borderRadius: "0 0 20px 20px ",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: "1",
                                  }}
                                >
                                  <VuiTypography variant="body2" color="light">
                                    {v_data.filename.length > 15
                                      ? v_data.filename.slice(0, 15) + "..."
                                      : v_data.filename}
                                  </VuiTypography>
                                </div>
                          </Card>
                        </Grid>
                      );
                    })}
                    {allEditorVideos.map((v_data, index) => {
                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3} onMouseEnter={() => setIsPlaying(true)} onMouseLeave={() => setIsPlaying(false)} key={v_data.video_id}>
                          <Card
                            onClick={() => setCurrentlyPlaying(v_data)}
                            sx={{cursor: "pointer"}}
                          >
                            <ReactPlayer url={v_data.file} muted width="100%" height="100%" playing={isPlaying} />
                          </Card>
                          <Card
                            style={{
                              zIndex: "1",
                              marginTop: "-30px",
                              borderRadius: "0 0 20px 20px ",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: "1",
                                  }}
                                >
                                  <VuiTypography variant="body2" color="light">
                                    {v_data.filename.length > 15
                                      ? v_data.filename.slice(0, 15) + "..."
                                      : v_data.filename}
                                  </VuiTypography>
                                </div>
                          </Card>
                        </Grid>
                      );
                    })}
                    {allSaasVideos.map((v_data, index) => {
                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3} onMouseEnter={() => setIsPlaying(true)} onMouseLeave={() => setIsPlaying(false)} key={v_data.video_id}>
                          <Card
                            onClick={() => setCurrentlyPlaying(v_data)}
                            sx={{cursor: "pointer"}}
                          >
                            <ReactPlayer url={v_data.file} muted width="100%" height="100%" playing={isPlaying} />
                          </Card>
                          <Card
                            style={{
                              zIndex: "1",
                              marginTop: "-30px",
                              borderRadius: "0 0 20px 20px ",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: "1",
                              }}
                            >
                              <VuiTypography variant="body2" color="light">
                                {v_data.filename.length > 15
                                  ? v_data.filename.slice(0, 15) + "..."
                                  : v_data.filename}
                              </VuiTypography>
                            </div>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </VuiBox>
            </VuiBox>
          )}
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}

export default Wishlist;