/*eslint-disable no-unused-vars */

import React from "react";
import QuickVideoIcon from "../QuickVideoIcon.png";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import VuiButton from "components/VuiButton";
import { Box, Paper } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
// import Card from "assets/theme/components/card";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import VuiInput from "components/VuiInput";

function FirstStep() {
  

  return (
    <VuiBox style={{height: '250px'}}>
      <Grid>
        <VuiTypography
          style={{ display: "flex", justifyContent: "center" }}
          variant="h5"
          color="light"
          textGradient
          fontWeight="medium"
        >
          What do you intend to use this product for?
        </VuiTypography>
        <VuiBox
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <VuiInput />
        </VuiBox>
      </Grid>
      <Grid sx={{marginTop: '20px'}}>
        <VuiTypography
          style={{ display: "flex", justifyContent: "center" }}
          variant="h5"
          color="light"
          textGradient
          verticalAlign="middle"
          fontWeight="medium"
        >
          Where did you hear about this product?
        </VuiTypography>
        <VuiBox
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <VuiInput />
        </VuiBox>
      </Grid>
    </VuiBox>
  );
}

export default FirstStep;
