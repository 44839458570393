/*eslint-disable */

export default [
  {
    video_id: "V1",
    ts: "2021-08-01 12:00:00",
    user_init: "Jaynam Modi",
    v_length: "00:45:00",
    v_title: "Demo Project 1",
    v_subtitle: "Demo Project 1",
    v_description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable ",
    v_url: "https://synthesia-results.s3.eu-west-1.amazonaws.com/website_demos/Homepage/Hero/Product-demo-long.webm",
    v_thumbnail: "https://assets-global.website-files.com/61dc0796f359b6145bc06ea6/632473d99ebeb0b338432d3c_product_demo_static_00000.jpg",
    v_status: "success",
  },
  {
    video_id: "V2",
    ts: "2021-08-01 12:00:00",
    user_init: "USER 2",
    v_length: "00:45:00",
    v_title: "Demo Project 2",
    v_subtitle: "Demo Project 2",
    v_description: "It is a long established fact that a reader will be distracted by the readable content  ",
    v_url: "https://synthesia-results.s3.eu-west-1.amazonaws.com/website_demos/Homepage/Hero/Product-demo-long.webm",
    v_thumbnail: "https://assets-global.website-files.com/61dc0796f359b6145bc06ea6/632473d99ebeb0b338432d3c_product_demo_static_00000.jpg",
    v_status: "success",
  },
  {
    video_id: "V3",
    ts: "2021-08-01 12:00:00",
    user_init: "USER 3",
    v_length: "00:45:00",
    v_title: "Demo Project 3",
    v_subtitle: "Demo Project 3",
    v_description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable ",
    v_url: "https://synthesia-results.s3.eu-west-1.amazonaws.com/website_demos/Homepage/Hero/Product-demo-long.webm",
    v_thumbnail: "https://assets-global.website-files.com/61dc0796f359b6145bc06ea6/632473d99ebeb0b338432d3c_product_demo_static_00000.jpg",
    v_status: "success",
  },
  {
    video_id: "V4",
    ts: "2021-08-01 12:00:00",
    user_init: "USER 3",
    v_length: "00:45:00",
    v_title: "Demo Project 3",
    v_subtitle: "Demo Project 3",
    v_description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable ",
    v_url: "https://synthesia-results.s3.eu-west-1.amazonaws.com/website_demos/Homepage/Hero/Product-demo-long.webm",
    v_thumbnail: "https://assets-global.website-files.com/61dc0796f359b6145bc06ea6/62f4b26d0245dad56561c565_Cybersecurity.jpg",
    v_status: "success",
  },
  {
    video_id: "V5",
    ts: "2021-08-01 12:00:00",
    user_init: "USER 3",
    v_length: "00:45:00",
    v_title: "Demo Project 3",
    v_subtitle: "Demo Project 3",
    v_description: "Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable ",
    v_url: "https://synthesia-results.s3.eu-west-1.amazonaws.com/website_demos/Homepage/Hero/Product-demo-long.webm",
    v_thumbnail: "https://assets-global.website-files.com/61dc0796f359b6145bc06ea6/632473d99ebeb0b338432d3c_product_demo_static_00000.jpg",
    v_status: "success",
  },
];
