import React, { useEffect, useState } from 'react';
import QuickVideoIcon from "./QuickVideoIcon.png";
import tick from "./tick.png";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import BASE_URL from 'context/api';

function Success(props) {
  const[message, setMessage] = useState('');
  const history = useHistory();

  const { token } = useParams();

  useEffect(() => {

    if (token) {
      axios.post(`${BASE_URL}/activate/token`, {
        token: token
      })
      .then((response) => {
        if (response.data.status === 'success') {
          setMessage("Your account is verified");
          setTimeout(() => {
            history.push('/signin');
          }, 1000);
        } else if (response.data.status === 'failed') {
          setMessage(response.data.message);
          setTimeout(() => {
            history.push('/signin');
          }, 1000);
        }
      })
      .catch((error) => {
        console.error(error);
        // setMessage(error);
      });
    }
    //eslint-disable-next-line
  }, [token]);

  return (
    <div style={{display: "flex", flexDirection: 'column', justifyContent: window.innerWidth < 768 ? 'flex-start' :'center', alignItems: 'center', color: 'white', paddingTop: "100px", height: window.innerWidth < 768 ? '100vh' : ''}}>
      <img src={QuickVideoIcon} height={window.innerWidth < 768 ? "30px" : "60px"} alt="Icon" />
      <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', marginTop: '5rem'}}>
        <h1 style={{fontSize: window.innerWidth < 768 ? "2rem" : '3rem', fontWeight: '400', letterSpacing: '2px'}}>Welcome</h1>
        <img src={tick} alt="tick" height={window.innerWidth < 768 ? "35px" : "70px"} width="100%" style={{paddingLeft: '20px'}} />
      </div>
      <h2 style={{fontSize: window.innerWidth < 768 ? "1rem" : '1.5rem', fontWeight: '200', letterSpacing: '2px'}}>{message}</h2>
      <h3 style={{paddingTop: "30px", fontWeight: '200'}}>Redirecting you to Sign In page...</h3>
    </div>
  )
}

export default Success;