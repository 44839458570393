import { Button, Card, Tab, Tabs } from '@mui/material'
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography'
import React, { useState } from 'react'
import ChatModal from './ChatModal';
import Pagination from 'Pagination';

const StreamingLogs = ({data}) => {
  const [tabValue, setTabValue] = useState(0);
  const itemsPerPage = 4;
  const itemsPerPageBA = 4;
  const [chatModal, setChatModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageBA, setCurrentPageBA] = useState(1);

  const tabHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageChangeBA = (newPage) => {
    setCurrentPageBA(newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = data?.streamLogs?.slice(startIndex, endIndex);
  const startIndexBA = (currentPageBA - 1) * itemsPerPageBA;
  const endIndexBA = startIndexBA + itemsPerPageBA;
  const displayedDataBA = data?.agentLogs?.slice(startIndexBA, endIndexBA);

  return (
    <Card>
      <Tabs value={tabValue} onChange={tabHandler} sx={{width: '200px', maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto', overflow: 'auto'}}>
        <Tab
          style={{minWidth:"50%"}}
          label={
            <VuiTypography color="light" variant="body2">
              API
            </VuiTypography>
          }
        />
        <Tab
          style={{minWidth:"50%"}}
          label={
            <VuiTypography color="light" variant="body2">
              Bot Agent
            </VuiTypography>
          }
        />
      </Tabs>
      {tabValue === 0 && <Card>
        {displayedData?.map((item, index) => (
          <VuiBox key={index} sx={{border: '1px solid', '&:hover': {border: '1px solid skyblue'}, padding: '5px 20px', borderRadius: '10px', margin: '10px 0px', cursor: 'pointer'}} onClick={()=>setChatModal(item)}>
            <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: window.innerWidth < 768 && 'column'}}>
              <VuiTypography color='white' variant='h6' sx={{fontSize: '14px'}}>Stream id: {item.stream_id.length > 10 ? item.stream_id.slice(0, 10) : item.stream_id}</VuiTypography>
              <VuiTypography color='error' variant='h6' sx={{fontSize: '12px'}}>{item.total_credits_used.toFixed(2)} &nbsp; credits</VuiTypography>
            </VuiBox>
            <VuiBox sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flexDirection: window.innerWidth < 768 && 'column'}}>
              {/* <Button variant='outlined' onClick={()=>setChatModal(item)} sx={{padding: '2px 5px'}}>Show Chat</Button> */}
              <VuiTypography color='info' variant='h6' sx={{fontSize: '12px'}}>{item.timestamp}</VuiTypography>
            </VuiBox>
          </VuiBox>
        ))}
        {data?.streamLogs.length > 5 && <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(data?.streamLogs.length / itemsPerPage)}
          onPageChange={handlePageChange}
        />}
      </Card>}
      {tabValue === 1 && <Card>
        {displayedDataBA?.map((item, index) => (
          <VuiBox key={index} sx={{border: '1px solid', '&:hover': {border: '1px solid skyblue'}, padding: '5px 20px', borderRadius: '10px', margin: '10px 0px'}}>
            <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: window.innerWidth < 768 && 'column'}}>
              <VuiTypography color='white' variant='h6' sx={{fontSize: '13px'}}>Stream id: &nbsp;  {item.stream_id.length > 10 ? item.stream_id.slice(0, 10) : item.stream_id}</VuiTypography>
              <VuiTypography color='error' variant='h6' sx={{fontSize: '12px'}}>-{item.total_credits_used.toFixed(2)} &nbsp; credits</VuiTypography>
            </VuiBox>
            <VuiBox sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flexDirection: window.innerWidth < 768 && 'column'}}>
              {/* <Button variant='outlined' onClick={()=>setChatModal(item)} sx={{padding: '2px 5px'}}>Show Chat</Button> */}
              <VuiTypography color='white' variant='h6' sx={{fontSize: '13px'}}>Session id: &nbsp; {item.session_id.slice(0, 20)} ..</VuiTypography>
              <VuiTypography color='info' variant='h6' sx={{fontSize: '12px'}}>{item.timestamp}</VuiTypography>
            </VuiBox>
          </VuiBox>
        ))}
        {data?.agentLogs.length > 5 && <Pagination
          currentPage={currentPageBA}
          totalPages={Math.ceil(data?.agentLogs.length / itemsPerPageBA)}
          onPageChange={handlePageChangeBA}
        />}
      </Card>}
      {chatModal && <ChatModal chatModal={chatModal} setChatModal={setChatModal} />}
    </Card>
  )
}

export default StreamingLogs