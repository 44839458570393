/*eslint-disable no-unused-vars */
import { Typography } from "@mui/material";
import VuiDropzone from "components/VuiDropzone";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Check, Computer, ExitToApp, FileUpload, Headphones, Mail, People, Quiz, Shop, Shop2, ShoppingBag, Star, YouTube } from "@mui/icons-material";
import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import VuiBox from "components/VuiBox";
import { IoShirt } from "react-icons/io5";
import { Box3 } from "three";

function Ecom(){
    return(
        <Grid sx={{ display: 'flex' }} style={{marginTop:"25px"}} >
        <Grid style={{width:"30%",marginRight:"5px"}} sx={{ display: 'flex', flexDirection: 'column'}} item xs={6} md={5} lg={6} xl={5}>
          <VuiBox style={{width:"10"}} mb ={3}>
        <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Shoppable Videos", fontWeight: "regular" }}
                    
                    icon={<ShoppingBag color="white" size="22px" />}
                  />
            </VuiBox>
            <VuiBox mb ={3}>
        <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Video Support", fontWeight: "regular" }}
                    
                    icon={<Headphones color="white" size="22px" />}
                  />
            </VuiBox>
            <VuiBox mb ={3}>
        <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Personal Stylist", fontWeight: "regular" }}
                    
                    icon={<IoShirt color="white" size="22px" />}
                  />
            </VuiBox>
  
        </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'column'}} style={{width:"30%",marginRight:"5px"}} item xs={6} md={5} lg={6} xl={5}>
          <VuiBox mb ={3}>
          <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Exit Intent", fontWeight: "regular" }}
                    
                    icon={<ExitToApp color="white" size="22px" />}
                  />
            </VuiBox>
            <VuiBox mb ={3}>
          <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Welcome Visitors", fontWeight: "regular" }}
                    
                    icon={<Star color="white" size="22px" />}
                  />
            </VuiBox>
            <VuiBox mb ={3}>
          <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Video Quiz", fontWeight: "regular" }}
                    
                    icon={<Quiz color="white" size="22px" />}
                  />
            </VuiBox>
  
        </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'column'}} style={{width:"30%",marginRight:"5px"}} item xs={6} md={5} lg={6} xl={5}>
          <VuiBox mb ={3}>
          <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Email And SMS Campaigns", fontWeight: "regular" }}
                    
                    icon={<Mail color="white" size="22px" />}
                  />
            </VuiBox>
            <VuiBox mb ={3}>
          <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Porduct Video", fontWeight: "regular" }}
                    
                    icon={<YouTube color="white" size="22px" />}
                  />
            </VuiBox>
            <VuiBox mb ={3}>
          <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Unboxing", fontWeight: "regular" }}
                    
                    icon={<Box color="white" size="22px" />}
  
                  />
            </VuiBox>
  
        </Grid>
      </Grid>
      
    );
}

export default Ecom;