import { Button, ButtonGroup, Slider, Tooltip, Typography } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiInput from 'components/VuiInput';
import VuiSelect from 'components/VuiSelect';
import React, { useState } from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import zIndex from '@mui/material/styles/zIndex';
import VuiTypography from 'components/VuiTypography';

const TextSettings = ({fonts, selectedFonts, textVariant, indexToStore, setIndexToStore, scenes, updateSceneKey, currentSceneIndex, fontSize, setFontSize, selectedFontSize, setSelectedFontSize}) => {

  const [font, setFont] = useState(scenes[currentSceneIndex].textToggle[textVariant].font);
  const [fontSizeSelected, setFontSizeSelected] = useState(scenes[currentSceneIndex].textToggle[textVariant].fontSize);
  const [color, setColor] = useState(scenes[currentSceneIndex].textToggle[textVariant].color || '#000000');
  const [bgColor, setBgColor] = useState(scenes[currentSceneIndex].textToggle[textVariant].background || '');
  const [stroke, setStroke] = useState(scenes[currentSceneIndex].textToggle[textVariant].stroke);
  const [border, setBorder] = useState(scenes[currentSceneIndex].textToggle[textVariant].border || 0);
  const [borderColor, setBorderColor] = useState(scenes[currentSceneIndex].textToggle[textVariant].borderColor || '');
  const [padding, setPadding] = useState(scenes[currentSceneIndex].textToggle[textVariant].padding || 0);
  const [align, setAlign] = useState(scenes[currentSceneIndex].textToggle[textVariant].align);
  const [scale, setScale] = useState(scenes[currentSceneIndex].textToggle[textVariant].scale || 100);
  const [zIndex, setZIndex] = useState(scenes[currentSceneIndex].textToggle[textVariant].zIndex || 40);
  const [opacity, setOpacity] = useState(scenes[currentSceneIndex].textToggle[textVariant].opacity || 1);
  const [top, setTop] = useState(scenes[currentSceneIndex].textToggle[textVariant].marginTop || 0);
  const [left, setLeft] = useState(scenes[currentSceneIndex].textToggle[textVariant].marginLeft || 0);
  const [text, setText] = useState(scenes[currentSceneIndex].textToggle[textVariant].text || textVariant);
  const variant = textVariant;

  const handleLeftMinus = () => {
    setLeft(prevLeft => prevLeft - 1);
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].marginLeft = left;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }
  
  const handleLeftPlus = () => {
    setLeft(prevLeft => prevLeft + 1);
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].marginLeft = left;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }

  const handleTopMinus = () => {
    setTop(prevTop => prevTop - 1);
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].marginTop = top;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }
  
  const handleTopPlus = () => {
    setTop(prevTop => prevTop + 1);
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].marginTop = top;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }

  const handleFontChange = (e) => {
    setFont(e.value);
    const font = e.value;
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].font = font;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  };
  
  const handleFontSizeChange = (e) => {
    setFontSizeSelected(e.value);
    const font = e.value;
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].fontSize = font;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  };

  const handleOpacity = (event, newValue) => {
    setOpacity(newValue);
    const opacity = newValue;
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].opacity = opacity;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }

  const handleZIndex = (event, newValue) => {
    setZIndex(newValue);
    const zIndex = newValue;
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].zIndex = zIndex;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }

  const handleZdecrease = () => {
    setZIndex(prevZ => {
      const newZIndex = prevZ - 10;
      const newTextArrays = { ...scenes[currentSceneIndex].textArrays };
  
      if (newTextArrays[variant]) {
        if (newTextArrays[variant][indexToStore[variant]]) {
          newTextArrays[variant][indexToStore[variant]].zIndex = newZIndex;
        } else {
          console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
        }
        updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
      } else {
        console.error(`Variant "${variant}" does not exist in textArrays.`);
      }
  
      return newZIndex; // Return the new state value
    });
  }

  const handleZincrease = () => {
    setZIndex(prevZ => {
      const newZIndex = prevZ + 10;
      const newTextArrays = { ...scenes[currentSceneIndex].textArrays };
  
      if (newTextArrays[variant]) {
        if (newTextArrays[variant][indexToStore[variant]]) {
          newTextArrays[variant][indexToStore[variant]].zIndex = newZIndex;
        } else {
          console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
        }
        updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
      } else {
        console.error(`Variant "${variant}" does not exist in textArrays.`);
      }
  
      return newZIndex;
    });
  }

  const handleScale = (event, newValue) => {
    setScale(newValue);
    const scale = newValue;
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].scale = scale;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }

  const handlePadding = (event, newValue) => {
    setPadding(newValue);
    const padding = newValue;
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].padding = padding;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }

  const handleBorder = (event, newValue) => {
    setBorder(newValue);
    const border = newValue;
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].border = border;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }
  
  const handleBorderColor = (e) => {
    setBorderColor(e.target.value);
    const borderColor = e.target.value;
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].borderColor = borderColor;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }

  const handleColor = (e) => {
    setColor(e.target.value);
    const color = e.target.value;
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].color = color;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }
  
  const handleBgColor = (e) => {
    setBgColor(e.target.value);
    const bgColor = e.target.value;
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].background = bgColor;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }

  const removeBgColor = () => {
    setBgColor('');
    const bgColor = '';
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].background = bgColor;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }
  }

  const handleText = (e) => {
    const value = e.target.value;
    setText(value);
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    if (newTextArrays[variant]) {
      if (newTextArrays[variant][indexToStore[variant]]) {
        newTextArrays[variant][indexToStore[variant]].text = value;
      } else {
        console.error(`Index ${indexToStore[variant]} does not exist in "${variant}" array.`);
      }
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Variant "${variant}" does not exist in textArrays.`);
    }

    // setText((prevState) => ({
    //   ...prevState,
    //   [variant]: value,
    // }));

    // const indexToIncrement = indexToStore[textVariant];

    // setTextArrays((prevState) => {
    //   const updatedArray = [...prevState[variant]];
    //   updatedArray[indexToIncrement] = value;
    //   return {
    //     ...prevState,
    //     [variant]: updatedArray,
    //   };
    // });
  };

  return (
    <VuiBox sx={{height: '100%'}}>
      <VuiInput
        placeholder="Type your text here..."
        multiline
        minRows={6}
        sx={{marginTop: '10px'}}
        onChange={handleText}
        value={text}
      />

      <VuiBox>
        <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Font style</Typography>
        <VuiSelect
          options={fonts.map((entry) => ({ value: entry, label: entry }))}
          onChange={(e)=>handleFontChange(e)}
          value={selectedFonts}
        />
      </VuiBox>
      
      <VuiBox>
        <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Font size</Typography>
        <VuiSelect
          options={fontSize.map((entry) => ({ value: entry, label: entry }))}
          onChange={(e)=>handleFontSizeChange(e)}
          // value={selectedFontSize}
        />
      </VuiBox>

      <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px'}}>
        <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Color</Typography>
        <input type='color' style={{appearance: 'none', backgroundColor: 'transparent', width: '30px', height: '30px', cursor: 'pointer', border: 'none'}} value={color} onChange={handleColor} />
      </VuiBox>
      
      <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Background</Typography>
        <div style={{display: 'flex'}}>
          {bgColor !== '' && <Tooltip title='remove background'>
            <VuiTypography color='white' variant='h6' sx={{marginRight: '10px', cursor: 'pointer'}} onClick={removeBgColor}>x</VuiTypography>
          </Tooltip>}
          <input type='color' style={{appearance: 'none', backgroundColor: 'transparent', width: '30px', height: '30px', cursor: 'pointer', border: 'none'}} value={bgColor} onChange={handleBgColor} />
        </div>
      </VuiBox>

      {/* <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Stroke</Typography>
        <Slider value={10} min={0} max={100}  color="secondary" sx={{width: '100px'}} />
        <VuiBox sx={{color: 'whitesmoke', border: '1px solid whitesmoke', borderRadius: '10px', padding: '0px 20px', fontSize: '14px'}}>1px</VuiBox>
        <input type='color' style={{appearance: 'none', backgroundColor: 'transparent', width: '30px', height: '30px', cursor: 'pointer', border: 'none'}} />
      </VuiBox> */}

      {/* <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Border</Typography>
        <Slider min={0} max={50}  color="secondary" sx={{width: '100px'}} onChange={handleBorder} value={border} />
        <VuiBox sx={{color: 'whitesmoke', border: '1px solid whitesmoke', borderRadius: '10px', padding: '0px 20px', fontSize: '14px'}}>{border}px</VuiBox>
        <input type='color' style={{appearance: 'none', backgroundColor: 'transparent', width: '30px', height: '30px', cursor: 'pointer', border: 'none'}} value={borderColor} onChange={handleBorderColor} />
      </VuiBox>

      <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Padding</Typography>
        <Slider min={0} max={100}  color="secondary" sx={{width: '100px'}} onChange={handlePadding} value={padding} />
        <VuiBox sx={{color: 'whitesmoke', border: '1px solid whitesmoke', borderRadius: '10px', padding: '0px 20px', fontSize: '14px'}}>{padding === '' ? "0px" : `${padding} px`}</VuiBox>
      </VuiBox> */}

      {/* <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Align</Typography>
        <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Button><FormatAlignLeftIcon color='white' /></Button>
          <Button><FormatAlignCenterIcon color='white' /></Button>
          <Button><FormatAlignRightIcon color='white' /></Button>
        </VuiBox>
      </VuiBox> */}

      {/* <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Scale</Typography>
        <Slider min={0} max={500}  color="secondary" sx={{width: '100px'}} onChange={handleScale} value={scale} />
        <VuiBox sx={{color: 'whitesmoke', border: '1px solid whitesmoke', borderRadius: '10px', padding: '0px 20px', fontSize: '14px'}}>{scale === '' ? "0%" : `${scale}%`}</VuiBox>
      </VuiBox> */}
      
      <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
        {/* <Typography variant='h6' sx={{marginTop: '10px'}}>Position</Typography>
        <VuiBox sx={{display: 'flex', justifyContent: 'space-between'}}>
          <VuiBox sx={{display: 'flex' ,flexDirection: 'column'}}>
            <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>X</Typography>
            <ButtonGroup variant='outlined' aria-label="outlined button group" sx={{width: '150px'}}>
              <Button sx={{padding: '5px'}} onClick={handleLeftMinus}>-</Button>
              <Button sx={{padding: '5px'}}>{left}</Button>
              <Button sx={{padding: '5px'}} onClick={handleLeftPlus}>+</Button>
            </ButtonGroup>
          </VuiBox>
          <VuiBox sx={{display: 'flex' ,flexDirection: 'column'}}>
            <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Y</Typography>
            <ButtonGroup variant='outlined' aria-label="outlined button group" sx={{width: '150px'}}>
              <Button sx={{padding: '5px'}} onClick={handleTopMinus}>-</Button>
              <Button sx={{padding: '5px'}}>{top}</Button>
              <Button sx={{padding: '5px'}} onClick={handleTopPlus}>+</Button>
            </ButtonGroup>
          </VuiBox>
        </VuiBox> */}

        <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px auto'}}>
          <ButtonGroup variant="contained">
            <Button sx={{color: 'whitesmoke', '&:disabled': {color: 'gray' }}} onClick={handleZdecrease} disabled={zIndex < 1}>move back</Button>
            <Button sx={{color: 'whitesmoke'}} onClick={handleZincrease}>move front</Button>
          </ButtonGroup>
        </VuiBox>

        <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
          <Typography variant='h6'>Opacity</Typography>
          <Slider min={0} max={1} step={0.01} color="secondary" sx={{width: '100px'}} value={opacity} onChange={handleOpacity} />
          <VuiBox sx={{color: 'whitesmoke', border: '1px solid whitesmoke', borderRadius: '10px', padding: '0px 20px', fontSize: '14px'}}>{opacity}</VuiBox>
        </VuiBox>
      </VuiBox>
    </VuiBox>
  )
}

export default TextSettings