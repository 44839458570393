import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles, withStyles } from "@mui/styles";
import StepConnector from "@mui/material/StepConnector";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// // Vision UI Dashboard PRO React example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";

// Wizard page components
import FirstStep from "../components/First";
import FinalStep from "../components/FinalStep";
import SecondStep from "../components/Second";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Tour from "reactour";
import { setIsTourOpen } from "context";
import { useVisionUIController } from "context";
import { companyInfo } from "context";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles({
  connectorLine: {
    '& .MuiStepConnector-line': {
      borderColor: 'white',
    },
  },
});

const BlueStepConnector = withStyles((theme) => ({
  active: {
    "& .MuiStepConnector-line": {
      borderColor: '#0075ff',
    },
    "& .MuiStepConnector-root": {
      "& .MuiStepConnector-line": {
        transition: theme.transitions.create("border-color"),
      },
    },
  },
  completed: {
    "& .MuiStepConnector-line": {
      borderColor: '#0075ff',
    },
    "& .MuiStepConnector-root": {
      "& .MuiStepConnector-line": {
        transition: theme.transitions.create("border-color"),
      },
    },
  },
  disabled: {
    "& .MuiStepConnector-line": {
      borderColor: theme.palette.grey[500],
    },
    "& .MuiStepConnector-root": {
      "& .MuiStepConnector-line": {
        transition: theme.transitions.create("border-color"),
      },
    },
  },
}))(StepConnector);

function Wizard() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  //eslint-disable-next-line
  const isLastStep = activeStep === steps.length - 1;
  const [controller, dispatch] = useVisionUIController();
  const { isTourOpen, userDetails  } = controller;
  const [value, setValue] = React.useState("Alone");
  const [size, setSize] = React.useState("Real Estate");
  const [role, setRole] = React.useState("Administration");
  const { user_id } = userDetails;

  function getSteps() {
    return ["First Step", "Second Step","Final Step"];
  }
  
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <FirstStep />;
      case 1:
        return <SecondStep
          value={value}
          setValue={setValue}
          size={size}
          setSize={setSize}
          role={role}
          setRole={setRole}
        />;
      case 2:
        return <FinalStep />;
      default:
        return null;
    }
  }

  const handleNext = () => {setActiveStep(activeStep + 1);console.log('next');}
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleTourToggle = () => {
    setIsTourOpen(dispatch, !isTourOpen);
    try{
      companyInfo(dispatch, value, size, role, user_id);
    } catch(err) {
      console.log(err);
    }
    
  };

  return (
    <DashboardLayout>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(dispatch, !isTourOpen)}
        // maskClassName="mask"
        className="helper"
        rounded={10}
        accentColor="#007aff"
        highlightedMaskClassName="highlight"
        nextButton="Next"
        prevButton="Back"
        lastStepNextButton="Finish"
        showNumber={true}
        showButtons={true}
        showNavigation={true}
        disableInteraction={false}
        disableDotsNavigation={false}
        disableKeyboardNavigation={false}
        disableFocusLock={false}
      />
      <DashboardNavbar />
      <VuiBox>
        <Grid container>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} alternativeLabel connector ={<BlueStepConnector />} className={classes.connector} >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card>
              <VuiBox p={2}>
                <VuiBox>{getStepContent(activeStep)}</VuiBox>
                <VuiBox mt={3} width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  {activeStep === 0 ? (
                      <VuiBox />
                    ) : (
                      <VuiButton
                        variant="gradient"
                        color="light"
                        onClick={handleBack}
                      >
                        Back
                      </VuiButton>
                    )
                  }
                  {isLastStep ? (
                    <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                      {window.innerWidth > 768 && 
                        <NavLink to='/create'>
                          <VuiTypography color="white" fontWeight="medium" sx={{textDecoration: 'underline', textUnderlineOffset: '5px'}} variant='h6' mr='20px'>
                            skip
                          </VuiTypography>
                        </NavLink>
                      }
                      <NavLink to='/create'>
                        <Button variant="outlined" onClick={handleTourToggle}> Continue</Button>
                      </NavLink>
                    </VuiBox>
                    ) : (
                    <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                      {window.innerWidth > 768 &&
                        <NavLink to='/create'>
                          <VuiTypography color="white" fontWeight="medium" sx={{textDecoration: 'underline', textUnderlineOffset: '5px'}} variant='h6' mr='20px'>
                            skip
                          </VuiTypography>
                        </NavLink>
                        }
                      <VuiButton variant="gradient" color="light" onClick={handleNext}> Next </VuiButton>
                    </VuiBox>
                    )
                  }
                </VuiBox>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
}

export default Wizard;
