import { Button, CircularProgress, IconButton, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import VuiBox from 'components/VuiBox';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import VuiInput from 'components/VuiInput';
import VuiTypography from 'components/VuiTypography';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BackupIcon from '@mui/icons-material/Backup';
import { Cancel } from '@mui/icons-material';
import axios from 'axios';
import BASE_URL from "context/api";

const QuestionModal = ({questionMod, setQuestionMod, basicDetails, setBasicDetails, initialScene, setScenes, scenes, currentSceneIndex}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleClose = () => {
    history.push('/saastutorial');
  };
  
  const handleNext = async () => {
    setLoading(true);
    if(selectedFile) {
      const formData = new FormData();
      formData.append('video', selectedFile, selectedFile.name);
  
      try {
        const response = await axios.post(`${BASE_URL}/ai_video_indexer`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        setLoading(false);
        setQuestionMod(false);
        const newScenes = response.data.map(item => ({
          ...initialScene,
          script: item.transcription.join(' '),
          selectedVideo: {
            video: item.url,
            zIndex: 10,
            opacity: 1,
            x: 170,
            y: 20,
            size: {
              width: scenes[currentSceneIndex].resolution.expectedSize.width,
              height: scenes[currentSceneIndex].resolution.expectedSize.width,
            },
            handle: false,
            fullscreen: false,
            recorded: false,
          }
        }));
    
        setScenes(newScenes);
      } catch (error) {
        console.error('Error uploading video:', error);
        setLoading(false);
      }
    }    
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const videoObject = {
          video: e.target.result,
          name: file.name, 
        };
        setBasicDetails((prevDetails) => ({...prevDetails, video: videoObject}));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDetails = (e, prop) => {
    setBasicDetails(prevDetails => ({
      ...prevDetails,
      [prop]: e.target.value
    }));
  };

  const handleRemoveVideo = () => {
    setBasicDetails(prevDetails => ({
      ...prevDetails,
      video: null
    }));
  };

  return (
    <div>
        <Modal open={questionMod}>
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 100%, rgba(10, 14, 35, 0.49) 76.65%)",
            color: "#090d2c",
            outline: "none",
            padding: "25px",
            minHeight: '40vh',
            maxHeight: "50vh",
            width: "35vw",
            boxShadow: "rgb(54, 68, 97) 0px 0px 100px 20px",
            borderRadius: '5px',
            overflow: 'auto',
          }}>
            {loading ? <CircularProgress color='white' sx={{position: 'absolute', top: '50%', left: '50%'}} /> : <>
            <div style={{position: 'absolute', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px', top: 10, right: 10}}>
              <CloseIcon onClick={handleClose} sx={{ color: "whitesmoke", cursor: 'pointer' }} />
            </div>
            <VuiTypography textAlign='center' variant='h4' mb='10px'>Let us know some basic details</VuiTypography>
            <VuiBox sx={{display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
              <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'wrap'}}>
                <VuiBox sx={{display: 'flex', flexDirection: 'column', margin: '15px', alignItems: 'flex-start'}}>
                  <VuiTypography color='white' variant='h6'>Tutorial Name</VuiTypography>
                  <VuiInput value={basicDetails.tutorialName} onChange={(e) => handleDetails(e, 'tutorialName')} />
                </VuiBox>
                <VuiBox sx={{display: 'flex', flexDirection: 'column', margin: '15px', alignItems: 'flex-start'}}>
                  <VuiTypography color='white' variant='h6'>Product Name</VuiTypography>
                  <VuiInput value={basicDetails.productName} onChange={(e) => handleDetails(e, 'productName')} />
                </VuiBox>
              </VuiBox>
              <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <VuiBox sx={{display: 'flex', flexDirection: 'column', margin: '15px', alignItems: 'flex-start'}}>
                  <VuiTypography color='white' variant='h6'>Business Name</VuiTypography>
                  <VuiInput value={basicDetails.businessName} onChange={(e) => handleDetails(e, 'businessName')} />
                </VuiBox>
                <VuiBox sx={{display: 'flex', flexDirection: 'column', margin: '15px', alignItems: 'center'}}>
                  {basicDetails.video ? 
                    <VuiBox sx={{position: 'relative', marginTop: '30px', display: 'flex'}}>
                      <VuiTypography variant='h6'>{basicDetails.video.name.length > 12 ? basicDetails.video.name.slice(0, 10) + '...' : basicDetails.video.name}</VuiTypography>
                      <IconButton
                        size="small"
                        color="white"
                        onClick={handleRemoveVideo}
                        style={{ background: 'black' }}
                      >
                        <Cancel />
                      </IconButton>
                    </VuiBox>
                    :
                    <>
                      <VuiTypography color='white' variant='h6'>Upload your video here</VuiTypography>
                      <label htmlFor="video-file" style={{cursor: 'pointer', width: '180px'}}>
                        <Typography variant='h6' sx={{color: '#fff', background: 'transparent', padding: '5px 15px', borderRadius: '15px', border: '1px solid skyblue', textAlign: 'center'}}>
                          <BackupIcon fontSize='medium' />
                          <input
                            accept="video/*"
                            style={{ display: 'none' }}
                            type="file"
                            id="video-file"
                            name="video-file"
                            onChange={handleFileUpload}
                          />
                        </Typography>
                      </label>
                    </>
                  }
                </VuiBox>
              </VuiBox>
              <VuiBox>
                <Button variant='contained' sx={{color: 'whitesmoke'}} onClick={handleNext}>Next &#10140;</Button>
              </VuiBox>
            </VuiBox>
            </>}
          </div>
        </Modal>
      </div>
  )
}

export default QuestionModal