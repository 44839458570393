import { Modal } from '@mui/material';
import React from 'react';
import './index.css';
import loading from './White Loading.gif';
import GIF from './drinking_5973304.png';
import VuiTypography from 'components/VuiTypography';

const LoadingModal = ({loadingModal, setLoadingModal}) => {
  
  // const handleClose = () => {
  //   setLoadingModal(false);
  // }

  return (
    <div>
      <Modal open={loadingModal}>
        <div className='loading_modal' style={{boxShadow: "rgb(54, 68, 97) 0px 0px 30px 2px"}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {/* <img src={loading} alt="loading" width={200} style={{position: 'absolute', marginTop: '40%', opacity: 0.1}} /> */}
            <div style={{display: 'flex', flexDirection: window.innerWidth < 768 ? 'column' : '', alignItems: 'center', justifyContent: 'space-around'}}>
              <img src={GIF} alt="gif" width={250} />
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <VuiTypography variant='h4' style={{color: 'white', textAlign: window.innerWidth < 768 && 'center'}}>Sit, Back and Relax.</VuiTypography>
                <VuiTypography style={{color: 'lightgray', textAlign: window.innerWidth < 768 && 'center'}}>Your AI Video is generating soon ... </VuiTypography>
                <img src={loading} alt="loading" width={40} />
              </div>
            </div>
          </div>
        </div> 
      </Modal>
    </div>
  )
}

export default LoadingModal;