/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { forwardRef, useEffect, useRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select, {components} from "react-select";

// Vision UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

// Custom styles for VuiSelect
import styles from "components/VuiSelect/styles";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';

const CustomOptionWithAudio = ({ isPlaying, onPlayPauseClick, ...props }) => {
  const audioRef = useRef(new Audio(props.data.audioUrl));

  // const handlePlayPauseClick = (e) => {
  //   e.stopPropagation();
  //   if (isPlaying) {
  //     audioRef.current.pause();
  //   } else {
  //     audioRef.current.play();
  //   }
  //   setIsPlaying(!isPlaying);
  // };
  useEffect(() => {
    const audio = audioRef.current;

    const handleAudioEnd = () => {
      onPlayPauseClick(props.data.value, false);
    };

    audio.addEventListener('ended', handleAudioEnd);

    return () => {
      audio.removeEventListener('ended', handleAudioEnd);
      audio.pause();
      audio.currentTime = 0;
    };
  }, [onPlayPauseClick, props.data.value]);

  useEffect(() => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [isPlaying]);

  const handlePlayPauseClick = (e) => {
    e.stopPropagation();
    onPlayPauseClick(props.data.value, !isPlaying);
  };


  return (
    <components.Option {...props}>
      <button
        style={{
          marginRight: '10px',
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          color: 'white',
          position: 'absolute',
          right: 10,
          zIndex: 10
        }}
        onClick={handlePlayPauseClick}
      >
        {isPlaying ? <PauseCircleOutlineIcon fontSize="small" /> : <PlayCircleOutlineIcon fontSize="small" />}
      </button>
      {props.children}
    </components.Option>
  );
};

const CustomOptionDefault = (props) => {
  return (
    <components.Option {...props}>
      {props.children}
    </components.Option>
  );
};

const VuiSelect = forwardRef(({ size, error, success, options, enableAudio, currentlyPlaying, onPlayPauseClick, ...rest }, ref) => {
  const { light } = colors;

  return (
    <Select
      {...rest}
      ref={ref}
      options={options}
      styles={styles(size, error, success, enableAudio)}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: light.main,
          primary: light.main,
        },
      })}
      // components={{ Option: enableAudio ? CustomOptionWithAudio : CustomOptionDefault }}
      components={{ 
        Option: enableAudio 
          ? (props) => (
              <CustomOptionWithAudio
                {...props}
                isPlaying={currentlyPlaying === props.data.value}
                onPlayPauseClick={onPlayPauseClick}
              />
            )
          : CustomOptionDefault
      }}
    />
  );
});

// Setting default values for the props of VuiSelect
VuiSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  currentlyPlaying: null,
  onPlayPauseClick: () => {},
};

// Typechecking props for the VuiSelect
VuiSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  options: PropTypes.array.isRequired,
  enableAudio: PropTypes.bool,
  currentlyPlaying: PropTypes.string,
  onPlayPauseClick: PropTypes.func,
};

export default VuiSelect;
