/*eslint-disable no-unused-vars */

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import Icon from "@mui/material/Icon";
import { Grid } from "@mui/material";

// Vision UI Dashboard PRO React icons
import SpaceShip from "examples/Icons/SpaceShip";
import Document from "examples/Icons/Document";
import Cube from "examples/Icons/Cube";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Settings from "examples/Icons/Settings";
import CreditCard from "examples/Icons/CreditCard";
import { MdPermIdentity, MdOutlineNotificationsActive, MdWorkspaces, MdOutlineBrandingWatermark, MdPriceCheck } from "react-icons/md";
import { AiOutlineTeam } from "react-icons/ai";
import { useState } from "react";

function Sidenav(props) {
  console.log("SideNavProps", props);
  const sidenavItems = [
    // { icon: <MdPermIdentity color={props.currentPane === "profile" ? "#0175FF" : "white" } size={"20px"}/>, label: "My Profile", href: "profile" },
    { icon: <MdOutlineNotificationsActive size={"20px"} color={props.currentPane === "notifications" ? "#0175FF" : "white" } />, label: "Notifications", href: "notifications" },
    { icon: <MdWorkspaces color={props.currentPane === "workspace" ? "#0175FF" : "white" } size="20px"/>, label: "My Workspace", href: "workspace" },
    { icon: <AiOutlineTeam size="20px" color={props.currentPane === "team" ? "#0175FF" : "white" }/>, label: "Team", href: "team" },
    // { icon: <MdOutlineBrandingWatermark size="20px" color={props.currentPane === "branding" ? "#0175FF" : "white" } />, label: "Branding", href: "branding" },
    // { icon: <MdPriceCheck size="20px" color={props.currentPane === "plans" ? "#0175FF" : "white" } />, label: "Plans", href: "plans" },
  ];


  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <VuiBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <VuiButton 
          variant="text" color="info"
          fontWeight="regular"
          textTransform="capitalize"
          style={{textDecoration: "none", color: props.currentPane === href ? "#0175FF" : "white", width: "100%", fontSize: "13px"}}
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light, grey },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(14)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: grey[700],
            },
          })}
          onClick={() => props.handleSettingsState(href)}
        >
        <Grid container spacing={2}>
          <Grid item>
            {icon}
          </Grid>
          <Grid item>
            {label}
          </Grid>
        </Grid>
        </VuiButton>
      </VuiBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <VuiBox component="ul" display="flex" flexDirection="column" m={0} sx={{ listStyle: "none" }}>
        {renderSidenavItems}
      </VuiBox>
    </Card>
  );
}

export default Sidenav;
