import React, { useEffect } from 'react';
// import QuickVideoIcon from "./QuickVideoIcon.png";
import tick from "./tick.png";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import { useLocation } from 'react-router-dom';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import BASE_URL from 'context/api';

function Success() {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push('/gopremium');
    }, 2000);
    //eslint-disable-next-line
  }, [])

  return (
    <div style={{display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'white', paddingTop: "100px"}}>
      <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', marginTop: '5rem'}}>
        <h1 style={{fontWeight: '400', letterSpacing: '2px'}}>Plan changed successfully</h1>
        <img src={tick} alt="tick" height="50px" width="80px" style={{paddingLeft: '20px'}} />
      </div>
    </div>
  )
}

export default Success;