/*eslint-disable no-unused-vars */

import { Typography } from "@mui/material";
import VuiDropzone from "components/VuiDropzone";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Check, Computer, ExitToApp, FileUpload, Headphones, InsertDriveFile, Mail, People, Quiz, Shop, Shop2, ShoppingBag, Star, TaskAlt, YouTube } from "@mui/icons-material";
import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import VuiBox from "components/VuiBox";
import { IoShirt } from "react-icons/io5";
import { Box3 } from "three";

function Recruit(){
    return(
        <Grid container xs={6} md={6} lg={12} xl={12} sx={{ mt: '25px' }} direction='row' justifyContent='center'>
          <Grid sx={{ mr: '5px'}} item xs={6} md={6} lg={4} xl={4}>
            <VuiBox mb ={3}>
              <MiniStatisticsCard 
                // style={{width:"120"}}
                bgColor="info"
                title={{ text: "Video Recruiting", fontWeight: "regular" }}
                icon={<InsertDriveFile color="white" size="22px" />}
              />
            </VuiBox>
          </Grid>
          <Grid item xs={6} md={6} lg={4} xl={4}>
            <VuiBox mb ={3}>
              <MiniStatisticsCard 
                // style={{width:"100"}}
                bgColor="info"
                title={{ text: "Happiness Check in", fontWeight: "regular" }}
                icon={<TaskAlt color="white" size="22px" />}
              />
            </VuiBox>
          </Grid>
        </Grid>
    );
}

export default Recruit;