import { Button, CircularProgress, Modal } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiInput from 'components/VuiInput';
import VuiTypography from 'components/VuiTypography';
import { setLoading } from 'context';
import React, { useEffect, useState } from 'react';
import { IoMdColorWand } from "react-icons/io";

const ChatGptModal = ({open, setOpen, chatgptText, setChatgptText, makeAPIRequest}) => {
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpen(false);

  const handleCall = async() => {
    setLoading(true);
    try {
      const response = await makeAPIRequest(chatgptText);
      setLoading(false);
      setChatgptText(response);
    } catch (error) {
      console.error('API request failed:', error);
    }
    // handleClose();
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className='confirm_modal' style={{boxShadow: "rgb(54, 68, 97) 0px 0px 30px 2px"}}>
          {loading ? <VuiBox sx={{display: 'flex', alignItems:'center', justifyContent: 'center', marginTop: '20%'}}><CircularProgress size={30} color='white' /></VuiBox> : 
          <>
            <VuiTypography variant={window.innerWidth < 800 ? 'h6' : 'h4'} style={{color: 'white', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Generate script using AI <IoMdColorWand style={{marginLeft: '3px', transform: 'rotate(70deg)'}} /></VuiTypography>
            <VuiInput
              value={chatgptText}
              minRows={3}
              maxRows={6}
              onChange={e => setChatgptText(e.target.value)}
              multiline
              placeholder='Enter your prompt here'
            />
            <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '30px'}}>
              <Button variant='contained' sx={{color: 'whitesmoke'}} onClick={handleClose} disabled={chatgptText === ''}>Cancel</Button>
              <Button variant='contained' sx={{color: 'whitesmoke'}} onClick={handleCall}>Create</Button>
            </VuiBox>
          </>}
        </div>
      </Modal>
    </div>
  )
}

export default ChatGptModal