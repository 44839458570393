/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Grid from '@mui/material/Unstable_Grid2';
import Table from "../Table";
import MenuItem from "@mui/material/MenuItem";

import { useState } from "react";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";

import { MdEdit, MdDelete } from "react-icons/md";


function TeamSettings(props) {
  let TeamData = {
    columns: [
      { name: "name", align: "left" },
      { name: "email", align: "left" },
      { name: "role", align: "center" },
      { name: "status", align: "center" },
      { name: "added", align: "center" },
      { name: "actions", align: "center" },
    ],
    rows: props.teamData,
  }
  //eslint-disable-next-line
  const actionButtonStyles = {
    "& .material-icons-round": {
      transform: `translateX(0)`,
      transition: "all 200ms cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(4px)`,
    },
  };

  const TeamTable = () => {
    const [slackBotMenu, setSlackBotMenu] = useState(null);
    const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);
    const closeSlackBotMenu = () => setSlackBotMenu(null);

    const Menu = (state, close) => (
      <Menu
        anchorEl={state}
        open={Boolean(state)}
        onClose={close}
        keepMounted
      >
        <MenuItem onClick={close}>Action</MenuItem>
        <MenuItem onClick={close}>Another action</MenuItem>
        <MenuItem onClick={close}>Something else here</MenuItem>
      </Menu>
    );
    //eslint-disable-next-line
    const dropdown = {
      action: openSlackBotMenu,
      menu: Menu(slackBotMenu, closeSlackBotMenu),
    };

    let parsed_rows = TeamData.rows.map((row) => {
      return {
        name: row.name,
        email: row.email,
        role: row.role,
        status: row.status ? (<VuiBadge badgeContent="Active" variant="contained" container />) : (<VuiBadge badgeContent="Inactive" variant="contained" container />),
        added: row.added,
        actions: (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <VuiButton variant="outlined" color="info" iconOnly onClick={console.log(" > Edit Button Pressed")}>
                <MdEdit color="#FFFFFF" size="20px" />
              </VuiButton>
            </Grid>
            <Grid item xs={6}>
              <VuiButton variant="outlined" color="info" iconOnly onClick={console.log(" > Delete Button Pressed")}>
                <MdDelete color="#FFFFFF" size="20px" />
              </VuiButton>
            </Grid>
          </Grid>
        )
      }
    });
    return (
      <VuiBox>
        <Table rows={parsed_rows} columns={TeamData.columns} />
      </VuiBox>
    );
  }

  return (
    <Grid container id="sessions" xs={12} sx={{background: 'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)', backdropFilter: 'blur(120px)', justifyContent: 'center', alignItems: 'flex-start', borderRadius: '1.2rem', backgroundClip: 'border-box', padding: '22px', lineHeight: 1, marginBottom: '50px', paddingBottom: '200px'}}>
      <>
        <Grid container xs={12} mb={5} ml={2} justifyContent='space-between' alignItems='center'>
          <Grid item xs={9}>
            <VuiBox>
              <VuiTypography variant="lg" color="white" fontWeight="bold">
                Team Members
              </VuiTypography>
            </VuiBox>
            <VuiTypography variant="button" color="text" fontWeight="regular">
              This is a list of members that you've added to your workspace.
            </VuiTypography>
          </Grid>
          <Grid item xs={3}>
            <VuiBox>
              <VuiButton variant="outlined">
                + Invite Members
              </VuiButton>
            </VuiBox>
          </Grid>
        </Grid>
      </>
      {TeamTable()}
    </Grid>
  );
}

export default TeamSettings;
