import { AppBar, Button, ButtonGroup, CircularProgress, Grid, IconButton, Slider, Tab, Tabs, Typography } from '@mui/material'
import VuiBox from 'components/VuiBox'
import VuiTypography from 'components/VuiTypography'
import React, { useEffect, useState } from 'react'
import RecordScreen from './RecordScreen'
import VuiInput from 'components/VuiInput'
import VideoSettings from './VideoSettings'
import axios from 'axios'
import { AddCircleOutline, Cancel } from '@mui/icons-material'

const Video = ({uploadedVideo, setUploadedVideo, stockVideos, setStockVideos, videoQuery, setVideoQuery, videoPage, videoTotalPages, setVideoPage, lastVideoRef, loading, videoObserver, scenes, updateSceneKey, currentSceneIndex, openSettings, setOpenSettings, setScenes, setUploadedBackground, loadingModal, setLoadingModal, addScene}) => {
  const [videoTabValue, setVideoTabValue] = useState(0);
  // const [uploadedVideo, setUploadedVideo] = useState(null);
  // const [stockVideos, setStockVideos] = useState([]);
  // const [videoQuery, setVideoQuery] = useState('');
  // const [videoPage, setVideoPage] = useState(1);
  // const [loading, setLoading] = useState(false);

  // const observer = useRef(null);
  // const lastVideoRef = useRef(null);

  const handleSearchInputChange = (e) => {
    setStockVideos([]);
    setVideoQuery(e.target.value);
  };

  const tabHandler = (event, newValue) => {
    setVideoTabValue(newValue);
    setOpenSettings({
      ...openSettings,
      video: false,
    })
  };

  const [uploadedVideos, setUploadedVideos] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const videoObject = {
          video: e.target.result,
          // Other properties as needed
        };
        setUploadedVideos((prevVideos) => [...prevVideos, videoObject]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddVideo = (videoObject) => {
    const newVideoObject = {
      ...videoObject,
      zIndex: 10,
      opacity: 1,
      x: 170,
      y: 20,
      size: {
        width: 300,
        height: 200,
      },
      handle: false,
      fullscreen: false,
      recorded: false,
    };

    updateSceneKey(currentSceneIndex, 'selectedVideo', newVideoObject);
    setOpenSettings({
      ...openSettings,
      video: newVideoObject,
    });
  };

  const handleRemoveVideo = (index) => {
    setUploadedVideos((prevVideos) => prevVideos.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (lastVideoRef.current) {
      videoObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !loading) {
          setVideoPage((prevPage) => prevPage + 1);
        }
      });

      videoObserver.current.observe(lastVideoRef.current);
    }

    return () => {
      if (videoObserver.current) {
        videoObserver.current.disconnect();
      }
    };
    //eslint-disable-next-line
  }, [loading, videoTotalPages, videoPage, stockVideos]);

  const handleVideoSelection = (video) => {
    setOpenSettings({
      ...openSettings,
      video: false
    })

    const newVideoObject = {
      video: video,
      // scale: '',
      zIndex: 10,
      opacity: 1,
      // marginTop: '',
      // marginLeft: '',
      x: 170,
      y: 20,
      size: {
        width: 300,
        height: 200,
      },
      handle: false,
      fullscreen: false,
      recorded: false,
    };

    updateSceneKey(currentSceneIndex, 'selectedVideo', newVideoObject);

    setOpenSettings({
      ...openSettings,
      video: newVideoObject
    })
  }

  return (
    <VuiBox>
      <Grid container alignItems={"center"}>
        <AppBar
          position="static"
          sx={{ display: "flex", mb: 0, alignItems: "center"}}
        >
          <Tabs value={videoTabValue} onChange={tabHandler} sx={{overflow: 'auto', width: '100%' }}>
            <Tab
              style={{minWidth:"33%"}}
              label={
                <VuiTypography color="light" variant="body2">
                  Upload
                </VuiTypography>
              }
            />
            <Tab
              style={{minWidth:"33%"}}
              label={
                <VuiTypography color="light" variant="body2">
                  Record
                </VuiTypography>
              }
            />
            <Tab
              style={{minWidth:"33%"}}
              label={
                <VuiTypography color="light" variant="body2">
                  Stock
                </VuiTypography>
              }
            />
          </Tabs>
        </AppBar>
      </Grid>
      {videoTabValue === 0 && 
        <VuiBox sx={{marginTop: '15px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          {openSettings.video === false ? 
            <>
              <label htmlFor="video-file" style={{cursor: 'pointer'}}>
                <Typography variant='h6' sx={{color: '#000', background: 'lightgray', padding: '10px 25px', borderRadius: '15px'}}>
                  Click here to Upload
                  <input
                    accept="video/mp4, video/webm"
                    style={{ display: 'none' }}
                    type="file"
                    id="video-file"
                    name="video-file"
                    onChange={handleFileUpload}
                  />
                </Typography>
              </label>
              <div style={{ marginTop: '20px' }}>
                {uploadedVideos.map((videoObject, index) => (
                  <VuiBox key={index} sx={{position:"relative", display:"inline-block", margin:'5px', border: '2px solid gray', borderRadius: '5px'}}>
                    <video src={videoObject.video} width="180px" height='130px' controls style={{ display: 'block' }} />
                    <IconButton
                      size="small"
                      color="white"
                      onClick={() => handleAddVideo(videoObject)}
                      style={{ position: 'absolute', top: 0, left: 0, background: 'black' }}
                    >
                      <AddCircleOutline />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="white"
                      onClick={() => handleRemoveVideo(index)}
                      style={{ position: 'absolute', top: 0, right: 0, background: 'black' }}
                    >
                      <Cancel />
                    </IconButton>
                  </VuiBox>
                ))}
              </div>
            </>
            :
            <VideoSettings scenes={scenes} setScenes={setScenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} openSettings={openSettings} setOpenSettings={setOpenSettings} setUploadedBackground={setUploadedBackground} />}
        </VuiBox>
      }
      
      {videoTabValue === 1 && <VuiBox sx={{textAlign: 'center'}}>
        {openSettings.video === false ? 
          <RecordScreen scenes={scenes} setScenes={setScenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} openSettings={openSettings} setOpenSettings={setOpenSettings} />
        :
          <VideoSettings scenes={scenes} setScenes={setScenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} openSettings={openSettings} setOpenSettings={setOpenSettings} setUploadedBackground={setUploadedBackground} />}
      </VuiBox>}
      {videoTabValue === 2 && 
      <VuiBox sx={{marginTop: '15px'}}>
        {openSettings.video === false ? 
          <>
            <VuiInput placeholder='search here' value={videoQuery} onChange={(e)=>handleSearchInputChange(e)} sx={{marginBottom: '10px'}}  />
            <VuiBox sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-evenly', margin: '5px 0', overflow: 'auto', maxHeight: '100%'}}>
              {stockVideos.map((video, index) => (
                <div key={index}>
                  <video
                    src={video.video_files[0].link}
                    alt={video.id}
                    ref={index === stockVideos.length - 1 ? lastVideoRef : null}
                    width='130px'
                    style={{cursor: 'pointer', margin: '5px 0'}} 
                    onClick={()=>handleVideoSelection(video.video_files[0].link)}
                  />
                </div>
              ))}
            </VuiBox>
            {loading && <CircularProgress color='white' size={20} />}
          </>
          :
          <VideoSettings scenes={scenes} setScenes={setScenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} openSettings={openSettings} setOpenSettings={setOpenSettings} />}
      </VuiBox>}

      
    </VuiBox>
  )
}

export default Video