/*eslint-disable no-unused-vars */

import { useMemo } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// Vision UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Vision UI Dashboard React example components
import { useEffect, useState } from "react";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

//Data
import {
  lineChartDataGradient,
  lineChartOptionsGradient,
} from "layouts/pages/charts/data/gradientLineChartData";
import BarChart from "examples/Charts/BarCharts/BasicBarChart";
import VuiTypography from "components/VuiTypography";

function Analytics() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar position="static">
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
            sx={{
              background: "transparent",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Tab label="Engagement" />
            <Tab label="Conversion" />
          </Tabs>
        </AppBar>
      </Grid>

      {tabValue === 0 && <Engagement />}
      {tabValue === 1 && <Conversion />}
    </Grid>
  );
}
export default Analytics;
function Engagement() {
  return (
    <VuiBox mt={2}>
      <Grid container spacing={1}>

      <Grid item xs={3}>
          <VuiBox mb={1}>
            <Card >
              <VuiTypography
                variant="h1"
                color="info"
                textGradient
                verticalAlign="middle"
                fontWeight="bold"
              >
                0
              </VuiTypography>
              <VuiTypography
                marginTop="15%"
                variant="h4"
                color="dark"
                textGradient
                verticalAlign="middle"
                fontWeight="regular"
              >
                Unique Impressions
              </VuiTypography>
            </Card>
          </VuiBox>
        </Grid>

        <Grid item xs={3}>
          <VuiBox mb={1}>
            <Card >
              <VuiTypography
                variant="h1"
                color="info"
                textGradient
                verticalAlign="middle"
                fontWeight="bold"
              >
                0
              </VuiTypography>
              <VuiTypography
                marginTop="15%"
                variant="h4"
                color="dark"
                textGradient
                verticalAlign="middle"
                fontWeight="regular"
                minHeight="65px"
              >
                Unique Plays
              </VuiTypography>
            </Card>
          </VuiBox>
        </Grid>

        <Grid item xs={3}>
          <VuiBox mb={1}>
            <Card >
              <VuiTypography
                variant="h1"
                color="info"
                textGradient
                verticalAlign="middle"
                fontWeight="bold"
              >
                0
              </VuiTypography>
              <VuiTypography
                marginTop="15%"
                variant="h4"
                color="dark"
                textGradient
                verticalAlign="middle"
                fontWeight="regular"
              >
                Unique Interactions
              </VuiTypography>
            </Card>
          </VuiBox>
        </Grid>

        <Grid item xs={3}>
          <VuiBox mb={1}>
            <Card >
              <VuiTypography
                variant="h1"
                color="info"
                textGradient
                verticalAlign="middle"
                fontWeight="bold"
              >
                0
              </VuiTypography>
              <VuiTypography
                marginTop="15%"
                variant="h4"
                color="dark"
                textGradient
                verticalAlign="middle"
                fontWeight="regular"
              >
                Avg. session duration
              </VuiTypography>
            </Card>
          </VuiBox>
        </Grid>

      </Grid>

      <Card>
        <GradientLineChart />
      </Card>
    </VuiBox>
  );
}
function Conversion() {
  return (
    <VuiBox mt={2}>
      <Grid container spacing={1}>

      <Grid item xs={3}>
          <VuiBox mb={1}>
            <Card >
              <VuiTypography
                variant="h1"
                color="info"
                textGradient
                verticalAlign="middle"
                fontWeight="bold"
              >
                0
              </VuiTypography>
              <VuiTypography
                marginTop="15%"
                variant="h4"
                color="dark"
                textGradient
                verticalAlign="middle"
                fontWeight="regular"
              >
                Unique Impressions
              </VuiTypography>
            </Card>
          </VuiBox>
        </Grid>

        <Grid item xs={3}>
          <VuiBox mb={1}>
            <Card >
              <VuiTypography
                variant="h1"
                color="info"
                textGradient
                verticalAlign="middle"
                fontWeight="bold"
              >
                0
              </VuiTypography>
              <VuiTypography
                marginTop="15%"
                variant="h4"
                color="dark"
                textGradient
                verticalAlign="middle"
                fontWeight="regular"
              >
                Unique Plays
              </VuiTypography>
            </Card>
          </VuiBox>
        </Grid>

        <Grid item xs={3}>
          <VuiBox mb={1}>
            <Card >
              <VuiTypography
                variant="h1"
                color="info"
                textGradient
                verticalAlign="middle"
                fontWeight="bold"
              >
                0
              </VuiTypography>
              <VuiTypography
                marginTop="15%"
                variant="h4"
                color="dark"
                textGradient
                verticalAlign="middle"
                fontWeight="regular"
              >
                Unique Interactions
              </VuiTypography>
            </Card>
          </VuiBox>
        </Grid>

        <Grid item xs={3}>
          <VuiBox mb={1}>
            <Card >
              <VuiTypography
                variant="h1"
                color="info"
                textGradient
                verticalAlign="middle"
                fontWeight="bold"
              >
                0
              </VuiTypography>
              <VuiTypography
                marginTop="15%"
                variant="h4"
                color="dark"
                textGradient
                verticalAlign="middle"
                fontWeight="regular"
              >
                Avg. session duration
              </VuiTypography>
            </Card>
          </VuiBox>
        </Grid>

      </Grid>

      <Card>
        <BarChart />
      </Card>
    </VuiBox>
  );
}
