/* eslint-disable no-unused-vars */
import { Button, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import BackupIcon from "@mui/icons-material/Backup";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useVisionUIController } from "context";
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from "notistack";
import BASE_URL from "context/api";

function File() {
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const videosPerPage = 1;
  const [loading, setLoading] = useState(false);
  const [controller, dispatch] = useVisionUIController();
  const { userDetails } = controller;
  const { user_id } = userDetails;
  const { enqueueSnackbar } = useSnackbar();

  const handleDrop = (acceptedFiles) => {
    const updatedFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        progress: 0,
      })
    );
    setUploadedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    setUploadingFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
  };

  const handleContainerClick = (event) => {
    event.stopPropagation();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    maxSize: 150 * 1024 * 1024,
  });

  const uploadFiles = async (files, user_id) => {
    const uploadPromises = files.map(async (file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user_id", user_id);
      // formData.append("filename", file.name);

      try {
        const response = await axios.post(
          `${BASE_URL}/upload_video`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (event) => {
              const progress = Math.round((event.loaded * 100) / event.total);
              updateFileProgress(file, progress);
            },
          }
        );

        console.log("File uploaded successfully:", response.data);
        if (response.data.error) {
          throw new Error(response.data.error);
        } else {
          const uploadedFile = { ...file, name: response.data.fileName };
          updateFileProgress(uploadedFile, response);
          setUploadedVideos((prevVideos) => [
            ...prevVideos,
            URL.createObjectURL(file),
          ]);
        }
      } catch (error) {
        console.error("File upload failed", error.message);
        updateFileProgress(file, error.message);
        throw error;
      }
    });


    try {
      await Promise.all(uploadPromises);
    } catch (error) {
      // Handle error from any of the upload promises
      console.error("One or more files failed to upload");
      throw error;
    }
    // await Promise.all(uploadPromises);
  };

  const updateFileProgress = (fileToUpdate, response) => {
  setUploadedFiles((prevFiles) =>
    prevFiles.map((uploadedFile) =>
      uploadedFile.name === fileToUpdate.name
        ? { ...uploadedFile, response: response || '' }
        : uploadedFile
    )
  );
};

  
  const getFileExtension = (filename) => {
    const extensionIndex = filename.lastIndexOf(".");
    return extensionIndex !== -1 ? filename.substring(extensionIndex) : "";
  };

  const getFileBaseName = (filename) => {
    if (!filename) {
      return '';
    }
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1) {
      return filename;
    }
    return filename.substring(0, dotIndex);
  };
  
  
  
  const handleInputChange = (event, fileToUpdate) => {
    const updatedFileName = event.target.value.trim();
    const extension = getFileExtension(fileToUpdate.name);
    const updatedFileNameWithExtension = updatedFileName + extension;
  
    setUploadedFiles((prevFiles) =>
      prevFiles.map((uploadedFile) =>
        uploadedFile === fileToUpdate
          ? { ...uploadedFile, name: updatedFileNameWithExtension }
          : uploadedFile
      )
    );
  };
  
  const handleUpload = async () => {
    setLoading(true);
    try {
      await uploadFiles(uploadedFiles, user_id);
      setUploadedFiles([]);
      enqueueSnackbar('Video Uploaded.', { 
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch (error) {
      console.error("File upload failed:", error);
      enqueueSnackbar(error.message, {
        variant: "warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePrevClick = (event) => {
    event.stopPropagation();
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextClick = (event) => {
    event.stopPropagation();
    const totalPages = Math.ceil(uploadedFiles.length / videosPerPage);
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  

  return (
    <Grid style={{ float: "right" }}>
      <Typography
        style={{
          fontWeight: 500,
          fontSize: 16,
          bottom: "50px",
          color: "whitesmoke",
          textAlign: "center"
        }}
      >
        Upload Files
      </Typography>
      <Card
        style={{
          width: 620,
          height: "fit-content",
          marginLeft: 5,
          marginTop: 25,
          marginRight: 5,
          float: "left",
          boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)"
        }}
      >
        <div
          onClick={handleContainerClick}
          {...getRootProps()}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "fit-content",
            color: "whitesmoke",
            border: "1px dashed gray",
            cursor: "pointer",
            borderRadius: "5px",
            overflow: "hidden",
            padding: "20px"
          }}
        >
          <input {...getInputProps()} />
          {uploadedFiles.length > 0 ? (
            <div style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content" }}>
              {uploadedFiles
                .slice(currentPage * videosPerPage, (currentPage + 1) * videosPerPage)
                .map((file, index) => (
                  <div key={index}>
                    {file.preview && (
                      <div>
                        <video
                          src={file.preview}
                          controls
                          style={{ 
                            width: "100%",
                            height: "100%",
                          }}
                          onClick={handleContainerClick}
                        />
                        <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                          <h4 
                            onClick={(event) => {
                              event.stopPropagation();
                              setUploadedFiles((prevFiles) =>
                                prevFiles.filter((uploadedFile) => uploadedFile !== file)
                              );
                            }} 
                            style={{
                              padding: "5px 15px",
                              border: "1px dashed gray",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            Discard
                          </h4>
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
               
              {uploadedFiles.length > videosPerPage && (
                <div style={{display: "flex", justifyContent: "space-around", alignItems: "center", minWidth: "200px", marginTop: "5px"}}>
                  <h5
                    onClick={handlePrevClick}
                    disabled={currentPage === 0}
                    style={{
                      padding: "5px 15px",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Prev
                  </h5>
                  <h5
                    onClick={handleNextClick}
                    disabled={(currentPage + 1) * videosPerPage >= uploadedFiles.length}
                    style={{
                      padding: "5px 15px",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Next
                  </h5>
                </div>
              )}
            </div>
          ) : (
            isDragActive ? (
              <p>Drop the files here...</p>
            ) : 
              <>
                <BackupIcon sx={{ width: "3em", height: "3em" }} />
                <h3 style={{fontWeight: 400}}>Drag and drop files here</h3>
                <h4>or</h4>
                <h3
                  style={{
                    padding: "5px",
                    marginTop: "5px",
                    border: "1px solid gray",
                    borderRadius: "5px",
                    fontWeight: 400
                  }}
                >
                  Browse Files
                </h3>
              </>
          
          )}
        </div>
        {uploadedFiles.length > 0 && (
          <div style={{display: "flex", justifyContent: "flex-end", color: "whitesmoke", marginTop: "10px"}}>
            <h4 
              onClick={handleUpload}
              disabled={loading} 
              style={{
                padding: "5px 15px",
                border: "1px solid gray",
                borderRadius: "5px",
                cursor: "pointer",
                minWidth: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {loading ? <CircularProgress size={22} color="white" /> : "Upload"}
            </h4>
          </div>
        )}
      </Card>
    </Grid>
  );
}

export default File;
