import VuiBox from 'components/VuiBox';
import React, { useEffect, useState } from 'react';
import { AppBar, Button, CircularProgress, Grid, Tab, Tabs, Tooltip } from '@mui/material';
import VuiTypography from 'components/VuiTypography';
import ModelSettings from './ModelSettings';
import { useSnackbar } from "notistack";
import axios from 'axios';
import { useVisionUIController } from 'context';
import BASE_URL from "context/api";
import ClearIcon from '@mui/icons-material/Clear';
import AiMan from '../ai-generated-8578267_640.jpg';

const Models = ({models, faceModels, scenes, updateSceneKey, currentSceneIndex, openSettings, setOpenSettings}) => {
  const [modelTabValue, setModelTabValue] = useState(0);
  const [uploadedAvatars, setUploadedAvatars] = useState([]);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [controller] = useVisionUIController();
  const { userDetails } = controller;
  const { user_id, access_token } = userDetails;
  const fullModels = models.slice(20);
  const { enqueueSnackbar } = useSnackbar();

  const isFileSizeValid = (file) => {
    const maxSize = 4 * 1024 * 1024;
    return file.size <= maxSize;
  };

  const generateUniqueId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  };

  const callUploadImage = async () => {
    setAvatarLoading(true);
    try {
      const res = await axios.get(`${BASE_URL}/generate_image`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id
        },
      });
      setAvatarLoading(false);

      const uploadedAvatars = res.data.uploaded_avatars;

      if (uploadedAvatars.length > 0) {
        const imageFiles = uploadedAvatars.map((file) => ({
          id: generateUniqueId(),
          imageUrl: file,
        }));

        setUploadedAvatars((prevFiles) => [...imageFiles, ...prevFiles]);
      } else {
        setUploadedAvatars([]);
      }
    } catch (error) {
      setAvatarLoading(false);
      enqueueSnackbar(error.message || 'Something went wrong', {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } 
  }

  useEffect(() => {
    callUploadImage();
    //eslint-disable-next-line
  }, [])

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!isFileSizeValid(file)) {
      enqueueSnackbar("File size exceeds the limit of 10MB", {
        variant: "Warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("user_id", user_id);
  
      const response = await axios.post(`${BASE_URL}/generate_image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${access_token}`,
        },
      });
  
      // const imageFiles = event.map((file) => ({
      //   id: generateUniqueId(),
      //   url: URL.createObjectURL(file),
      //   imageUrl: response.data.image_url,
      // }));

      const imageFile = {
        id: generateUniqueId(),
        url: URL.createObjectURL(file),
        imageUrl: response.data.image_url,
      };

      setAvatarLoading(false);
      setUploadedAvatars((prevFiles) => [imageFile, ...prevFiles]);
    } catch (error) {
      setAvatarLoading(false);
      enqueueSnackbar(error.message || 'Something went wrong', {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }

  const deleteImageUpload = async (index) => {
    setAvatarLoading(true);
    try {
      await axios.delete(`${BASE_URL}/generate_image`, {
        params: {
          image_url: uploadedAvatars[index].imageUrl,
          user_id
        },
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        setUploadedAvatars([]);
        callUploadImage();
        setAvatarLoading(false);
      })
      .catch(err => {
        console.log(err);
        setAvatarLoading(false);
      })
    } catch (error) {
      setAvatarLoading(false);
      enqueueSnackbar(error.message || 'Something went wrong', {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }

  const tabHandler = (event, newValue) => {
    setModelTabValue(newValue);
  };

  const handleSelectedModel = (item, type, uploaded) => {
    const newModelObject = {
      model: type === 'full' ? uploaded ? item.imageUrl : item.image_url : item,
      scale: 100,
      zIndex: 19,
      opacity: 1,
      marginTop: 0,
      marginLeft: 0,
      x: 170,
      y: 20,
      size: {
        width: 180,
        height: 180,
      },
      handle: false,
      presenter_id: type === 'full' && item.presenter_id,
    };
    
    updateSceneKey(currentSceneIndex, 'avatarVideo', true);
    updateSceneKey(currentSceneIndex, 'selectedModel', newModelObject);
    // setOpenModelSettings(newModelObject);
    setOpenSettings({
      ...openSettings,
      model: newModelObject,
    })
  }
  
  return (
    <VuiBox>
      <Grid container alignItems={"center"}>
        <AppBar
          position="static"
          sx={{ display: "flex", mb: 0, alignItems: "center"}}
        >
          <Tabs value={modelTabValue} onChange={tabHandler} sx={{overflow: 'auto', width: '100%' }}>
            <Tab
              style={{minWidth:"50%"}}
              label={
                <VuiTypography color="light" variant="body2">
                  Full
                </VuiTypography>
              }
            />
            <Tab
              style={{minWidth:"50%"}}
              label={
                <VuiTypography color="light" variant="body2">
                  Head
                </VuiTypography>
              }
            />
          </Tabs>
        </AppBar>
      </Grid>

      {modelTabValue === 0 && <VuiBox sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-evenly', overflow: 'auto', maxHeight: '80vh'}}>
        {openSettings.model === false ?
          avatarLoading ? 
            <VuiBox
                mt={1}
                bgColor="white"
                borderRadius="50%"
                width="100px"
                height="100px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="white"
                mx="auto"
              >
                <CircularProgress size={30} color="black" />
              </VuiBox>
            : 
          <>
          <Tooltip placement="left"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  color: "#fff",
                  maxWidth: '500px',
                  backgroundColor: 'rgb(7, 11, 36)',
                  opacity: 1,
                  border: '1px solid gray'
                }
              }
            }}
            title={
              <>
                <VuiTypography variant='h6' color="inherit">Upload your own image</VuiTypography>
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', borderTop: '1px solid #fff', marginTop: '5px'}}>
                  <img src={AiMan} alt='tooltip' height='160px' style={{filter: 'drop-shadow(0px 0px 100px skyblue)', borderRadius: '5px'}} />
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: '3px'}}>
                    <h3 style={{marginBottom: '3px'}}>Requirements</h3>
                    <p>&#10004; Front facing</p>
                    <p>&#10004; Centered</p>
                    <p>&#10004; Neutral expression</p>
                    <p>&#10004; Good lighting</p>
                    <p>&#10004; Recommended size: 512x512 px</p>
                    <p>&#10008; No face obstructions</p>
                  </div>
                </div>
              </>
            }
          >
            <label htmlFor="image-upload" style={{cursor: 'pointer'}}>
            <VuiBox sx={{width: '100px', margin: '10px 20px', borderRadius: '10px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '15px', textAlign: 'center', cursor: 'pointer', boxShadow: '0px 0px 20px 0px skyblue', color: 'whitesmoke', '&:hover': {transform: 'scale(1.1)'}}}>
              <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} id='upload'>
                <VuiTypography
                  fontSize={window.innerWidth < 768 ? 12 : 14}
                  color="white"
                  fontWeight="regular"
                >
                  + Upload
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    type="file"
                    id="image-upload"
                    name="image-upload"
                    onChange={handleFileUpload}
                  />
                </VuiTypography>
              </VuiBox>
            </VuiBox>
            </label>
            </Tooltip>
            {uploadedAvatars.map((item, index) => (
              <VuiBox sx={{position: 'relative'}}>
                <img src={item.imageUrl} key={index} alt={item.name} width='100px' style={{margin: '10px 20px', cursor: 'pointer', background: 'gray', borderRadius: '10px', maxHeight: '100px'}} onClick={()=>handleSelectedModel(item, 'full', true)} />
                <ClearIcon sx={{position: 'absolute', top: '12px', right: '20px', color: 'whitesmoke', cursor: 'pointer'}}  onClick={()=>deleteImageUpload(index)} />
              </VuiBox>
            ))}
            {fullModels.map((item, index) => (
              <img src={item.image_url} key={index} alt='model' width='100px' style={{margin: '10px 20px', cursor: 'pointer', background: 'gray', borderRadius: '10px'}} onClick={()=>handleSelectedModel(item, 'full', false)} />
            ))}
          </>
        : 
          <ModelSettings openSettings={openSettings} setOpenSettings={setOpenSettings} scenes={scenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} />
        }
      </VuiBox>}

      {modelTabValue === 1 && <VuiBox sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-evenly', overflow: 'auto', maxHeight: '80vh'}}>
        {openSettings.model === false ?
          faceModels.map((item, index) => (
            <img src={item} key={index} alt='model' width='100px' style={{margin: '10px 20px', cursor: 'pointer', background: 'gray', borderRadius: '10px'}} onClick={()=>handleSelectedModel(item, 'half', false)} />
          ))
        : 
          <ModelSettings openSettings={openSettings} setOpenSettings={setOpenSettings} scenes={scenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} />
        }
      </VuiBox>}
    </VuiBox>
  )
}

export default Models