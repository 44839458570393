import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
// import VuiAvatar from "components/VuiAvatar";

// Images
// import burceMars from "assets/images/avatar-simmmple.png";
import { Avatar, Divider, Popover, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

function Header(props) {
  const [visible, setVisible] = useState(true);
  //eslint-disable-next-line
  const handleSetVisible = () => setVisible(!visible);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  function stringAvatar(name) {
    return {
      children: `${name.split(' ')[0][0]}`,
    };
  }

  return (
    <Card id="profile" sx={{borderRadius: '0', paddingBottom: '0'}}>
        <VuiBox display="flex" justifyContent="space-between" alignItems="center">
          <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
            <Grid item>
              <Avatar
                {...stringAvatar(props.name)} sx={{ width: 40, height: 40, fontSize: '20px !important', background: "transparent", border: '1px solid white' }}
              />
            </Grid>
            <Grid item>
              <VuiBox height="100%" display="flex" flexDirection="column" lineHeight={1}>
                <VuiTypography variant="lg" color="white" fontWeight="bold">
                  {props.uname}
                </VuiTypography>
                <VuiTypography variant="button" color="text" fontWeight="regular">
                  {props.date_init}
                </VuiTypography>
              </VuiBox>
            </Grid>
          </Grid>
          <Grid item>
            <>
              <InfoOutlined aria-describedby={id} onClick={handleClick} sx={{color: 'whitesmoke', width: "2em", height: '1.5em', cursor: 'pointer'}} />
            </>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div style={{background: 'url(/static/media/body-background.7d7d88a8.png)', borderRadius: '10px', color: 'whitesmoke', paddingBottom: '20px'}}>
                <VuiTypography textAlign='center' paddingTop= '10px'>User Details</VuiTypography>
                <Divider light />
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography sx={{ px: 2, py: 0, fontSize: "18px" }}>Name</Typography>
                  <Typography sx={{ px: 2, py: 0, fontSize: "18px" }}>{props.uname}</Typography>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: '200'}}>
                  <Typography sx={{ px: 2, py: 0, fontSize: "18px" }}>Phone</Typography>
                  <Typography sx={{ px: 2, py: 0, fontSize: "18px" }}>+919876543210</Typography>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: '200'}}>
                  <Typography sx={{ px: 2, py: 0, fontSize: "18px" }}>Email</Typography>
                  <Typography sx={{ px: 2, py: 0, fontSize: "18px" }}>abc@qv.io</Typography>
                </div>
              </div>
            </Popover>
          </Grid>
        </VuiBox>
        <Divider light />
    </Card>
  );
}

export default Header;
