/*eslint-disable no-unused-vars */

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "components/Sidenav/styles/sidenavCollapse";

// Vision UI Dashboard PRO React context
import { useVisionUIController, setOpenSideMenu } from "context";
import { imageListClasses } from "@mui/material";
import PremiumIcon from 'assets/images/premium_4907289.png';
import PlanIcon from './crown (1).png';

function SidenavCollapse({ color, icon, name, children, active, noCollapse, open, premium, iconNext, ...rest }) {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentSidenav, openSideMenu } = controller;

  return (
    <>
      <ListItem component="li">
        <VuiBox {...rest} sx={(theme) => collapseItem(theme, { active, transparentSidenav })}>
          <ListItemIcon
            sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, color })}
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
            onClick = {() => window.innerWidth <= 768 ? setOpenSideMenu(dispatch, !openSideMenu) : ''}
          />

          {premium && 
            <img src={PremiumIcon} alt='premium icon' width={17} height={17} />
          }
          
          {iconNext && 
            <img src={PlanIcon} alt='premium icon' width={15} height={15} style={{position: 'absolute', right: '10%'}} />
          }

          {/* <Icon
            sx={(theme) =>
              collapseArrow(theme, { noCollapse, transparentSidenav, miniSidenav, open })
            }
          >
            expand_less
          </Icon> */}
        </VuiBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  color: "info",
  active: false,
  noCollapse: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavCollapse;
