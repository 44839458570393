// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";
import { useState } from "react";
import { Card, CircularProgress } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function About({name, setName, email, setEmail, businessName, setBusinessSetName, number, setNumber, role, setRole, size, setSize, postDetails, res, loader, resToggle, setShowForm}) {
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const handleName = (e) => {
    setName(e.target.value);
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(e.target.value)) {
      setEmailError("Invalid! please enter a valid email.");
    } else {
      setEmailError("");
    }
  }

  const handleBusinessName = (e) => {
    setBusinessSetName(e.target.value);
  }

  const handleSize = (e) => {
    setSize(e.target.value);
  }

  const handleRole = (e) => {
    setRole(e.target.value);
  }

  const handleNumber = (e) => {
    setNumber(e.target.value);
    const phoneRegex = /^[0-9]+$/;
    if(!phoneRegex.test(e.target.value)) {
      setPhoneError("Enter valid phone number");
    } else {
      setPhoneError("");
    }
  }

  return (
    <>
      {loader ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh'}}>
        <CircularProgress color="white" size={30} />
      </div> : 
      <>
        {resToggle === true ? <Card style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh', color: 'whitesmoke', fontSize: 30}}>{res}</Card> : 
        <>
          <Card sx={{paddingTop: 5, paddingBottom: 10, position: 'relative'}}>
            <ArrowBackIcon sx={{position: 'absolute', cursor: 'pointer', left: 20, top: 20}} color="white" onClick={() => setShowForm(false)} />
            <VuiBox width="100%" textAlign="center" mt={10}>
              <VuiBox mb={1}>
                <VuiTypography variant="h5" fontWeight="regular" color="white">
                  Let&apos;s start with the basic information
                </VuiTypography>
              </VuiBox>
              <VuiTypography variant="body2" fontWeight="regular" color="text">
                Let us know your name and email address. Use an address you don&apos;t mind other users
                contacting you at.
              </VuiTypography>
            </VuiBox>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px'}}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '1000px'}}>
                <div style={{width: window.innerWidth<=800 ? '200px' : '400px'}}>
                  <FormField type="text" label="full name" placeholder="Eg. Michael" onChange={handleName} value={name} disabled />
                </div>
                <div style={{width: window.innerWidth<=800 ? '200px' : '400px'}}>
                  <FormField type="text" label="Business name" placeholder="Eg. QuickVideo" onChange={handleBusinessName} value={businessName} />
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '1000px'}}>
                <div style={{width: window.innerWidth<=800 ? '200px' : '400px'}}>
                  <FormField type="text" label="email address" placeholder="Eg. micheal@xyz.com" onChange={handleEmail} value={email} disabled />
                  {emailError && <h6 style={{color: 'red'}}>{emailError}</h6>}
                </div>
                <div style={{width: window.innerWidth<=800 ? '200px' : '400px'}}>
                  <FormField type="phone" label="phone number" placeholder="Eg. 9876543210" onChange={handleNumber} value={number} />
                  {phoneError && <h6 style={{color: 'red'}}>{phoneError}</h6>}
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '1000px'}}>
                <div style={{width: window.innerWidth<=800 ? '200px' : '400px'}}>
                  <FormField type="text" label="company size" placeholder="Eg. 51-100" onChange={handleSize} value={size} />
                </div>
                <div style={{width: window.innerWidth<=800 ? '200px' : '400px'}}>
                  <FormField type="phone" label="role" placeholder="Eg. Engineer" onChange={handleRole} value={role} />
                </div>
              </div>
              <VuiButton sx={{marginLeft: window.innerWidth<=800 ? 0 : '810px', marginTop: '25px'}} onClick={postDetails} disabled={name === '' || email === '' || number === '' || businessName === '' || phoneError !== '' || emailError !== '' || size === '' || role === ''}>Submit</VuiButton>
            </div>
          </Card>
        </>
        }
      </>
      }
    </>
  );
}

export default About;
