import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

const RecordScreen = ({ scenes, setScenes, updateSceneKey, currentSceneIndex, openSettings, setOpenSettings }) => {
  const [recording, setRecording] = useState(false);
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const mediaChunksRef = useRef([]);
  const streamRef = useRef(null);

  const handleStartRecording = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: { width: 1920, height: 1080, frameRate: 60, bitrate: 2000000 },
        audio: true,
      });

      // Try to capture microphone audio if available
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Combine screen and audio streams
      const combinedStream = new MediaStream([
        ...screenStream.getVideoTracks(),
        ...audioStream.getAudioTracks(),
      ]);

      streamRef.current = combinedStream;

      combinedStream.getVideoTracks()[0].addEventListener('ended', handleStopRecording);

      mediaChunksRef.current = [];
      const mediaRecorder = new MediaRecorder(combinedStream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          mediaChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(mediaChunksRef.current, { type: 'video/mp4' });
        const url = URL.createObjectURL(blob);
        setMediaBlobUrl(url);
      };

      mediaRecorder.start();
      setRecording(true);
    } catch (err) {
      console.error("Error starting screen recording:", err);
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }

    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach(track => track.stop());
      streamRef.current = null;
    }

    setRecording(false);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUse = async (video) => {
    setOpenSettings({
      ...openSettings,
      video: false,
    });

    try {
      const blob = await fetch(video).then((response) => response.blob());
      const base64Video = await convertToBase64(blob);

      const newVideoObject = {
        video: base64Video,
        scale: '',
        zIndex: 5,
        opacity: 1,
        marginTop: '',
        marginLeft: '',
        x: 170,
        y: 20,
        size: {
          width: 300,
          height: 200,
        },
        handle: false,
        fullscreen: false,
        recorded: true,
      };

      updateSceneKey(currentSceneIndex, 'selectedVideo', newVideoObject);

      setOpenSettings((prevState) => ({
        ...prevState,
        video: newVideoObject,
      }));
    } catch (error) {
      console.error('Error converting video to base64:', error);
    }
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <VuiTypography>Coming soon ..</VuiTypography>
      {/* <VuiBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        {!recording && (
          <Button
            variant='contained'
            sx={{ color: 'whitesmoke', marginTop: '5px', marginBottom: '10px' }}
            onClick={handleStartRecording}
          >
            Start Recording
          </Button>
        )}
        {recording && (
          <Button
            variant='contained'
            sx={{ color: 'whitesmoke', marginTop: '5px', marginBottom: '10px' }}
            onClick={handleStopRecording}
          >
            Stop Recording
          </Button>
        )}
        {mediaBlobUrl && <video width={320} src={mediaBlobUrl} controls autoPlay loop />}
        {mediaBlobUrl && (
          <Button
            variant='contained'
            sx={{ color: 'whitesmoke', marginTop: '10px' }}
            onClick={() => handleUse(mediaBlobUrl)}
          >
            Use this recording
          </Button>
        )}
      </VuiBox> */}
    </div>
  );
};

export default RecordScreen;
