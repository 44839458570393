import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard PRO React example components
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

function PricingCards({ prices, handleSelectLite, handleSelectBusiness, handleSelectEnterprise, handleSelectPersonal, currentPlan, currentPlanYearly, personalPlan, personalPlanYearly, businessPlan, businessPlanYearly,enterprisePlan, tabValue, handleInnerType, currentCredit}) {
  const [lite, pro, business, enterprise] = prices;
  const [plan, setPlan] = useState('trial');

  useEffect(() => {
    if(currentPlan) { 
      setPlan('currentPlan');
    } else if(currentPlanYearly) {
      setPlan('currentPlanYearly');
    } else if(personalPlan) {
      setPlan('personalPlan');
    } else if(personalPlanYearly) {
      setPlan('personalPlanYearly');
    } else if(businessPlan) {
      setPlan('businessPlan');
    } else if(businessPlanYearly) {
      setPlan('businessPlanYearly')
    }else if(enterprisePlan) {
      setPlan('enterprisePlan');
    } else {
      setPlan('trial');
    }
    //eslint-disable-next-line
  }, []);

  return (
    <VuiBox
      position="relative"
      zIndex={10}
    >
      {tabValue === 0 && <Grid
        container
        spacing={1}
        sx={{ display: "flex", justifyContent: "center", flexDirection: "row" }}
      >
        <Grid item xs={12} lg={3} xl={3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "lite" }}
            price={{ currency: "$", value: lite }}
            minutes={[
              'Up to 15 mins of video',
              'Up to 30 mins of video',
              'Up to 45 mins of video'
            ]}
            specifications={[
              { label: "40 Avatars", includes: true },
              { label: "Watermark removed", includes: true },
              { label: "AI Script Generation", includes: true },
              { label: "Email Support", includes: true },
              // { label: "10 Templates", includes: true },
              { label: "Video Editor with watermark", includes: true },
              { label: "Voice Clone", includes: false },
              { label: "Video Personalization", includes: false },
              { label: "Bot AI", includes: false },
              { label: "APIs", includes: false },
            ]}
            credits={['60', '120', '180']}
            action={{
              onClick: handleSelectLite,
              onInnerClick: handleInnerType,
              plan: currentPlan ? 'currentPlan' : '',
              type: "internal",
              //route: "/",
              color: "info",
              label: "pro",
              currCredit: currentCredit
            }}
            description='Best for individuals'
          />
        </Grid>
        <Grid item xs={12} lg={3} xl={3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "pro" }}
            price={{ currency: "$", value: pro }}
            minutes={[
              'Up to 60 mins of video',
              'Up to 90 mins of video',
              'Up to 120 mins of video'
            ]}
            specifications={[
              { label: "70 Avatars", includes: true },
              { label: "Watermark removed", includes: true },
              { label: "AI Script Generation", includes: true },
              { label: "Email Support", includes: true },
              // { label: "30 Templates", includes: true },
              { label: "Video Editor", includes: true },
              { label: "Voice Clone", includes: true },
              { label: "Video Personalization", includes: true },
              { label: "Bot AI", includes: false },
              { label: "APIs", includes: false },
            ]}
            credits={['240', '360', '480']}
            action={{
              onClick: handleSelectPersonal,
              onInnerClick: handleInnerType,
              plan: personalPlan ? 'personalPlan' : '',
              type: "internal",
              //route: "/",
              color: "info",
              label: "personal",
              currCredit: currentCredit
            }}
            description="Best for pro's"
          />
        </Grid>
        <Grid item xs={12} lg={3} xl={3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "business" }}
            price={{ currency: "$", value: business }}
            minutes={[
              'Up to 180 mins of video',
              'Up to 240 mins of video',
              'Up to 360 mins of video'
            ]}
            specifications={[
              { label: "100 Avatars", includes: true },
              { label: "Watermark removed", includes: true },
              { label: "AI Script Generation", includes: true },
              { label: "Email + CSM Support", includes: true },
              // { label: "50+ Templates", includes: true },
              { label: "Video Editor", includes: true },
              { label: "Voice Clone", includes: true },
              { label: "Video Personalization", includes: true },
              { label: "Bot AI", includes: true },
              { label: "APIs", includes: true },
            ]}
            credits={['720', '960', '1440']}
            action={{
              onClick: handleSelectBusiness,
              onInnerClick: handleInnerType,
              plan: businessPlan ? 'businssPlan' : '',
              type: "internal",
              //route: "/",
              color: "info",
              label: "business",
              currCredit: currentCredit
            }}
            description='Best for businesses'
          />
        </Grid>
        <Grid item xs={12} lg={3} xl={3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "enterprise" }}
            price={{ currency: "", value: enterprise }}
            minutes={[
              'Customized video mins',
              'Customized video mins',
              'Customized video mins'
            ]}
            specifications={[
              { label: "Unlimited Avatars", includes: true },
              { label: "Custom Watermark", includes: true },
              { label: "AI Script Generation", includes: true },
              { label: "Email + CSM Support", includes: true },
              // { label: "50+ Templates", includes: true },
              { label: "Video Editor", includes: true },
              { label: "Voice Clone", includes: true },
              { label: "Video Personalization", includes: true },
              { label: "Bot AI", includes: true },
              { label: "APIs", includes: true },
            ]}
            action={{
              onClick: handleSelectEnterprise,
              onInnerClick: handleInnerType,
              plan: enterprisePlan ? 'enterprisePlan' : '',
              type: "internal",
              //route: "/",
              color: "info",
              label: "enterprise",
              currCredit: currentCredit
            }}
            description='Generate studio-quality content catering to your needs. Custom credit plan.'
          />
        </Grid>
      </Grid>}
      
      {tabValue === 1 && <Grid
        container
        spacing={1}
        sx={{ display: "flex", justifyContent: "center", flexDirection: "row" }}
      >
        <Grid item xs={12} lg={3} xl={3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "lite" }}
            price={{ currency: "$", value: lite }}
            minutes={[
              'Up to 15 mins of video',
              'Up to 30 mins of video',
              'Up to 45 mins of video'
            ]}
            specifications={[
              { label: "40 Avatars", includes: true},
              { label: "Watermark removed", includes: true },
              { label: "AI Script Generation", includes: true },
              { label: "Email Support", includes: true },
              // { label: "10 Templates", includes: true },
              { label: "Video Editor with watermark", includes: true },
              { label: "Voice Clone", includes: false },
              { label: "Video Personalization", includes: false },
              { label: "Bot AI", includes: false },
              { label: "APIs", includes: false },
            ]}
            credits={['60', '120', '180']}
            action={{
              onClick: handleSelectLite,
              onInnerClick: handleInnerType,
              plan: currentPlanYearly ? 'currentPlan Yearly' : '',
              type: "internal",
              //route: "/",
              color: "info",
              label: "pro",
              currCredit: currentCredit
            }}
            description='Best for individuals'
          />
        </Grid>
        <Grid item xs={12} lg={3} xl={3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "pro" }}
            price={{ currency: "$", value: pro }}
            minutes={[
              'Up to 60 mins of video',
              'Up to 90 mins of video',
              'Up to 120 mins of video'
            ]}
            specifications={[
              { label: "70 Avatars", includes: true},
              { label: "Watermark removed", includes: true },
              { label: "AI Script Generation", includes: true },
              { label: "Email Support", includes: true },
              // { label: "30 Templates", includes: true },
              { label: "Video Editor", includes: true },
              { label: "Voice Clone", includes: true },
              { label: "Video Personalization", includes: true },
              { label: "Bot AI", includes: false },
              { label: "APIs", includes: false },
            ]}
            credits={['240', '360', '480']}
            action={{
              onClick: handleSelectPersonal,
              onInnerClick: handleInnerType,
              plan: personalPlanYearly ? 'personalPlan Yearly' : '',
              type: "internal",
              //route: "/",
              color: "info",
              label: "personal",
              currCredit: currentCredit
            }}
            description="Best for pro's"
          />
        </Grid>
        <Grid item xs={12} lg={3} xl={3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "business" }}
            price={{ currency: "$", value: business }}
            minutes={[
              'Up to 180 mins of video',
              'Up to 240 mins of video',
              'Up to 360 mins of video'
            ]}
            specifications={[
              { label: "100 Avatars", includes: true},
              { label: "Watermark removed", includes: true },
              { label: "AI Script Generation", includes: true },
              { label: "Email +CSM Support", includes: true },
              // { label: "50+ Templates", includes: true },
              { label: "Video Editor", includes: true },
              { label: "Voice Clone", includes: true },
              { label: "Video Personalization", includes: true },
              { label: "Bot AI", includes: true },
              { label: "APIs", includes: true },
            ]}
            credits={['720', '960', '1440']}
            action={{
              onClick: handleSelectBusiness,
              onInnerClick: handleInnerType,
              plan: businessPlanYearly ? 'businssPlan Yearly' : '',
              type: "internal",
              //route: "/",
              color: "info",
              label: "business",
              currCredit: currentCredit
            }}
            description='Best for businesses'
          />
        </Grid>
        <Grid item xs={12} lg={3} xl={3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "enterprise" }}
            price={{ currency: "", value: enterprise }}
            minutes={[
              'Customized video mins',
              'Customized video mins',
              'Customized video mins'
            ]}
            specifications={[
              { label: "Unlimited Avatars", includes: true },
              { label: "Custom Watermark", includes: true },
              { label: "AI Script Generation", includes: true },
              { label: "Email + CSM Support", includes: true },
              // { label: "50+ Templates", includes: true },
              { label: "Video Editor", includes: true },
              { label: "Voice Clone", includes: true },
              { label: "Video Personalization", includes: true },
              { label: "Bot AI", includes: true },
              { label: "APIs", includes: true },
            ]}
            action={{
              onClick: handleSelectEnterprise,
              onInnerClick: handleInnerType,
              plan: enterprisePlan ? 'enterprisePlan' : '',
              type: "internal",
              //route: "/",
              color: "info",
              label: "enterprise",
              currCredit: currentCredit
            }}
            description='Generate studio-quality content  catering to your needs. Custom credit plan.'
          />
        </Grid>
      </Grid>}
    </VuiBox>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingCards;
