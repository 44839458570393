import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";

// React-icons
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { Tab, Tabs, Tooltip } from "@mui/material";
import { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';

function DefaultPricingCard({ badge, price, minutes, specifications, action, description, credits }) {
  const {plan, onClick, onInnerClick, currCredit} = action;
  const [tabValue, setTabValue] = useState(currCredit || 0);

  const tabHandler = (event, newValue) => {
    setTabValue(newValue);
    onInnerClick(newValue);
  };

  const renderSpecifications = specifications.map(({ label, includes }) => (
    <VuiBox key={label} display="flex" alignItems="center" p={1}>
      <VuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        borderRadius="50%"
        shadow="md"
        mr={2}
      >
        <VuiTypography variant="button" color="white" sx={{ lineHeight: 0 }}>
          {includes ? (
            <AiFillCheckCircle color="#fff" size="22px" />
          ) : (
            <AiFillCloseCircle color="#424563" size="22px" />
          )}
        </VuiTypography>
      </VuiBox>
      <VuiTypography variant="body2" color="text">
        {label}
      </VuiTypography>
    </VuiBox>
  ));

  return (
    <Card sx={{border: '1px solid beige', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      {badge.label === "pro" && <VuiBox sx={{position: 'absolute', top: '2%', transform: 'translate(-50%, -50%)' ,background: 'rgb(45, 103, 320)', padding: '5px 20px', display: 'flex', borderBottomRightRadius: '10px', alignItems: 'center', borderBottomLeftRadius: '10px', left: '50%'}}>
        <VuiTypography variant='h6' color='white'>Popular</VuiTypography>
      </VuiBox>}
      <VuiBox textAlign="center" my={2} sx={{width: '100%', height: '250px', overflow: 'hidden'}}>
        <VuiBadge
          variant="basic"
          color={badge.color}
          size="sm"
          badgeContent={badge.label}
          circular
          container
        />
        <VuiBox>
          <VuiTypography variant="h1" color="white" mb={price.value === "Custom" ? 7.7 : 1}>
            <VuiTypography display="inline" variant="h1" color="white">
              {price.currency}
            </VuiTypography>
            {/* {Array.isArray(price.value) ? price.value[tabValue] : price.value} */}
            {Array.isArray(price.value) ? price.value[tabValue] : <VuiTypography variant='h2' color='white'>{price.value}</VuiTypography>}
            {price.value !== "Custom" && <VuiTypography variant='h6' color='white'>/month</VuiTypography>}
          </VuiTypography>
          <VuiTypography variant='h6' my={1}>{description}</VuiTypography>
        </VuiBox>
        {credits && <VuiBox sx={{border: '2px solid rgb(59 130 246 / .5)', borderRadius: '20px'}}>
          <Tabs value={tabValue} onChange={tabHandler}>
            {credits.map((item) => 
              <Tab
                style={{minWidth: "33.33%"}}
                label={item}
              />
            )}
          </Tabs>
        </VuiBox>}
        {badge.label !== "enterprise" && <VuiTypography variant='h6'>credits</VuiTypography>}
      </VuiBox>
      <VuiBox>
        <VuiBox mt={1} mb={3}>
          <VuiButton fullWidth variant={plan && tabValue === currCredit ? "outlined" : "gradient"} color="primary" onClick={onClick} disabled={plan && tabValue === currCredit} sx={{ color: 'white !important' }}>
            {plan && tabValue === currCredit ? "Current" : 'Choose plan'}
          </VuiButton>
        </VuiBox>
        <VuiBox display="flex" alignItems="center" p={1}>
          <VuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="1.5rem"
            height="1.5rem"
            borderRadius="50%"
            shadow="md"
            mr={2}
          >
            <VuiTypography variant="button" color="white" sx={{ lineHeight: 0 }}>
              <AiFillCheckCircle color="#fff" size="22px" />
            </VuiTypography>
          </VuiBox>
          <VuiTypography variant="h6" color="text" sx={{fontSize: '14px'}}>
            {minutes[tabValue]}
          </VuiTypography>
          <Tooltip title='1 credit = up to 15 secs of video' placement="top">
            <InfoIcon color="white" sx={{marginLeft: '5px', cursor: 'pointer'}} />
          </Tooltip>
        </VuiBox>
        {renderSpecifications}
      </VuiBox>
    </Card>
  );
}

DefaultPricingCard.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "text",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
};

export default DefaultPricingCard;
