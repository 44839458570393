/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
// Vision UI Dashboard PRO React base styles
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Globe from "examples/Globe";
// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { CircularProgress, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import storiesData from "layouts/pages/profile/teams/components/Stories/data/storiesData";
import axios from "axios";
import { useVisionUIController, setSelectedImageName, setPresenterId, setImage } from "context";
import BASE_URL from "context/api";
import { useHistory } from 'react-router-dom';

function Default() {
  const [avatars, setAvatars] = useState(storiesData);
  const [preAvatars, setPreAvatars] = useState([]);
  const [totalCredits, setTotalCredits] = useState(null);
  const [usedCredits, setUsedCredits] = useState(null);
  const [recentlyCreated, setRecentlyCreated] = useState(null);
  const [totalCreated, setTotalCreated] = useState(null);
  const [creditsloader, setCreditsloader] = useState(false);
  const { values } = breakpoints;
  const [controller, dispatch] = useVisionUIController();
  const { userDetails, image } = controller;
  const { user_id, access_token } = userDetails;
  const history = useHistory();

  useEffect(() => {
    try {
      setCreditsloader(true);
      axios.get(`${BASE_URL}/dashboard`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id
        },
      })
      .then(response =>
        {
          setTotalCredits(response.data.credits.Total);
          setUsedCredits(response.data.credits.Used);
          setRecentlyCreated(response.data.quickvideos.recently);
          setTotalCreated(response.data.quickvideos.total);
          setCreditsloader(false);
        }
      )
      .catch(err => {
        console.log(err);
        setCreditsloader(false);
      })
    } catch (error) {
      console.error('Error fetching data:', error);
      setCreditsloader(false);
    }
    //eslint-disable-next-line
  }, [])

  const handleAvatarClicked = (item, index) => {
    index < 20 && setPresenterId(dispatch, item.presenter_id);
    setSelectedImageName(dispatch, index);
    history.push('/create');
    switch (index) {
      case 0:
        return setImage(dispatch, preAvatars[index].image_url);
      case 1:
        return setImage(dispatch, preAvatars[index].image_url);
      case 2:
        return setImage(dispatch, preAvatars[index].image_url);
      case 3:
        return setImage(dispatch, preAvatars[index].image_url);
      case 4:
        return setImage(dispatch, preAvatars[index].image_url);
      case 5:
        return setImage(dispatch, preAvatars[index].image_url);
      case 6:
        return setImage(dispatch, preAvatars[index].image_url);
      case 7:
        return setImage(dispatch, preAvatars[index].image_url);
      case 8:
        return setImage(dispatch, preAvatars[index].image_url);
      case 9:
        return setImage(dispatch, preAvatars[index].image_url);
      case 10:
        return setImage(dispatch, preAvatars[index].image_url);
      case 11:
        return setImage(dispatch, preAvatars[index].image_url);
      case 12:
        return setImage(dispatch, preAvatars[index].image_url);
      case 13:
        return setImage(dispatch, preAvatars[index].image_url);
      case 14:
        return setImage(dispatch, preAvatars[index].image_url);
      case 15:
        return setImage(dispatch, preAvatars[index].image_url);
      case 16:
        return setImage(dispatch, preAvatars[index].image_url);
      case 17:
        return setImage(dispatch, preAvatars[index].image_url);
      case 18:
        return setImage(dispatch, preAvatars[index].image_url);
      case 19:
        return setImage(dispatch, preAvatars[index].image_url);
      case 20:
        return setImage(dispatch, preAvatars[index].image_url);
      case 21:
        return setImage(dispatch, preAvatars[index].image_url);
      case 22:
        return setImage(dispatch, preAvatars[index].image_url);
      case 23:
        return setImage(dispatch, preAvatars[index].image_url);
      case 24:
        return setImage(dispatch, preAvatars[index].image_url);
      case 25:
        return setImage(dispatch, preAvatars[index].image_url);
      case 26:
        return setImage(dispatch, preAvatars[index].image_url);
      case 27:
        return setImage(dispatch, preAvatars[index].image_url);
      case 28:
        return setImage(dispatch, preAvatars[index].image_url);
      case 29:
        return setImage(dispatch, preAvatars[index].image_url);
      case 30:
        return setImage(dispatch, preAvatars[index].image_url);
      case 31:
        return setImage(dispatch, preAvatars[index].image_url);
      case 32:
        return setImage(dispatch, preAvatars[index].image_url);
      case 33:
        return setImage(dispatch, preAvatars[index].image_url);
      case 34:
        return setImage(dispatch, preAvatars[index].image_url);
      case 35:
        return setImage(dispatch, preAvatars[index].image_url);
      case 36:
        return setImage(dispatch, preAvatars[index].image_url);
      case 37:
        return setImage(dispatch, preAvatars[index].image_url);
      case 38:
        return setImage(dispatch, preAvatars[index].image_url);
      case 39:
        return setImage(dispatch, preAvatars[index].image_url);
      case 40:
        return setImage(dispatch, preAvatars[index].image_url);
      case 41:
        return setImage(dispatch, preAvatars[index].image_url);
      case 42:
        return setImage(dispatch, preAvatars[index].image_url);
      case 43:
        return setImage(dispatch, preAvatars[index].image_url);
      case 44:
        return setImage(dispatch, preAvatars[index].image_url);
      case 45:
        return setImage(dispatch, preAvatars[index].image_url);
      case 46:
        return setImage(dispatch, preAvatars[index].image_url);
      case 47:
        return setImage(dispatch, preAvatars[index].image_url);
      case 48:
        return setImage(dispatch, preAvatars[index].image_url);
      case 49:
        return setImage(dispatch, preAvatars[index].image_url);
      case 50:
        return setImage(dispatch, preAvatars[index].image_url);
      case 51:
        return setImage(dispatch, preAvatars[index].image_url);
      case 52:
        return setImage(dispatch, preAvatars[index].image_url);
      case 53:
        return setImage(dispatch, preAvatars[index].image_url);
      case 54:
        return setImage(dispatch, preAvatars[index].image_url);
      case 55:
        return setImage(dispatch, preAvatars[index].image_url);
      case 56:
        return setImage(dispatch, preAvatars[index].image_url);
      case 57:
        return setImage(dispatch, preAvatars[index].image_url);
      case 58:
        return setImage(dispatch, preAvatars[index].image_url);
      case 59:
        return setImage(dispatch, preAvatars[index].image_url);
      case 60:
        return setImage(dispatch, preAvatars[index].image_url);
      case 61:
        return setImage(dispatch, preAvatars[index].image_url);
      case 62:
        return setImage(dispatch, preAvatars[index].image_url);
      case 63:
        return setImage(dispatch, preAvatars[index].image_url);
      case 64:
        return setImage(dispatch, preAvatars[index].image_url);
      case 65:
        return setImage(dispatch, preAvatars[index].image_url);
      case 66:
        return setImage(dispatch, preAvatars[index].image_url);
      case 67:
        return setImage(dispatch, preAvatars[index].image_url);
      case 68:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/D.png');
      case 69:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/E.jpeg');
      case 70:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/F.png');
      case 71:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/G.jpeg');
      case 72:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/H.jpeg');
      case 73:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/I.jpeg');
      case 74:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/J.png');
      case 75:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/K.jpeg');
      case 76:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/L.png');
      case 77:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/M.jpeg');
      case 78:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/N.jpeg');
      case 79:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/O.png');
      case 80:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/P.png');
      case 81:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/Q.jpeg');
      case 82:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/R.jpeg');
      case 83:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/S.jpeg');
      case 84:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/T.jpeg');
      case 85:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/U.png');
      case 86:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/V.jpeg');
      case 87:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/W.png');
      case 88:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/X.jpeg');
      default:
        return setImage(dispatch, 'https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/D.png');
    }
  }

  useEffect(() => {
    axios.get(`${BASE_URL}/presenters`, {
      params: {
        user_id
      },
    })
    .then(response => {
      const newAvatars = response.data.presenters;
      setAvatars((preAvatar)=> [...newAvatars, ...preAvatar]);
      setPreAvatars(newAvatars);
    })
    .catch(error => console.error(error));
  }, [setAvatars]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <>
        <Grid item xs={12} lg={7}>
          <VuiBox mb={3} p={1}>
            <VuiTypography
              variant={window.innerWidth < values.sm ? "h3" : "h2"}
              textTransform="capitalize"
              fontWeight="bold"
              color="white"
            >
              dashboard
            </VuiTypography>
          </VuiBox>
          <Grid container>
            <Grid item xs={12}>
              <Globe
                display={{ xs: "none", md: "block" }}
                position="absolute"
                top="10%"
                right={0}
                mt={{ xs: -12, lg: -5 }}
                mr={{ xs: 0, md: -10, lg: 10 }}
                canvasStyle={{ marginTop: "3rem" }}
              />
            </Grid>
          </Grid>

          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: window.innerWidth < 800 ? 'center' : 'flex-start', flexDirection: window.innerWidth < 800 ? 'column' : ''}}>
            <div style={{flex: 0.3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', marginLeft: '-10px'}}>
              <VuiBox mb={3}>
                <Card sx={{minWidth: '250px'}}>
                  <VuiTypography variant='h5' style={{color: '#a0aec0'}}>QuickVideos</VuiTypography>
                  <Divider light />
                  <Grid container mt={1} justifyContent='space-between'>
                    <VuiTypography variant = 'h6'>Recently created</VuiTypography>
                    <VuiTypography variant='h5' style={{color: 'darkgray', opacity: '0.8'}}>{creditsloader ? <CircularProgress size={16} color="white" /> : recentlyCreated}</VuiTypography>
                  </Grid>
                  <Grid container mt={1} justifyContent='space-between'>
                    <VuiTypography variant = 'h6'>Total created</VuiTypography>
                    <VuiTypography variant='h5' style={{color: 'whitesmoke'}}>{creditsloader ? <CircularProgress size={16} color="white" /> : totalCreated}</VuiTypography>
                  </Grid>
                </Card>
              </VuiBox>
              <VuiBox mb={3}>
                <Card sx={{minWidth: '250px'}}>
                  <VuiTypography variant='h5' style={{color: '#a0aec0'}}>Credits</VuiTypography>
                  <Divider light />
                  <Grid container mt={1} justifyContent='space-between'>
                    <VuiTypography variant = 'h6'>Utilized Credits</VuiTypography>
                    <VuiTypography variant='h5' style={{color: 'red', opacity: '0.8'}}>{creditsloader ? <CircularProgress size={16} color="white" /> : usedCredits}</VuiTypography>
                  </Grid>
                  <Grid container mt={1} justifyContent='space-between'>
                    <VuiTypography variant = 'h6'>Remaining Credits</VuiTypography>
                    <VuiTypography variant='h5' style={{color: 'green'}}>{creditsloader ? <CircularProgress size={16} color="white" /> : totalCredits}</VuiTypography>
                  </Grid>
                </Card>
              </VuiBox>
            </div>
            <div style={{flex: 0.9, padding: '0 5px 5px 5px'}}>
              <Card>
                <VuiTypography variant='h5' style={{color: '#a0aec0', marginBottom: '5px', textAlign: 'center'}}>Total AI presenters</VuiTypography>
                <div style={{display: 'flex', flexWrap: 'wrap', padding: '5px'}}>
                  {avatars.map((item, index) => (
                    <div
                      key={index}
                      item
                      // mt={1}
                      // columns={4}
                      // xs={1}
                      // sm={1}
                      // md={1}
                      // lg={1}
                      style={{
                        display: 'flex',
                        alignItems: "center",
                        margin: '5px 15px',
                        cursor: 'pointer',
                      }}
                      onClick={()=>handleAvatarClicked(item, index)}
                    >
                      <img
                        src={index < preAvatars.length ? item.image_url : item.image}
                        alt={index < preAvatars.length ? item.id : item.name}
                        style={{
                          borderRadius: "100%",
                          width: "50px",
                          height: "50px",
                          background: "rgb(241, 242, 246)",
                          objectFit: "cover"
                        }}
                        onMouseOver={(e) => {
                          e.target.style.transform = "scale(1.05)";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.transform = "none";
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Card>
            </div>
            {/* <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column'}}>
              <VuiBox mb={3}>
                <Card sx={{minWidth: '250px'}}>
                  <VuiTypography variant='h5' style={{color: '#a0aec0'}}>Messages</VuiTypography>
                  <Divider light />
                  <Grid container mt={1} justifyContent='space-between'>
                    <VuiTypography variant = 'h6'>Unread Messages</VuiTypography>
                    <VuiTypography variant='h5' style={{color: 'skyblue', opacity: '0.8'}}>4</VuiTypography>
                  </Grid>
                  <Grid container mt={1} justifyContent='space-between'>
                    <VuiTypography variant = 'h6'>Total Messages</VuiTypography>
                    <VuiTypography variant='h5' style={{color: 'wheat'}}>20</VuiTypography>
                  </Grid>
                </Card>
              </VuiBox>
              <VuiBox mb={3}>
                <Card sx={{minWidth: '250px'}}>
                  <VuiTypography variant='h5' style={{color: '#a0aec0'}}>Connected Plugins</VuiTypography>
                  <Divider light />
                  <Grid>
                    <VuiTypography>ABC</VuiTypography>
                    <VuiTypography>DEF</VuiTypography>
                  </Grid>
                </Card>
              </VuiBox>
            </div> */}
          </div>
        </Grid>
        {/* <Card>
          <VuiTypography variant='h5' style={{color: '#a0aec0', marginBottom: '5px', textAlign: 'center'}}>Catalog created</VuiTypography>
          <div style={{display: 'flex', alignItems: 'center', justifyContent:'space-around', marginTop: '10px'}}>
            <Card sx={{width: '300px', height: '200px', marginTop: '5px'}}></Card>
            <Card sx={{width: '300px', height: '200px', marginTop: '5px'}}></Card>
            <Card sx={{width: '300px', height: '200px', marginTop: '5px'}}></Card>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent:'space-around', marginTop: '10px'}}>
            <Card sx={{width: '300px', height: '200px', marginTop: '5px'}}></Card>
            <Card sx={{width: '300px', height: '200px', marginTop: '5px'}}></Card>
            <Card sx={{width: '300px', height: '200px', marginTop: '5px'}}></Card>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent:'space-around', marginTop: '10px'}}>
            <Card sx={{width: '300px', height: '200px', marginTop: '5px'}}></Card>
            <Card sx={{width: '300px', height: '200px', marginTop: '5px'}}></Card>
            <Card sx={{width: '300px', height: '200px', marginTop: '5px'}}></Card>
          </div>
        </Card> */}
        
        {/* <Grid container spacing={3} mt="2px">
          <Grid item xs={12} lg={6} xl={5}>
            <ActiveUsers />
          </Grid>
          <Grid item xs={12} lg={6} xl={7}>
            <SalesOverview />
          </Grid>
        </Grid> */}
      </>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Default;
