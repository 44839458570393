/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Card, CircularProgress, Divider, Grid, Tooltip } from '@mui/material';
import VuiTypography from 'components/VuiTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Bot from './chatbot_1698535.png';
import VuiInput from 'components/VuiInput';
import VuiButton from 'components/VuiButton';
// import BASE_URL from "context/api";
import { setBotAiAvatar, useVisionUIController, setOpenPricingModal, setVideoAvatarsLoading } from "context";
import FirstDiv from './FirstDiv';
import SecondDiv from './SecondDiv';
import BotAvatar from '../profile/teams/components/Stories/data/BotAvatar';
import { setWidget } from 'context';
import axios from 'axios';
import BASE_URL from 'context/api';
import { connect, destroy } from '../../../video-streaming/streaming-client-api';
import UpgradeModal from '../UpgradeModal/Modal';
import VuiBox from 'components/VuiBox';
import borders from 'assets/theme/base/borders';
import { useSnackbar } from "notistack";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setAgentsCreated, setSessionActive } from 'context';
import DeleteIcon from '@mui/icons-material/Delete';
import './botai.css';
import { setBotDetails } from 'context';
import Lock from 'lock';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import mixpanel from 'mixpanel-browser';

const BotAi = () => {
  // const { connect } = useStreamingLogic();
  const [controller, dispatch] = useVisionUIController();
  const { widget, videoAvatars, botAiAvatar, userDetails, openPricingModal, videoAvatarsLoading, agentsCreated, sessionActive } = controller;
  const {user_id, plan} = userDetails;
  const [continueToggle, setContinueToggle] = useState(false);
  const [showBotAvatars, setShowBotAvatars] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [sessionResponse, setSessionResponse] = useState('');
  //eslint-disable-next-line
  const [botAvatars, setBotAvatars] = useState();
  const [activeButton, setActiveButton] = useState(false);
  // const [openEntryModal, setOpenEntryModal] = useState(false);
  const handleOpen = () => setOpenPricingModal(dispatch, true);
  const handleClose = () => setOpenPricingModal(dispatch, false);
  const { borderWidth } = borders;
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const agentUse = false;

  const handleUpgradeClick = () => {
    history.push('/upgrade');
  };

  useEffect(() => {
    setIsVideoLoaded(false);
  }, [botAiAvatar]);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  const handleContinue = async () => {
    setWidget(dispatch, !widget);
    setLoading(true);
    await connect('https://quickvideo.blob.core.windows.net/quickvideo/ai_bot/0.jpg', user_id, 'talks');
    setLoading(false);
  }

  const handleSecondContinue = () => {
    setContinueToggle(true);
    handleSetRememberMe();
  }

  const handleToggleBack = () => {
    setContinueToggle(false);
  }

  const handleThumbnailClick = async (index, id) => {
    setBotAiAvatar(dispatch, videoAvatars[0][index]);
    setLoading(true);
    index < 15 ? 
    await connect(`https://quickvideo.blob.core.windows.net/quickvideo/ai_bot/${index}.jpg`, user_id, 'talks') 
    :  await connect(`https://quickvideo.blob.core.windows.net/quickvideo/ai_bot/${index}.png`, user_id, 'talks'); 
    // await connect('https://clips-presenters.d-id.com/matt/PEvEohn_gk/hOIr_2INMA/image.png');
    // await connect('https://quickvideo.blob.core.windows.net/quickvideo/bot_ai.png');
    setLoading(false);
    setImage(videoAvatars[0][index].preview_url);
    setActiveButton(true);
  };

  const handleSingleAvatar = async () => {
    setLoading(true);
    setBotAiAvatar(dispatch, "https://quickvideo.blob.core.windows.net/quickvideo/presenters/preview/50bafc.mp4");
    // await destroy(user_id);
    setBotDetails(dispatch, {
      name: 'Casey',
      companyName: 'QuickVideo',
      wMessage: 'Welcome to QuickVideo, How can i assist you today ?'
    })
    enqueueSnackbar('Stream is getting ready in few seconds', { 
      variant: "info",
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
    await connect('rian-pbMoTzs7an', user_id, 'clips', agentUse);
    enqueueSnackbar('You can now click on below BOT AI to check the demo session', { 
      variant: "success",
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
    setLoading(false);
    setWidget(dispatch, !widget);
    setSessionActive(dispatch, true);
    mixpanel.track('BOT AI demo used', {
      'User ID': user_id,
    });
  }

  const handleDestroy = async () => {
    await destroy();
    setActiveButton(false);
  };

  const handleSetRememberMe = () => setWidget(dispatch, !widget);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(plan === '' || plan === '' || plan === '') && <VuiBox sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '2'}}>
        <Lock height={500} line="Bot AI Feature" />
      </VuiBox>}
      <Card sx={{padding: window.innerWidth<768 ? '30px 50px auto 50px' : "30px", minHeight: window.innerWidth<768 ? '100vh' : '60vh', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap'}} className={plan==='' && "blurred-content"}>
        <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: window.innerWidth < 768 ? 'column' : 'row'}}>
          {/* {videoAvatarsLoading ? 
            <VuiBox
              bgColor="white"
              borderRadius="50%"
              width="200px"
              height="200px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mx="auto"
            >
              <CircularProgress size={30} color="black" />
            </VuiBox>
            : 
            <VuiBox sx={{flex: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column', margin: '10px'}}>
              <video autoPlay loop muted style={{borderRadius:'100%', width: window.innerWidth < 768 ? '150px' : '250px', height: window.innerWidth < 768 ? '150px' : '250px', objectFit: 'cover', marginLeft: 'auto', marginRight: 'auto', opacity: isVideoLoaded ? 1 : 0, transition: 'opacity 0.5s ease-in-out 0s',}} key={botAiAvatar.preview_url} onLoadedData={handleVideoLoad} preload='yes'>
                <source src={window.location.pathname === '/botai' ? botAiAvatar && botAiAvatar.preview_url : 'https://quickvideo.blob.core.windows.net/quickvideo/presenters/preview/646efc.mp4'} type="video/mp4" />
              </video>
              {window.location.pathname === '/botai' ? botAvatars && <div style={{display: 'flex', flexWrap: 'wrap', maxHeight: '20vh', overflow: 'auto', padding: '10px', marginTop: '10px'}}>
                {botAvatars.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    mt={1}
                    // columns={4}
                    // xs={1}
                    // sm={1}
                    // md={1}
                    // lg={1}
                    sx={{
                      // flex: "5%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      borderRadius="100%"
                      // // width="3.625rem"
                      width="70px"
                      height="70px"
                      // // height="3.625rem"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color="white"
                      // mx="10px"
                      border={`${borderWidth[2]} solid`}
                      sx={{ cursor: loading ? "wait" : "pointer"}}
                      onClick={() => handleThumbnailClick(index, item.id)}
                      disabled={loading || activeButton}
                    >
                      <img
                        src={item.image_url}
                        alt='avatar'
                        style={{
                          borderRadius: "100%",
                          width: "67px",
                          height: "67px",
                          background: "rgb(241, 242, 246)",
                          objectFit: "cover"
                        }}
                        onMouseOver={(e) => {
                          e.target.style.border = "3px solid rgb(47, 57, 156)";
                          setBotAiAvatar(dispatch, videoAvatars[0][index]);
                        }}
                        onMouseOut={(e) => {
                          e.target.style.border = "none";
                        }}
                      />
                    </Button>
                  </Grid>
                ))}
              </div> : <></>}
            </VuiBox>
          } */}
          <VuiBox sx={{margin: '10px'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <VuiTypography color='white' textAlign='center'>
                <span style={{color: 'cornflowerblue', fontSize: window.innerWidth < 768 ? '24px' : '34px', fontWeight: 500}}>Conversational Video Chatbot </span><br /><span>with integration support</span>
              </VuiTypography>
              <img src={Bot} alt="bot" width={50} style={{marginLeft: '10px'}} />
            </div>
            <VuiTypography textAlign='center' sx={{fontWeight: 400, lineHeight: 1.2, marginTop: '10px', color: 'darkgray', fontSize: '18px'}}>Experience the future of Conversational AI with QuickVideo.<br /> Chat in real time and get answers to all your queries.</VuiTypography>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px'}}>
              {/* <VuiInput
                placeholder="enter your company url"
                variant="outlined"
                value='quickvideo.ai'
                sx={{width: '400px !important', height: '50px !important', color: 'white !important'}}
              /> */}
              {window.location.pathname === '/genpact' ? <VuiButton sx={{ minWidth: '100px', borderRadius: '0.9rem', fontSize: '12px'}} disabled={loading} onClick={handleSingleAvatar}>{loading ? <CircularProgress size={16} color='black' /> : 'Connect ➜'}</VuiButton> : 
              <>
                {/* <Tooltip title={!activeButton ? 'please select one of the presenter to continue' : ''}>
                  <VuiButton sx={{ minWidth: '100px', borderRadius: '0.9rem', fontSize: '12px'}} disabled={loading || !activeButton} onClick={handleContinue}>{loading ? <CircularProgress size={16} color='black' /> : 'Talk with Video Chatbot ➜'}</VuiButton>
                </Tooltip> */}
                <VuiButton sx={{ minWidth: '100px', borderRadius: '0.9rem', fontSize: '12px', opacity: sessionActive ? 0.5 : 1, pointerEvents: sessionActive && 'none', cursor: sessionActive && 'not-allowed'}} disabled={loading} onClick={handleSingleAvatar}>
                  {loading ? <CircularProgress size={16} color='black' /> : 
                    <VuiBox sx={{display: 'flex', alignItems: 'center'}}>
                      Show Demo
                      <PersonPinIcon sx={{marginLeft: '5px'}} />
                    </VuiBox>
                  }
                </VuiButton>
              </>
              }
            </div>
            <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '70px', }}>
              <VuiTypography variant='h4' color='white' sx={{textAlign: 'center'}}>Features which Bot AI provides</VuiTypography>
              <VuiBox sx={{padding: '10px', borderTop: '1px solid skyblue'}}>
                <VuiTypography color='white' variant='h6'>1. You can integrate your bot ai with your given knowledge base.</VuiTypography>
                <VuiTypography color='white' variant='h6'>2. You can choose any ai avatar from given list.</VuiTypography>
              </VuiBox>
              <NavLink to={!sessionActive && '/aiwidget'} style={{cursor: sessionActive && 'not-allowed'}}>
                <Button variant='contained' sx={{color: 'whitesmoke', marginTop: '5px', opacity: sessionActive ? 0.5 : 1, pointerEvents: sessionActive && 'none'}}>Design your own Bot AI <ChevronRightIcon /></Button>
              </NavLink>
            </VuiBox>
            {/* <VuiTypography variant='h5' sx={{marginTop: '25px'}}>Ask it. Bot AI will try it's best to answer your queries. <br /> Powered by QuickVideo.</VuiTypography> */}
          </VuiBox>
        </VuiBox>
        {agentsCreated.length > 0 && <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', margin: '20px 0px', maxWidth: '400px', opacity: sessionActive ? 0.5 : 1, pointerEvents: sessionActive && 'none', cursor: sessionActive && 'not-allowed'}}>
          <VuiTypography textAlign='center' variant='h4' sx={{color: 'whitesmoke'}}>My Agents</VuiTypography>
          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px', maxWidth: '500px', maxHeight: '50vh', overflowY: 'scroll'}}>
            { loading ? <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25%'}}><CircularProgress size={28} color='white' /></VuiBox>
              :
              agentsCreated.map((item, index) => 
                <NavLink to={{
                  pathname: '/aiwidget',
                  state: { selectedAgent: item }
                }}>
                  <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: '10px', cursor: 'pointer'}} key={index}>
                    <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', borderRadius: '100%', overflow: 'hidden'}}>
                      <img src={item.selected_avatar_url} alt="agent_id" width='100px' height='100px' />
                    </VuiBox>
                    <VuiTypography sx={{color: 'gray'}} variant='h6'>{item.avatar_name}</VuiTypography>
                    {/* <DeleteIcon /> */}
                  </VuiBox>
                </NavLink>
              )
            }
          </VuiBox>
        </VuiBox>}
      </Card>
      {/* {openPricingModal && <UpgradeModal />} */}
    </DashboardLayout>
  )
}

export default BotAi;