import { Card, CircularProgress } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiInput from 'components/VuiInput';
import VuiTypography from 'components/VuiTypography';
import React, { useState } from 'react';

const Script = ({manualSubtitles, setManualSubtitles, loading}) => {

  const handleTranscribe = (e) => {
    setManualSubtitles(e.target.value)
  };

  return (
    <VuiBox>
      <VuiTypography textAlign= 'center' color='white' sx={{marginBottom: '15px'}}>Confirm your Script</VuiTypography>
      <Card>
        {loading ? <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
          <CircularProgress size={24} color='white' />
          <VuiTypography>Transcribing your script</VuiTypography>
        </div> 
          : 
          <VuiBox sx={{maxHeight: '350px', overflowY: 'auto'}}>
            <VuiTypography color='white'>{manualSubtitles}</VuiTypography>
          </VuiBox>
        }
      </Card>
    </VuiBox>
  )
}

export default Script