 /* eslint-disable no-unused-vars */
import routes from "routes";

const Welcome = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <p><strong>Welcome</strong></p>
      <p>Let's help you get started, in less than a minute.</p>
    </div>
  );
}

const Dashboard = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <p><strong>Everything you need at one place</strong></p>
      <p>Get a summary of all your activities from videos generated, stats, videos uploaded and more.</p>
    </div>
  );
}

const Library = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <p><strong>Here you get all your Videos in one place</strong></p>
      <p>View and store all the Videos you created and generated</p>
    </div>
  );
}

const Chatbot = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <p><strong>And here you can build an AI Video Chatbot</strong></p>
      <p>Make an easy-to-build Video Chatbot for your AI-generated Videos. Simply create your flow logic and hit start.</p>
    </div>
  );
}

const Inbox = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <p><strong>Keep track of all your conversations</strong></p>
      <p>Simplified inbox to gather all conversations initiated by your users.</p>
    </div>
  );
}

const Catalogue = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <p><strong>Generate your powerful custom product video</strong></p>
      <p>Automatically generate a custom product video for your requirements by simply selecting a template and adding your product catalogue.</p>
    </div>
  );
}

const Create = () => {
  return (
    <div>
      <p style={{textAlign: 'center'}}><strong>Start Generating your AI Video</strong></p>
      <ul type="circle" style={{marginBottom: '10px'}}>
        <li>Choose a presenter</li>
        <li>Enter the script</li>
        <li>Select the language with style and create your own AI-generated video.</li>
      </ul>
      <p><u>Go Ahead and give it a try!</u></p>
    </div>
  );
}

export const steps = [
  {
    selector: `#${routes[routes.length-4].id}`,
    content: <Welcome />
  },
  {
    selector: `#${routes[0].id}`,
    content: <Create />
  },
  {
    selector: `#${routes[1].id}`,
    content: <Library />
  },
  {
    selector: `#${routes[2].id}`,
    content: `Click here to edit your videos.`
  },
  {
    selector: `#${routes[3].id}`,
    content: `Click here to make personalized video.`
  },
  {
    selector: `#${routes[4].id}`,
    content: `Click here to make your own bot for support.`
  },
  {
    selector: `#${routes[5].id}`,
    content: `Click here to view history of credits and subscription.`
  },
  {
    selector: `#${routes[6].id}`,
    content: `Click here to check plans.`
  },
  {
    selector: `#${routes[7].id}`,
    content: `Click here to check API key details.`
  },
];