/*eslint-disable*/
import beauty01 from './images/tags/beauty/beauty1.jpg';
import beauty02 from './images/tags/beauty/beauty2.jpg';
import beauty03 from './images/tags/beauty/beauty3.jpg';
import beauty04 from './images/tags/beauty/beauty4.jpg';
import news01 from './images/tags/news/news1.jpg';
import news02 from './images/tags/news/news2.jpg';
import news03 from './images/tags/news/news3.jpg';
import news04 from './images/tags/news/news4.jpg';
import business01 from '././images/tags/business/business1.jpg';
import business02 from '././images/tags/business/business2.jpg';
import business03 from '././images/tags/business/business3.jpg';
import business04 from '././images/tags/business/business4.jpg';
import business05 from '././images/tags/business/business5.jpg';
import business06 from '././images/tags/business/business6.jpg';
import business07 from '././images/tags/business/business7.jpg';
import business08 from '././images/tags/business/business8.jpg';
import business09 from '././images/tags/business/business9.jpg';
import business10 from '././images/tags/business/business10.jpg';
import business11 from '././images/tags/business/business11.jpg';
import business12 from '././images/tags/business/business12.jpg';
import business13 from '././images/tags/business/business13.jpg';
import business14 from '././images/tags/business/business14.jpg';
import sales01 from '././images/tags/sales/sales1.jpg';
import sales02 from '././images/tags/sales/sales2.jpg';
import sales03 from '././images/tags/sales/sales3.jpg';
import sales04 from '././images/tags/sales/sales4.jpg';
import sales05 from '././images/tags/sales/sales5.jpg';
import sales06 from '././images/tags/sales/sales6.jpg';
import sales07 from '././images/tags/sales/sales7.jpg';
import sales08 from '././images/tags/sales/sales8.jpg';
import sales09 from '././images/tags/sales/sales9.jpg';
import sales10 from '././images/tags/sales/sales10.jpg';
import sales11 from '././images/tags/sales/sales11.jpg';
import social01 from './images/tags/socialmedia/social1.jpg';
import social02 from './images/tags/socialmedia/social2.jpg';
import social03 from './images/tags/socialmedia/social3.jpg';
import social04 from './images/tags/socialmedia/social4.jpg';
import social05 from './images/tags/socialmedia/social5.jpg';
import presentation01 from './images/tags/presentation/presentation1.jpg';
import presentation02 from './images/tags/presentation/presentation2.jpg';
import presentation03 from './images/tags/presentation/presentation3.jpg';
import presentation04 from './images/tags/presentation/presentation4.jpg';
import presentation05 from './images/tags/presentation/presentation5.jpg';
import presentation06 from './images/tags/presentation/presentation6.jpg';
import presentation07 from './images/tags/presentation/presentation7.jpg';
import presentation08 from './images/tags/presentation/presentation8.jpg';
import presentation09 from './images/tags/presentation/presentation9.jpg';
import education1 from './images/tags/education/education1.jpg';
import education2 from './images/tags/education/education2.jpg';
import education3 from './images/tags/education/education3.jpg';
import education4 from './images/tags/education/education4.jpg';
import education5 from './images/tags/education/education5.jpg';
import event01 from './images/tags/event/event1.jpg';
import event02 from './images/tags/event/event2.jpg';
import event03 from './images/tags/event/event3.jpg';
import event04 from './images/tags/event/event4.jpg';
import event05 from './images/tags/event/event5.jpg';
import event06 from './images/tags/event/event6.jpg';
import event07 from './images/tags/event/event7.jpg';
import event08 from './images/tags/event/event8.jpg';
import event09 from './images/tags/event/event9.jpg';
import event10 from './images/tags/event/event10.jpg';
import hobby01 from './images/tags/hobby/hobby1.jpg';
import hobby02 from './images/tags/hobby/hobby2.jpg';
import hobby03 from './images/tags/hobby/hobby3.jpg';
import hobby04 from './images/tags/hobby/hobby4.jpg';
import hobby05 from './images/tags/hobby/hobby5.jpg';
import hobby06 from './images/tags/hobby/hobby6.jpg';
import chat01 from './images/tags/ChatGPT/chatgpt1.jpg';
import chat02 from './images/tags/ChatGPT/chatgpt2.jpg';
import chat03 from './images/tags/ChatGPT/chatgpt3.jpg';
import chat04 from './images/tags/ChatGPT/chatgpt4.jpg';
import chat05 from './images/tags/ChatGPT/chatgpt5.jpg';
import chat06 from './images/tags/ChatGPT/chatgpt6.jpg';
import chat07 from './images/tags/ChatGPT/chatgpt7.jpg';
import chat08 from './images/tags/ChatGPT/chatgpt8.jpg';
import chat09 from './images/tags/ChatGPT/chatgpt9.jpg';
import chat10 from './images/tags/ChatGPT/chatgpt10.jpg';
import chat11 from './images/tags/ChatGPT/chatgpt11.jpg';
import chat12 from './images/tags/ChatGPT/chatgpt12.jpg';
import chat13 from './images/tags/ChatGPT/chatgpt13.jpg';
import chat14 from './images/tags/ChatGPT/chatgpt14.jpg';
import chat15 from './images/tags/ChatGPT/chatgpt15.jpg';
import chat16 from './images/tags/ChatGPT/chatgpt16.jpg';
import chat17 from './images/tags/ChatGPT/chatgpt17.jpg';
import chat18 from './images/tags/ChatGPT/chatgpt18.jpg';
import chat19 from './images/tags/ChatGPT/chatgpt19.jpg';
import chat20 from './images/tags/ChatGPT/chatgpt20.jpg';
import chat21 from './images/tags/ChatGPT/chatgpt21.jpg';
import chat22 from './images/tags/ChatGPT/chatgpt22.jpg';
import chat23 from './images/tags/ChatGPT/chatgpt23.jpg';
import chat24 from './images/tags/ChatGPT/chatgpt24.jpg';
import chat25 from './images/tags/ChatGPT/chatgpt25.jpg';
import chat26 from './images/tags/ChatGPT/chatgpt26.jpg';
import chat27 from './images/tags/ChatGPT/chatgpt27.jpg';

export default [
  {
    id: 'beauty',
    src: beauty01,
    title: 'beauty 01'
  },
  {
    id: 'beauty',
    src: beauty02,
    title: 'beauty 02'
  },
  {
    id: 'beauty',
    src: beauty03,
    title: 'beauty 03'
  },
  {
    id: 'beauty',
    src: beauty04,
    title: 'beauty 04'
  },
  {
    id: 'news',
    src: news01,
    title: 'news 01'
  },
  {
    id: 'news',
    src: news02,
    title: 'news 02'
  },
  {
    id: 'news',
    src: news03,
    title: 'news 03'
  },
  {
    id: 'news',
    src: news04,
    title: 'news 04'
  },
  {
    id: 'business',
    src: business01,
    title: 'business 01'
  },
  {
    id: 'business',
    src: business02,
    title: 'business 02'
  },
  {
    id: 'business',
    src: business03,
    title: 'business 03'
  },
  {
    id: 'business',
    src: business04,
    title: 'business 04'
  },
  {
    id: 'business',
    src: business05,
    title: 'business 05'
  },
  {
    id: 'business',
    src: business06,
    title: 'business 06'
  },
  {
    id: 'business',
    src: business07,
    title: 'business 07'
  },
  {
    id: 'business',
    src: business08,
    title: 'business 08'
  },
  {
    id: 'business',
    src: business09,
    title: 'business 09'
  },
  {
    id: 'business',
    src: business10,
    title: 'business 10'
  },
  {
    id: 'business',
    src: business11,
    title: 'business 11'
  },
  {
    id: 'business',
    src: business12,
    title: 'business 12'
  },
  {
    id: 'business',
    src: business13,
    title: 'business 13'
  },
  {
    id: 'business',
    src: business14,
    title: 'business 14'
  },
  {
    id: 'sales',
    src: sales01,
    title: 'sales 01'
  },
  {
    id: 'sales',
    src: sales02,
    title: 'sales 02'
  },
  {
    id: 'sales',
    src: sales03,
    title: 'sales 03'
  },
  {
    id: 'sales',
    src: sales04,
    title: 'sales 04'
  },
  {
    id: 'sales',
    src: sales05,
    title: 'sales 05'
  },
  {
    id: 'sales',
    src: sales06,
    title: 'sales 06'
  },
  {
    id: 'sales',
    src: sales07,
    title: 'sales 07'
  },
  {
    id: 'sales',
    src: sales08,
    title: 'sales 08'
  },
  {
    id: 'sales',
    src: sales09,
    title: 'sales 09'
  },
  {
    id: 'sales',
    src: sales10,
    title: 'sales 10'
  },
  {
    id: 'sales',
    src: sales11,
    title: 'sales 11'
  },
  {
    id: 'social',
    src: social01,
    title: 'social 1'
  },
  {
    id: 'social',
    src: social02,
    title: 'social 2'
  },
  {
    id: 'social',
    src: social03,
    title: 'social 3'
  },
  {
    id: 'social',
    src: social04,
    title: 'social 4'
  },
  {
    id: 'social',
    src: social05,
    title: 'social 5'
  },
  {
    id: 'presentation',
    src: presentation01,
    title: 'presentation 01'
  },
  {
    id: 'presentation',
    src: presentation02,
    title: 'presentation 02'
  },
  {
    id: 'presentation',
    src: presentation03,
    title: 'presentation 03'
  },
  {
    id: 'presentation',
    src: presentation04,
    title: 'presentation 04'
  },
  {
    id: 'presentation',
    src: presentation05,
    title: 'presentation 05'
  },
  {
    id: 'presentation',
    src: presentation06,
    title: 'presentation 06'
  },
  {
    id: 'presentation',
    src: presentation07,
    title: 'presentation 07'
  },
  {
    id: 'presentation',
    src: presentation08,
    title: 'presentation 08'
  },
  {
    id: 'presentation',
    src: presentation09,
    title: 'presentation 09'
  },
  {
    id: 'education',
    src: education1,
    title: 'education 01'
  },
  {
    id: 'education',
    src: education2,
    title: 'education 02'
  },
  {
    id: 'education',
    src: education3,
    title: 'education 03'
  },
  {
    id: 'education',
    src: education4,
    title: 'education 04'
  },
  {
    id: 'education',
    src: education5,
    title: 'education 05'
  },
  {
    id: 'event',
    src: event01,
    title: 'event 01'
  },
  {
    id: 'event',
    src: event02,
    title: 'event 02'
  },
  {
    id: 'event',
    src: event03,
    title: 'event 03'
  },
  {
    id: 'event',
    src: event04,
    title: 'event 04'
  },
  {
    id: 'event',
    src: event05,
    title: 'event 05'
  },
  {
    id: 'event',
    src: event06,
    title: 'event 06'
  },
  {
    id: 'event',
    src: event07,
    title: 'event 07'
  },
  {
    id: 'event',
    src: event08,
    title: 'event 08'
  },
  {
    id: 'event',
    src: event09,
    title: 'event 09'
  },
  {
    id: 'event',
    src: event10,
    title: 'event 10'
  },
  {
    id: 'hobby',
    src: hobby01,
    title: 'hobby 01'
  },
  {
    id: 'hobby',
    src: hobby02,
    title: 'hobby 02'
  },
  {
    id: 'hobby',
    src: hobby03,
    title: 'hobby 03'
  },
  {
    id: 'hobby',
    src: hobby04,
    title: 'hobby 04'
  },
  {
    id: 'hobby',
    src: hobby05,
    title: 'hobby 05'
  },
  {
    id: 'hobby',
    src: hobby06,
    title: 'hobby 06'
  },
  {
    id: 'chat',
    src: chat01,
    title: 'ChatGPT 01'
  },
  {
    id: 'chat',
    src: chat02,
    title: 'ChatGPT 02'
  },
  {
    id: 'chat',
    src: chat03,
    title: 'ChatGPT 03'
  },
  {
    id: 'chat',
    src: chat04,
    title: 'ChatGPT 04'
  },
  {
    id: 'chat',
    src: chat05,
    title: 'ChatGPT 05'
  },
  {
    id: 'chat',
    src: chat06,
    title: 'ChatGPT 06'
  },
  {
    id: 'chat',
    src: chat07,
    title: 'ChatGPT 07'
  },
  {
    id: 'chat',
    src: chat08,
    title: 'ChatGPT 08'
  },
  {
    id: 'chat',
    src: chat09,
    title: 'ChatGPT 09'
  },
  {
    id: 'chat',
    src: chat10,
    title: 'ChatGPT 10'
  },
  {
    id: 'chat',
    src: chat11,
    title: 'ChatGPT 11'
  },
  {
    id: 'chat',
    src: chat12,
    title: 'ChatGPT 12'
  },
  {
    id: 'chat',
    src: chat13,
    title: 'ChatGPT 13'
  },
  {
    id: 'chat',
    src: chat14,
    title: 'ChatGPT 14'
  },
  {
    id: 'chat',
    src: chat15,
    title: 'ChatGPT 15'
  },
  {
    id: 'chat',
    src: chat16,
    title: 'ChatGPT 16'
  },
  {
    id: 'chat',
    src: chat17,
    title: 'ChatGPT 17'
  },
  {
    id: 'chat',
    src: chat18,
    title: 'ChatGPT 18'
  },
  {
    id: 'chat',
    src: chat19,
    title: 'ChatGPT 19'
  },
  {
    id: 'chat',
    src: chat20,
    title: 'ChatGPT 20'
  },
  {
    id: 'chat',
    src: chat21,
    title: 'ChatGPT 21'
  },
  {
    id: 'chat',
    src: chat22,
    title: 'ChatGPT 22'
  },
  {
    id: 'chat',
    src: chat23,
    title: 'ChatGPT 23'
  },
  {
    id: 'chat',
    src: chat24,
    title: 'ChatGPT 24'
  },
  {
    id: 'chat',
    src: chat25,
    title: 'ChatGPT 25'
  },
  {
    id: 'chat',
    src: chat26,
    title: 'ChatGPT 26'
  },
  {
    id: 'chat',
    src: chat27,
    title: 'ChatGPT 27'
  },
]