/*eslint-disable no-unused-vars */

import { Typography } from "@mui/material";
import VuiDropzone from "components/VuiDropzone";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Check, Computer, ExitToApp, FileUpload, Headphones, Mail, People, Quiz, Shop, Shop2, ShoppingBag, Star, YouTube } from "@mui/icons-material";
import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import VuiBox from "components/VuiBox";
import { IoShirt } from "react-icons/io5";
import { Box3 } from "three";
import Ecom from "./Ecom";
import Saas from "./Saas";
import Recruit from './recruit';
import All from './all';
import VuiTypography from "components/VuiTypography";
import { useState , useEffect} from "react";
import breakpoints from "assets/theme/base/breakpoints";


function Templat(){
  const [value, setValue] = React.useState(0);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("Builder");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return(
    <Grid container justifyContent='center' alignItems='center'>
        <Typography style={{ justifySelf:'center', fontWeightMedium: 500,fontSizeMD: 16, bottom:"50px", color: "whitesmoke"}}> 
        What do you want your Quickvideo to do ?
        </Typography>
        <Box sx={{ width:'620', borderRadius:"5px",marginTop:'2%'}}>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab icon={<Shop style={{fontSize:"30px"}} />} iconPosition="start" label={<VuiTypography color="light" variant="body2"> Ecommerce </VuiTypography>} />
        <Tab icon={<Computer style={{fontSize:"30px"}} />} iconPosition="start" label={<VuiTypography color="light" variant="body2"> Saas </VuiTypography>} />
        <Tab icon={<People style={{fontSize:"30px"}} />} iconPosition="start" label={<VuiTypography color="light" variant="body2"> Recruiting </VuiTypography>} />
        <Tab icon={<Check style={{fontSize:"30px"}} />} iconPosition="start" label={<VuiTypography color="light" variant="body2"> View All </VuiTypography>} />
      </Tabs>
    </Box>
    {/* <Grid sx={{ display: 'flex' }} style={{marginTop:"25px"}} >
      <Grid style={{width:"30%",marginRight:"5px"}} sx={{ display: 'flex', flexDirection: 'column'}} item xs={6} md={5} lg={6} xl={5}>
        <VuiBox style={{width:"10"}} mb ={3}>
      <MiniStatisticsCard 
                  style={{width:"100"}}
                  bgColor="info"
                  title={{ text: "Shoppable Videos", fontWeight: "regular" }}
                  
                  icon={<ShoppingBag color="white" size="22px" />}
                />
          </VuiBox>
          <VuiBox mb ={3}>
      <MiniStatisticsCard 
                  style={{width:"100"}}
                  bgColor="info"
                  title={{ text: "Video Support", fontWeight: "regular" }}
                  
                  icon={<Headphones color="white" size="22px" />}
                />
          </VuiBox>
          <VuiBox mb ={3}>
      <MiniStatisticsCard 
                  style={{width:"100"}}
                  bgColor="info"
                  title={{ text: "Personal Stylist", fontWeight: "regular" }}
                  
                  icon={<IoShirt color="white" size="22px" />}
                />
          </VuiBox>

      </Grid>
      <Grid sx={{ display: 'flex', flexDirection: 'column'}} style={{width:"30%",marginRight:"5px"}} item xs={6} md={5} lg={6} xl={5}>
        <VuiBox mb ={3}>
        <MiniStatisticsCard 
                  style={{width:"100"}}
                  bgColor="info"
                  title={{ text: "Exit Intent", fontWeight: "regular" }}
                  
                  icon={<ExitToApp color="white" size="22px" />}
                />
          </VuiBox>
          <VuiBox mb ={3}>
        <MiniStatisticsCard 
                  style={{width:"100"}}
                  bgColor="info"
                  title={{ text: "Welcome Visitors", fontWeight: "regular" }}
                  
                  icon={<Star color="white" size="22px" />}
                />
          </VuiBox>
          <VuiBox mb ={3}>
        <MiniStatisticsCard 
                  style={{width:"100"}}
                  bgColor="info"
                  title={{ text: "Video Quiz", fontWeight: "regular" }}
                  
                  icon={<Quiz color="white" size="22px" />}
                />
          </VuiBox>

      </Grid>
      <Grid sx={{ display: 'flex', flexDirection: 'column'}} style={{width:"30%",marginRight:"5px"}} item xs={6} md={5} lg={6} xl={5}>
        <VuiBox mb ={3}>
        <MiniStatisticsCard 
                  style={{width:"100"}}
                  bgColor="info"
                  title={{ text: "Email And SMS Campaigns", fontWeight: "regular" }}
                  
                  icon={<Mail color="white" size="22px" />}
                />
          </VuiBox>
          <VuiBox mb ={3}>
        <MiniStatisticsCard 
                  style={{width:"100"}}
                  bgColor="info"
                  title={{ text: "Porduct Video", fontWeight: "regular" }}
                  
                  icon={<YouTube color="white" size="22px" />}
                />
          </VuiBox>
          <VuiBox mb ={3}>
        <MiniStatisticsCard 
                  style={{width:"100"}}
                  bgColor="info"
                  title={{ text: "Unboxing", fontWeight: "regular" }}
                  
                  icon={<Box color="white" size="22px" />}

                />
          </VuiBox>

      </Grid>
    </Grid> */}
    {value === 0 && (
        
       <Ecom></Ecom>
    )} 
    {value === 1 && (
        
        <Saas> </Saas>
     )} 
     {value === 2 && (
        <Recruit> </Recruit>
     )}
     {value === 3 && (
      <All></All>
     )}
     
     
    

    </Grid>
  )
}


export default Templat;