/*eslint-disable no-unused-vars */

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React components
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Billing page components
import PaymentMethod from "layouts/pages/account/billing/components/PaymentMethod";
import Invoices from "layouts/pages/account/billing/components/Invoices";
import BillingInformation from "layouts/pages/account/billing/components//BillingInformation";
import Transactions from "layouts/pages/account/billing/components//Transactions";
import CreditBalance from "layouts/pages/account/billing/components/CreditBalance";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

import { HiUsers, HiEye, HiCursorClick } from "react-icons/hi"
import { RiVideoChatFill } from "react-icons/ri"
import axios from "axios";
import { useVisionUIController } from "context";
import { useState } from "react";
import { setSupportModal } from "context";
import { useSnackbar } from "notistack";
import VuiTypography from "components/VuiTypography";
import { Button, Card } from "@mui/material";
import BASE_URL from "context/api";
import TicketModal from "./TicketModal";
import SupportModal from "./SupportModal";
import { useEffect } from "react";
import AllLogs from "./components/AllLogs";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

function Billing() {
  const [recentTickets, setRecentTickets] = useState([]);
  const [ticketModal, setTicketModal] = useState(false);
  const [controller, dispatch] = useVisionUIController();
  const { userDetails, supportModal } = controller;
  const { user_id } = userDetails;
  const { enqueueSnackbar } = useSnackbar();

  const getTickets = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/customer_support`, {
        params: {
          user_id,
        },
      });
      setRecentTickets(response.data.support);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return [];
    }
  }
  
  const closeTicket = async () => {
    try {
      const response = await axios.patch(`${BASE_URL}/customer_support`, {
        user_id,
        ticket_id: ticketModal.ticket_id,
        state: 'closed',
      });
      getTickets();
      setTicketModal(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return [];
    }
  }

  useEffect(() => {
    getTickets();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mt={4}>   
        <VuiBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* <Transactions /> */}
              <AllLogs />
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox mb={1.5}>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} lg={12} xl={12}> */}
              <Grid container spacing={1}>
                <Grid item xs={12} lg={8}>
                  <BillingInformation />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    {/* <VuiBox sx={{border: '1px solid skyblue', background: 'transparent', padding: '20px', width: '300px', maxHeight: '300px', borderRadius: '15px', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-around', marginBottom: '20px'}}>
                      <VuiTypography variant='h5' textAlign='center' color='white'>Click below to check <br/> streaming logs</VuiTypography>
                      <NavLink to='/streaminglogs'>
                        <Button variant='contained' sx={{color: 'whitesmoke', marginTop: '20px'}}>Streaming Logs </Button>
                      </NavLink>
                    </VuiBox> */}
                    <VuiBox sx={{border: '1px solid skyblue', background: 'transparent', padding: '10px', width: '300px', maxHeight: '300px', borderRadius: '20px', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-around'}}>
                      <VuiBox sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'column'}}>
                        <VuiTypography variant='h6' color='white'>Need support ?</VuiTypography>
                        <Button sx={{color: 'whitesmoke', marginTop: '5px'}} variant="contained" onClick={()=>setSupportModal(dispatch, true)}>Raise a ticket</Button>
                      </VuiBox>
                      {recentTickets.length > 0 && <Card sx={{marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <VuiTypography color='white' variant='h6'>Past tickets</VuiTypography>
                        <VuiBox sx={{overflow: 'scroll'}}>
                          {recentTickets.map((item, index) => (
                            <VuiBox key={item.ticket_id} sx={{marginTop: '5px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', borderBottom: '1px dashed lightgray', padding: '5px 10px', width: '200px', cursor: 'pointer'}} onClick={() => setTicketModal(item)}>
                              <VuiBox sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>   
                                <VuiBox sx={{display: 'flex', alignItems: 'center'}}>
                                  <VuiTypography color='white' variant='h6' sx={{fontSize: '14px'}}>{index + 1} &nbsp;</VuiTypography>
                                  <VuiTypography color='white' variant='h6' sx={{fontSize: '14px'}}>{item.issue.length > 10 ? `${item.issue.slice(0, 5)}..` : item.issue}</VuiTypography>
                                </VuiBox>
                                <VuiTypography color='white' variant='h6'>
                                  {item.state === 'active' ?
                                    <VuiBox sx={{display: 'flex', alignItems: 'center', fontSize: '12px', color: 'green'}}><VuiBox sx={{borderRadius: '100%', background: 'green', width: '10px', height: '10px'}} />&nbsp; Active</VuiBox> : item.state === 'pending' ? <VuiBox sx={{display: 'flex', alignItems: 'center', fontSize: '12px', color: 'red'}}><VuiBox sx={{borderRadius: '100%', background: 'red', width: '10px', height: '10px'}} />&nbsp; Pending</VuiBox> : <VuiBox sx={{display: 'flex', alignItems: 'center', fontSize: '12px', color: 'gray'}}><VuiBox sx={{borderRadius: '100%', background: 'gray', width: '10px', height: '10px'}} />&nbsp; Closed</VuiBox>}
                                </VuiTypography>
                              </VuiBox>
                            </VuiBox>
                          ))}
                        </VuiBox>
                      </Card>}
                    </VuiBox>
                  </VuiBox>
                  {supportModal && <SupportModal getTickets={getTickets} />}
                  {ticketModal && <TicketModal ticketModal={ticketModal} setTicketModal={setTicketModal} closeTicket={closeTicket} />}
                </Grid>
              </Grid>
            {/* </Grid>
          </Grid> */}
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
