import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiButton from 'components/VuiButton';
import VuiTypography from 'components/VuiTypography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'video-react/dist/video-react.css';
import { Player, BigPlayButton, ControlBar, ReplayControl, ForwardControl, PlaybackRateMenuButton, CurrentTimeDisplay ,TimeDivider, VolumeMenuButton } from 'video-react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65vw',
  bgcolor: '#000',
  border: '2px solid gray',
  borderRadius: '10px',
  padding: 4,
  outline: 'none',
};

const VideoModal = ({openModal, handleClose, url, details}) => {
  
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: window.innerWidth < 1000 ? 'row' : 'column'}}>
            <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '15px'}}>
              <ArrowBackIcon color="white" onClick={handleClose} sx={{cursor: 'pointer', margin: '0px 10px'}} />
              <VuiTypography sx={{marginLeft: '50px', color: 'whitesmoke'}}>{details}</VuiTypography>
            </VuiBox>
            {/* <VuiBox sx={{height: '500px'}}>
              <Player src={url}>
                <BigPlayButton position="center" />
              </Player>
            </VuiBox> */}
            <Player src={url}>
              <BigPlayButton position="center" />
              <ControlBar autoHide={true} className="my-class">
                <ReplayControl seconds={10} order={1.1} />
                <ForwardControl seconds={30} order={1.2} />
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                <VolumeMenuButton disabled />
              </ControlBar>
            </Player>
            {/* <video src={url} controls height={500} /> */}
          </VuiBox>
        </Box>
      </Modal>
    </div>
  )
}

export default VideoModal