/*eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";

// Vision UI Dashboard PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Vision UI Dashboard PRO React context
import {
  useVisionUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setIsTourOpen,
  logout,
  setOpenSideMenu,
  setSupportModal,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { Avatar, Badge, Button, Card, Divider } from "@mui/material";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Tour, {Navigation, Dot} from 'reactour';
import { steps } from "steps";
import MenuIcon from '@mui/icons-material/Menu';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "notistack";
import CreditIcon from 'assets/images/Credits.svg';
import axios from "axios";
import BASE_URL from "context/api";
import DraftsIcon from '@mui/icons-material/Drafts';

function DashboardNavbar({ absolute, light, isMini }) {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, isTourOpen, isLoggedIn, userDetails, openSideMenu, notifications } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openSecondMenu, setOpenSecondMenu] = useState(false);
  const [credits, setCredits] = useState(null);
  const route = useLocation().pathname.split("/").slice(1);
  const { user_id, access_token } = userDetails;
  const handleResize = () => {
		setScreenSize(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

  useEffect(() => {
    try {
      axios.get(`${BASE_URL}/credits`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id
        },
      })
      .then((res) => {
        setCredits(res.data.credits.Total);
      })
      .catch((err) => console.log(err))
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    //eslint-disable-next-line
  }, [])

  const handleTourToggle = () => {
    setIsTourOpen(dispatch, !isTourOpen);
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenSecondMenu = (event) => setOpenSecondMenu(event.currentTarget);
  const handleCloseSecondMenu = () => setOpenSecondMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {/* <NotificationItem
        image={<img src={team2} alt="person" />}
        title={["New message", "from Laur"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt="person" />}
        title={["New album", "by Travis Scott"]}
        date="1 day"
        onClick={handleCloseMenu}
      /> */}
      {notifications && notifications.slice(0, 5).map((notification, index) => (
        <NavLink to="/notifications">
          <NotificationItem
            key={index}
            color="secondary"
            image={
              notification.read ? <DraftsIcon /> : <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                {/* payment */}
                mark_email_unread
              </Icon>
            }
            title={notification.Event.split('_').join(' ')}
            date={notification.created_at.date + ' ' + notification.created_at.time}
            onClick={handleCloseMenu}
          />
          <Divider light />
        </NavLink>
      ))}
      {notifications && 
        <>
          <NavLink to="/notifications">
            <VuiTypography sx={{color: "whitesmoke", fontSize: "14px", textAlign: "center", paddingBottom: "10px", cursor: "pointer", minWidth: 200}} onClick={handleCloseMenu}>See all Notifications</VuiTypography>
          </NavLink>
        </>
      }
    </Menu>
  );

  function stringAvatar(name) {
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: `${name.split(" ")[0][0]}`,
    };
  }

  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = () => {
    // handleClick();
    logout(dispatch, !isLoggedIn);
    enqueueSnackbar("Signed out successfully!", { 
      variant: "success",
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const renderSecondMenu = () => (
    <Menu
      anchorEl={openSecondMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "bottom",
      }}
      open={Boolean(openSecondMenu)}
      onClose={handleCloseSecondMenu}
      sx={{ mt: 2 }}
    >
      <VuiBox sx={{padding: "5px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "fit-content"}}>
        <Card>
          <VuiBox sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start"}} to="/profile" component={Link}>
            <VuiTypography
            variant="button"
            fontWeight="medium"
            color="white"
            sx={{marginRight: '10px', textAlign: "center"}}
            >
              <Avatar
                {...stringAvatar(userDetails.first_name)}
                sx={{
                  width: 60,
                  height: 60,
                  fontSize: "22px !important",
                  background: "transparent",
                  border: "1px solid white",
                }}
              />
            </VuiTypography>
            <VuiBox sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
              <VuiTypography
                fontWeight="medium"
                color="white"
                sx={{fontSize: "16px"}}
              >
                {userDetails.first_name}
              </VuiTypography>
              <VuiTypography
                fontWeight="medium"
                color="white"
                sx={{fontSize: "16px"}}
              >
                {userDetails.email_id}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
          <Divider light />
          <VuiBox sx={{'&:hover': {backgroundColor: '#070b28'},cursor: "pointer", padding: "10px 100px", textAlign: "center"}}>
            <VuiTypography
              variant="button"
              fontWeight="medium"
              color="white"
              sx={{ color: "whitesmoke", fontSize: "16px", textAlign: "center"}}
              to="/profile"
              component={Link}
            >
              Profile
            </VuiTypography>
          </VuiBox>
          <VuiBox sx={{'&:hover': {backgroundColor: '#070b28'},cursor: "pointer", padding: "10px 100px", textAlign: "center"}}>
            <VuiTypography
              variant="button"
              fontWeight="medium"
              color="white"
              sx={{ color: "whitesmoke", fontSize: "16px", textAlign: "center"}}
              to="/wishlist"
              component={Link}
            >
              Favourites
            </VuiTypography>
          </VuiBox>
          {/* <VuiBox sx={{'&:hover': {backgroundColor: '#070b28'},cursor: "pointer", padding: "10px 100px", textAlign: "center"}} onClick={()=>setSupportModal(dispatch, true)}>
            <VuiTypography
              variant="button"
              fontWeight="medium"
              color="white"
              sx={{ color: "whitesmoke", fontSize: "16px", textAlign: "center"}}
            >
              Support ticket
            </VuiTypography>
          </VuiBox> */}
          <VuiBox sx={{'&:hover': {backgroundColor: '#070b28'},cursor: "pointer", padding: "10px 100px", textAlign: "center"}} onClick={handleLogout}>
            <VuiTypography
              variant="button"
              fontWeight="medium"
              color="white"
              sx={{color: "whitesmoke", fontSize: "16px", textAlign: "center"}}
            >
              { isLoggedIn ? "Sign Out" : "Sign in" }
            </VuiTypography>
          </VuiBox>
        </Card>
      </VuiBox>
    </Menu>
  );

  const unreadCount = notifications?.filter((item) => !item.read).length;

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(dispatch, !isTourOpen)}
        // maskClassName="mask"
        // className="helper"
        rounded={10}
        accentColor="#060b27"
        // highlightedMaskClassName="highlight"
        nextButton="Next"
        prevButton="Back"
        lastStepNextButton="Finish"
        showNumber={true}
        showButtons={true}
        showNavigation={true}
        disableInteraction={false}
        disableDotsNavigation={false}
        disableKeyboardNavigation={false}
        disableFocusLock={false}
      />
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <VuiBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          {/* <Icon
            fontSize="medium"
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? "menu_open" : "menu"}
          </Icon> */}
          {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> */}
        </VuiBox>
        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <VuiBox pr={1} maxWidth={{ sm: "175px", md: "100%" }}>
              <VuiInput
                placeholder="Type here..."
                icon={{ component: "search", direction: "left" }}
                sx={({ breakpoints }) => ({
                  [breakpoints.down("sm")]: {
                    maxWidth: "80px",
                  },
                  [breakpoints.only("sm")]: {
                    maxWidth: "80px",
                  },
                  backgroundColor: "info.main !important",
                })}
              />
            </VuiBox> */}
            <VuiBox color={light ? "white" : "inherit"}>
              {screenSize > 768 ? 
                <IconButton sx={navbarIconButton} size="small" onClick={handleTourToggle}>
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: white.main,
                    })}
                  >
                    web_icon
                  </Icon>
                  <VuiTypography
                    variant="button"
                    fontWeight="medium"
                    color="white"
                  >
                    Site Guide
                  </VuiTypography>
                </IconButton> : ""
              }
              {screenSize <= 768 ? <MenuIcon sx={{color: "whitesmoke", cursor: "pointer"}} onClick = {() => setOpenSideMenu(dispatch, !openSideMenu)} /> : ""}
            </VuiBox>
            <VuiBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                color="white"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                // onClick={handleOpenMenu}
              >
                <NavLink to="/usage">
                  <>
                  {credits ? <VuiTypography variant="button" fontWeight="medium" color="white"
                    >
                      Credits
                      <span style={{borderRadius: '5px', background: 'white', color: "black", marginLeft: '5px', padding: '2px'}}>{credits}</span> 
                    </VuiTypography> : ' ...'}
                  </>
                </NavLink>
              </IconButton>
              {/* {renderMenu()} */}
            </VuiBox>
            <VuiBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                color="white"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Badge badgeContent={notifications?.some((item) => !item.read) ? unreadCount : 0} color="primary" max={10}>
                  <Icon>notifications</Icon>
                </Badge>
              </IconButton>
              {renderMenu()}
            </VuiBox>
            <VuiBox color={light ? "white" : "inherit"} sx={{ml: 1}}>
              {/* <Link to="/authentication/sign-in/basic" id="signin"> */}
                <IconButton size="small" onClick={handleOpenSecondMenu}>
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: white.main,
                    })}
                  >
                    account_circle
                  </Icon>
                  {openSecondMenu ? <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: white.main,
                    })}
                  >
                    arrow_drop_upLine
                  </Icon> : <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: white.main,
                    })}
                  >
                    arrow_drop_downLine
                  </Icon>}
                </IconButton>
                {renderSecondMenu()}
              {/* </Link> */}
            </VuiBox>
          </VuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
