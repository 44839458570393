/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiEditor from "components/VuiEditor";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import VuiSelect from "components/VuiSelect";

function Details({project, setProject, allProjects, projectNameError, setProjectNameError}) {
  
  const handleDescriptionChange = (newDescription) => {
    setProject({
      ...project,
      description: newDescription,
    });
  };

  const handleVariableType = (selectedOption) => {
    setProject({
      ...project,
      variableType: selectedOption.value,
    });
  }

  const handlePersonalizationVariableType = (selectedOption) => {
    setProject({
      ...project,
      personalizationVariableType: selectedOption.value,
    });
  }

  const handleChange = (e) => {
    const newName = e.target.value;
    if (allProjects.includes(newName)) {
      setProjectNameError('This project name already exists.');
      setProject({ ...project, name: newName });
    } else {
      setProjectNameError('');
      setProject({ ...project, name: newName });
    }
  };

  return (
    <VuiBox>
      <VuiTypography variant="h5" color="white" textAlign='center'>
        Project Details
      </VuiTypography>
      <VuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <FormField type="text" label="project name" placeholder="eg. untitled" value={project.name} onChange={handleChange} />
            {projectNameError && <VuiTypography variant='h6' color='error' sx={{paddingLeft: '5px'}}>{projectNameError}</VuiTypography>}
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <FormField type="text" label="project usecase" placeholder="eg. production" value={project.use} onChange={(e)=>setProject({...project, use: e.target.value})}  />
          </Grid>
        </Grid>
      </VuiBox>
      <VuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                Project Description&nbsp;&nbsp;
                {/* <VuiTypography variant="caption" fontWeight="regular" color="text">
                  (optional)
                </VuiTypography> */}
              </VuiTypography>
            </VuiBox>
            <VuiEditor  value={project.description} onChange={handleDescriptionChange}  />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <VuiTypography color='white' sx={{fontSize: '12px', fontWeight: 800, marginTop: '10px', marginBottom: '5px'}}>Personalization Variable</VuiTypography>
            <VuiSelect
              placeholder="Select Personalization variable"
              options={[
                { value: "Name", label: "Name" },
                { value: "Information", label: "Information" },
                { value: "Invite", label: "Invite" },
                { value: "Other", label: "Other" },
              ]}
              onChange={handlePersonalizationVariableType}
            />

            <VuiTypography color='white' sx={{fontSize: '12px', fontWeight: 800, marginTop: '10px', marginBottom: '5px'}}>Position of variable</VuiTypography>
            <VuiSelect
              placeholder="Select variable type"
              options={[
                { value: "Prefix", label: "Prefix" },
                { value: "Suffix", label: "Suffix" }
              ]}
              onChange={handleVariableType}
            />
            {/* <FormField type="select" label="variable type" placeholder="eg. micheal" value={project.handler} onChange={(e)=>setProject({...project, handler: e.target.value})}  /> */}
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  );
}

export default Details;
