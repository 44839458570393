/*eslint-disable no-unused-vars */

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAlert from "components/VuiAlert";
import VuiButton from "components/VuiButton";
import VuiSnackbar from "components/VuiSnackbar";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useVisionUIController } from "context";
import axios from "axios";
import BASE_URL from "context/api";
import { Button, CircularProgress, Icon, Modal } from "@mui/material";
import Pagination from "Pagination";
import CloseIcon from "@mui/icons-material/Close";
import DraftsIcon from '@mui/icons-material/Drafts';

function Alerts() {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [messages, setMessages] = useState([]);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [controller, dispatch] = useVisionUIController();
  const {userDetails, notifications} = controller;
  const {user_id} = userDetails;
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const itemsPerPage = 10;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedNotification = notifications && notifications.slice(startIndex, endIndex);

  // useEffect(() => {
  //   setLoader(true);
  //   axios.get(`${BASE_URL}/notifications`, {
  //     params: {
  //       user_id
  //     }
  //   })
  //   .then((res) => {
  //     setMessages(res.data.notifications);
  //     setLoader(false);
  //   })
  //   .catch((err)=>{
  //     console.log(err);
  //     setLoader(false);
  //   })
  //   //eslint-disable-next-line
  // }, []);

  const handleDeleteAll = async () => {
    setLoader(true);
    await axios.delete(`${BASE_URL}/notifications`, {
      params: {
        user_id
      }
    })
    .then((res) => {
      console.log(res);
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
      setLoader(false);
    })
  }

  const renderSuccessSB = (
    <VuiSnackbar
      color="success"
      icon="check"
      title="Vision UI Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const renderInfoSB = (
    <VuiSnackbar
      color="info"
      icon="notifications"
      title="Vision UI Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <VuiSnackbar
      color="warning"
      icon="star"
      title="Vision UI Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
    />
  );

  const renderErrorSB = (
    <VuiSnackbar
      color="error"
      icon="warning"
      title="Vision UI Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const colors = ['primary', 'secondary', 'success', 'error', 'warning', 'info', 'lightblue'];
  const [ showModal, setShowModal] = useState(false);
  useEffect(() => {
    if(showModal) {
      axios.patch(`${BASE_URL}/notifications`, {
        user_id,
        id: showModal.id
      })
      .then((res) => console.log(res.data))
    }
  }, [showModal]);

  const MessageModal = () => {
    const handleClose = () => {
      setShowModal(false);
    };
  
    return (
      <div>
        <Modal open={showModal} onClose={handleClose}>
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 79.41%, rgba(10, 14, 35, 1) 76.65%)",
            color: "#090d2c",
            outline: "none",
            padding: "20px",
            height: "35vh",
            width: "30vw",
            maxHeight: "50vh",
            boxShadow: "rgb(54, 68, 97) 0px 0px 10px 2px",
            borderRadius: '5px',
            overflow: 'hidden'
          }}>
            <CloseIcon color='white' sx={{marginLeft: 'auto', cursor: 'pointer'}} onClick={handleClose} />
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'}}>
              <h2 style={{color: "whitesmoke", borderBottom: '1px solid skyblue'}}>{showModal.Event}</h2>
              <h5 style={{color: "whitesmoke", fontWeight: "400", marginTop: '20px'}}>{showModal.Message}</h5>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        // sx={{ display: "flex", flexWrap: "wrap" }}
        sx={{
          mt: 1,
          marginBottom: "20px",
          // minHeight: '40vh',
          height: '100vh'
        }}
      >
        <Grid
          container
        >
          <Grid item xs={12}>
            <Grid item xs={12} lg={12}>
              <Card sx={{minHeight: '40vh'}}>
                <VuiBox>
                  {notifications && notifications.length === 0 ? 
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%'}}>
                      <VuiTypography
                        style={{
                          fontWeightMedium: 500,
                          fontSize: 24,
                          // bottom: "50px",
                          color: "whitesmoke",
                        }}
                      >
                        You haven't received any notifications yet.
                      </VuiTypography>
                    </div> 
                    : 
                    <>
                      {loader ? 
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '12%'}}>
                          <CircularProgress size={50} color="white" /> 
                        </div>
                        : 
                        <>
                          {/* {notifications.map((msg, index) => {
                            const color = colors[index % colors.length];
                            return (
                            <VuiAlert color={msg.color} key={index}>
                              <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                                mark_email_unread
                              </Icon>
                              <VuiTypography variant="body2" color="white" style={{marginLeft: '10px'}}>
                                {msg.data}
                              </VuiTypography>
                            </VuiAlert>
                          )}
                          )} */}
                          {notifications && displayedNotification.map((msg, index) => {
                            const color = colors[index % colors.length];
                            return (
                              <VuiBox sx={{cursor: 'pointer'}} onClick={() => setShowModal(msg)}>
                                <VuiAlert dismissible color={msg.color} key={index}>
                                  {msg.read ? <DraftsIcon /> : <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main}}>
                                    mark_email_unread
                                  </Icon>}
                                  <VuiBox sx={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                                    <VuiTypography variant="h5" color="white">
                                      {msg.Event}
                                    </VuiTypography>
                                    <VuiTypography variant="body2" color="white">
                                      {msg.Message.length > 25 ? `${msg.Message.slice(0, 25)}...` : msg.Message}
                                    </VuiTypography>
                                  </VuiBox>
                                </VuiAlert>
                              </VuiBox>
                            )
                          })}
                          {notifications && notifications.length > 0 && 
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                              <Button variant="contained" sx={{color: 'whitesmoke'}} onClick={handleDeleteAll}>Delete All</Button>
                              {notifications.length > 10 && <Pagination
                                currentPage={currentPage}
                                totalPages={Math.ceil(messages.length / itemsPerPage)}
                                onPageChange={handlePageChange}
                              />}
                            </div>
                          }
                        </>
                      }
                    </>
                  }
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      {showModal && <MessageModal />}
    </DashboardLayout>
  );
}

export default Alerts;