import React, { useState, useRef } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { Slider } from '@mui/material';

const AudioPlayer = ({ audioSrc }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  // Function to handle audio play/pause
  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Function to update the current time of the audio
  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  // Function to handle seeking
  const handleSeek = (e) => {
    const seekTime = e.target.value;
    audioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  // Function to handle audio end
  const handleAudioEnd = () => {
    setIsPlaying(false);
    setCurrentTime(0);
  };

  return (
    <div>
      <audio
        ref={audioRef}
        src={audioSrc}
        onTimeUpdate={handleTimeUpdate}
        onLoadedData={() => setDuration(audioRef.current.duration)}
        onEnded={handleAudioEnd}
      />
      <div style={{color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center'}}>
          {/* <input
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            onChange={handleSeek}
          /> */}
          <button onClick={handlePlayPause} style={{borderRadius: '50%', border: '1px solid white', padding: '5px 7px', display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: '20px'}}> 
            {isPlaying ? <PauseIcon color="black" /> : <PlayArrowIcon color="black" sx={{fontSize: '20px'}} />}
          </button>
          <Slider value={currentTime} min={0} max={duration} onChange={handleSeek} color="secondary" sx={{width: '100px'}} />
        </div>
        <div>
          <span>{formatTime(currentTime)}</span> / <span>{formatTime(duration)}</span>
        </div>
      </div>
    </div>
  );
};

// Helper function to format time in minutes and seconds
const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export default AudioPlayer;
