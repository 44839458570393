import { Card, Tab, Tabs } from '@mui/material'
import VuiBox from 'components/VuiBox'
import VuiTypography from 'components/VuiTypography'
import React, { useState } from 'react'
import PresenterLogs from './PresenterLogs'
import EditorLogs from './EditorLogs'
import PersonalizationLogs from './PersonalizationLogs'
import BotAILogs from './BotAILogs'
import StreamingLogs from './StreamingLogs'
import { useVisionUIController } from 'context'
import styled, { keyframes } from 'styled-components'

const AllLogs = () => {
  const [tabValue, setTabValue] = useState(0);
  const [controller] = useVisionUIController();
  const { allLogs } = controller;

  const tabHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

  const BlinkingCircle = styled.div`
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 6px;
    animation: ${blink} 2s infinite;
  `;

  return (
    <VuiBox>
      <Card>
        <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px'}}>
          <VuiBox sx={{fontSize: 18, fontWeight: 500, color: 'whitesmoke'}}>Credits Logs</VuiBox>
          <VuiBox sx={{fontSize: 13, color: 'red'}}><BlinkingCircle />Updating live</VuiBox>
        </VuiBox>
        <Tabs value={tabValue} onChange={tabHandler} sx={{overflow: 'scroll'}}>
          <Tab
            style={{minWidth:"25%"}}
            label={
              <VuiTypography color="light" variant="body2">
                Presenter Videos
              </VuiTypography>
            }
          />
          <Tab
            style={{minWidth:"25%"}}
            label={
              <VuiTypography color="light" variant="body2">
                Editor Videos
              </VuiTypography>
            }
          />
          <Tab
            style={{minWidth:"25%"}}
            label={
              <VuiTypography color="light" variant="body2">
                Personalization Projects
              </VuiTypography>
            }
          />
          {/* <Tab
            style={{minWidth:"20%"}}
            label={
              <VuiTypography color="light" variant="body2">
                Bot AI Logs
              </VuiTypography>
            }
          /> */}
          <Tab
            style={{minWidth:"25%"}}
            label={
              <VuiTypography color="light" variant="body2">
                Streaming Logs
              </VuiTypography>
            }
          />
        </Tabs>
        {tabValue === 0 && <PresenterLogs data={allLogs?.createVideo} />}
        {tabValue === 1 && <EditorLogs data={allLogs?.videoEditor}  />}
        {tabValue === 2 && <PersonalizationLogs data={allLogs?.bulkPersonalization}  />}
        {/* {tabValue === 3 && <BotAILogs />} */}
        {tabValue === 3 && <StreamingLogs data={allLogs}  />}
      </Card>
    </VuiBox>
  )
}

export default AllLogs