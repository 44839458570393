// icons
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
// Images

// Vision UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Vision UI Dashboard React icons

// Vision UI Dashboard React example components
import { useEffect, useState } from "react";

// Feed Components
import Builder from "./components/Builder";
import Design from "./components/Design";
import Rules from "./components/Rules";
import Settings from "./components/Settings";
import Responses from "./components/Responses";
import Analytics from "./components/Analytics";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VuiTypography from "components/VuiTypography";

function Feed() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("Builder");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    // <VuiBox position="relative">
    <DashboardLayout>
      <DashboardNavbar />
    <Card
      sx={{
        p: 3,
        minHeight: "660px",
        maxWidth: "100%",
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        // sx={({ breakpoints }) => ({
        //   [breakpoints.up("xs")]: {
        //     gap: "16px",
        //   },
        //   [breakpoints.up("xs")]: {
        //     gap: "0px",
        //   },
        //   [breakpoints.up("xl")]: {
        //     gap: "0px",
        //   },
        // })}
      >
        <Grid item>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{
                background: "transparent",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Tab label={<VuiTypography variant="body2" color="light">Builder</VuiTypography>} value="Builder" />
              <Tab label={<VuiTypography variant="body2" color="light">Design</VuiTypography>} value="Design" />
              <Tab label={<VuiTypography variant="body2" color="light">Rules</VuiTypography>} value="Rules" />
              <Tab label={<VuiTypography variant="body2" color="light">Settings</VuiTypography>}  value="Settings" />
              <Tab label={<VuiTypography variant="body2" color="light">Responses</VuiTypography>} value="Responses" />
              <Tab label={<VuiTypography variant="body2" color="light">Analytics</VuiTypography>} value="Analytics" />
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>

      {tabValue === "Builder" && <Builder />}
      {tabValue === "Design" && <Design />}
      {tabValue === "Rules" && <Rules />}
      {tabValue === "Settings" && <Settings />}
      {tabValue === "Responses" && <Responses />}
      {tabValue === "Analytics" && <Analytics />}
    </Card>
    
    </DashboardLayout>
  );
}

export default Feed;
