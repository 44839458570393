import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Images
// import burceMars from "assets/images/avatar-simmmple.png";
import VuiButton from "components/VuiButton";

function Header(props) {
  const [visible, setVisible] = useState(true);
  //eslint-disable-next-line
  const handleSetVisible = () => setVisible(!visible);

  return (
      <VuiBox sx={{paddingBottom: "10px"}}>
        <Grid container spacing={3} alignItems="center" justifyContent="flex-start">
          <Grid item>
            <VuiButton onClick={props.handleClose} variant="gradient" color="info" fullWidth> ← Back to All Videos</VuiButton>
          </Grid>
          {/* <Grid item >
            <VuiBox height="100%" display="flex" flexDirection="column" mt={0.5} lineHeight={1}>
              <VuiTypography variant="lg" color="white" fontWeight="bold">
                {props.v_data.filename}
              </VuiTypography>
              <VuiTypography variant="button" color="text" fontWeight="regular">
                {props.v_data.created_at}
              </VuiTypography>
            </VuiBox>
          </Grid> */}
        </Grid>
      </VuiBox>
  );
}

export default Header;
