import { Button, Modal, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from "@mui/icons-material/Close";
import VuiBox from 'components/VuiBox';
import VuiInput from 'components/VuiInput';
import templates from './templates';

const WelcomeModal = ({welcomeModal, setWelcomeModal, setCreateVideo}) => {
  const handleClose = () => {
    setWelcomeModal(false);
  };

  const chatTemplate = templates.filter(item => item.id === 'chat');

  return (
    <div>
        <Modal open={welcomeModal} onClose={handleClose}>
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 100%, rgba(10, 14, 35, 0.49) 76.65%)",
            color: "#090d2c",
            outline: "none",
            padding: "25px",
            // textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            minHeight: "55vh",
            minWidth: "55vw",
            boxShadow: "rgb(54, 68, 97) 0px 0px 10px 2px",
            borderRadius: '5px'
          }}>
            <div style={{position: 'absolute', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px', top: 10, right: 10}}>
              <CloseIcon onClick={handleClose} sx={{ color: "whitesmoke", cursor: 'pointer' }} />
            </div>
            <Typography variant="h5" textAlign='left' sx={{color: 'whitesmoke'}}>Create AI Video with ChatGPT: Try It Free!</Typography>
            <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '25px'}}>
              <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <Typography variant="h6" textAlign='left' sx={{color: 'whitesmoke', marginBottom: '10px'}}>1. Write a topic or question</Typography>
                <VuiInput placeholder='write a topic or question that you want to create for your video' />
                <VuiBox sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px'}}>
                  <Button variant='contained' sx={{color: 'whitesmoke'}}>5 ways to improve your B2B sales</Button>
                  <Button variant='contained' sx={{color: 'whitesmoke'}}>How to master in presentation ?</Button>
                  <Button variant='contained' sx={{color: 'whitesmoke'}}>Improve your efficiency in marketing</Button>
                </VuiBox>
              </VuiBox>
              <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '25px'}}>
                <Typography variant="h6" textAlign='left' sx={{color: 'whitesmoke', marginBottom: '10px'}}>2. Select template</Typography>
                <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: '10px', maxWidth: '55vw', overflowX: 'auto', padding: '0 20px'}}>
                  {
                    chatTemplate.map((item, index) => (
                      <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center', marginBottom: '15px'}} key={index}>
                        <Button sx={{padding: '10px'}}>
                          <img src={item.src} alt="icons" width={200} height={110} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                        </Button>
                        <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px', fontSize: '12px'}}>{item.title}</Typography>
                      </VuiBox>
                    ))
                  }
                </VuiBox>
              </VuiBox>
            </VuiBox>
            <Button variant='contained' sx={{color: 'whitesmoke', width: '200px', marginLeft: 'auto', marginRight: 'auto', marginTop: '25px'}} onClick={() => {setCreateVideo(true); setWelcomeModal(false)}}>Create your AI Video</Button>
          </div>
        </Modal>
      </div>
  )
}

export default WelcomeModal