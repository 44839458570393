/*eslint-disable no-unused-vars */
import { Typography } from "@mui/material";
import VuiDropzone from "components/VuiDropzone";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { AttachMoney, AutoFixHigh, Check, Computer, ExitToApp, FileUpload, Headphones, LaptopMac, Mail, MonetizationOn, People, Person, Quiz, Restore, Sell, Shop, Shop2, ShoppingBag, Star, YouTube } from "@mui/icons-material";
import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import VuiBox from "components/VuiBox";
import { IoShirt } from "react-icons/io5";
import { Box3 } from "three";
import { MdAdsClick } from "react-icons/md";

function Saas(){
    return(
        <Grid sx={{ display: 'flex' }} style={{marginTop:"25px"}} >
        <Grid style={{width:"30%",marginRight:"5px"}} sx={{ display: 'flex', flexDirection: 'column'}} item xs={6} md={5} lg={6} xl={5}>
          <VuiBox style={{width:"10"}} mb ={3}>
        <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Prospecting", fontWeight: "regular" }}
                    
                    icon={<MdAdsClick color="white" size="22px" />}
                  />
            </VuiBox>
            <VuiBox mb ={3}>
        <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Lead Generation", fontWeight: "regular" }}
                    
                    icon={<MonetizationOn color="white" size="22px" />}
                  />
            </VuiBox>
            <VuiBox mb ={3}>
        <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Pre-meeting Discovery", fontWeight: "regular" }}
                    
                    icon={<LaptopMac color="white" size="22px" />}
                  />
            </VuiBox>
  
        </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'column'}} style={{width:"30%",marginRight:"5px"}} item xs={6} md={5} lg={6} xl={5}>
          <VuiBox mb ={3}>
          <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Product Pitch", fontWeight: "regular" }}
                    
                    icon={<AutoFixHigh color="white" size="22px" />}
                  />
            </VuiBox>
            <VuiBox mb ={3}>
          <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Post Meeting follow up", fontWeight: "regular" }}
                    
                    icon={<Restore color="white" size="22px" />}
                  />
            </VuiBox>
            
  
        </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'column'}} style={{width:"30%",marginRight:"5px"}} item xs={6} md={5} lg={6} xl={5}>
          <VuiBox mb ={3}>
          <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Personalized Outreach Blast", fontWeight: "regular" }}
                    
                    icon={<Sell color="white" size="22px" />}
                  />
            </VuiBox>
            <VuiBox mb ={3}>
          <MiniStatisticsCard 
                    style={{width:"100"}}
                    bgColor="info"
                    title={{ text: "Personal Introduction", fontWeight: "regular" }}
                    
                    icon={<Person color="white" size="22px" />}
                  />
            </VuiBox>
           


  
        </Grid>
      </Grid>
      
    );
}

export default Saas;