import VuiBox from 'components/VuiBox'
import VuiTypography from 'components/VuiTypography'
import React from 'react'

const Preview = () => {
  return (
    <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 'auto', marginBottom: 'auto', flexDirection: 'column'}}>
      <VuiTypography>Your Personalized AI Videos will be generated soon.</VuiTypography>
      <VuiTypography>You will get notified once it is generated.</VuiTypography>
    </VuiBox>
  )
}

export default Preview