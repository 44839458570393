import { Button, Card, CircularProgress } from '@mui/material'
import axios from 'axios'
import VuiBox from 'components/VuiBox'
import VuiInput from 'components/VuiInput'
import VuiTypography from 'components/VuiTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import BASE_URL from 'context/api';
import { useVisionUIController } from "context";
import Avatar from './components/Avatar'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { makeStyles } from '@mui/styles'
import Questions from './components/Questions'
import Script from './components/Script'
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import { NavLink, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useSnackbar } from "notistack";
import { connect } from 'video-streaming/streaming-client-api'
import { setWidget } from 'context'
import { setBotAiAvatar } from 'context'
import { destroy } from 'video-streaming/streaming-client-api'
import { setSessionActive } from 'context'
import { setBotDetails } from 'context'
import { setAgentsCreated } from 'context'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import mixpanel from 'mixpanel-browser'

const useStyles = makeStyles(theme => ({
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));

const AiWidget = () => {
  const location = useLocation();
  const { selectedAgent } = location.state || {};
  const [controller, dispatch] = useVisionUIController();
  const { userDetails, widget, agentsCreated } = controller;
  const {user_id, plan} = userDetails;
  const [presenters, setPresenters] = useState([]);
  const [UIIndex, setUIIndex] = useState(0);
  const [voices, setVoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [widgetDetails, setWidgetDetails] = useState({
    selectedAvatar: '',
    selectedAvatarPreview: '',
    presenterId: '',
    // domains: [],
    wMessage: '',
    avatarName: '',
    companyName: '',
    knowledgeBase: [],
    knowledgeName: '',
    knowledgeDescription: '',
    selectedLanguage: '',
    selectedVoice: '',
    template: 'rag-grounded',
  })
  const [agentId, setAgentId] = useState('');
  const classes = useStyles();
  const totalUIIndexes = 3;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  let updatedDocsUrl = selectedAgent && selectedAgent.uploaded_docs_url;
  // updatedDocsUrl = updatedDocsUrl.slice(1, -1).split(',');
  updatedDocsUrl = updatedDocsUrl && updatedDocsUrl.map(url => url.trim().replace(/^['"]|['"]$/g, ''));
  const fileNames = updatedDocsUrl && updatedDocsUrl.map(url => {
    const parts = url.split('/');
    return parts[parts.length - 1];
  });

  useEffect(() => {
    selectedAgent && setWidgetDetails((prev) => ({
      ...prev, 
      selectedAvatar: selectedAgent.selected_avatar_url,
      selectedAvatarPreview: selectedAgent.selected_avatar_preview_url,
      presenterId: selectedAgent.presenter_id,
      wMessage: selectedAgent.welcome_message,
      avatarName: selectedAgent.avatar_name,
      companyName: selectedAgent.company_name,
      knowledgeBase: fileNames,
      knowledgeName: selectedAgent.knowledge_name,
      knowledgeDescription: selectedAgent.knowledge_description,
      selectedVoice: selectedAgent.selected_voice,
      template: selectedAgent.template,
    }));
    selectedAgent && setAgentId(selectedAgent.agent_id);
  }, []);

  const fetchPresenters = async () => {
    await axios.get(`${BASE_URL}/presenters`, {
      params: {
        user_id,
      },
    })
    .then(response => {
      const newAvatars = response.data.presenters;
      setPresenters(newAvatars);
    })
    .catch(error => {
      console.error(error);
    });
  }

  const fetchVoices = async () => {
    const apiKey = '39ac8d95df8d5d45639e30ca0cbcdfbb';
    const url = 'https://api.elevenlabs.io/v1/voices';

    const headers = {
      Accept: 'application/json',
      'xi-api-key': apiKey,
    };

    try {
      const response = await axios.get(url, { headers });
      const premadeVoices = response.data.voices.filter(option => option.category === 'premade');
      const options = [
        ...premadeVoices.map(option => ({ value: option.voice_id, label: option.name, audioUrl: option.preview_url })),
      ];
      setVoices(options);
    } catch (error) {
      console.error('Error fetching available voices:', error);
      return [];
    }
  }

  useEffect(() => {
    fetchPresenters();
    fetchVoices();
    //eslint-disable-next-line
  }, []);

  function UIRender() {
    switch(UIIndex) {
      case 0:
        return <Avatar presenters={presenters} setWidgetDetails={setWidgetDetails} widgetDetails={widgetDetails} voices={voices} selectedAgent={selectedAgent} />;
      case 1:
        return <Questions widgetDetails={widgetDetails} setWidgetDetails={setWidgetDetails} selectedAgent={selectedAgent} />;
      case 2:
        return <Script widgetDetails={widgetDetails} agentId={agentId} loading={loading} />
      default:
        return <Avatar presenters={presenters} setWidgetDetails={setWidgetDetails} widgetDetails={widgetDetails} selectedAgent={selectedAgent} />;
    }
  }

  const createKnowledge = async () => {
    setLoading(true);
    const data = {
      widgetDetails,
    };
    
    const headers = {
      accept: 'application/json',
      'content-type': 'multipart/form-data',
      'Authorization': `Bearer ${user_id}`,
    };
    
    const options = {
      method: 'POST',
      url: `${BASE_URL}/v1/chatbot`,
      headers: headers,
      data: data
    };
    
    axios(options)
      .then(response => {
        setAgentId(response.data);
        setLoading(false);
        enqueueSnackbar('Chatbot generated successfully.', { 
          variant: "success",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar('Something went wrong.', { 
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setLoading(false);
      });
  }

  const showScript = async () => {
    await createKnowledge();
    setUIIndex(prev => Math.min(prev + 1, totalUIIndexes));
    mixpanel.track('New Bot script created', {
      'User ID': user_id,
    });
  }

  const getChatbotInfo = async () => {
    setAgentsCreated(dispatch, []);
    const headers = {
      accept: 'application/json',
      'content-type': 'multipart/form-data',
      'Authorization': `Bearer ${userDetails.user_id}`,
    };
    
    const options = {
      method: 'GET',
      url: `${BASE_URL}/v1/chatbot_info`,
      headers: headers,
    };
    
    await axios(options)
      .then(response => {
        setAgentsCreated(dispatch, response.data.widgetDetails);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const handleAgentDelete = async () => {
    setLoading(true);
    const data = {
      agent_id: selectedAgent.agent_id,
      knowledgeId: selectedAgent.knowledge_id
    };
    
    const headers = {
      accept: 'application/json',
      'Authorization': `Bearer ${user_id}`,
    };
    
    const options = {
      method: 'DELETE',
      url: `${BASE_URL}/v1/chatbot_destroy`,
      headers: headers,
      data: data
    };
    
    axios(options)
      .then(response => {
        setLoading(false);
        enqueueSnackbar(response.data, { 
          variant: "success",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setWidget(dispatch, false);
        getChatbotInfo();
        history.push('/botai');
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  const handleBotTest = async () => {
    setLoading(true);
    setBotAiAvatar(dispatch, selectedAgent.selected_avatar_preview_url);
    setBotDetails(dispatch, {
      name: selectedAgent.avatar_name,
      companyName: selectedAgent.company_name,
      wMessage: selectedAgent.welcome_message
    });
    enqueueSnackbar('Stream is getting ready in few seconds', { 
      variant: "info",
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
    await connect(selectedAgent.presenter_id, user_id, 'clips', true, selectedAgent.agent_id);
    enqueueSnackbar('You can now check the bot', { 
      variant: "success",
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
    setLoading(false);
    setWidget(dispatch, true);
    setSessionActive(dispatch, true);
  }

  const handleNewBot = async () => {
    setLoading(true);
    setBotAiAvatar(dispatch, widgetDetails.selectedAvatarPreview);
    setBotDetails(dispatch, {
      name: widgetDetails.avatarName,
      companyName: widgetDetails.companyName,
      wMessage: widgetDetails.wMessage
    });
    enqueueSnackbar('Stream is getting ready in few seconds', { 
      variant: "info",
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
    await connect(widgetDetails.presenterId, user_id, 'clips', true, agentId);
    enqueueSnackbar('You can now check the bot', { 
      variant: "success",
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
    setLoading(false);
    setWidget(dispatch, true);
    setSessionActive(dispatch, true);
  }

  const handleDestroy = async () => {
    await destroy(user_id);
    setWidget(dispatch, false);
    setSessionActive(dispatch, false);
    setBotDetails(dispatch, {
      name: '',
      companyName: '',
      wMessage: ''
    });
    await getChatbotInfo();
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    return '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox>
        <NavLink to='/botai'>
          <ArrowBackIcon size={20} color='white' sx={{cursor: 'pointer', marginLeft: '10px', marginTop: '10px'}} onClick={handleDestroy} />
        </NavLink>
        <VuiTypography color='white' textAlign='center' variant='h1'>
          AI Widget
        </VuiTypography>
        <VuiTypography color='white' textAlign='center'>
          Give your website a better interactive Widget
        </VuiTypography>
        <VuiBox sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginTop: '15px'}}>
          <Card sx={{width: '29%', height: '510px', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
            <VuiBox sx={{background: 'gray', width: '80px', height: '80px', borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
              <video autoPlay muted width='100%' height='100%' loop style={{borderRadius: '100%'}} key={widgetDetails.selectedAvatarPreview}>
                <source src={widgetDetails.selectedAvatarPreview} type="video/mp4" />
              </video>
            </VuiBox>
            <VuiBox sx={{position: 'absolute', height: '350px', width: '250px', background: 'rgb(8, 8, 54)', border: '1px solid skyblue', borderRadius: '10px', bottom: '95px', right: '60px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', padding: '7px'}}>
              <VuiBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '5px'}}>
                <VuiTypography variant='h6' color='white'>
                  {widgetDetails.avatarName ? widgetDetails.avatarName : '_____' }
                  {' '}from{' '} 
                  {widgetDetails.companyName ? widgetDetails.companyName : '_____' }
                </VuiTypography>
                <VuiBox sx={{background: 'gray', width: '120px', height: '120px', borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5px'}}>
                  <video autoPlay muted width='100%' height='100%' loop style={{borderRadius: '100%'}} key={widgetDetails.selectedAvatarPreview}>
                    <source src={widgetDetails.selectedAvatarPreview} type="video/mp4" />
                  </video>
                </VuiBox>
              </VuiBox>
              {widgetDetails.wMessage && 
                <VuiBox sx={{height: '200px', display: 'flex', alignItems: 'end', justifyContent: 'flex-end', padding: '10px'}}>
                  <VuiBox sx={{backgroundColor: "#0175FF", textAlign: "left", border: "1px solid #0175FF", borderRadius: "5px", marginRight: 'auto', padding: '3px 7px', maxWidth: '70%'}}>
                    <VuiTypography color='white' variant='h6' sx={{fontSize: '14px'}}>{widgetDetails.wMessage}</VuiTypography>
                  </VuiBox>
                </VuiBox>
              }
              <div style={{display:'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '7px'}}>
                <VuiInput sx={{width: window.innerWidth < 768 ? 'auto !important' : '130px !important'}} placeholder="Type your message here..." disabled />
                <SendIcon size={16} color="white" style={{margin: '0px 5px'}} />
                <Button variant='contained' sx={{margin: window.innerWidth < 768 ? 'auto' : '0 1px', padding: '0px'}}>
                  <MicIcon size={16} color='white' /> 
                </Button>
              </div>
            </VuiBox>
          </Card>
          <Card sx={{width: '69%', height: '510px', display: 'flex', justifyContent: 'space-between', position: 'relative'}}>
            {agentId !== '' && <Button variant='contained' sx={{position: "absolute", top: '3%', right: '7%', color: 'whitesmoke', zIndex: 5}} onClick={selectedAgent ? handleBotTest : handleNewBot} disabled={loading}>{loading ? <CircularProgress color='white' size={20} /> : 'Show bot'}</Button>}
            {UIRender()}
            <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Button
                color='white' 
                fontSize='medium' 
                variant='outlined'
                sx={{cursor: 'pointer', margin: '5px'}} 
                onClick={() => setUIIndex(prev => agentId ? Math.max(prev - 2, 0) : Math.max(prev - 1, 0))}
                className={UIIndex === 0 ? classes.disabled : ''}
              >prev</Button>
              {UIIndex + 1 === 2 ? 
                !selectedAgent && <Button variant='outlined' sx={{marginLeft: '10px', '&:disabled': {background: 'gray', color: 'whitesmoke'}}} onClick={showScript} disabled={widgetDetails.knowledgeBase.length < 1 || widgetDetails.knowledgeName === '' || widgetDetails.knowledgeDescription === ''}>Create Script</Button> 
                : 
                UIIndex + 1 !== totalUIIndexes && <Button
                color='white'
                fontSize='medium'
                variant='outlined'
                sx={{cursor: 'pointer', margin: '5px', '&:disabled': {background: 'gray', color: 'whitesmoke'}}}
                onClick={() => setUIIndex(prev => agentId ? Math.min(prev + 2, totalUIIndexes) : Math.min(prev + 1, totalUIIndexes))}
                disabled={widgetDetails.selectedAvatar === '' || widgetDetails.selectedAvatarPreview === '' || widgetDetails.presenterId === '' || widgetDetails.wMessage === '' || widgetDetails.avatarName === '' || widgetDetails.companyName === '' || widgetDetails.selectedVoice === ''}
              >
              next</Button>}
              {selectedAgent && <Button variant='contained' sx={{position: 'absolute', top: '3%', left: '7%', color: 'whitesmoke', background: 'red'}} onClick={handleAgentDelete} disabled={loading}>{loading ? <CircularProgress color='white' size={20} /> : 'Delete Bot'}</Button>}
            </VuiBox>
          </Card>
        </VuiBox>
        {UIIndex + 1 === totalUIIndexes && <VuiBox sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', marginTop: '15px', }}>
          <VuiTypography variant='h2' color='white'>Instructions</VuiTypography>
          <Card sx={{display: 'flex', flexDirection: 'column', padding: '20px 40px', color: 'whitesmoke', fontSize: '16px', marginTop: '10px'}}>
            <p>1. Copy and put the above script in body tag of your root file in your project (like index.html).</p>
            <p>2. Make sure to replace the api key in above script given. You can get your api key from <a href='https://app.quickvideo.ai/apidetails' style={{color: 'skyblue'}}>here</a>.</p>
            <p>3. If widget or chat window doesn't seem similar as shown, please check your project css, it must be overriding or contact us at <span style={{fontWeight: 600, marginLeft: '5px'}}>help@quickvideo.ai</span></p>
          </Card>
        </VuiBox>}
      </VuiBox>
    </DashboardLayout>
  )
}

export default AiWidget