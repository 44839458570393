/*eslint-disable no-unused-vars */

// @mui material components
import Grid from "@mui/material/Grid";
import { useState } from "react";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import Plans from "./components/Plans";

// Settings page components
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import Header from "layouts/pages/account/settings/components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
import Authentication from "layouts/pages/account/settings/components/Authentication";
import Accounts from "layouts/pages/account/settings/components/Accounts";
import Sessions from "layouts/pages/account/settings/components/Sessions";
import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";

import ProfileSettings from "./components/ProfileSettings";
import Notifications from "./components/Notifications";
import WorkspaceSettings from "./components/WorkspaceSettings";
import TeamSettings from "./components/TeamSettings";
import BrandingSettings from "./components/BrandingSettings";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function Settings(props) {
  // console.log(props.location.search.replace("?activePane=", ""));
  let queryPane = props.location.search.replace("?activePane=", ""); 
  const [currentPane, setCurrentPane] = useState(queryPane ? queryPane : "profile");
  console.log(currentPane);
  
  const curUserData = {
    avatar_url: "https://bit.ly/31BDLda",
    u_name: "Jaynam Modi",
    user_id: "@jaynammodi",
    user_email: "jaynam@kwiqreply.io",
    user_phone: "+91 9876543210",
    user_api_key: "123IDAFGIAG",
    user_api_secret: "123IDAFGIAG-sjdfrvguiasrhoige",
  };

  const curWorkspaceData = {
    avatar_url: "https://bit.ly/31BDLda",
    company_name: "QuickVideo",
    company_url: "https://quickvideo.ai",
    num_employees: "1-10",
    company_vertical: "ecommerce",
    company_use_case: "video_marketing",
  };

  const TeamData = [
      {
        name: "Alexa Liras",
        email: "alexa@quickvideo.ai",
        role: "Admin",
        status: true,
        added: "23/04/18",
      },
      {
        name: "Esthera Jackson",
        email: "esthera@quickvideo.ai",
        role: "Admin",
        status: false,
        added: "23/04/18",
      },
    ];

  function changeSettingsPane (pane) {
    console.log(pane);
    setCurrentPane(pane);
  }

  function renderCurrentState() {
    switch (currentPane) {
      case "profile":
        return <ProfileSettings user_data={curUserData}/>;
      case "notifications":
        return <Notifications />;
      case "workspace":
        return <WorkspaceSettings company_data={curWorkspaceData} />;
      case "team":
        return <TeamSettings teamData={TeamData}/>;
      case "branding":
        return <BrandingSettings user_data={curUserData}/>;
      // case "plans":
      //   return <Plans />;
      default:
        return <BasicInfo />;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mt={4}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12} lg={3}>
            <Sidenav handleSettingsState={changeSettingsPane} currentPane={currentPane}/>
          </Grid>
          <Grid item xs={12} lg={9}>
            <VuiBox mb={3}>
              {renderCurrentState()}
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Settings;
