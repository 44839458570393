import { CircularProgress } from '@mui/material'
import VuiBox from 'components/VuiBox'
import VuiButton from 'components/VuiButton'
import VuiTypography from 'components/VuiTypography'
import React from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

const SamplePreview = ({sampleVideoPreview, setSampleVideoPreview, loading, discardPreviewVideo, handleSelectPreviewVideo, selectVideoPreview}) => {
  return (
    <VuiBox>
    {loading ? 
      <VuiBox
        bgColor="white"
        borderRadius="50%"
        width="200px"
        height="200px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        mx="auto"
      >
        <CircularProgress size={30} color="black" />
      </VuiBox>
      : 
      <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        {sampleVideoPreview !== null ?
        <>
          <video controls style={{ width: "50vw", height: "50vh", marginTop: '20px' }}>
            <source src={sampleVideoPreview} type="video/mp4" />
          </video>
          {/* <VuiTypography color='white' variant='h5' mt='10px' mb='10px'>The sample video is generated. Are you happy with the output ?</VuiTypography>
          <VuiBox sx={{display: 'flex', alignItems: 'center'}}>
            <VuiButton onClick={discardPreviewVideo} disabled={selectVideoPreview} sx={{opacity: selectVideoPreview && 0.5}}><ClearIcon sx={{margin: '0 5px 0 0'}} color='error' /> No, I need changes</VuiButton>
            <VuiButton disabled={sampleVideoPreview === null } onClick={handleSelectPreviewVideo} sx={{marginLeft: '15px', opacity: sampleVideoPreview === null && 0.5}}><CheckIcon sx={{margin: '0 5px 0 0'}} color='success' /> Yes, It's perfect</VuiButton>
          </VuiBox> */}
        </>
        :
        <>
          <VuiTypography color='white' variant='h5'>No sample video generated.</VuiTypography>
          <VuiTypography color='white' variant='h5'>Something went wrong or celebrity detected in uploaded video.</VuiTypography>
        </>}
      </VuiBox>
    }
    </VuiBox>
  )
}

export default SamplePreview

// {projectDetails ? <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
//         <video controls style={{ width: "50%", height: "50%", marginTop: '20px' }}>
//           <source src={projectDetails} type="video/mp4" />
//         </video></VuiBox> : <VuiTypography>No videos created</VuiTypography>}