/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard components
import GradientBorder from "examples/GradientBorder";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Images
import bgBasic from "assets/images/background-basic-auth.png";

function SignIn() {
  // const [name, setName] = useState("");
  // function handleNameChange(event) {
  //   setName(event.target.value);
  //   console.log(name);
  // }
  // const [email, setEmail] = useState("");
  // function handleEmailChange(event) {
  //   setEmail(event.target.value);
  // }
  // const [password, setPassword] = useState("");
  // function handlePasswordChange(event) {
  //   setPassword(event.target.value);
  // }

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  function handleNameChange(event) {
    const value = event.target.value;
    setName(value);
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!nameRegex.test(value)) {
      setNameError("Invalid! please enter a valid name.");
    } else {
      setNameError("");
    }
  }

  function handleEmailChange(event) {
    const value = event.target.value;
    setEmail(value);
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(email)) {
      // regex to test if input contains only letters
      setEmailError("Invalid! please entry a valid email.");
    } else {
      setEmailError("");
    }
  }
  function handlePasswordChange(event) {
    const value = event.target.value;
    setPassword(value);
    const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    if (!passwordRegex.test(password)) {
      // regex to test if input contains only letters
      setPasswordError("Please enter Valid password.");
    } else {
      setPasswordError("");
    }
  }
  //eslint-disable-next-line
  function validateInputs() {
    console.log("In validate function");
    const emailRegex = /^\S+@\S+\.\S+$/;
    const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    console.log(name, email, password);

    let errors = {};
    if (!name) {
      alert("No username");
    } else if (!emailRegex.test(email)) {
      alert("Name format is invalid");
    } else {
      alert("Your Name is amazing");
    }
    if (!email) {
      alert("Email is required");
    } else if (!emailRegex.test(email)) {
      alert("Email is format required");
    } else {
      alert("Amazing Email");
    }
    if (!password) {
      alert("Password is required");
    } else if (!passwordRegex.test(password)) {
      alert("password format incorrect");
    } else {
      alert("saved your password, Mate");
    }
    console.log("you data is gone");
    return errors;
  }

  return (
    <BasicLayout
      title="Welcome!"
      description="Use these awesome forms to login or create new account in your project for free."
      image={bgBasic}
    >
      <GradientBorder
        borderRadius={borders.borderRadius.form}
        minWidth="100%"
        maxWidth="100%"
      >
        <VuiBox
          component="form"
          role="form"
          borderRadius="inherit"
          p="45px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.main,
          })}
        >
          <VuiTypography
            color="white"
            fontWeight="bold"
            textAlign="center"
            mb="24px"
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            Register with
          </VuiTypography>
          <Stack
            mb="25px"
            justifyContent="center"
            alignItems="center"
            direction="row"
            spacing={2}
          >
            <GradientBorder borderRadius="xl">
              {/* eslint-disable-next-line */}
              <a href="#">
                <IconButton
                  transition="all .25s ease"
                  justify="center"
                  align="center"
                  bg="rgb(19,21,54)"
                  borderRadius="15px"
                  sx={({
                    palette: { secondary },
                    borders: { borderRadius },
                  }) => ({
                    borderRadius: borderRadius.xl,
                    padding: "25px",
                    backgroundColor: secondary.focus,
                    "&:hover": {
                      backgroundColor: rgba(secondary.focus, 0.9),
                    },
                  })}
                >
                  <Icon
                    as={FaFacebook}
                    w="30px"
                    h="30px"
                    sx={({ palette: { white } }) => ({
                      color: white.focus,
                    })}
                  />
                </IconButton>
              </a>
            </GradientBorder>
            <GradientBorder borderRadius="xl">
              {/* eslint-disable-next-line */}
              <a href="#">
                <IconButton
                  transition="all .25s ease"
                  justify="center"
                  align="center"
                  bg="rgb(19,21,54)"
                  borderRadius="15px"
                  sx={({
                    palette: { secondary },
                    borders: { borderRadius },
                  }) => ({
                    borderRadius: borderRadius.xl,
                    padding: "25px",
                    backgroundColor: secondary.focus,
                    "&:hover": {
                      backgroundColor: rgba(secondary.focus, 0.9),
                    },
                  })}
                >
                  <Icon
                    as={FaApple}
                    w="30px"
                    h="30px"
                    sx={({ palette: { white } }) => ({
                      color: white.focus,
                    })}
                  />
                </IconButton>
              </a>
            </GradientBorder>
            <GradientBorder borderRadius="xl">
              {/* eslint-disable-next-line */}
              <a href="#">
                <IconButton
                  transition="all .25s ease"
                  justify="center"
                  align="center"
                  bg="rgb(19,21,54)"
                  borderRadius="15px"
                  sx={({
                    palette: { secondary },
                    borders: { borderRadius },
                  }) => ({
                    borderRadius: borderRadius.xl,
                    padding: "25px",
                    backgroundColor: secondary.focus,
                    "&:hover": {
                      backgroundColor: rgba(secondary.focus, 0.9),
                    },
                  })}
                >
                  <Icon
                    as={FaGoogle}
                    w="30px"
                    h="30px"
                    sx={({ palette: { white } }) => ({
                      color: white.focus,
                    })}
                  />
                </IconButton>
              </a>
            </GradientBorder>
          </Stack>
          <VuiTypography
            color="text"
            fontWeight="bold"
            textAlign="center"
            mb="14px"
            sx={({ typography: { size } }) => ({ fontSize: size.lg })}
          >
            or
          </VuiTypography>

          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography
                component="label"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                Name
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                type="text"
                placeholder="Your name..."
                value={name}
                onChange={handleNameChange}
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                success={!nameError && name}
                error={nameError}
              />
            </GradientBorder>
            {nameError && (
              <VuiTypography variant="body2" color="error" className="error">
                {nameError}
              </VuiTypography>
            )}
          </VuiBox>

          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography
                component="label"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                Email
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              padding="1px"
              borderRadius={borders.borderRadius.lg}
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                type="email"
                placeholder="Your email..."
                value={email}
                onChange={handleEmailChange}
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                success={!emailError && email}
                error={emailError}
              />
            </GradientBorder>
            {emailError && (
              <VuiTypography variant="body2" color="error" className="error">
                {emailError}
              </VuiTypography>
            )}
          </VuiBox>

          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography
                component="label"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                Password
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                type="password"
                placeholder="Your password..."
                value={password}
                onChange={handlePasswordChange}
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                success={!passwordError && password}
                error={passwordError}
              />
            </GradientBorder>
            {passwordError && (
              <VuiTypography variant="body2" color="error" className="error">
                {passwordError}
              </VuiTypography>
            )}
          </VuiBox>

          <VuiBox mt={4} mb={1}>
            <VuiTypography
              component={Link}
              to="/authentication/sign-in/basic"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              <VuiButton color="info" fullWidth>
                SIGN UP
              </VuiButton>
            </VuiTypography>
          </VuiBox>
          <VuiBox mt={3} textAlign="center">
            <VuiTypography variant="button" color="text" fontWeight="regular">
              Already have an account?{" "}
              <VuiTypography
                component={Link}
                to="/authentication/sign-in/basic"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                Sign in
              </VuiTypography>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </GradientBorder>
    </BasicLayout>
  );
}
//eslint-disable-next-line
function handleSubmit(username, email, password) {
  // submit handling
}

export default SignIn;
