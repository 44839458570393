import React, {  useState } from 'react';
import QuickVideoIcon from "./QuickVideoIcon.png";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import BASE_URL from 'context/api';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import GradientBorder from 'examples/GradientBorder';
import VuiInput from 'components/VuiInput';
import radialGradient from 'assets/theme/functions/radialGradient';
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import { Icon } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import VuiButton from 'components/VuiButton';
import { useSnackbar } from "notistack";

function ForgotPassword(props) {
  const history = useHistory();
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [error, setError] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  
  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  function handleNewPasswordChange(event) {
    const value = event.target.value;
    setPassword(value);
    const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    if (!passwordRegex.test(password)) {
      setNewPasswordError("Please enter valid password.");
    } else {
      setNewPasswordError("");
    }
  }
  
  function handleConfirmPasswordChange(event) {
    const value = event.target.value;
    setConfirmPassword(value);
    const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    if (!passwordRegex.test(confirmPassword)) { 
      setConfirmPasswordError("Please enter valid password.");
    } else {
      setConfirmPasswordError("");
    }
    
  }

  const handleResetPassword = () => {
    if(password !== confirmPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
      axios.post(`${BASE_URL}/reset_password/${token}`, {
        new_password: confirmPassword,
        token,
      })
      .then((response) => {
        console.log(response);
        enqueueSnackbar(response.data, {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        setTimeout(() => {
          history.push('/signin');
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.error);
        enqueueSnackbar(err.response.data.error, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        // setMessage(error);
      });
    }
  }

  return (
    <div style={{display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'white', paddingTop: "100px", paddingBottom: "100px"}}>
      <img src={QuickVideoIcon} height="60px" alt="Icon" />
      <GradientBorder
        borderRadius={borders.borderRadius.form}
        minWidth="100%"
        maxWidth="100%"
        style={{marginTop: '100px'}}
      >
        <VuiBox component="form" role="form" borderRadius="inherit"
          p="30px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.focus,
          })}
        >
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                New password
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                icon={{
                    component: (
                      <Icon onClick={handleToggleNewPasswordVisibility} sx={{cursor: "pointer"}}>
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </Icon>
                    ),
                    direction: "right",
                  }}
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Enter your new password"
                  // type="password"
                  value={password}
                  onChange = {handleNewPasswordChange}
                  sx={({ typography: { size } }) => ({
                    fontSize: size.sm, color: 'whitesmoke !important'
                  })}
                  success={!newPasswordError && password}
                  error={newPasswordError || passwordMatch}
                />
              </GradientBorder>
              {newPasswordError && <VuiTypography sx={{marginLeft: '10px', marginTop: '5px'}} variant="body2" color='error' className="error">{newPasswordError}</VuiTypography>}
          </VuiBox>
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                Confirm password
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                icon={{
                    component: (
                      <Icon onClick={handleToggleConfirmPasswordVisibility} sx={{cursor: "pointer"}}>
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </Icon>
                    ),
                    direction: "right",
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm your password"
                  // type="password"
                  value={confirmPassword}
                  onChange = {handleConfirmPasswordChange}
                  sx={({ typography: { size } }) => ({
                    fontSize: size.sm, color: 'whitesmoke !important'
                  })}
                  success={!confirmPasswordError && confirmPassword}
                  error={confirmPasswordError || passwordMatch}
                />
              </GradientBorder>
              {confirmPasswordError && <VuiTypography sx={{marginLeft: '10px', marginTop: '5px'}} variant="body2" color='error' className="error">{confirmPasswordError}</VuiTypography>}
              {error && <VuiTypography sx={{marginLeft: '10px', marginTop: '5px'}} variant="body2" color='error' className="error">{error}</VuiTypography>}
              {passwordMatch && <VuiTypography variant="button" color="text" fontWeight="regular">Typed passwords are not same.</VuiTypography>}
          </VuiBox>
          <VuiBox mt={4} mb={1}>
            <VuiTypography
              // component={Link}
              // to="/authentication/First/basic"
              variant="button"
              color="white"
              fontWeight="medium"
              onClick={handleResetPassword}
            >
              <VuiButton 
                color="info" 
                fullWidth
                // disabled={signinLoading}
              >
                {/* {signinLoading ? <CircularProgress size={24} color="white" /> : "SIGN IN"} */}
                Reset
              </VuiButton>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </GradientBorder>
    </div>
  )
}

export default ForgotPassword;