/*eslint-disable */

/* eslint-disable react/prop-types */
import VuiTypography from "components/VuiTypography/index";

// ProductsList page components
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/orders/order-list/components/CustomerCell";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import ivana from "assets/images/ivana-squares.jpg";

export default {
  columns: [
    
    { Header: "ID", accessor: "id", Cell: ({ value }) => <IdCell id={value} /> },
    { Header: "URL", accessor: "revenue", Cell: ({ value }) => <DefaultCell value={value} /> },
    {
      Header: "Title",
      accessor: "customer",
      Cell: ({ value: [name, data] }) => (
        <CustomerCell color={data.color || "dark"} name={name} />
      ),
    },
    {
      Header: "Time Stamp",
      accessor: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    // {
    //   Header: "STATUS",
    //   accessor: "status",
    //   Cell: ({ value }) => {
    //     let status;

    //     if (value === "paid") {
    //       status = <StatusCell icon="done" color="success" status="Paid" />;
    //     } else if (value === "refunded") {
    //       status = <StatusCell icon="replay" color="dark" status="Refunded" />;
    //     } else {
    //       status = <StatusCell icon="close" color="error" status="Canceled" />;
    //     }

    //     return status;
    //   },
    // },
    // {
    //   Header: "CUSTOMER",
    //   accessor: "customer",
    //   Cell: ({ value: [name, data] }) => (
    //     <CustomerCell image={data.image} color={data.color || "dark"} name={name} />
    //   ),
    // },
    // {
    //   Header: "PRODUCT",
    //   accessor: "product",
    //   Cell: ({ value }) => {
    //     const [name, data] = value;

    //     return (
    //       <DefaultCell
    //         value={typeof value === "string" ? value : name}
    //         suffix={data.suffix || false}
    //       />
    //     );
    //   },
    // },
    // { Header: "REVENUE", accessor: "revenue", Cell: ({ value }) => <DefaultCell value={value} /> },
  ],

  rows: [
    {
      id: "#10421",
      date: "1 Nov, 10:20 AM",
      status: "paid",
      customer: ["Text to Video", { image: team2 }],
      product: "Nike Sport V2",
      revenue: "quickvideo.ai/texttovideo",
    },
    {
      id: "#10422",
      date: "1 Nov, 10:53 AM",
      status: "paid",
      customer: ["Personalization", { image: team1 }],
      product: "Valvet T-shirt",
      revenue: "quickvideo.ai/personalization",
    },
    {
      id: "#10423",
      date: "1 Nov, 11:13 AM",
      status: "refunded",
      customer: ["Conversational", { image: "M", color: "info" }],
      product: [
        "Leather Wallet",
        {
          suffix: (
            <VuiTypography variant="caption" color="text" fontWeight="medium">
              +1 more
            </VuiTypography>
          ),
        },
      ],
      revenue: "quickvideo.ai/conversational",
    },
    {
      id: "#10424",
      date: "1 Nov, 12:20 PM",
      status: "paid",
      customer: ["Contact", { image: team3 }],
      product: "Bracelet Onu-Lino",
      revenue: "quickvideo.ai/contact",
    },
    {
      id: "#10425",
      date: "1 Nov, 1:40 PM",
      status: "canceled",
      customer: ["Information", { image: team4 }],
      product: [
        "Phone Case Pink",
        {
          suffix: (
            <VuiTypography variant="caption" color="text" fontWeight="medium">
              X 2
            </VuiTypography>
          ),
        },
      ],
      revenue: "quickvideo.ai/information",
    },
    {
      id: "#10426",
      date: "1 Nov, 2:19 PM",
      status: "paid",
      customer: ["Features", { image: "L", color: "info" }],
      product: "Backpack Niver",
      revenue: "quickvideo.ai/features",
    },
    {
      id: "#10427",
      date: "1 Nov, 3:42 AM",
      status: "paid",
      customer: ["Pricing", { image: "I", color: "info" }],
      product: "Adidas Vio",
      revenue: "quickvideo.ai/pricing",
    },
    // {
    //   id: "#10428",
    //   date: "2 Nov, 9:32 AM",
    //   status: "paid",
    //   customer: ["Arrias Liunda", { image: "A", color: "info" }],
    //   product: "Airpods 2 Gen",
    //   revenue: "$350,00",
    // },
    // {
    //   id: "#10429",
    //   date: "2 Nov, 10:14 AM",
    //   status: "paid",
    //   customer: ["Rugna Ilpio", { image: team5 }],
    //   product: "Bracelet Warret",
    //   revenue: "$15,00",
    // },
    // {
    //   id: "#10430",
    //   date: "2 Nov, 10:14 AM",
    //   status: "refunded",
    //   customer: ["Anna Landa", { image: ivana }],
    //   product: [
    //     "Watter Bottle India",
    //     {
    //       suffix: (
    //         <VuiTypography variant="caption" color="text" fontWeight="medium">
    //           X 3
    //         </VuiTypography>
    //       ),
    //     },
    //   ],
    //   revenue: "$25,00",
    // },
    // {
    //   id: "#10431",
    //   date: "2 Nov, 3:12 PM",
    //   status: "paid",
    //   customer: ["Karl Innas", { image: "K", color: "info" }],
    //   product: "Kitchen Gadgets",
    //   revenue: "$164,90",
    // },
    // {
    //   id: "#10432",
    //   date: "2 Nov, 5:12 PM",
    //   status: "paid",
    //   customer: ["Oana Kilas", { image: "O", color: "info" }],
    //   product: "Office Papers",
    //   revenue: "$23,90",
    // },
  ],
};
