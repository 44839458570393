import { AppBar, Card, Tab, Tabs, Tooltip } from '@mui/material'
import VuiBox from 'components/VuiBox'
import VuiDropzone from 'components/VuiDropzone'
import VuiInput from 'components/VuiInput'
import VuiSelect from 'components/VuiSelect'
import VuiTypography from 'components/VuiTypography'
import React, { useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import { Message } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'

const Questions = ({widgetDetails, setWidgetDetails, voices, selectedAgent}) => {
  const [tabValue, setTabValue] = useState(0);

  const tabHandler = (event, newValue) => {
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        setWidgetDetails((prevDetails) => ({
          ...prevDetails,
          template: 'rag-grounded'
        }))
        break;

      case 1:
        setWidgetDetails((prevDetails) => ({
          ...prevDetails,
          template: 'rag-ungrounded'
        }))
        break;

      case 2:
        setWidgetDetails((prevDetails) => ({
          ...prevDetails,
          template: 'assistant'
        }))
        break;
    
      default:
        break;
    }
  };

  const handleDetails = (e, section) => {
    const domainArray = e.target.value.split(/\s*,\s*/);

    setWidgetDetails((prevDetails) => ({
      ...prevDetails,
      [section]: section === 'domains' ? domainArray : e.target.value,
    }));
  }

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    setWidgetDetails((prevDetails) => ({
      ...prevDetails,
      knowledgeBase: [...prevDetails.knowledgeBase, ...files].slice(0, 5),
    }));
    enqueueSnackbar('File uploaded.', { 
      variant: "success",
      autoHideDuration: 2000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  const removeFile = (index) => (event) => {
    event.stopPropagation();
    setWidgetDetails((prevDetails) => ({
      ...prevDetails,
      knowledgeBase: prevDetails.knowledgeBase.filter((_, i) => i !== index),
    }));
  }

  return (
    <VuiBox sx={{maxHeight: '650px', overflowY: 'auto', margin: '10px auto', width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
      <VuiBox sx={{display: 'flex', flexDirection: 'column'}}>
        <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
          <VuiTypography color={selectedAgent ? "error" : "white"}>{selectedAgent ? "Knowledge name can't be updated" : 'Write your knowledge base name'}</VuiTypography>
          <VuiInput
            placeholder="write your knowledge base name here..."
            onChange={(e) => handleDetails(e, 'knowledgeName')}
            value={widgetDetails.knowledgeName}
            // disabled={textLoading || availableVoiceLoading}
            // onBlur={handleAvailableVoiceBlur}
            // error={availableVoiceError}
            sx={{marginTop:'10px'}}
            disabled={selectedAgent}
          />
        </VuiBox>
        <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginTop: '30px', alignItems: 'center'}}>
          <VuiTypography color={selectedAgent ? "error" : "white"}>{selectedAgent ? "Knowledge description can't be updated" : 'Write your knowledge base description'}</VuiTypography>
          <VuiInput
            placeholder="write your knowledge base description here..."
            onChange={(e) => handleDetails(e, 'knowledgeDescription')}
            value={widgetDetails.knowledgeDescription}
            // disabled={textLoading || availableVoiceLoading}
            // onBlur={handleAvailableVoiceBlur}
            // error={availableVoiceError}
            sx={{marginTop:'10px'}}
            disabled={selectedAgent}
          />
        </VuiBox>
      </VuiBox>
      <VuiBox sx={{display: 'flex', flexDirection: 'column', marginTop: '30px'}}>
        <VuiTypography variant="h5" color={selectedAgent ? "error" : "white"} textAlign='center'>
          {selectedAgent ? "Knowledge base can't be updated" : 'Upload your knowledge base'}
        </VuiTypography>
        {selectedAgent && <VuiTypography variant="h6" color={selectedAgent ? "error" : "white"} textAlign='center'>
          selected documents
        </VuiTypography>}
        {!selectedAgent && 
          <>
            <AppBar
              position="static"
              sx={{ display: "flex", mt: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
            >
              <Tabs value={tabValue} onChange={tabHandler}>
                <Tab
                  style={{minWidth:"30%"}}
                  label={
                    <Tooltip title='Grounded: Only uses your knowledge sources for responses' placement='top'>
                      <VuiTypography color="light" variant="body2">
                        Grounded
                      </VuiTypography>
                    </Tooltip>
                  }
                />
                <Tab
                  style={{minWidth:"30%"}}
                  label={
                    <Tooltip title='Hybrid: Primarily uses your sources, but also relies on general knowledge' placement='top'>
                      <VuiTypography color="light" variant="body2">
                        Hybrid
                      </VuiTypography>
                    </Tooltip>
                  }
                />
                <Tab
                  style={{minWidth:"30%"}}
                  label={
                    <Tooltip title='Ungrounded: Will not use any of your sources' placement='top'>
                      <VuiTypography color="light" variant="body2">
                        Ungrounded
                      </VuiTypography>
                    </Tooltip>
                  }
                />
              </Tabs>
            </AppBar>
            <label htmlFor="video-file" style={{ cursor: 'pointer' }}>
              <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "fit-content",
                  color: "whitesmoke",
                  border: "1px dashed gray",
                  cursor: "pointer",
                  borderRadius: "15px",
                  overflow: "hidden",
                  padding: "40px",
                  maxWidth: "500px",
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  // marginTop: '20px'
                }}
              >
                <input
                  accept="application/pdf"
                  style={{ display: 'none', pointer: 'cursor' }}
                  type="file"
                  id="video-file"
                  name="video-file"
                  multiple
                  onChange={handleFileUpload}
                />
                <h4
                  style={{
                    padding: "5px 15px",
                    marginTop: "5px",
                    borderRadius: "5px",
                    fontWeight: 400
                  }}
                >
                  Click to upload file
                </h4>
                <h6
                  style={{
                    padding: "2px 15px",
                    borderRadius: "5px",
                    fontWeight: 400
                  }}
                >
                  Up to 5 files, total 75MB, PDF allowed
                </h6>
              </Card>
            </label>
          </>
        }
        {widgetDetails.knowledgeBase.length > 0 && (
          widgetDetails.knowledgeBase.map((file, index) => (
            <Card key={index} sx={{ width: '400px', marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', padding: '5px', marginLeft: 'auto', marginRight: 'auto'}}>
              {selectedAgent ? <VuiTypography>{`${file.slice(0, 20)}...`}</VuiTypography> : <VuiTypography>{`${file.name.slice(0, 20)}...`}</VuiTypography>}
              {!selectedAgent && <ClearIcon
                sx={{
                  color: 'whitesmoke',
                  boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)",
                  borderRadius: '100%',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '10px',
                  right: '10px'
                }}
                onClick={removeFile(index)}
              />}
            </Card>
          ))
        )}
      </VuiBox>
      
      {/* <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '10px'}}>
        <VuiTypography color='white'>On which domain or path's do you want to show the AI widget ? (seperated by commas)</VuiTypography>
        <VuiInput
          placeholder="Type your complete domain or pathname here..."
          multiline
          minRows={5}
          maxRows={10}
          onChange={(e) => handleDetails(e, 'domains')}
          value={widgetDetails.domains}
          // disabled={textLoading || availableVoiceLoading}
          // onBlur={handleAvailableVoiceBlur}
          // error={availableVoiceError}
          sx={{marginTop:'10px'}}
        />
      </VuiBox> */}
    </VuiBox>
  )
}

export default Questions