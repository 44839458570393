import VuiBox from 'components/VuiBox';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import Webcam from 'react-webcam';
import CameraIcon from '@mui/icons-material/Camera';
import BlockIcon from '@mui/icons-material/Block';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { Button, CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VuiTypography from 'components/VuiTypography';

const VideoRecording = ({width, height, onUpload}) => {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState('');
  const [showMessage, setShowMessage] = useState(true);

  const handleStartRecording = useCallback(() => {
    setShowMessage(false);
    setTimeout( async() => {
      if (webcamRef.current) {
        const webcamStream = webcamRef.current.stream;
  
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const audioTrack = audioStream.getAudioTracks()[0];
  
        webcamStream.addTrack(audioTrack);
  
        const options = { mimeType: 'video/webm; codecs=vp9' };
        const mediaRecorder = new MediaRecorder(webcamStream, options);
  
        mediaRecorder.ondataavailable = handleDataAvailable;
        mediaRecorder.onstop = handleStopRecording;
  
        mediaRecorderRef.current = mediaRecorder;
  
        mediaRecorder.start();
        setRecording(true);
      }
    }, 1000)
  }, []);

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      setRecordedChunks((prev) => prev.concat(event.data));
    }
  };

  const handleCancelPreview = () => {
    setRecordedChunks([]);
    setVideoPreviewUrl('');
  };

  const handlePreview = () => {
    const blob = new Blob(recordedChunks, { type: 'video/webm' });
    const videoUrl = URL.createObjectURL(blob);
    setVideoPreviewUrl(videoUrl);
  };

  // const handleUpload = async () => {
  //   const blob = new Blob(recordedChunks, { type: 'video/webm' });
  //   const apiUrl = 'YOUR_UPLOAD_API_URL';

  //   const formData = new FormData();
  //   formData.append('video', blob, 'recordedVideo.webm');

  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: 'POST',
  //       body: formData,
  //     });

  //     if (response.ok) {
  //       // Handle successful upload
  //       console.log('Video uploaded successfully');
  //     } else {
  //       // Handle upload error
  //       console.error('Video upload failed');
  //     }
  //   } catch (error) {
  //     // Handle network error
  //     console.error('Video upload failed:', error);
  //   }
  // };

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
    }
  }, [showMessage]);

  return (
    <div>
      {videoPreviewUrl ? (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
          <video src={videoPreviewUrl} controls autoPlay style={{ width: width ? width : '720px', height: height ? height : '450px' }} />
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: 300}}>
            <Button variant='contained' style={{display: "flex", alignItems: 'center', justifyContent: 'space-between', padding: '1px 12px', fontSize: 14, marginTop: 10, color: 'whitesmoke'}} onClick={handleCancelPreview}>
              Discard
              <BlockIcon fontSize='small' sx={{
                color: 'red',
                cursor: 'pointer',
                marginLeft: 1
              }} />
            </Button>
            <Button variant='contained' style={{display: "flex", alignItems: 'center', justifyContent: 'space-between', padding: '1px 12px', fontSize: 14, marginTop: 10, color: 'whitesmoke'}} onClick={onUpload ? onUpload : handleCancelPreview}>
              Upload
              <CloudUploadIcon fontSize='small' sx={{
                color: 'white',
                cursor: 'pointer',
                marginLeft: 1
              }} />
            </Button>
          </div>
        </div>
      ) : (
        <>
          {showMessage && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
              <CircularProgress size={28} color="white" />
              <VuiTypography>Get ready! Recording will start in 2 seconds...</VuiTypography>
            </div>
          )}
          <Webcam 
            audio={false} 
            video={true} 
            ref={webcamRef} 
            videoConstraints={{
              width: width ? width: 750,
              height: height ? height : 450,
            }}
            mirrored={true} 
          />
          <VuiBox
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              position: 'absolute',
              bottom: '60px',
              left: '45%',
              backdropFilter: 'blur(10px)',
              width: 100,
              padding: "5px 10px"
            }}
          >
            {recording ? (
              <StopCircleIcon
                sx={{
                  border: '1px solid whitesmoke',
                  borderRadius: '50%',
                  color: 'red',
                  cursor: 'pointer',
                }}
                onClick={handleStopRecording}
                fontSize='large'
              />
            ) : (
              recordedChunks.length > 0 && !videoPreviewUrl ? (
                <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
                  <PlayCircleIcon onClick={handlePreview} fontSize='large' sx={{
                    border: '1px solid whitesmoke',
                    borderRadius: '50%',
                    color: 'red',
                    cursor: 'pointer',
                    margin: 1,
                    bottom: 0
                  }} />
                  <BlockIcon onClick={handleCancelPreview} fontSize='large' sx={{
                    border: '1px solid whitesmoke',
                    borderRadius: '50%',
                    color: 'red',
                    cursor: 'pointer',
                    margin: 1
                  }} />
                </div>
              ) : (
              <CameraIcon
                sx={{
                  border: '1px solid whitesmoke',
                  borderRadius: '50%',
                  color: 'red',
                  cursor: 'pointer',
                }}
                onClick={handleStartRecording}
                fontSize='large'
              />
            ))}
          </VuiBox>
        </>
      )}
    </div>
  );
};

export default VideoRecording;
