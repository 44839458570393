import { Button, Modal, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import VuiBox from 'components/VuiBox';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ConfirmationModal = ({confirmModal, setConfirmModal, handleFinalSubmit, finalApiHit, setFinalApiHit}) => {
  const history = useHistory();
  
  const handleClose = () => {
    setConfirmModal(false);
    setFinalApiHit(false);
    history.push('/library', {Saas: true});
  };

  return (
    <div>
        <Modal open={confirmModal} onClose={handleClose}>
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 100%, rgba(10, 14, 35, 0.49) 76.65%)",
            color: "#090d2c",
            outline: "none",
            padding: "25px",
            // textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            maxHeight: "55vh",
            minWidth: "55vw",
            boxShadow: "rgb(54, 68, 97) 0px 0px 30px 10px",
            borderRadius: '5px'
          }}>
            <div style={{position: 'absolute', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px', top: 10, right: 10}}>
              <CloseIcon onClick={handleClose} sx={{ color: "whitesmoke", cursor: 'pointer' }} />
            </div>
            {!finalApiHit && <Typography variant="h5" textAlign='left' sx={{color: 'whitesmoke', textAlign: 'center'}}>Are you sure want to export the video ?</Typography>}
            {!finalApiHit && <Typography variant="h6" textAlign='left' sx={{color: 'gray', fontSize: '14px', textAlign: 'center'}}>P.S You will not be able to edit once the video is exported</Typography>}
            {!finalApiHit && <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Button variant='contained' sx={{color: 'whitesmoke', width: '200px', margin: '10px'}} onClick={() => {setConfirmModal(false)}}><CloseIcon sx={{marginRight: '5px'}} /> Cancel</Button>
              <Button variant='contained' sx={{color: 'whitesmoke', width: '200px', margin: '10px'}} onClick={handleFinalSubmit}><AutoModeIcon sx={{marginRight: '5px'}} /> Export</Button>
            </VuiBox>}
            {finalApiHit && <Typography variant="h5" textAlign='left' sx={{color: 'gray', textAlign: 'center'}}>You will get notified once it is generated. <br /> You can check generated video on video library later on.</Typography>}
          </div>
        </Modal>
      </div>
  )
}

export default ConfirmationModal