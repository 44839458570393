/*eslint-disable no-unused-vars */

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// account page components
import FormField from "layouts/pages/account/components/FormField";

// React icons
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { column } from "stylis";
import breakpoints from "assets/theme/base/breakpoints";

function Collapsed({ title, open, children, ...rest }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  // const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("12")
        : setTabsOrientation("4");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  // const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <Grid container spacing={2}>
      <Grid item xs={tabsOrientation}>
    <VuiBox mb={2} mr={0}>
      <VuiBox
        {...rest}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{ cursor: "pointer" }}
      >
        <VuiTypography variant="lg" color="white" sx={{ userSelect: "none" }}>
          {title}
        </VuiTypography>

        <VuiBox color="white">
          {open ? (
            <IoIosArrowUp color="dark" />
          ) : (
            <IoIosArrowDown color="white" />
          )}
        </VuiBox>
      </VuiBox>

      <Collapse timeout={400} in={open}>
        <VuiBox p={2} lineHeight={1}>
          <VuiTypography
            variant="button"
            color="white"
            opacity={0.8}
            fontWeight="regular"
          >
            {children}
          </VuiTypography>
        </VuiBox>
      </Collapse>

      <Divider light />
    </VuiBox>
    </Grid>
    </Grid>
  );
}

function Settings() {
  const [Generalcollapse, GeneralsetCollapse] = useState(1);
  const [Noticollapse, NotisetCollapse] = useState(false);
  const [Intecollapse, IntesetCollapse] = useState(false);

  return (
    <Grid container spacing={1} paddingTop={2} paddingLeft={2}>
      <Grid item xs={12}>
        {/* <Grid item xs={12} md={10}> */}
        <Collapsed
          title="General"
          open={Generalcollapse === 1}
          onClick={() =>
            Generalcollapse === 1
              ? GeneralsetCollapse(false)
              : GeneralsetCollapse(1)
          }
        >
          <VuiBox mb={1}>
            <p>
              <span style={{ color: "white" }}>
                {`Need some help configuring your settings? `}
              </span>
              <a href="https://support.example.com">Chat with support</a>
            </p>
          </VuiBox>
          <VuiBox>
            <VuiBox component="form" mt={2}>
              <Grid container spacing={3} direction="column">
                <Grid item>
                  <FormField label="Feed Name" placeholder="Feed #1" />
                </Grid>
                <Grid item>
                  <FormField label="Feed ID" placeholder="oypbbb01rxpo2" />
                </Grid>
              </Grid>
            </VuiBox>
            <VuiBox mt={2}>
              <VuiTypography
                variant=""
                fontWeight="bold"
                color="white"
                sx={{ userSelect: "none" }}
              >
                Tracking
              </VuiTypography>
            </VuiBox>
            <VuiBox component="form" mt={2}>
              <Grid container spacing={3} direction={"column"}>
                <Grid item>
                  <FormField
                    label="Google Analytics"
                    placeholder="UA-12312312-1"
                  />
                </Grid>
                <Grid item>
                  <FormField
                    label="Facebook Pixel"
                    placeholder="123456789123456"
                  />
                </Grid>
              </Grid>
            </VuiBox>
          </VuiBox>
        </Collapsed>
      </Grid>
      <Grid item xs={12}>
        <Collapsed
          title="Notifications"
          open={Noticollapse === 1}
          onClick={() =>
            Noticollapse === 1 ? NotisetCollapse(false) : NotisetCollapse(1)
          }
        >
          <VuiBox mb={1}>
            <p>
              <span style={{ color: "white" }}>
                {`Set which notifications you’d like to receive. You can add multiple emails to receive notifications.`}
              </span>
            </p>
          </VuiBox>
          <VuiBox>
            <VuiBox component="form" mt={2}>
              <Grid container spacing={3} direction={"column"}>
                <Grid item>
                  <FormField
                    label="Email Notifications"
                    placeholder="**********"
                  />
                </Grid>
              </Grid>
            </VuiBox>
          </VuiBox>
        </Collapsed>
      </Grid>
      <Grid item xs={12}>
        <Collapsed
          title="Integrations"
          open={Intecollapse === 1}
          onClick={() =>
            Intecollapse === 1 ? IntesetCollapse(false) : IntesetCollapse(1)
          }
        >
          <VuiBox mb={1}>
            <p>
              <span>{`Need some help configuring your settings? `}</span>
              <a href="https://support.example.com">Chat with support</a>
            </p>
          </VuiBox>
          <VuiBox>
            <VuiBox component="form" mt={2}>
              <Grid container spacing={3} direction={"column"}>
                <Grid item>
                  <FormField label="Feed Name" placeholder="Feed #1" />
                </Grid>
                <Grid item>
                  <FormField label="Feed ID" placeholder="oypbbb01rxpo2" />
                </Grid>
              </Grid>
            </VuiBox>
            <VuiBox mt={2}>
              <VuiTypography
                variant=""
                fontWeight="bold"
                color="white"
                sx={{ userSelect: "none" }}
              >
                Tracking
              </VuiTypography>
            </VuiBox>
            <VuiBox component="form" mt={2}>
              <Grid container spacing={3} direction={"column"}>
                <Grid item>
                  <FormField
                    label="Google Analytics"
                    placeholder="UA-12312312-1"
                  />
                </Grid>
                <Grid item>
                  <FormField
                    label="Facebook Pixel"
                    placeholder="123456789123456"
                  />
                </Grid>
              </Grid>
            </VuiBox>
          </VuiBox>
        </Collapsed>
      </Grid>
    </Grid>
  );
}
export default Settings;
