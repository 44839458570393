/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import VuiSwitch from "components/VuiSwitch";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Setting pages components
import TableCell from "layouts/pages/account/settings/components/TableCell";
import { Grid } from "@mui/material";

const notificationData = {
  "show_anonymous": {
    "title": "Show Anonymous",
    "description": "Show anonymous users in the list of users who have liked your videos.",
    "email": true,
    "push": false,
    "sms": false,
  },
  "impressions": {
    "title": "Impressions",
    "description": "Notify when your video is shown to a user.",
    "email": true,
    "push": true,
    "sms": false,
  },
  "played_quickvideo": {
    "title": "Played QuickVideo",
    "description": "Notify when your video is played.",
    "email": true,
    "push": true,
    "sms": false,
  },
  "interacted": {
    "title": "Interacted",
    "description": "Notify when a user interacts with your video.",
    "email": true,
    "push": true,
    "sms": false,
  },
  "text_response": {
    "title": "Text Response",
    "description": "Notify when a user responds to your video with text.",
    "email": true,
    "push": true,
    "sms": false,
  },
  "audio_response": {
    "title": "Audio Response",
    "description": "Notify when a user responds to your video with audio.",
    "email": true,
    "push": true,
    "sms": false,
  },
  "video_response": {
    "title": "Video Response",
    "description": "Notify when a user responds to your video with video.",
    "email": true,
    "push": true,
    "sms": false,
  },
  "calendar_invite": {
    "title": "Calendar Invite",
    "description": "Notify when a user invites you to a calendar event.",
    "email": true,
    "push": true,
    "sms": false,
  },
  "conversion": {
    "title": "Conversion",
    "description": "Notify when a user converts to a lead.",
    "email": true,
    "push": true,
    "sms": false,
  },
  "reached_end": {
    "title": "Reached End",
    "description": "Notify when a user reaches the end of your video.",
    "email": true,
    "push": true,
    "sms": true,
  },
};

function Notifications() {
  return (
    <Grid id="notifications" container xs={12} sx={{background: 'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)', backdropFilter: 'blur(120px)', justifyContent: 'center', alignItems: 'flex-start', borderRadius: '1.2rem', backgroundClip: 'border-box', padding: '22px', lineHeight: 1}}>
      <Grid xs={12} item lineHeight={1} mb="40px" justifyContent= 'flex-start'>
        <VuiBox>
          <VuiTypography variant="lg" color="white" fontWeight="bold">
            Notifications
          </VuiTypography>
        </VuiBox>
        <VuiTypography variant="button" color="text" fontWeight="regular">
          Choose how you receive notifications. These notification settings apply to the things
          you’re watching.
        </VuiTypography>
      </Grid>
      <Grid item xs={12}>
        <VuiBox minWidth="auto" overflow={{ sm: "scroll", lg: "hidden" }}>
          <Table sx={{ minWidth: "45rem" }}>
            <VuiBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Activity
                </TableCell>
                <TableCell width="100%" align="center" padding={[1.5, 6, 1.5, 6]}>
                  Email
                </TableCell>
                <TableCell width="100%" align="center" padding={[1.5, 6, 1.5, 6]}>
                  Push
                </TableCell>
                <TableCell width="100%" align="center" padding={[1.5, 6, 1.5, 6]}>
                  SMS
                </TableCell>
              </TableRow>
            </VuiBox>
            <TableBody>
              {Object.keys(notificationData).map((key) => {

                return <TableRow>
                <TableCell padding={[2, 1, 2, 0.5]}>
                  <VuiBox lineHeight={1.4}>
                    <VuiTypography
                      display="block"
                      variant="button"
                      color="white"
                      fontWeight="regular"
                    >
                      {notificationData[key].title}
                    </VuiTypography>
                    <VuiTypography variant="caption" color="text" fontWeight="regular">
                    {notificationData[key].description}
                    </VuiTypography>
                  </VuiBox>
                </TableCell>
                <TableCell align="center" padding={[2, 1, 2, 0.5]}>
                  <VuiSwitch color="info" checked={ notificationData[key].email }/>
                </TableCell>
                <TableCell align="center" padding={[2, 1, 2, 0.5]}>
                  <VuiSwitch color="info" checked={ notificationData[key].push }/>
                </TableCell>
                <TableCell align="center" padding={[2, 1, 2, 0.5]}>
                  <VuiSwitch color="info" checked={ notificationData[key].sms }/>
                </TableCell>
              </TableRow>
              })}
              
            </TableBody>
          </Table>
        </VuiBox>
      </Grid>
    </Grid>
  );
}

export default Notifications;
