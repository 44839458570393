/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/
import { Grid } from "@mui/material";
//eslint-disable-next-line
import ReactPlayer from "react-player";
import Header from "./Header";
import VuiTypography from "components/VuiTypography";
import { voiceOptions, languageOptions } from "layouts/pages/createvideo/options";
import 'video-react/dist/video-react.css';
import { Player, BigPlayButton, ControlBar, ReplayControl, ForwardControl, PlaybackRateMenuButton, CurrentTimeDisplay ,TimeDivider, VolumeMenuButton } from 'video-react';

function VideoPopup(props) {
  const voiceId = props.video_data.voice_id;

  function findLanguageAndVoice(voiceId) {
    if(!voiceId) {
      return "audio selected"
    }
    const hyphenIndex = voiceId.lastIndexOf('-');
    const languageCode = voiceId.substring(0, hyphenIndex);
    const voiceCode = voiceId.substring(hyphenIndex + 1);

    const language = languageOptions.find(option => option.value === languageCode);
    const voiceArray = voiceOptions[languageCode];

    if (!language || !voiceArray) {
      return {
        language: null,
        voice: null,
        voiceCode: null,
      };
    }

    const voice = voiceArray.find(option => option.value === voiceId);

    return {
      language,
      voice,
      voiceCode,
    };
  }

  const { language, voice } = findLanguageAndVoice(voiceId);

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" style={{width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)", zIndex: "1000", borderRadius: "10px", padding: "15px"}}>
      <Grid item xs={12}>
        <Header v_data={props.video_data} handleClose={props.handleClose}/>
      </Grid>  
      <Grid container xs={12}>
        <Grid item xs={window.innerWidth <= 768 ? 12 : 6}>
          {/* <video controls={true} width="100%" height="100%" style={{padding: "30px", boxShadow: "rgb(54, 68, 97) 0px 0px 10px 2px", marginTop: "10px"}}>
            <source src={props.video_data.file} type="video/mp4" />
          </video> */}
          <Player height={500} width={600} fluid={false} src={props.video_data.file || props.video_data.video_url}>
            <BigPlayButton position="center" />
            <ControlBar autoHide={true} className="my-class">
              <ReplayControl seconds={10} order={1.1} />
              <ForwardControl seconds={30} order={1.2} />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
              <VolumeMenuButton disabled />
            </ControlBar>
          </Player>
        </Grid>
        <Grid item xs={window.innerWidth < 768 ? 12 : 4} sx={{padding: window.innerWidth < 768 ? '30px 10px' : '20px 50px'}}>
          <Grid item xs={12} sx={{marginBottom: '10px'}}>
            <h4 style={{color: 'whitesmoke', marginBottom: '5px'}}>File Name</h4>
            <div style={{border: '1px solid lightblue', padding: '10px', maxHeight: '30vh', overflowY: 'auto', borderRadius: '10px'}}>
              <VuiTypography variant='h6'>{props.video_data.filename || props.video_data.video_name}</VuiTypography>
            </div>
          </Grid>
          {props.video_data.script_type === 'text' && <Grid item xs={12} sx={{marginBottom: '10px'}}>
            <h4 style={{color: 'whitesmoke', marginBottom: '5px'}}>Script Input</h4>
            <div style={{border: '1px solid lightblue', padding: '10px', maxHeight: '30vh', overflowY: 'auto', borderRadius: '10px'}}>
              <VuiTypography variant='h6'>{props.video_data.script_input}</VuiTypography>
            </div>
          </Grid>}
          {(props.video_data.voice_id !== '' && props.video_data.voice_id !== null && props.video_data.voice_id !== undefined) && <Grid item xs={12} sx={{marginBottom: '10px'}}>
            <h4 style={{color: 'whitesmoke', marginBottom: '5px'}}>Language selected</h4>
            <div style={{border: '1px solid lightblue', padding: '10px', maxHeight: '30vh', overflowY: 'auto', borderRadius: '10px'}}>
              <VuiTypography variant='h6'>{language.label}</VuiTypography>
            </div>
          </Grid>}
          {(props.video_data.voice_id !== '' && props.video_data.voice_id !== null && props.video_data.voice_id !== undefined) && <Grid item xs={12} sx={{marginBottom: '10px'}}>
            <h4 style={{color: 'whitesmoke', marginBottom: '5px'}}>Voice selected</h4>
            <div style={{border: '1px solid lightblue', padding: '10px', maxHeight: '30vh', overflowY: 'auto', borderRadius: '10px'}}>
              <VuiTypography variant='h6'>{voice.label}</VuiTypography>
            </div>
          </Grid>}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default VideoPopup;
