import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import VuiTypography from 'components/VuiTypography';
import VuiBox from 'components/VuiBox';
import logo from './QuickVideoIcon.png';
import VuiInput from 'components/VuiInput';
import VuiButton from 'components/VuiButton';
import VuiSelect from 'components/VuiSelect';
import { Close } from '@mui/icons-material';
import { useVisionUIController } from 'context';
import { setSupportModal } from 'context';
import BASE_URL from "context/api";
import { enqueueSnackbar } from 'notistack';

const SupportModal = ({getTickets}) => {
  const [details, setDetails] = useState({
    name: '',
    email: '',
    issue: '',
    description: '',
    attachment: null,
    browser: '',
    country: '',
    contact: '',
    priority: '',
    attachmentType: '',
  });
  const [countries, setCountries] = useState([]);
  const [controller, dispatch] = useVisionUIController();
  const { userDetails, supportModal } = controller;

  useEffect(() => {
    setDetails({
      ...details,
      name: userDetails.first_name,
      email: userDetails.email_id,
      contact: userDetails.mobile,
    })
  }, []);

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      });
  }, []);

  const priorityOptions = [
    { value: "None", label: "None" },
    { value: "Low", label: "Low" },
    { value: "Medium", label: "Medium" },
    { value: "High", label: "High" },
    { value: "Critical", label: "Critical" },
  ];
  
  const browserOptions = [
    { value: "Chrome", label: "Chrome" },
    { value: "Firefox", label: "Firefox" },
    { value: "Opera", label: "Opera" },
    { value: "Safari", label: "Safari" },
    { value: "Edge", label: "Edge" },
    { value: "Brave", label: "Brave" },
    { value: "Other", label: "Other" },
  ];
  
  const issueOptions = [
    { value: "General information", label: "General information" },
    { value: "Account issue", label: "Account issue" },
    { value: "Presenter issue", label: "Presenter issue" },
    { value: "API access", label: "API access" },
    { value: "Billing", label: "Billing" },
    { value: "Can't generate video", label: "Can't generate video" },
    { value: "Can't upload audio", label: "Can't upload audio" },
    { value: "Can't upload image", label: "Can't upload image" },
    { value: "Pricing issue", label: "Pricing issue" },
    { value: "Report misuse", label: "Report misuse" },
    { value: "Logs are not correct", label: "Logs are not correct" },
    { value: "Subscription issue", label: "Subscription issue" },
    { value: "Other", label: "Other" },
  ];
  
  const handleClose = () => {
    setSupportModal(dispatch, false);
  };

  const handleSubmit = async () =>{
    try {
      const formData = new FormData();
      Object.keys(details).forEach((key) => {
        if (details[key]) {
          formData.append(key, details[key]);
        }
      });
      if (details.attachment) {
        formData.append('attachment', details.attachment);
      }
      await fetch(`${BASE_URL}/customer_support`, {
        method: 'POST',
        body: formData,
      });
      setSupportModal(dispatch, false);
      setDetails({
        name: '',
        email: '',
        issue: '',
        description: '',
        attachment: null,
        browser: '',
        country: '',
        contact: '',
        priority: '',
      });
      enqueueSnackbar('Support request sent successfully', { 
        variant: 'success',
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      getTickets();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to send support request', { 
        variant: 'error',
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }

  return (
    <div>
      <Modal open={supportModal} onClose={handleClose}>
        <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 100%, rgba(10, 14, 35, 0.49) 76.65%)",
            outline: "none",
            padding: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            minHeight: "80vh",
            minWidth: "60vw",
            boxShadow: "rgb(54, 68, 97) 0px 0px 10px 20px 2px",
            borderRadius: '5px'
          }}>
            <Close color='white' sx={{position: 'absolute', right: 20, top: 20, cursor: 'pointer'}} onClick={handleClose} />
            <VuiBox sx={{display: 'flex', flexDirection: 'column'}}>
              <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img src={logo} alt="logo" width={150} />
                <VuiTypography color='white' variant='h5' mb='5px'> &nbsp; | &nbsp;Support Request</VuiTypography>
              </VuiBox>
              <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                <VuiTypography color='info' variant='h6' textAlign='center'>Please provide us with the following details</VuiTypography>
                <form style={{marginTop: '5%', marginLeft: 'auto', marginRight: 'auto'}}>
                  <VuiBox sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                    <VuiBox m='10px 20px' width='250px'>
                      <VuiTypography color='white' variant='h6'>Name</VuiTypography>
                      <VuiBox>
                        <VuiInput type='text' disabled value={details.name} />
                      </VuiBox>
                    </VuiBox>
                    <VuiBox m='10px 20px' width='250px'>
                      <VuiTypography color='white' variant='h6'>Email</VuiTypography>
                      <VuiBox>
                        <VuiInput type='email' disabled value={details.email} />
                      </VuiBox>
                    </VuiBox>
                  </VuiBox>
                  <VuiBox sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                    <VuiBox m='10px 20px' width='250px'>
                      <VuiTypography color='white' variant='h6'>Contact</VuiTypography>
                      <VuiBox>
                        <VuiInput type="tel" disabled={details.contact} value={details.contact} onChange={e => setDetails({...details, contact: e.target.value })} />
                      </VuiBox>
                    </VuiBox>
                    <VuiBox m='10px 20px' width='250px'>
                      <VuiTypography color='white' variant='h6'>Issue</VuiTypography>
                      <VuiBox>
                        <VuiSelect options={issueOptions} value={{label: details.issue}} onChange={e => setDetails({...details, issue: e.value })} />
                      </VuiBox>
                    </VuiBox>
                  </VuiBox>
                  <VuiBox sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                    <VuiBox m='10px 20px' width='250px'>
                      <VuiTypography color='white' variant='h6'>Browser</VuiTypography>
                      <VuiBox>
                        <VuiSelect options={browserOptions} value={{label: details.browser}} onChange={e => setDetails({...details, browser: e.value })} />
                      </VuiBox>
                    </VuiBox>
                    <VuiBox m='10px 20px' width='250px'>
                      <VuiTypography color='white' variant='h6'>Country</VuiTypography>
                      <VuiBox>
                        <VuiSelect options={countries} value={{label: details.country}} onChange={e => setDetails({...details, country: e.label })} />
                      </VuiBox>
                    </VuiBox>
                  </VuiBox>
                  <VuiBox sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                    <VuiBox m='10px 20px' width='250px'>
                        <VuiTypography color='white' variant='h6'>Priority</VuiTypography>
                        <VuiBox>
                          <VuiSelect options={priorityOptions} value={{label: details.priority}} onChange={e => setDetails({...details, priority: e.value })} />
                        </VuiBox>
                    </VuiBox>
                    <VuiBox m='10px 20px' width='250px'>
                      <VuiTypography color='white' variant='h6'>Attach File (Optional)</VuiTypography>
                      <VuiBox>
                        <VuiInput 
                          type='file' sx={{height: '250px'}} 
                          onChange={e => {
                            setDetails({
                              ...details, 
                              attachment: e.target.files[0],
                              attachmentType: e.target.files[0].type
                            });
                          }} />
                      </VuiBox>
                    </VuiBox>
                  </VuiBox>
                  <VuiBox sx={{display: 'flex', justifyContent: 'center', marginTop: '10px 20px'}}>
                    <VuiBox m='10px 20px' width= '100%'>
                      <VuiTypography color='white' variant='h6'>Please describe the problem</VuiTypography>
                      <VuiBox>
                        <VuiInput multiline minRows={4} maxRows={6} placeholder='describe your issue her' type='text' value={details.description} onChange={e => setDetails({...details, description: e.target.value })} />
                      </VuiBox>
                    </VuiBox>
                  </VuiBox>
                  <VuiButton sx={{left: '40%', marginTop: '20px'}} onClick={handleSubmit} disabled={details.name === '' || details.email === '' || details.issue === '' || details.contact === '' || details.country === '' || details.browser === ''}>Submit Request</VuiButton>
                </form>
              </VuiBox>
            </VuiBox>
        </div> 
      </Modal>
    </div>
  )
}

export default SupportModal;