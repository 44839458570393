import React, { useState } from "react";
import VuiInput from "components/VuiInput";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import ReactPlayer from "react-player";
import videoData from "../../data/videoData";

function Stock(){
    const [isPlaying, setIsPlaying] = useState(false);

    return(
        <Grid container alignItems="center" justifyContent="center">
            <Typography style={{ fontWeightMedium: 500,fontSizeMD: 16, bottom:"50px", color: "whitesmoke"}}> 
                Videos by Pixel
            </Typography>
            <Card style={{ width:620,marginLeft:5,marginTop:25,marginRight:5, marginBottom: 25}}>
                <VuiInput style={{width:100}} placeholder="search" />
            </Card>
            <VuiBox sx={{display: "flex", justifyContent: "space-around", width: 600}}>
                <Card style={{width:"250px",height: "200px", borderRadius: '6px'}} onMouseEnter={() => setIsPlaying(true)} onMouseLeave={() => setIsPlaying(false)} >
                    <ReactPlayer url={videoData[0].v_url} muted width="100%" playing={isPlaying} />
                </Card>
                <Card style={{width:"250px",height: "200px", borderRadius: '6px'}} onMouseEnter={() => setIsPlaying(true)} onMouseLeave={() => setIsPlaying(false)}>
                    <ReactPlayer url={videoData[1].v_url} width="100%" playing={isPlaying} />
                </Card>
            </VuiBox>
        </Grid>
);

}

export default Stock;