/*eslint-disable no-unused-vars */

import React from "react";
import { useState, useEffect } from "react";

//icons
import {
  Alarm,
  Message,
  Mic,
  Speaker,
  Upload,
  VolumeUp,
} from "@mui/icons-material";
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import { IoMdColorWand, IoMdPerson } from "react-icons/io";

//mui
import { CircularProgress, Divider, Grid, Tooltip, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import ButtonGroup from "@mui/material/ButtonGroup";
import AppBar from "@mui/material/AppBar";

//components
import VuiBox from "components/VuiBox";
import VuiDropzone from "components/VuiDropzone";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiInput from "components/VuiInput";
import RecorderBox from "./Recorder/components/RecorderBox";
import { languageOptions, voiceOptions } from "./options";
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import AudioRecorder from "./AudioRecorder";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from "axios";
import BASE_URL from "context/api";
import { useVisionUIController } from "context";
import AudioPlayer from "./AudioPlayer";
import emojiRegex from 'emoji-regex';
import UpgradeModal from "../UpgradeModal/Modal";
import VuiButton from "components/VuiButton";
import { useHistory } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import ChatGptModal from "./chatGptModal";
import './index.css';
import mixpanel from "mixpanel-browser";

function Sidediv({scriptTouched, setScriptTouched, text, setText, textEmojiError, setTextEmojiError, style, setStyle, selectedLanguage, setSelectedLanguage, selectedVoice, setSelectedVoice, setVoiceId, loading, selectedFile, setSelectedFile, setType, recordedFile, setRecordedFile, availableVoiceText, setAvailableVoiceText, cloneText, setCloneText, cloneName, setCloneName, audioResponse, setAudioResponse, cloneScriptCharacterCount, voiceScriptCharacterCount, scriptCharacterCount, setVideoLength, setCreditsBurn, videoLength, selectAudio, setSelectAudio, selectedAudioFiles, setSelectedAudioFiles, currentFileIndex, setCurrentFileIndex, chatgptModal, setChatgptModal}) {
  // const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [availableVoiceTouched, setAvailableVoiceTouched] = useState(false);
  const [cloneScriptTouched, setCloneScriptTouched] = useState(false);
  const [cloneNameTouched, setCloneNameTouched] = useState(false);
  const [voiceButton, setVoiceButton] = useState(false);
  const [availableVoice, setAvailableVoice] = useState(false);
  const [allOptions, setAllOptions] = useState([]);
  const [availableVoiceSelect, setAvailableVoiceSelect] = useState('');
  const [modelSelection, setModelSelection] = useState('');
  const [availableVoiceLoading, setAvailableVoiceLoading] = useState(false);
  const [cloneVoiceLoading, setCloneVoiceLoading] = useState(false);
  const [textLoading, setTextLoading] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);
  const [availableVoiceToggle, setAvailableVoiceToggle] = useState(false);
  const [cloneVoiceToggle, setCloneVoiceToggle] = useState(false);
  const [availableStability, setAvailableStability] = useState('');
  const [availableStabilityTouch, setAvailableStabilityTouch] = useState(false);
  const [availableSimilarityBoost, setAvailableSimilarityBoost] = useState('');
  const [availableSimilarityBoostTouch, setAvailableSimilarityBoostTouch] = useState(false);
  const [availableStyle, setAvailableStyle] = useState('');
  const [availableStyleTouch, setAvailableStyleTouch] = useState(false);
  const [availableSpeakerBoost, setAvailableSpeakerBoost] = useState(false);
  const [chatgptText, setChatgptText] = useState('');
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const history = useHistory();
  
  const audioRef = React.useRef(null);
  
  const [controller, dispatch] = useVisionUIController();
  const { userDetails, openPricingModal } = controller;
  const { user_id, access_token, plan } = userDetails;

  const handleUpgradeClick = () => {
    history.push('/upgrade');
  };

  const apiKey = '39ac8d95df8d5d45639e30ca0cbcdfbb';

  const fetchAvailableVoices = async () => {
    const url = 'https://api.elevenlabs.io/v1/voices';
    const headers = {
      Accept: 'application/json',
      'xi-api-key': apiKey,
    };

    try {
      const response = await axios.get(url, { headers });
      return response.data.voices;
    } catch (error) {
      console.error('Error fetching available voices:', error);
      return [];
    }
  };

  const fetchAllVoices = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/get_all_voices`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching all voices:', error);
      return [];
    }
  };

  const fetchData = async () => {
    const availableVoices = await fetchAvailableVoices();
    const allVoices = await fetchAllVoices();

    const premadeVoices = availableVoices.filter(option => option.category === 'premade');

    const combinedOptions = [
      ...premadeVoices.map(option => ({ value: option.voice_id, label: option.name, audioUrl: option.preview_url })),
      ...allVoices.map(option => ({ value: option.voice_id, label: option.name, audioUrl: option.preview_url })),
    ];

    setAllOptions(combinedOptions);
  };

  const handlePlayPauseClick = (voiceId, isPlaying) => {
    if (isPlaying) {
      setCurrentlyPlaying(voiceId);
    } else {
      setCurrentlyPlaying(null);
    }
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);


  const handleToggleVoices = () => {
    setVoiceButton(false);
    setAvailableVoice(false);
  }

  const handleSelectCloneVoice = () => {
    setVoiceButton(false);
    setAvailableVoice(true);
    setAvailableVoiceSelect(audioResponse);
  }

  const handleVoiceButton = () => {
    setVoiceButton(true);
  }

  const handleAvailableVoiceButton = () => {
    setAvailableVoice(true);
  }

  const tabHandler = (event, newValue) => {
    setTabValue(newValue);

    if(newValue === 0){
      setType('text');
    } else if(newValue === 1) {
      setType('audio');
    } else {
      setType('audio');
    }
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleBlur = () => {
    setScriptTouched(true);
  };

  const handleAvailableVoiceBlur = () => {
    setAvailableVoiceTouched(true);
  }

  const handleCloneScriptBlur = () => {
    setCloneScriptTouched(true);
  }

  const handleCloneNameBlur = () => {
    setCloneNameTouched(true);
  }

  const handleAvailableStabilityBlur = () => {
    setAvailableStabilityTouch(true);
  }

  const handleAvailableSimilarityBoostBlur = () => {
    setAvailableSimilarityBoostTouch(true);
  }

  const handleAvailableStyleBlur = () => {
    setAvailableStyleTouch(true);
  }

  const error = (scriptTouched && text === '') || textEmojiError;
  const availableVoiceError = availableVoiceTouched && availableVoiceText === '';
  const cloneError = cloneScriptTouched && cloneText === '';
  const cloneNameError = cloneNameTouched && cloneName === '';
  const availableStabilityError = availableStabilityTouch && availableStability === '';
  const availableSimilarityBoostError = availableSimilarityBoostTouch && availableSimilarityBoost === '';
  const availableStyleError = availableStyleTouch && availableStyle === ''

  const handleText = (e) => {
    const newText = e.target.value;
  
    if (newText.length > 1024) {
      setTextEmojiError('Text cannot exceed 1024 characters');
      return;
    } else {
      setTextEmojiError('');
    }

    setText(newText);
    const emojiPattern = emojiRegex();

    if (emojiPattern.test(e.target.value)) {
      setTextEmojiError("Emoji's are not allowed in script");
    } else {
      setTextEmojiError('');
    }
  }

  const handleAvailableVoiceScript = (e) => {
    setAvailableVoiceText(e.target.value);
  }

  const handleCloneText = (e) => {
    setCloneText(e.target.value);
  }

  const handleCloneName = (e) => {
    setCloneName(e.target.value);
  }

  const handleStyle = (e) => {
    setStyle(e.label);
  }

  const handleAvailableStability = (e) => {
    setAvailableStability(e.target.value);
  }

  const handleAvailableSimilarityBoost = (e) => {
    setAvailableSimilarityBoost(e.target.value);
  }

  const handleAvailableStyle = (e) => {
    setAvailableStyle(e.target.value);
  }

  const handleAvailableSpeakerBoost = (selectedOption) => {
    setAvailableSpeakerBoost(selectedOption.value);
  }

  const handleLanguageChange = (selectedOption) => {
    setSelectedVoice(null);
    setSelectedLanguage(selectedOption.value);
  };

  const handleVoiceChange = (selectedOption) => {
    setSelectedVoice(selectedOption.value);
    setVoiceId(selectedOption.value);
  };

  const removeFile = (event) => {
    event.stopPropagation();
    setSelectedFile(null);
  }

  const isFileSizeValid = (file) => {
    const maxSize = 15 * 1024 * 1024;
    return file.size <= maxSize;
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!isFileSizeValid(file)) {
      enqueueSnackbar("File size exceeds the limit of 15MB", {
        variant: "Warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }

    // const replacedFileName = "file.mp3";

    // const replacedFile = new File([file], replacedFileName, { type: "audio/mp3" });
    setSelectedFile(file);

    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    audioRef.current = audio;
    audio.addEventListener('durationchange', handleDurationChange);
  }

  const handleFilesUpload = async (event) => {
    const file = event.target.files;

    if (selectedAudioFiles.length + file.length > 24) {
      enqueueSnackbar("You can upload a maximum of 25 audio files", {
        variant: "warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }

    const invalidFiles = Array.from(file).filter((file) => !isFileSizeValid(file));

    if (invalidFiles.length > 0) {
      invalidFiles.forEach((file) => {
        enqueueSnackbar(`File "${file.name}" exceeds the limit of 15MB`, {
          variant: "warning",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
      return;
    }

    // const replacedAudioFiles = Array.from(file).map((file, index) => {
    //   const replacedFileName = `file${index + 1}.mp3`;
    //   return new File([file], replacedFileName, { type: "audio/mp3" });
    // });
  
    setSelectedAudioFiles([...selectedAudioFiles, ...Array.from(file)]);

    // const audio = new Audio();
    // audio.src = URL.createObjectURL(file);
    // audioRef.current = audio;
    // audio.addEventListener('durationchange', handleDurationChange);
  };

  const removeFiles = (index) => {
    const updatedFiles = [...selectedAudioFiles];
    updatedFiles.splice(index, 1);
    setSelectedAudioFiles(updatedFiles);

    if (index === currentFileIndex && updatedFiles.length > 0) {
      setCurrentFileIndex(currentFileIndex === 0 ? 0 : currentFileIndex - 1);
    }
  };

  const handleArrowClick = (direction) => {
    const newIndex =
      direction === 'next'
        ? (currentFileIndex + 1) % selectedAudioFiles.length
        : (currentFileIndex - 1 + selectedAudioFiles.length) % selectedAudioFiles.length;
    setCurrentFileIndex(newIndex);
  };

  const handleDurationChange = () => {
    if (audioRef.current) {
      const refs = audioRef.current.duration;
      setVideoLength(Math.ceil(refs));
      audioRef.current.removeEventListener('durationchange', handleDurationChange);
      audioRef.current.src = '';
      const credits = Math.ceil(refs / 15);
      setCreditsBurn(credits);
    }
  };

  const handleAvailableVoiceSelection = (selectedOption) => {
    setAvailableVoiceSelect(selectedOption.value);
  }

  const handleModelSelection = (selectedOption) => {
    setModelSelection(selectedOption.value);
  }

  const discardAvailableVoice = () => {
    setDisableLoading(true);
    axios.delete(`${BASE_URL}/generate_speech`, {
      headers: {
        'Authorization': `Bearer ${access_token}`,
      },
      params:{
        user_id,
        audioResponse
      }
    })
    .then((res) => {
      console.log(res.data);
      setAvailableVoiceToggle(false);
      setAudioResponse('');
      setAvailableVoiceText('');
      setCloneText('');
      setDisableLoading(false);
    })
    .catch((err) => {
      console.log(err);
      enqueueSnackbar(err.response.data.description, {
        variant: "Warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setDisableLoading(false);
    })
  }

  const selectAvailableVoice = () => {
    setSelectAudio(true);
    setAvailableVoiceToggle(false);
  }

  const handleAvailableVoiceApi = () => {
    setAvailableVoiceLoading(true);
    try {
      axios.post(`${BASE_URL}/generate_speech`, {
        voice_id: availableVoiceSelect,
        model_id: modelSelection,
        text: availableVoiceText,
        similarity_boost: availableSimilarityBoost,
        stability: availableStability,
        style: availableStyle,
        use_speaker_boost: availableSpeakerBoost,
      }, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const audioData = res.data;
        setAvailableVoiceToggle(true);
        setAudioResponse(audioData);
        setSelectAudio(true);
        setAvailableVoiceLoading(false);
        setAvailableVoiceSelect('');
        setModelSelection('');
        mixpanel.track('Available clone voice used', {
          'Voice id': availableVoiceSelect,
        });
        // setAvailableVoiceText('');
      })
      .catch((err) => {
        console.log(err);
        setAvailableVoiceLoading(false);
      })
    } catch (err) {
      console.log(err);
      setAvailableVoiceLoading(false);
    }
  }

  const handleVoiceCloneApi = () => {
    setCloneVoiceLoading(true);
    const formData = new FormData();
      // formData.append("file", selectedAudioFiles);
      selectedAudioFiles.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });
      formData.append("user_id", user_id);
      formData.append("name", cloneName);
      formData.append("description", cloneText);
      formData.append("mode_id", modelSelection);
    try {
      axios.post(`${BASE_URL}/generate_voice`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const audioData = res.data.voice_id;
        setAudioResponse(audioData);
        setCloneVoiceToggle(true);
        setCloneVoiceLoading(false);
        fetchData();
        setTimeout(() => {
          setVoiceButton(false);
          setAvailableVoice(true);
          setAvailableVoiceSelect(audioResponse);
        }, 2000);
        mixpanel.track('New voice cloned', {
          'New clone name': cloneName,
        });
      })
      .catch((err) => {
        // enqueueSnackbar(err.response.data.description, {
        enqueueSnackbar('Something went wrong', {
          variant: "Warning",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setCloneVoiceLoading(false);
      })
    } catch (err) {
      console.log(err);
      setCloneVoiceLoading(false);
    }
  }

  const handleChatgptText = async (field) => {
    let prompt;

    if (field === 1) {
      prompt = text;
    } else if (field === 2) {
      prompt = availableVoiceText;
    } else if (field === 3) {
      prompt = cloneText;
    }

    setChatgptText(prompt);
    setChatgptModal(field);

    // if (prompt) {
    //   try {
    //     const response = await makeAPIRequest(prompt);
    //     if(field === 1) {
    //       setText(response);
    //     } else if(field === 2){
    //       setAvailableVoiceText(response);
    //     } else if(field === 3) {
    //       setCloneText(response);
    //     }
    //   } catch (error) {
    //     console.error('API request failed:', error);
    //   }
    // } else {
    //   console.error('Input field must have input.');
    // }
  }

  const makeAPIRequest = async (prompt) => {
    setTextLoading(true);
    try {
      const res = await axios.post(`${BASE_URL}/generate_ai_text`, {
        prompt_text: prompt
      })
      
      const newAvailableText = res.data.generated_text;
      setTextLoading(false);
      mixpanel.track('Chat GPT used', {
        'Given prompt': prompt,
      });
      if(newAvailableText) {
        return newAvailableText.trim();
      } else {
        enqueueSnackbar(res.data.error, {
          variant: "Warning",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (err) {
      setTextLoading(false);
      enqueueSnackbar(err.response.data.error, {
        variant: "Warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }

  return (
    <Card sx={{ maxWidth: "100%", minHeight: "fit-content", height: '100%' }}>
      <Grid container alignItems={"center"}>
        <AppBar
          position="static"
          sx={{ display: "flex", mb: 0, alignItems: "center" }}
        >
          <Tabs value={tabValue} onChange={tabHandler}>
            <Tab
              style={{minWidth:"30%"}}
              icon={<Message color="white" size="22px" />}
              label={
                <VuiTypography color="light" variant="body2">
                  Script
                </VuiTypography>
              }
              id='scriptTab'
            />
            <Tab
              style={{minWidth:"30%"}}
              icon={<AudiotrackIcon color="white" size="22px" />}
              label={
                <VuiTypography color="light" variant="body2">
                  Audio
                </VuiTypography>
              }
              id='audioTab'
            />
            <Tab
              style={{minWidth:"30%"}}
              icon={<SpatialTrackingIcon color="white" size="22px" />}
              label={
                <VuiTypography color="light" variant="body2">
                  Clone
                </VuiTypography>
              }
              id='cloneTab'
            />
          </Tabs>
        </AppBar>
      </Grid>
      {tabValue === 0 && (
        <Grid>
          <Card>
            <VuiInput
              placeholder="Type your script here..."
              multiline
              minRows={5}
              maxRows={10}
              onChange={(e) => handleText(e)}
              value={text}
              disabled={loading || textLoading}
              onBlur={handleBlur}
              error={error}
              data-clarity-mask="true"
              id='scriptTabInput'
            />
            {textEmojiError !== '' && <VuiTypography variant="body2" color="error" className="error">
              {textEmojiError}
            </VuiTypography>}
            <VuiBox
              sx={{
                width: "500px",
                borderRadius: "5px",
                marginTop: "2%",
                height: "48px",
                display: "flex",
                overflow: "hidden",
                alignItems: "center",
              }}
            >
              <Tooltip title="Character count" placement="top">
                <Button disabled={textLoading} sx={{border: '1px solid gray', color: 'whitesmoke', fontSize: '16px'}}>{scriptCharacterCount ? scriptCharacterCount : 0}</Button>
              </Tooltip>
              <ButtonGroup variant="text" aria-label="text button group">
                {/* <Tooltip title="Listen" placement="top">
                  <IconButton aria-label="Audio" color="light">
                    <VolumeUp />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add a break of 0.5sec" placement="top">
                  <IconButton aria-label="Timer" color="light">
                    <Alarm />
                  </IconButton>
                </Tooltip> */}
                {plan !== 'Trial' && <Tooltip title="Generate AI Script" placement="top">
                  <IconButton aria-label="AI" color="light" onClick={()=>handleChatgptText(1)} disabled={textLoading} id='scriptTabAiInput'>
                    {textLoading ? <CircularProgress size={14} color="white" /> : <IoMdColorWand />}
                  </IconButton>
                </Tooltip>}
              </ButtonGroup>
            </VuiBox>
            <VuiBox sx={{ height: "400px", marginTop: '10px' }} id='scriptTabOption'>
              <VuiBox>
                <VuiSelect
                  placeholder="Language"
                  options={languageOptions}
                  onChange={handleLanguageChange}
                  defaultValue={selectedLanguage}
                />
              </VuiBox>
              {selectedLanguage && (
                <VuiBox sx={{ marginTop: "2%" }}>
                  <VuiSelect
                    marginTop="2%"
                    placeholder="Voice"
                    options={voiceOptions[selectedLanguage]}
                    onChange={handleVoiceChange}
                    // defaultValue={selectedVoice}
                  />
                </VuiBox>
              )}
              <VuiBox sx={{ marginTop: "2%" }}>
                <VuiSelect
                  defaultValue={style}
                  placeholder={style ? style : "Style"}
                  onChange={(e) => handleStyle(e)}
                  options={[
                    { value: "neutral", label: "neutral" },
                    { value: "happy", label: "happy" },
                    { value: "surprise", label: "surprise" },
                    { value: "serious", label: "serious" },
                  ]}
                />
              </VuiBox>
            </VuiBox>
          </Card>
        </Grid>
      )}
      {tabValue === 1 && (
        <Grid sx={{ minHeight: "415px", textAlign: "center" }}>
          <Card>
            {selectAudio ? 
              <>
                <VuiTypography component='h6' sx={{color: 'gray !important', fontWeight: '300 !important', marginBottom: '20px'}}>
                  You have selected below audio file
                </VuiTypography>
                <Card sx={{boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)"}}>
                  {/* <audio controls style={{width: '100%', height: '50px'}}>
                    <source src={audioResponse} type="audio/mpeg" />
                  </audio> */}
                  <AudioPlayer audioSrc={audioResponse} />
                </Card>
              </> 
              :
              <>
                {selectedFile ? (
                  <VuiBox sx={{ height: "400px" }}>
                    <Card sx={{boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)"}}>
                      <VuiTypography>{selectedFile.name}</VuiTypography>
                      <ClearIcon sx={{color: 'whitesmoke', boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)", borderRadius: '100%', position: "absolute", right: 10, top: 6, cursor: 'pointer'}} onClick={removeFile} />
                      <AudioPlayer audioSrc={URL.createObjectURL(selectedFile)} />
                    </Card>
                    <VuiBox sx={{ marginTop: "5%" }}>
                        <VuiSelect
                          defaultValue={style}
                          placeholder={style ? style : "Style"}
                          onChange={(e) => handleStyle(e)}
                          options={[
                            { value: "neutral", label: "neutral" },
                            { value: "happy", label: "happy" },
                            { value: "surprise", label: "surprise" },
                            { value: "serious", label: "serious" },
                          ]}
                        />
                      </VuiBox>
                    </VuiBox>
                ) : (
                  <>
                    <label htmlFor="audio-file" style={{cursor: 'pointer'}}>
                      <Card sx={{boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)", color: 'whitesmoke', marginBottom: '10px', textAlign: 'center', display: 'flex', alignItems: 'center'}}>
                        <VuiTypography component='h6' sx={{color: 'gray !important', fontWeight: '300 !important'}}>
                          Upload file here
                          <input
                            accept="audio/mp3"
                            style={{ display: 'none' }}
                            type="file"
                            id="audio-file"
                            name="audio-file"
                            value={selectedFile}
                            onChange={handleFileUpload}
                          />
                        </VuiTypography>
                        <CloudUploadIcon />
                      </Card>
                    </label>
                  </>
                )}
  
              </>
            }
          </Card>
        </Grid>
      )}
      {tabValue === 2 && (
        <>
          {plan === 'Trial' && <VuiBox sx={{position: 'absolute', top: '17%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '2'}}>
            <Button variant="contained" sx={{color: 'whitesmoke'}} onClick={handleUpgradeClick}>
              Upgrade plan
            </Button>
          </VuiBox>}
          <Grid sx={{ minHeight: "600px" }} className={plan==='Trial' && "blurred-content"}>
            <Card>
              {(availableVoice || voiceButton) && <ArrowBackIcon color="white" onClick={handleToggleVoices} sx={{cursor: 'pointer', marginBottom: '10px'}} />}
              {!voiceButton && (
                <>
                  {!availableVoice && <Button onClick={handleAvailableVoiceButton} variant="contained" sx={{color: 'whitesmoke', marginTop: '15px'}}>Use Available Voice</Button>}
                  {selectAudio ? 
                    <>
                      <VuiTypography component='h6' sx={{color: 'gray !important', fontWeight: '300 !important', marginBottom: '20px', marginTop: '20px', textAlign: 'center'}}>
                        You have selected below audio file
                      </VuiTypography>
                      <Card sx={{boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)"}}>
                        {/* <audio controls style={{width: '100%', height: '50px'}}>
                          <source src={audioResponse} type="audio/mpeg" />
                        </audio> */}
                        <DeleteOutlineIcon sx={{color: 'whitesmoke', borderRadius: '100%', position: "absolute", right: 10, top: 6, cursor: 'pointer'}} onClick={()=>{setSelectAudio(null); setAvailableVoiceText(''); setAvailableVoiceTouched(false); setAvailableSimilarityBoost(''); setAvailableStability(''); setAvailableStyle(''); }} />
                        <AudioPlayer audioSrc={audioResponse} />
                      </Card>
                    </> 
                    : 
                    availableVoice && 
                    <>
                      {availableVoiceLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", marginTop: "20%" }}>
                          <CircularProgress size={30} color="white" />
                        </div> 
                      : 
                        <VuiBox sx={{minHeight: '500px'}}>
                          <h5 style={{color: 'whitesmoke', fontWeight: 400}}>Available Voices</h5>
                          <VuiSelect
                            placeholder="None"
                            options={allOptions}
                            onChange={handleAvailableVoiceSelection}
                            enableAudio={true}
                            currentlyPlaying={currentlyPlaying}
                            onPlayPauseClick={handlePlayPauseClick}
                          />
                          <VuiTypography variant='h6' sx={{marginTop: '5px', marginBottom: '5px'}}>Select language</VuiTypography>
                          <VuiSelect
                            placeholder="None"
                            options={[
                              { value: "eleven_monolingual_v1", label: "English" },
                              { value: "eleven_multilingual_v1", label: "German" },
                              { value: "eleven_multilingual_v1", label: "Polish" },
                              { value: "eleven_multilingual_v1", label: "Spanish" },
                              { value: "eleven_multilingual_v1", label: "Italian" },
                              { value: "eleven_multilingual_v1", label: "French" },
                              { value: "eleven_multilingual_v1", label: "Portugeese" },
                              { value: "eleven_multilingual_v1", label: "Hindi" },
                            ]}
                            onChange={handleModelSelection}
                          />
                          <VuiTypography variant='h6' sx={{marginTop: '5px', marginBottom: '5px'}}>Stability</VuiTypography>
                          <VuiInput
                            placeholder="0-1"
                            onChange={(e) => handleAvailableStability(e)}
                            value={availableStability}
                            disabled={textLoading || availableVoiceLoading}
                            onBlur={handleAvailableStabilityBlur}
                            error={availableStabilityError}
                            data-clarity-mask="true"
                          />
                          <VuiTypography variant='h6' sx={{marginTop: '5px', marginBottom: '5px'}}>Similarity boost</VuiTypography>
                          <VuiInput
                            placeholder="0-1"
                            onChange={(e) => handleAvailableSimilarityBoost(e)}
                            value={availableSimilarityBoost}
                            disabled={textLoading || availableVoiceLoading}
                            onBlur={handleAvailableSimilarityBoostBlur}
                            error={availableSimilarityBoostError}
                            data-clarity-mask="true"
                          />
                          <VuiTypography variant='h6' sx={{marginTop: '5px', marginBottom: '5px'}}>Style</VuiTypography>
                          <VuiInput
                            placeholder="0-1"
                            onChange={(e) => handleAvailableStyle(e)}
                            value={availableStyle}
                            disabled={textLoading || availableVoiceLoading}
                            onBlur={handleAvailableStyleBlur}
                            error={availableStyleError}
                            data-clarity-mask="true"
                          />
                          <VuiTypography variant='h6' sx={{marginTop: '5px', marginBottom: '5px'}}>Speaker boost</VuiTypography>
                          <VuiSelect
                            placeholder="None"
                            options={[
                              { value: "True", label: "True" },
                              { value: "False", label: "False" }
                            ]}
                            onChange={handleAvailableSpeakerBoost}
                          />
                          <VuiInput
                            placeholder="Type your script here..."
                            multiline
                            minRows={5}
                            maxRows={10}
                            onChange={(e) => handleAvailableVoiceScript(e)}
                            value={availableVoiceText}
                            disabled={textLoading || availableVoiceLoading}
                            onBlur={handleAvailableVoiceBlur}
                            error={availableVoiceError}
                            data-clarity-mask="true"
                            sx={{marginTop:'15px'}}
                          />
                          <VuiBox
                            sx={{
                              width: "500px",
                              borderRadius: "5px",
                              marginTop: "2%",
                              height: "48px",
                              display: "flex",
                              overflow: "hidden",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip title="Character count" placement="top">
                              <Button disabled={textLoading} sx={{border: '1px solid gray', color: 'whitesmoke', fontSize: '16px'}}>{voiceScriptCharacterCount ? voiceScriptCharacterCount : 0}</Button>
                            </Tooltip>
                            <ButtonGroup variant="text" aria-label="text button group">
                              {/* <Tooltip title="Listen" placement="top">
                                <IconButton aria-label="Audio" color="light">
                                  <VolumeUp />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Add a break of 0.5sec" placement="top">
                                <IconButton aria-label="Timer" color="light">
                                  <Alarm />
                                </IconButton>
                              </Tooltip> */}
                              {plan !== 'Trial' && <Tooltip title="Generate your text using AI" placement="top">
                                <IconButton aria-label="AI" color="light" onClick={()=>handleChatgptText(2)} disabled={availableVoiceText === '' || textLoading}>
                                  {textLoading ? <CircularProgress size={14} color="white" /> : <IoMdColorWand />}
                                </IconButton>
                              </Tooltip>}
                            </ButtonGroup> 
                          </VuiBox>
                          <Button variant="contained" sx={{color: 'whitesmoke', width: '100%', "&.Mui-disabled": { opacity: 0.5, cursor: "not-allowed", backgroundColor: "gray", color: 'whitesmoke' }}} onClick={handleAvailableVoiceApi} disabled={availableVoiceSelect === '' || modelSelection === '' || availableVoiceText === '' || availableSimilarityBoost === '' || availableStability === '' || availableStyle === ''}>Generate</Button>
                        </VuiBox>
                      }
                    </>
                  }
                </>
              )}
              {(!availableVoice && !voiceButton) && <VuiTypography variant='h4' sx={{textAlign: 'center', marginTop: '15px'}}>or</VuiTypography>}
              {!availableVoice && 
                (
                  <>
                    {!voiceButton && <Button onClick={handleVoiceButton} variant="contained" sx={{color: 'whitesmoke', marginTop: '15px'}}>Generate Voice Clone</Button>}
                    {voiceButton && (
                      <>
                      {(cloneVoiceToggle && audioResponse !== '') ? (
                        <div style={{marginTop: '50px', textAlign: 'center'}}>
                          <h5 style={{color: 'gray', marginBottom: '20px', textAlign: 'center'}}>Voice Clone has been generated successfully. <br /> You can select the voice from available voices.</h5>
                          <Button variant="outlined" onClick={handleSelectCloneVoice} disabled={disableLoading}>{disableLoading ? <CircularProgress size={14} color="white" /> : 'Go to available voices'}</Button>
                          {/* <Card sx={{boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)"}}>
                            <AudioPlayer audioSrc={audioResponse} />
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '10px'}}>
                              <Tooltip title='Delete this sample'>
                                <Button variant="outlined" onClick={discardAvailableVoice} disabled={disableLoading}>{disableLoading ? <CircularProgress size={14} color="white" /> : 'Discard'}</Button>
                              </Tooltip>
                              <Tooltip title='Select this audio for making AI video'>
                                <Button variant="outlined" sx={{"&.Mui-disabled": { opacity: 0.5, cursor: "not-allowed", backgroundColor: "gray", color: 'whitesmoke' }}} onClick={selectAvailableVoice} disabled={selectAudio}>{selectAudio ? "Selected" : 'Select'}</Button> 
                              </Tooltip>
                            </div>
                          </Card> */}
                        </div>
                      ) : cloneVoiceLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", marginTop: "20%" }}>
                          <CircularProgress size={30} color="white" />
                        </div> : 
                        <>
                          <VuiTypography variant='h6' sx={{marginBottom: '5px', marginTop: '15px'}}>Voice Name </VuiTypography>
                          <VuiInput
                            multiline
                            onChange={(e) => handleCloneName(e)}
                            value={cloneName}
                            onBlur={handleCloneNameBlur}
                            error={cloneNameError}
                            sx={{marginBottom: '25px'}}
                            disabled={textLoading || cloneVoiceLoading}
                            data-clarity-mask="true"
                          />
                          {selectedAudioFiles.length > 0 ? (
                            <Card sx={{ boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)" }}>
                              <ClearIcon sx={{ color: 'whitesmoke', boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)", borderRadius: '100%', position: "absolute", right: 15, top: 10, cursor: 'pointer' }} onClick={() => removeFiles(currentFileIndex)} />
                              <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                {selectedAudioFiles.length > 1 && <IconButton disabled={currentFileIndex === 0} color="white" onClick={() => handleArrowClick('prev')} sx={{cursor: 'pointer'}}>
                                  <ArrowBackIcon fontSize="small" />
                                </IconButton>}
                                <VuiBox>
                                  <VuiTypography sx={{ textAlign: 'center', marginBottom: '5px' }}>{selectedAudioFiles[currentFileIndex].name}</VuiTypography>
                                  <AudioPlayer audioSrc={URL.createObjectURL(selectedAudioFiles[currentFileIndex])} />
                                </VuiBox>
                                {selectedAudioFiles.length > 1 && <IconButton disabled={currentFileIndex === selectedAudioFiles.length-1} color="white" onClick={() => handleArrowClick('next')} sx={{cursor: 'pointer'}}>
                                  <ArrowForwardIcon fontSize="small" />
                                </IconButton>}
                              </VuiBox>
                              <label htmlFor="audio-files-input" style={{ cursor: 'pointer', marginLeft: 'auto', marginRight: 'auto' }}>
                                <VuiTypography variant='h6' sx={{marginTop: '10px', border: '1px solid gray', maxWidth: '150px', background: 'white', padding: '0px 5px', borderRadius: '5px'}}>
                                  Upload more
                                  <input
                                    accept="audio/mp3"
                                    style={{ display: 'none' }}
                                    type="file"
                                    id="audio-files-input"
                                    name="audio-files-input"
                                    // value={selectedAudioFiles}
                                    multiple
                                    onChange={handleFilesUpload}
                                  />
                                </VuiTypography>
                              </label>
                            </Card>
                          ) : (
                            <label htmlFor="audio-file-input" style={{ cursor: 'pointer' }}>
                              <Card sx={{ boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)", color: 'whitesmoke', marginBottom: '10px', textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                                <VuiTypography component='h6' sx={{ color: 'gray !important', fontWeight: '300 !important' }}>
                                  Upload voice samples here!
                                  <input
                                    accept="audio/mp3"
                                    style={{ display: 'none' }}
                                    type="file"
                                    id="audio-file-input"
                                    name="audio-file-input"
                                    value={selectedAudioFiles}
                                    multiple
                                    onChange={handleFilesUpload}
                                  />
                                </VuiTypography>
                                <CloudUploadIcon />
                              </Card>
                            </label>
                          )}
                          <VuiTypography variant='h5' sx={{marginTop: '25px', marginBottom: '5px'}}>Samples {selectedAudioFiles ? selectedAudioFiles.length : 0}/25 </VuiTypography>
                            <div style={{border: '1px dashed gray', maxHeight: '100px', padding: '5px 10px', overflowY: 'scroll'}}>
                              {selectedAudioFiles.length > 0 ? 
                                selectedAudioFiles.map((file) => (
                                  <p style={{color: 'gray', fontSize: '12px'}}>{file.name}</p>
                                ))
                                : <p style={{color: 'gray', fontSize: '12px'}}>Currently no sample selected yet.</p>
                              }
                            </div>
                          <VuiTypography variant='h6' sx={{marginTop: '15px', marginBottom: '5px'}}>Select language</VuiTypography>
                          <VuiSelect
                            placeholder="None"
                            options={[
                              { value: "eleven_monolingual_v1", label: "English" },
                              { value: "eleven_multilingual_v1", label: "German" },
                              { value: "eleven_multilingual_v1", label: "Polish" },
                              { value: "eleven_multilingual_v1", label: "Spanish" },
                              { value: "eleven_multilingual_v1", label: "Italian" },
                              { value: "eleven_multilingual_v1", label: "French" },
                              { value: "eleven_multilingual_v1", label: "Portugeese" },
                              { value: "eleven_multilingual_v1", label: "Hindi" },
                            ]}
                            onChange={handleModelSelection}
                          />
                          <VuiTypography variant='h5' sx={{marginTop: '25px'}}>Description </VuiTypography>
                          <VuiInput
                            placeholder="How would you describe the voice? e.g. 'An old American make voice with a sight hoarseness in his throat. Perfect for news.'"
                            multiline
                            minRows={5}
                            maxRows={10}
                            onChange={(e) => handleCloneText(e)}
                            value={cloneText}
                            disabled={textLoading || cloneVoiceLoading}
                            onBlur={handleCloneScriptBlur}
                            error={cloneError}
                            sx={{marginTop: '5px'}}
                            data-clarity-mask="true"
                          />
                          <VuiBox
                            sx={{
                              width: "500px",
                              borderRadius: "5px",
                              marginTop: "2%",
                              height: "48px",
                              display: "flex",
                              overflow: "hidden",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip title="Character count" placement="top">
                              <Button disabled={textLoading} sx={{border: '1px solid gray', color: 'whitesmoke', fontSize: '16px'}}>{ cloneScriptCharacterCount ? cloneScriptCharacterCount : 0}</Button>
                            </Tooltip>
                            {plan !== 'Trial' && <ButtonGroup variant="text" aria-label="text button group">
                              <Tooltip title="Generate AI Script" placement="top">
                                <IconButton aria-label="AI" color="light" onClick={()=>handleChatgptText(3)} disabled={cloneText === '' || textLoading}>
                                  {textLoading ? <CircularProgress size={14} color="white" /> : <IoMdColorWand />}
                                </IconButton>
                              </Tooltip>
                            </ButtonGroup>}
                          </VuiBox>
                          <Button variant="contained" sx={{marginTop: '10px', color: 'whitesmoke', "&.Mui-disabled": { opacity: 0.5, cursor: "not-allowed", backgroundColor: "gray", color: 'whitesmoke' }}} onClick={handleVoiceCloneApi} disabled={cloneName === '' || cloneText === '' || selectedFile === ''}>Generate</Button>
                        </>
                      }
                      </>
                    )}
                  </>
                )
              }
            </Card>
          </Grid>
        </>
      )}
      {chatgptModal && 
        <ChatGptModal open={chatgptModal} setOpen={setChatgptModal} chatgptText={chatgptText} setChatgptText={setChatgptText} makeAPIRequest={makeAPIRequest} setText={setText} setAvailableVoiceText={setAvailableVoiceText} setCloneText={setCloneText} textEmojiError={textEmojiError} setTextEmojiError={setTextEmojiError} handleText={handleText} />
      }
    </Card>
  );
}

export default Sidediv;

// on 539 line if needed
// {selectedFile === null && (
//   <>
//     <Divider light />
//     <Card sx={{marginTop: '10px', boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)"}}>
//       <VuiTypography sx={{marginBottom: '10px'}}>
//         Record Audio here ↓
//       </VuiTypography>
//       {/* <RecorderBox setRecordedFile={setRecordedFile} /> */}
//       <AudioRecorder setRecordedFile={setRecordedFile} />
//     </Card>
//   </>
// )} 
