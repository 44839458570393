import { Card } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import React from 'react';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import VuiInput from 'components/VuiInput';

const Variables = ({manualSubtitles, selectedWords, selectedIndices, setSelectedWords, setSelectedIndices, selectedVariablesName, setSelectedVariablesName, project, setProject}) => {
  const words = manualSubtitles.split(' ');

  function cleanWord(word) {
    return word.replace(/[^a-zA-Z0-9\s]/g, '');
  }  

  const handleWordClick = (index) => {
    if (selectedIndices.includes(index)) {
      setSelectedIndices(selectedIndices.filter((i) => i !== index));
      setSelectedWords(selectedWords.filter((word) => word !== cleanWord(words[index])));
    } else {
      setSelectedIndices([...selectedIndices, index]);
      setSelectedWords([...selectedWords, cleanWord(words[index])]);
    }
  };

  const handleRemoveWord = (word) => {
    const wordIndex = words.indexOf(word);
    if (wordIndex !== -1) {
      setSelectedIndices(selectedIndices.filter((i) => i !== wordIndex));
      setSelectedWords(selectedWords.filter((w) => w !== word));
      setSelectedVariablesName((prevNames) => {
        const updatedNames = [...prevNames];
        updatedNames.splice(word, 1);
        return updatedNames;
      });
    }
  };

  return (
    <VuiBox>
      <VuiBox sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
        <div style={{flex: selectedWords.length === 0 ? '1' : '0.5', marginRight: "5px"}}>
          {/* <VuiTypography textAlign= 'center' color='white'>Click on below words to select your variables to make personalized pitch</VuiTypography> */}
          <VuiTypography textAlign= 'center' color='white'>Below variable placement will be the final placement in script for creating personalized videos</VuiTypography>
          {/* <VuiTypography variant='h6' textAlign= 'center' sx={{marginBottom: '15px'}}>e.g, name, location </VuiTypography> */}
          {/* <Card sx={{color: 'whitesmoke', display: 'flex', flexWrap: 'wrap', flexDirection: 'row'}}> */}
          <Card sx={{color: 'whitesmoke', display: 'flex', flexDirection: 'row'}}>
            {project.variableType === 'Suffix' ? 
              <VuiTypography variant='h5'>
                {manualSubtitles}&nbsp;&nbsp;
                <span
                  style={{
                    cursor: 'pointer',
                    border: '1px solid whitesmoke',
                    marginRight: '5px',
                    fontSize: '16px',
                    color: 'black',
                    backgroundColor: 'white',
                    padding: "2px 8px",
                    borderRadius: "2px 8px",
                    lineHeight: 2,
                  }}
                >
                  {project.personalizationVariableType}
                </span>
              </VuiTypography>
            :
              <VuiTypography variant='h5'>
                <span
                  style={{
                    cursor: 'pointer',
                    border: '1px solid whitesmoke',
                    marginRight: '5px',
                    fontSize: '14px',
                    color: 'black',
                    backgroundColor: 'white',
                    padding: "2px 8px",
                    borderRadius: "2px 8px",
                    lineHeight: 2,
                  }}
                >
                  {project.personalizationVariableType}
                </span>&nbsp;&nbsp;
                {manualSubtitles}
              </VuiTypography>  
            }
            {/* {project.variableType === 'Suffix' && <VuiTypography>{manualSubtitles}</VuiTypography>}
            <span
              style={{
                cursor: 'pointer',
                border: '1px solid whitesmoke',
                marginRight: '5px',
                fontSize: '14px',
                color: 'black',
                backgroundColor: 'white',
                padding: "2px 8px",
                borderRadius: "2px 8px",
                lineHeight: 2,
              }}
            >
              {project.personalizationVariableType}
            </span>
            {project.variableType === 'Prefix' && <VuiTypography>{manualSubtitles}</VuiTypography>} */}
            {/* {words.map((word, index) => (
              <span
                key={index}
                onClick={() => handleWordClick(index)}
                style={{
                  cursor: 'pointer',
                  border: selectedIndices.includes(index) ? '1px solid whitesmoke' : 'none',
                  marginRight: '5px',
                  fontSize: '14px',
                  color: selectedIndices.includes(index) ? 'black' : '',
                  backgroundColor: selectedIndices.includes(index) ? 'white' : '',
                  padding: selectedIndices.includes(index) ? "2px 8px": '',
                  borderRadius: selectedIndices.includes(index) ? "2px 8px": '',
                  lineHeight: 2,
                }}
              >
                {word}
              </span>
            ))} */}
          </Card>
        </div>
        {/* {selectedWords.length > 0 && <div style={{flex: '0.5'}}> */}
        {<div style={{flex: '0.5'}}>
          <VuiTypography textAlign= 'center' color='white'>{project.variableType}</VuiTypography>
          <VuiTypography variant='h6' textAlign= 'center' sx={{marginBottom: '15px'}}>name your {project.variableType} below</VuiTypography>
          <Card sx={{color: 'whitesmoke', display: 'flex', flexDirection: 'column', height: '20vh'}}>
            <div style={{display: 'flex', alignItems: 'center', padding: '2px', justifyContent: 'space-between'}}>
              <h5 style={{margin: '0px 5px'}}>{project.personalizationVariableType}</h5>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <VuiInput 
                  sx={{maxWidth: '200px', margin: '0px 5px'}} 
                  value={project.variableText} 
                  onChange={
                    (e) => setProject({...project, variableText: e.target.value})
                  }
                />
              </div>
            </div>
          </Card>
          {/* <Card sx={{color: 'whitesmoke', display: 'flex', flexWrap: 'wrap', flexDirection: 'column'}}>
            {selectedWords.map((word, index) => (
              <div style={{display: 'flex', alignItems: 'center', padding: '2px', justifyContent: 'space-between'}}>
                <h5 key={index} style={{margin: '0px 5px'}}>{word}</h5>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <VuiInput key={index} sx={{maxWidth: '200px', margin: '0px 5px'}} value={selectedVariablesName[index]} onChange={(e) => {
                    const updatedNames = [...selectedVariablesName]; // Create a copy of the array
                    updatedNames[index] = e.target.value; // Update the name at the current index
                    setSelectedVariablesName(updatedNames); // Update the state
                  }}/>
                  <IndeterminateCheckBoxIcon color='error' onClick={() => handleRemoveWord(word)} sx={{cursor: 'pointer'}} />
                </div>
              </div>
            ))}
          </Card> */}
        </div>}
      </VuiBox>
    </VuiBox>
  )
}

export default Variables