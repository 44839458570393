import VuiBox from 'components/VuiBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from './DataTable/index';
import React, { useEffect, useState } from 'react';
import dataTableData from './dataTableData';
import VuiTypography from 'components/VuiTypography';
import { Card } from '@mui/material';
import axios from 'axios';
import { useVisionUIController } from 'context';

function StreamingLogs() {
  const [controller] = useVisionUIController();
  const { userDetails } = controller;
  const {user_id} = userDetails;
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "SR", accessor: "index", width: "10%" },
      { Header: "Agent", accessor: "source_url", width: "5%" },
      { Header: "ID", accessor: "id", width: "25%" },
      { Header: "STREAM ID", accessor: "stream_id", width: "25%" },
      { Header: "CREATED AT", accessor: "session_start", width: "25%" },
      { Header: "STREAM STATUS", accessor: "is_active", width: "10%" },
    ],
    rows: []
  });

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    return `${dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}, ${dateTime.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' })}`;
  };

  const fetchStreamingDetails = async () => {
    await axios.get('https://api.quickvideo.ai/v1/logs/streams', {
      headers: {
        'Authorization': `Bearer ${user_id}`
      }
    }).then((res) => {
      const formattedRows = res.data.map(row => ({
        ...row,
        index: res.data.indexOf(row) + 1,
        session_start: formatDateTime(row.session_start),
        is_active: row.is_active ? 'Active' : 'Inactive',
        source_url: (
          <img src={row.source_url} alt='avatar' width={40} height={40} style={{borderRadius: '100%'}} />
        )
      }));
      
      setTableData(prevState => ({
        ...prevState,
        rows: formattedRows
      }));
    })
    .catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    fetchStreamingDetails();
  }, [])
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <VuiBox mb={3}>
          <Card>
            <VuiBox p={3} pl={0} lineHeight={1}>
              <VuiTypography variant="h5" fontWeight="medium" color="white">
                Streaming Logs
              </VuiTypography>
            </VuiBox>
            <DataTable table={tableData} canSearch />
          </Card>
        </VuiBox>
      </VuiBox>
    </DashboardLayout>
  )
}

export default StreamingLogs