import { AppBar, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useEffect, useRef, useState } from 'react';
import chatgpticon from './images/openai-chatgpt.jpeg';
import urltoaiicon from './images/ico_url_2_video.png';
import solidicon from './images/chromakey_paris.jpeg';
import defaulticon from './images/ico_default_template.png';
import EditingSection from './EditingSection';
import axios from 'axios';
import BASE_URL from 'context/api';
import { useVisionUIController } from "context";
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import ConfirmationModal from './confirmationModal';
import './editor.css';
import Lock from 'lock';
import QuestionModal from './questionModal';

const MainSaas = () => {
  const [controller] = useVisionUIController();
  const { userDetails } = controller;
  const { plan, user_id } = userDetails;
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [visibleItems, setVisibleItems] = useState(10);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [questionMod, setQuestionMod] = useState(false);
  const [finalApiHit, setFinalApiHit] = useState(false);
  const [createVideo, setCreateVideo] = useState(true);
  const [selectBreak, setSelectBreak] = useState('0.5s');
  const [selectSpeed, setSelectSpeed] = useState('1.0x');
  const [changeInput, setChangeInput] = useState(false);
  const [videoName, setVideoName] = useState('');
  const [selectLanguage, setSelectLanguage] = useState('English');
  const [selectVoice, setSelectVoice] = useState('');
  const [voiceOption, setVoiceOption] = useState([]);
  const [models, setModels] = useState([]);
  const [touched, setTouched] = useState({
    template: false,
    model: true,
    text: false,
    subtitle: false,
    image: false,
    background: false,
    video: false,
    audio: false,
    shapes: false,
    animation: false,
  });

  const [alignment, setAlignment] = useState('sl');
  const [value, setValue] = useState('one');
  const [fonts, setFonts] = useState(["Times New Roman","Georgia","Garamond","Baskerville","Palatino","Arial","Helvetica","Calibri","Verdana","Futura","Courier New","Consolas","Inconsolata","Monaco","Liberation Mono","Brush Script","Lucida Calligraphy","Zapfino","Pacifico","Snell Roundhand","Comic Sans MS","Papyrus","Jokerman","Curlz MT","Chiller","Century Gothic","Avant Garde Gothic","Didot","Bodoni","Rockwell","D'Nealian","Schoolbell","Comic Neue","Quicksand","Architect's Daughter","Edwardian Script","Brush Script MT","Snell Roundhand","French Script","Palace Script MT","Impact","Stencil", "Playbill", "Bauhaus 93", "Broadway", "Rockwell", "Courier", "Egyptienne", "Clarendon", "Archer"]);
  const [selectedFont, setSelectedFont] = useState('Times New Roman');
  const [fontSize, setFontSize] = useState([10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50]);
  const [selectedFontSize, setSelectedFontSize] = useState(12);

  const [selectedFile, setSelectedFile] = useState(null);
  const [stockImages, setStockImages] = useState([]);
  const [imageQuery, setImageQuery] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  // const [selectedBackground, setSelectedBackground] = useState('#141041');
  const [selectedBackground, setSelectedBackground] = useState('#F0F0FF');
  const [uploadedBackground, setUploadedBackground] = useState(null);
  const [backgroundTabValue, setBackgroundTabValue] = useState(0);

  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [stockVideos, setStockVideos] = useState([]);
  const [videoQuery, setVideoQuery] = useState('');
  const [videoPage, setVideoPage] = useState(1);
  const [loadingModal, setLoadingModal] = useState(false);
  const [uploadedAudio, setUploadedAudio] = useState(null);
  const [imageTotalPages, setImageTotalPages] = useState(null);
  const [videoTotalPages, setVideoTotalPages] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [stockShapes, setStockShapes] = useState([]);
  const [shapesQuery, setShapesQuery] = useState('basic shape');
  const [indexToStore, setIndexToStore] = useState({
    header: -1,
    title: -1,
    main_text: -1,
    caption: -1,
  });
  const [uploadedIntroVideos, setUploadedIntroVideos] = useState([]);
  const [uploadedOutroVideos, setUploadedOutroVideos] = useState([]);

  const calculateDimensions = (aspectRatio, baseHeight) => {
    let height = baseHeight;
    let width;
  
    if (aspectRatio === '4:3') {
      width = (4 / 3) * height;
    } else if (aspectRatio === '16:9') {
      width = (16 / 9) * height;
    }
  
    return { width, height };
  };

  const initialScene = { 
    snapshot: null, 
    script: '', 
    background: '#F0F0FF',
    selectedModel: null,
    selectedIntro: null,
    selectedOutro: null,
    selectedImage: [],
    selectedVideo: null,
    selectedShape: [],
    avatarVideo: false,
    textToggle: {
      header: false,
      title: false,
      main_text: false,
      caption: false
    },
    textArrays: {
      header: [],
      title: [],
      main_text: [],
      caption: [],
    },
    audio: {selectBreak: '0.5s', selectSpeed: '1.0x', selectLanguage: 'English', selectVoice: {value: '21m00Tcm4TlvDq8ikWAM', label: 'Rachel (female)'}},
    resolution: {
      type: '720p (16:9)',
      expectedSize: {
        width: 1280,
        height: 720
      },
      ...calculateDimensions('16:9', 360),
    },
  };

  const [openSettings, setOpenSettings] = useState({
    model: false,
    text: false,
    image: false,
    video: false,
    audio: false,
    shape: false,
    intro: false,
    outro: false,
  })

  const [showIO, setShowIO] = useState({
    intro: false,
    outro: false,
  });

  const [basicDetails, setBasicDetails] = useState({
    tutorialName: '',
    businessName: '',
    productName: '',
    video: null
  });

  const [scenes, setScenes] = useState([initialScene]);
  const [intro, setIntro] = useState(null);
  const [outro, setOutro] = useState(null);
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);
  const componentRef = useRef(null);

  const updateSceneKey = (index, key, value) => {
    setScenes(prevScenes => {
      const updatedScenes = [...prevScenes];
      updatedScenes[index][key] = value;
      return updatedScenes;
    });
  };

  const addScene = () => {
    setScenes(prevScenes => [...prevScenes, { ...initialScene }]);
    setCurrentSceneIndex(prevIndex => prevIndex + 1);
    setOpenSettings({
      model: false,
      text: false,
      image: false,
      video: false,
      audio: false,
      shape: false,
      intro: false,
      outro: false,
    });
  };

  const deleteScene = (index) => {
    setScenes((prevScenes) => {
      const updatedScenes = [...prevScenes];
      updatedScenes.splice(index, 1);
  
      const newIndex = Math.min(index, updatedScenes.length - 1);
      setCurrentSceneIndex(newIndex);
  
      return updatedScenes;
    });
    setOpenSettings({
      model: false,
      text: false,
      image: false,
      video: false,
      audio: false,
      shape: false,
      intro: false,
      outro: false,
    });
  };

  const handleBoxClick = (index) => {
    setCurrentSceneIndex(index);
    setOpenSettings({
      model: false,
      text: false,
      image: false,
      video: false,
      audio: false,
      shape: false,
      intro: false,
      outro: false,
    });
    // localStorage.setItem('currentSceneIndex', JSON.stringify(index));
  };

  // useEffect(() => {
  //   const storedIndex = JSON.parse(localStorage.getItem('currentSceneIndex'));
  //   if (storedIndex !== null) {
  //     setCurrentSceneIndex(storedIndex);
  //   }
  // }, []);

  const [items, setItems] = useState([]);
  
  const lastVideoRef = useRef(null);
  const imageObserver = useRef(null);
  const videoObserver = useRef(null);
  const lastImageRef = useRef(null);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    axios.get(`${BASE_URL}/stock_images`, {
      params: {shapesQuery},
    })
    .then((res) => {
      setStockShapes(res.data.icons);
    })
    .catch((err) => console.log(err));
  }, [shapesQuery]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        let apiUrl = `https://api.pexels.com/v1/popular?per_page=20&page=${page}`;

        if (imageQuery) {
          apiUrl = `https://api.pexels.com/v1/search?query=${imageQuery}&per_page=20&page=${page}`;
        }
        const response = await axios.get(
          apiUrl, {
            headers: {
              Authorization: 'MJYis5oZSkobDNMuOjniNnsiSjgwkpQdxOW8x3JyMm1tk6nBwdwRfeYU',
              'Content-Type': 'application/json',
            },
            params: {
              content_filter: 'high',
            },
          }
        );

        if (page > response.data.photos / 20) {
          setLoading(false);
          return;
        }

        setStockImages((prevImages) => [...prevImages, ...response.data.photos]);
        setImageTotalPages(Math.ceil(response.data.total / 20));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchImages();
  }, [imageQuery, page]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setLoading(true);
        let apiUrl = `https://api.pexels.com/videos/popular?per_page=20&page=${videoPage}`;

        if (videoQuery) {
          apiUrl = `https://api.pexels.com/videos/search?query=${videoQuery}&per_page=20&page=${videoPage}`;
        }
        const response = await axios.get(
          // `https://pixabay.com/api/videos/?key=39676690-85c2818d2b6382af1f6fc467e&q=${videoQuery}&page=${videoPage}&per_page=20&safesearch=true&orientation=horizontal`
          apiUrl, {
            headers: {
              Authorization: 'MJYis5oZSkobDNMuOjniNnsiSjgwkpQdxOW8x3JyMm1tk6nBwdwRfeYU',
              'Content-Type': 'application/json',
            },
            params: {
              content_filter: 'high',
            },
          }
        );

        if (videoPage > response.data.videos / 20) {
          setLoading(false);
          return;
        }

        setStockVideos((prevVideos) => [...prevVideos, ...response.data.videos]);
        setVideoTotalPages(Math.ceil(response.data.total / 20));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchVideos();
  }, [videoQuery, videoPage]);

  useEffect(() => {
    axios.get(`${BASE_URL}/presenters`, {
      params: {
        user_id,
      }
    })
    .then((res) => setModels(res.data.presenters));
    //eslint-disable-next-line
  }, [])

  const faceModels = ["https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/1.png?updatedAt=1705384899887", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/2.png?updatedAt=1705384965522", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/3.png?updatedAt=1705384985672", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/4.png?updatedAt=1705385138960", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/5.png?updatedAt=1705385160935", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/6.png?updatedAt=1705385201046", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/7.png?updatedAt=1705385231477", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/8.png?updatedAt=1705385304072"]

  useEffect(() => {
    // setWelcomeModal(true);
    setQuestionMod(true);
  }, []);

  const history = useHistory();
  
  const handleUpgradeClick = () => {
    history.push('/upgrade');
  };

  const tabHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  function handleLeaveClick() {
    history.push("/saastutorial");
  }

  const handleSubmit = () => {
    setConfirmModal(true);
  };

  const handleFinalSubmit = async () => {
    setFinalApiHit(true);
    await axios.post(`${BASE_URL}/saas_video`, {
      user_id,
      scenes,
      projectName: videoName,
      intro,
      outro
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    return '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, []);
  
  return (
    <>
      <DashboardNavbar />
      {plan === 'Trial' && <VuiBox sx={{position: 'absolute', top: '51%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '2'}}>
        <Lock height={500} line="Video Editor Feature" />
      </VuiBox>}
      {createVideo && <EditingSection 
        plan={plan}
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        questionMod={questionMod}
        setQuestionMod={setQuestionMod}
        handleLeaveClick={handleLeaveClick}
        setCreateVideo={setCreateVideo} 
        setSelectBreak={setSelectBreak}
        setSelectSpeed={setSelectSpeed}
        changeInput={changeInput}
        setChangeInput={setChangeInput}
        videoName={videoName}
        setVideoName={setVideoName}
        setSelectLanguage={setSelectLanguage}
        setSelectVoice={setSelectVoice}
        voiceOption={voiceOption}
        setVoiceOption={setVoiceOption}
        touched={touched}
        setTouched={setTouched}
        alignment={alignment}
        setAlignment={setAlignment}
        value={value}
        setValue={setValue}
        fonts={fonts}
        setFonts={setFonts}
        selectedFont={selectedFont}
        setSelectedFont={setSelectedFont}
        fontSize={fontSize}
        setFontSize={setFontSize}
        selectedFontSize={selectedFontSize}
        setSelectedFontSize={setSelectedFontSize}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        stockImages={stockImages}
        setStockImages={setStockImages}
        imageQuery={imageQuery}
        setImageQuery={setImageQuery}
        page={page}
        setPage={setPage}
        imageObserver={imageObserver}
        videoObserver={videoObserver}
        lastImageRef={lastImageRef}
        loading={loading}
        selectedBackground={selectedBackground}
        setSelectedBackground={setSelectedBackground}
        uploadedBackground={uploadedBackground}
        setUploadedBackground={setUploadedBackground}
        backgroundTabValue={backgroundTabValue}
        setBackgroundTabValue={setBackgroundTabValue}
        uploadedVideo={uploadedVideo}
        setUploadedVideo={setUploadedVideo}
        stockVideos={stockVideos}
        setStockVideos={setStockVideos}
        videoQuery={videoQuery}
        setVideoQuery={setVideoQuery}
        videoPage={videoPage}
        setVideoPage={setVideoPage}
        lastVideoRef={lastVideoRef}
        uploadedAudio={uploadedAudio}
        setUploadedAudio={setUploadedAudio}
        models={models}
        setModels={setModels}
        imageTotalPages={imageTotalPages}
        videoTotalPages={videoTotalPages}
        items={items}
        setItems={setItems}
        selectedAudio={selectedAudio}
        setSelectedAudio={setSelectedAudio}
        faceModels={faceModels}
        scenes={scenes}
        setScenes={setScenes}
        updateSceneKey={updateSceneKey}
        addScene={addScene}
        deleteScene={deleteScene}
        handleBoxClick={handleBoxClick}
        currentSceneIndex={currentSceneIndex}
        handleSubmit={handleSubmit}
        handleFinalSubmit={handleFinalSubmit}
        componentRef={componentRef}
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
        stockShapes={stockShapes}
        setStockShapes={setStockShapes}
        shapesQuery={shapesQuery}
        setShapesQuery={setShapesQuery}
        indexToStore={indexToStore}
        setIndexToStore={setIndexToStore}
        loadingModal={loadingModal}
        setLoadingModal={setLoadingModal}
        calculateDimensions={calculateDimensions}
        intro={intro}
        setIntro={setIntro}
        outro={outro}
        setOutro={setOutro}
        uploadedIntroVideos={uploadedIntroVideos}
        setUploadedIntroVideos={setUploadedIntroVideos}
        uploadedOutroVideos={uploadedOutroVideos}
        setUploadedOutroVideos={setUploadedOutroVideos}
        showIO={showIO}
        setShowIO={setShowIO}
      />}
      {confirmModal && <ConfirmationModal confirmModal={confirmModal} setConfirmModal={setConfirmModal} handleFinalSubmit={handleFinalSubmit} finalApiHit={finalApiHit} setFinalApiHit={setFinalApiHit} />}
      {questionMod && <QuestionModal questionMod={questionMod} setQuestionMod={setQuestionMod} basicDetails={basicDetails} setBasicDetails={setBasicDetails} initialScene={initialScene} setScenes={setScenes} scenes={scenes} currentSceneIndex={currentSceneIndex} />}
    </>
  )
}

export default MainSaas