import VuiBox from 'components/VuiBox'
import VuiTypography from 'components/VuiTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useRef, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

const useStyles = makeStyles(() =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageLightBlue: {
      position: "relative",
      minWidth:"fit-content",
      maxWidth: "80%",
      padding: "10px",
      backgroundColor: "#0175FF",
      // width: "60%",
      //height: "50px",
      textAlign: "left",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      // "&:after": {
      //   content: "''",
      //   position: "absolute",
      //   width: "0",
      //   height: "0",
      //   borderTop: "15px solid #0175FF",
      //   borderLeft: "15px solid transparent",
      //   borderRight: "15px solid transparent",
      //   top: "-1px",
      //   left: "-15px",
      // },
      // "&:before": {
      //   content: "''",
      //   position: "absolute",
      //   width: "0",
      //   height: "0",
      //   borderTop: "17px solid #0175FF",
      //   borderLeft: "16px solid transparent",
      //   borderRight: "16px solid transparent",
      //   top: "-1px",
      //   left: "-17px",
      // },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      color: "#fff",
      font: "Plus Jakarta Display",
    },
    messageTimeStampLeft: {
      fontSize: "0.75em",
      textAlign: "left",
      paddingTop: "5px",
      color: "#bfbfbf",
    },
    messageTimeStampRight: {
      fontSize: "0.75em",
      color: "#bfbfbf",
      textAlign: "right",
      paddingTop: "5px",
    },
    lightBlue: {
      color: "#FFF",
      width: "150%",
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

const useRightStyles = makeStyles(() =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageLightBlue: {
      position: "relative",
      minWidth:"23%",
      maxWidth: "47.5%",
      padding: "10px",
      backgroundColor: "#0175FF",
      // width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "200 .8em 'Plus Jakarta Display', sans-serif",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #0175FF",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "-1px",
        left: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #0175FF",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px",
      },
    },
    messageDeepBlue: {
      // right: "3%",
      position: "relative",
      // alignSelf: "right",
      minWidth: "fit-content",
      maxWidth: "80%",
      padding: "10px",
      backgroundColor: "transparent",
      //height: "50px",
      textAlign: "left",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      // "&:after": {
      //   content: "''",
      //   position: "absolute",
      //   width: "0",
      //   height: "0",
      //   borderTop: "15px solid transparent",
      //   borderLeft: "15px solid transparent",
      //   borderRight: "15px solid transparent",
      //   top: "0",
      //   right: "-15px",
      // },
      // "&:before": {
      //   content: "''",
      //   position: "absolute",
      //   width: "0",
      //   height: "0",
      //   borderTop: "17px solid #0175FF",
      //   borderLeft: "17px solid transparent",
      //   borderRight: "16px solid transparent",
      //   top: "-1px",
      //   right: "-17px",
      // },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      color: "#fff",
      font: "Plus Jakarta Display",
    },
    messageTimeStampLeft: {
      fontSize: "0.75em",
      textAlign: "left",
      paddingTop: "5px",
      color: "#bfbfbf",
    },
    messageTimeStampRight: {
      fontSize: "0.75em",
      color: "#bfbfbf",
      textAlign: "right",
      paddingTop: "5px",
    },
    deepBlue: {
      color: "#FFF",
      width: "150%",
    },
    lightBlue: {
      color: "#FFF",
      width: "150%",
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

const SingleDetail = (props) => {
  const { state } = props.location;
  const {id, stream_id, session_start, is_active, source_url, session_converse} = state;

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    return `${dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  };

  //eslint-disable-next-line
  // const [messages, setMessages] = useState([
  //   {message: 'hi', type: 'incoming', input: 'text', timestamp: '19:07'},
  //   {message: 'hello', type: 'outgoing', input: 'text', timestamp: '19:08'},
  //   {message: 'how are you ?', type: 'incoming', input: 'text', timestamp: '19:08'},
  //   {message: 'i am good, how are you doing ?', type: 'outgoing', input: 'text', timestamp: '19:08'},
  //   {message: 'well, going preety nice', type: 'incoming', input: 'text', timestamp: '19:09'},
  //   {message: 'great!!', type: 'outgoing', input: 'text', timestamp: '19:09'},
  // ]);


  const [isPlaying, setIsPlaying] = useState(new Array(session_converse?.length).fill(false));
  const audioRefs = useRef([]);

  const togglePlayPause = (index) => {
    const newIsPlaying = [...isPlaying];
    newIsPlaying[index] = !isPlaying[index];

    if (newIsPlaying[index]) {
      audioRefs.current[index].play();
    } else {
      audioRefs.current[index].pause();
    }

    setIsPlaying(newIsPlaying);
  };

  const classes = useStyles();
  const rightClasses = useRightStyles();

  const date = session_start.split(',');

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', paddingTop: '20px', flexWrap: 'wrap'}}>
        <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src={source_url} alt="" style={{width: '200px', height: '200px', objectFit: 'cover', borderRadius: '100%', boxShadow: 'whitesmoke 0px 0px 10px 3px', background: 'white'}} />
          <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '30px'}}>
            <VuiTypography variant='h6' sx={{color: 'whitesmoke'}}>Id: <span style={{fontSize: '16px', marginLeft: '10px'}}> {id}</span></VuiTypography>
            <VuiTypography variant='h6' sx={{color: 'whitesmoke'}}>Stream Id: <span style={{fontSize: '16px', marginLeft: '10px'}}> {stream_id}</span></VuiTypography>
            <VuiTypography variant='h6' sx={{color: 'whitesmoke'}}>Created at: <span style={{fontSize: '16px', marginLeft: '10px'}}> {session_start}</span></VuiTypography>
            <VuiTypography variant='h6' sx={{color: 'whitesmoke'}}>Status: <span style={{fontSize: '16px', marginLeft: '10px'}}> {is_active}</span></VuiTypography>
          </VuiBox>
        </VuiBox>
        <VuiBox sx={{width: '400px', height: '600px', objectFit: 'cover', borderRadius: '5%', boxShadow: 'whitesmoke 0px 0px 40px -15px', padding: '10px', border: '1px solid lightblue'}} >
          <VuiTypography sx={{color: 'whitesmoke', textAlign: 'center'}} variant='h6'>{date[1]}</VuiTypography>
          <div style={{maxHeight: '550px', overflowY: 'auto', width: '100%', padding: '5px'}}>
            {session_converse && session_converse.map(({chat, type, speaker, timestamp}, index) => (
              speaker === 'Avatar' ? 
                <VuiBox sx={{display: 'flex' ,alignItems: 'flex-start', paddingBottom: '5px'}}>
                  <img src={source_url} alt="" style={{width: '30px', height: '30px', objectFit: 'cover', borderRadius: '100%', background: 'white'}} />
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: 'auto'}}>
                    <div key={index} className={classes.messageLightBlue} style={{color: 'whitesmoke', marginTop: '5px', marginRight: 'auto', padding: '5px 10px', marginLeft: '5px'}}>
                      {type === 'audio' ? 
                        <div key={index}>
                          <audio ref={(element) => (audioRefs.current[index] = element)} style={{ display: 'none' }}>
                            <source src={chat} type="audio/mpeg" />
                          </audio> 
                          <div style={{background: 'transparent', cursor: 'pointer'}}>
                            {isPlaying[index] ? <PauseCircleIcon onClick={() => togglePlayPause(index)} fontSize='large' /> : <PlayCircleIcon onClick={() => togglePlayPause(index)} fontSize='large' />}
                          </div>
                        </div>
                      : 
                      <h6 style={{fontSize: window.innerWidth < 768 ? '12px' : ''}}>{chat}</h6>}
                      </div>
                    <h6 style={{color: 'lightgray', padding: '0px 5px', fontSize: '12px'}}>{formatDateTime(timestamp)}</h6>
                  </div>
                </VuiBox>
              :
                <VuiBox sx={{display: 'flex' ,alignItems: 'flex-start', paddingBottom: '5px'}}>
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: 'auto'}}>
                    <div key={index} className={rightClasses.messageDeepBlue} style={{color: 'whitesmoke', marginTop: '5px', marginLeft: 'auto', padding: '5px 10px', marginRight: '5px'}}>
                      <h6 style={{fontSize: window.innerWidth < 768 ? '12px' : ''}}>{chat}</h6>
                    </div>
                    <h6 style={{color: 'lightgray', padding: '0px 5px', fontSize: '12px'}}>{formatDateTime(timestamp)}</h6>
                  </div>
                  <AccountCircleIcon color='white' fontSize='large' />
                </VuiBox>
            ))}
          </div>
        </VuiBox>
      </VuiBox>
    </DashboardLayout>
  )
}

export default SingleDetail