import { Button, ButtonGroup, Slider, Typography } from '@mui/material';
import VuiBox from 'components/VuiBox';
import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const ShapesSettings = ({setOpenSettings, openSettings, scenes, updateSceneKey, currentSceneIndex, setScenes}) => {
  const [scale, setScale] = useState(openSettings.shape.scale);
  const [zIndex, setZIndex] = useState(openSettings.shape.zIndex);
  const [opacity, setOpacity] = useState(openSettings.shape.opacity);
  const [top, setTop] = useState(openSettings.shape.marginTop);
  const [left, setLeft] = useState(openSettings.shape.marginLeft);

  const handleScale = (event, newValue) => {
    setScale(newValue);
    if (openSettings.shape) {
      const updatedShapeSettings = { ...openSettings.shape };
      updatedShapeSettings.scale = newValue;
  
      setOpenSettings({
        ...openSettings,
        shape: updatedShapeSettings,
      })
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedShape: scene.selectedShape.map((image) => {
                if (image.image === openSettings.shape.image) {
                  return updatedShapeSettings;
                }
                return image;
              }),
            };
          }
          return scene;
        });
      });
    }
  }

  const handleLeftMinus = () => {
    setLeft(prevLeft => prevLeft - 1);
    if (openSettings.shape) {
      const updatedShapeSettings = { ...openSettings.shape };
      updatedShapeSettings.marginLeft = left;
  
      setOpenSettings((prevOpenSettings) => ({
        ...prevOpenSettings,
        shape: updatedShapeSettings,
      }));
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedShape: scene.selectedShape.map((image) => {
                if (image.image === openSettings.shape.image) {
                  return updatedShapeSettings;
                }
                return image;
              }),
            };
          }
          return scene;
        });
      });
    }
  }
  
  const handleLeftPlus = () => {
    setLeft(prevLeft => prevLeft + 1);
    if (openSettings.shape) {
      const updatedShapeSettings = { ...openSettings.shape };
      updatedShapeSettings.marginLeft = left;
  
      setOpenSettings({
        ...openSettings,
        shape: updatedShapeSettings,
      })
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedShape: scene.selectedShape.map((image) => {
                if (image.image === openSettings.shape.image) {
                  return updatedShapeSettings;
                }
                return image;
              }),
            };
          }
          return scene;
        });
      });
    }
  }

  const handleTopMinus = () => {
    setTop(prevTop => prevTop - 1);
    if (openSettings.shape) {
      const updatedShapeSettings = { ...openSettings.shape };
      updatedShapeSettings.marginTop = top;
  
      setOpenSettings({
        ...openSettings,
        shape: updatedShapeSettings,
      })
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedShape: scene.selectedShape.map((image) => {
                if (image.image === openSettings.shape.image) {
                  return updatedShapeSettings;
                }
                return image;
              }),
            };
          }
          return scene;
        });
      });
    }
  }
  
  const handleTopPlus = () => {
    setTop(prevTop => prevTop + 1);
    if (openSettings.shape) {
      const updatedShapeSettings = { ...openSettings.shape };
      updatedShapeSettings.marginTop = top;
  
      setOpenSettings({
        ...openSettings,
        shape: updatedShapeSettings,
      })
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedShape: scene.selectedShape.map((image) => {
                if (image.image === openSettings.shape.image) {
                  return updatedShapeSettings;
                }
                return image;
              }),
            };
          }
          return scene;
        });
      });
    }
  }

  const handleZIndex = (event, newValue) => {
    setZIndex(newValue);
    if (openSettings.shape) {
      const updatedShapeSettings = { ...openSettings.shape };
      updatedShapeSettings.zIndex = newValue;
  
      setOpenSettings({
        ...openSettings,
        shape: updatedShapeSettings,
      })
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedShape: scene.selectedShape.map((image) => {
                if (image.image === openSettings.shape.image) {
                  return updatedShapeSettings;
                }
                return image;
              }),
            };
          }
          return scene;
        });
      });
    }
  }

  const handleOpacity = (event, newValue) => {
    setOpacity(newValue);
    if (openSettings.shape) {
      const updatedShapeSettings = { ...openSettings.shape };
      updatedShapeSettings.opacity = newValue;
  
      setOpenSettings({
        ...openSettings,
        shape: updatedShapeSettings,
      })
  
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedShape: scene.selectedShape.map((image) => {
                if (image.image === openSettings.shape.image) {
                  return updatedShapeSettings;
                }
                return image;
              }),
            };
          }
          return scene;
        });
      });
    }
  }

  const handleDelete = () => {
    if (openSettings.shape) {
      setScenes((prevScenes) => {
        return prevScenes.map((scene, index) => {
          if (index === currentSceneIndex) {
            return {
              ...scene,
              selectedShape: scene.selectedShape.filter((image) => {
                return image.image !== openSettings.shape.image;
              }),
            };
          }
          return scene;
        });
      });
      setOpenSettings({...openSettings, shape: false});
    } else {
      setOpenSettings({...openSettings, shape: false});
    }
  }

  return (
    <VuiBox sx={{display: 'flex', flexDirection: 'column'}}>
      <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '5px'}}>
        <ArrowBackIcon color="white" sx={{cursor: 'pointer'}} onClick={()=>setOpenSettings({...openSettings, shape: false})} />
        <DeleteOutlineIcon color='white' sx={{cursor: 'pointer'}} onClick={handleDelete} />
      </VuiBox>
      <VuiBox sx={{background: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center'}} mt={1}>
        {openSettings.shape && 
          <img alt='shape settings' src={openSettings.shape.image} style={{ maxWidth: '100%', maxHeight: '20vh', background: 'whitesmoke', borderRadius: '10px', padding: '5px' }} />  
        }
      </VuiBox>
      <VuiBox sx={{marginTop: '10px'}}>
        {/* <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Scale</Typography>
          <Slider value={scale} onChange={handleScale} min={0} max={500}  color="secondary" sx={{width: '100px'}} />
          <VuiBox sx={{color: 'whitesmoke', border: '1px solid whitesmoke', borderRadius: '10px', padding: '0px 20px', fontSize: '14px'}}>{scale === '' ? "0%" : `${scale}%`}</VuiBox>
        </VuiBox> */}
        <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
          <Typography variant='h6' sx={{marginTop: '10px'}}>Position</Typography>
          <VuiBox sx={{display: 'flex', justifyContent: 'space-between'}}>
            <VuiBox sx={{display: 'flex' ,flexDirection: 'column'}}>
              <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>X</Typography>
              <ButtonGroup variant='outlined' aria-label="outlined button group" sx={{width: '150px'}}>
                <Button sx={{padding: '5px'}} onClick={handleLeftMinus}>-</Button>
                <Button sx={{padding: '5px'}}>{left}</Button>
                <Button sx={{padding: '5px'}} onClick={handleLeftPlus}>+</Button>
              </ButtonGroup>
            </VuiBox>
            <VuiBox sx={{display: 'flex' ,flexDirection: 'column'}}>
              <Typography variant='h6' sx={{marginTop: '10px', marginBottom: '10px'}}>Y</Typography>
              <ButtonGroup variant='outlined' aria-label="outlined button group" sx={{width: '150px'}}>
                <Button sx={{padding: '5px'}} onClick={handleTopMinus}>-</Button>
                <Button sx={{padding: '5px'}}>{top}</Button>
                <Button sx={{padding: '5px'}} onClick={handleTopPlus}>+</Button>
              </ButtonGroup>
            </VuiBox>
          </VuiBox>

          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px'}}>
            <Typography variant='h6' sx={{marginTop: '10px'}}>Z-index</Typography>
            <Slider value={zIndex} onChange={handleZIndex} min={0} max={1000}  color="secondary" sx={{width: '100px'}} />
            <VuiBox sx={{color: 'whitesmoke', border: '1px solid whitesmoke', borderRadius: '10px', padding: '0px 20px', fontSize: '14px'}}>{zIndex}</VuiBox>
          </VuiBox>

          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px'}}>
            <Typography variant='h6' sx={{marginTop: '10px'}}>Opacity</Typography>
            <Slider min={0} max={1} step={0.01} color="secondary" sx={{width: '100px'}} value={opacity} onChange={handleOpacity} />
            <VuiBox sx={{color: 'whitesmoke', border: '1px solid whitesmoke', borderRadius: '10px', padding: '0px 20px', fontSize: '14px'}}>{opacity}</VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </VuiBox>
  )
}

export default ShapesSettings