import { Button, Card, CircularProgress, Icon, Tooltip } from '@mui/material'
import VuiBox from 'components/VuiBox'
import VuiInput from 'components/VuiInput'
import VuiTypography from 'components/VuiTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import LockIcon from '@mui/icons-material/Lock';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { useSnackbar } from "notistack";
import axios from 'axios'
import { useVisionUIController } from "context";
import BASE_URL from "context/api";
import api_icon from './message-code_8770802.svg';
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Lock from 'lock'

const ApiDetails = () => {
  const [apikey, setApikey] = useState('');
  const [formattedDate, setFormattedDate] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [controller] = useVisionUIController();
  const { userDetails } = controller;
  const { user_id, plan } = userDetails;

  const history = useHistory();

  const handleUpgradeClick = () => {
    history.push('/upgrade');
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCopyClick = (url) => {
    url !== '' && navigator.clipboard.writeText(url)
      .then(() => {
        enqueueSnackbar('Key copied successfully.', { 
          variant: "success",
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
      .catch((error) => {
        console.error('Failed to copy URL: ', error);
      });
  };

  const handleGenerateKey = () => {
    setLoading(true);
    try {
      axios.post(`${BASE_URL}/auth_key`, {
        user_id
      })
      .then((res) => {
        getAPIKey();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const formattedDate = date.toLocaleString('en-IN', options);
    setFormattedDate(formattedDate);
  }

  function shortenString(inputString) {
    if (inputString.length <= 6) {
      return inputString;
    }
  
    const firstThree = inputString.substring(0, 3);
    const lastThree = inputString.slice(-3);
    return `${firstThree}......${lastThree}`;
  }

  const getAPIKey = () => {
    setLoading(true);
    try {
      axios.get(`${BASE_URL}/auth_key`, {
        params: {user_id}
      })
      .then((res) => {
        setApikey(res.data.api_key)
        setLoading(false);
        formatDate(res.data.created_at);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    getAPIKey();
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(plan === 'Trial' || plan === 'Lite' || plan === 'Pro') && <VuiBox sx={{position: 'absolute', top: '100%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '2'}}>
        <Lock height={500} line="API's" />
      </VuiBox>}
      <Card className={plan==='Trial' && "blurred-content"}>
        <VuiTypography variant='h4'>API details</VuiTypography>
        <VuiBox sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', flexWrap: 'wrap'}}>
          <VuiBox sx={{border: '1px solid gray', background: 'whitesmoke', margin: '25px 0px', padding: '30px', maxWidth: '1000px', borderRadius: '15px', flex: 0.7}}>
            <VuiBox sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'column'}}>
              <VuiBox sx={{display: 'flex', alignItems: 'center'}}>
                <img src={api_icon} alt="api_key" width='30px' />
                <VuiTypography variant='h5' ml='10px'>API key</VuiTypography>
              </VuiBox>
              <VuiBox sx={{marginTop: '5px', display: 'flex', flexDirection: 'column'}}>
                <VuiBox sx={{marginTop: '10px', display: 'flex', alignItems: 'center', flexDirection: window.innerWidth < 768 ? 'column' : ''}}>
                  <>
                    <Tooltip title={apikey==='' ? 'API key is not generated yet' : 'Copy API key'}  placement="top">
                      {apikey !== '' ? <VuiInput 
                        icon={{
                          component: (
                            <Icon onClick={handleTogglePasswordVisibility} sx={{cursor: "pointer"}}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </Icon>
                          ),
                          direction: "right",
                        }}
                        type={showPassword ? "text" : "password"}
                        value={apikey}
                        sx={{maxWidth: '300px !important'}}
                      /> : <VuiTypography color='info' variant='h5'>{loading ? <CircularProgress size={22} color='white' /> : 'Please generate a new API key \u2192'}</VuiTypography>}
                    </Tooltip>
                    {apikey !== '' && <Tooltip title='Copy API key'  placement="top">
                      <CopyAllIcon size={22} sx={{cursor: 'pointer', marginLeft: window.innerWidth < 768 ? '' : '10px', marginTop: window.innerWidth < 768 ? '10px' : '', marginBottom: window.innerWidth < 768 ? '10px' : ''}} onClick={() => handleCopyClick(apikey)}  />
                    </Tooltip>}
                  </>
                  <Button variant='contained' sx={{color:'whitesmoke', marginLeft: window.innerWidth < 768 ? '' : '30px', width: '150px'}} onClick={handleGenerateKey}>{loading ? <CircularProgress size={22} color='white' /> : apikey === '' ? "Generate key" : "Regenerate key"}</Button>
                </VuiBox>
                {(formattedDate && apikey !== '') && <VuiTypography sx={{fontSize: '14px', marginLeft: '5px'}}>
                  Created {formattedDate}
                </VuiTypography>}
              </VuiBox>
            </VuiBox>
            <VuiTypography variant='h6' color='error' sx={{marginTop: '10px', fontSize: '14px'}}>&#9888; Do not share your API key with others, or expose in the browser or other client side code. In order to protect the security of your account, QuickVideo may also automatically disable any API key that we've found has leaked publicly.</VuiTypography>
          </VuiBox>
          <VuiBox sx={{border: '1px solid skyblue', background: 'transparent', margin: '25px 0px', padding: '20px', maxWidth: '300px', height: '248px', borderRadius: '15px', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-around', flex: 0.4}}>
            <VuiTypography variant='h5' textAlign='center' color='white'>Click below to check <br/> API documentation</VuiTypography>
            <a href='https://quickvideoai.readme.io/reference/getsessiionllogs' rel="noreferrer" target="_blank">
              <Button variant='contained' sx={{color: 'whitesmoke', marginTop: '20px'}}>API Documentation<ArrowOutwardIcon color='white' sx={{marginLeft: '2px'}} /></Button>
            </a>
          </VuiBox>
        </VuiBox>
      </Card>
    </DashboardLayout>
  )
}

export default ApiDetails