// @mui material components
import { CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import axios from "axios";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useVisionUIController } from "context";
import BASE_URL from "context/api";
import Bill from "layouts/pages/account/billing/components/Bill";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

function BillingInformation() {
  const [loader, setLoader] = useState(false);
  const [controller] = useVisionUIController();
  const { subslogs } = controller;

  return (
    <Card id="delete-account">
      <VuiBox>
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Subscription Logs
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <VuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {loader ? (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '250px'}}>
              <CircularProgress size={30} color="white" />
            </div>
          ) : (
            subslogs.length > 0 ? (
              subslogs.map((log, index) => (
                <Bill
                  key={index}
                  name={log.agent_name}
                  subscription={log.plan_name}
                  date={log.date}
                  time={log.time}
                  days={log.days_left}
                  active={log.active}
                />
              ))
            ) : (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '250px'}}>
                No subscriptions done yet. Kindly upgrade your plan.
              </div>
            )
          )}
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default BillingInformation;
