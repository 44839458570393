/*eslint-disable no-unused-vars */

import { useEffect, useState } from "react";
// Vision UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
// import VuiProgress from "components/VuiProgress";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import Collapse from "@mui/material/Collapse";

// Pricing page components
// import FormField from "layouts/pages/account/components/FormField";
// import FaqCollapse from "layouts/pages/pricing-page/components/FaqCollapse";

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import Slider from '@mui/material/Slider';
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import FastRewindRounded from "@mui/icons-material/FastRewindRounded";
import VolumeUpRounded from "@mui/icons-material/VolumeUpRounded";
import VolumeDownRounded from "@mui/icons-material/VolumeDownRounded";

// React icons
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  Card,
  Icon,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  Circle,
  ContentCopyOutlined,
  DesktopWindowsOutlined,
  FeaturedVideoOutlined,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  GifBoxOutlined,
  LocalOfferOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";
import ReactPlayer from "react-player";
import VuiInput from "components/VuiInput";

function Collapsed({
  icon,
  title,
  open,
  children,
  width,
  height,
  titleskeleton,
  body,
  ...rest
}) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  // const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("12")
        : setTabsOrientation("4");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  // const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const [collapse, setCollapse] = useState(false);
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Card>
          <VuiBox
            {...rest}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ cursor: "pointer" }}
          >
            <VuiButton color="info">{icon}</VuiButton>
            <VuiTypography
              variant="lg"
              color="white"
              sx={{ userSelect: "none" }}
            >
              {title}
            </VuiTypography>

            <VuiBox color="white">
              {open ? (
                <IoIosArrowUp color="dark" />
              ) : (
                <IoIosArrowDown color="white" />
              )}
            </VuiBox>
          </VuiBox>

          <Collapse timeout={400} in={open} onClick={""}>
            <VuiBox lineHeight={1}>
              <Divider light />
              <VuiTypography
                variant="button"
                color="white"
                opacity={0.8}
                fontWeight="regular"
              >
                {children}
              </VuiTypography>
            </VuiBox>
          </Collapse>
        </Card>
      </Grid>

      {open && (
        <Grid item xs={8}>
          <Card
            sx={{
              width: width,
              height: height,
              maxWidth: 740,
              // background: "white",
            }}
          >
            <Stack spacing={1} style={{ gap: 5 }}>
              <Stack
                direction="row"
                spacing={3}
                style={{
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <Stack direction="row" spacing={1}>
                  <Circle style={{ color: "#CC646F", width: 15, height: 15 }} />
                  <Circle style={{ color: "#EAC959", width: 15, height: 15 }} />
                  <Circle style={{ color: "#7EBA89", width: 15, height: 15 }} />
                </Stack>
                {titleskeleton}
              </Stack>
              <Stack>{body}</Stack>
            </Stack>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}

function Storiesdiv() {
  return (
    // <Grid item xs={10 - 4}>
    <Card sx={{ minWidth: "600px", background: "white" }}>
      <Stack spacing={1}>
        <Stack
          direction="row"
          spacing={5}
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack direction="row" spacing={1}>
            <Skeleton
              variant="circular"
              width={15}
              height={15}
              style={{ background: "#CC646F" }}
            />
            <Skeleton
              variant="circular"
              width={15}
              height={15}
              style={{ background: "#EAC959" }}
            />
            <Skeleton
              variant="circular"
              width={15}
              height={15}
              style={{ background: "#7EBA89" }}
            />
          </Stack>
          <Skeleton variant="text" width={"100%"} sx={{ fontSize: "1rem" }} />
        </Stack>

        <Skeleton variant="rounded" height={100} />
        <br />

        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width={"40%"} sx={{ fontSize: "1rem" }} />

        <Skeleton variant="rounded" width={210} height={60} />
      </Stack>
    </Card>
    // </Grid>
  );
}
function Stories() {
  const [collapse, setCollapse] = useState(false);
  const [button1, setButton1] = useState(false);
  const handleSetButton1 = () => setButton1(!button1);
  const [button2, setButton2] = useState(false);
  const handleSetButton2 = () => setButton2(!button2);
  const [button3, setButton3] = useState(false);
  const handleSetButton3 = () => setButton3(!button3);

  return (
    <Collapsed
      icon={<FeaturedVideoOutlined color="white" size="20px" />}
      title="Stories"
      open={collapse === 1}
      onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
      width={550}
      titleskeleton={
        <Skeleton
          variant="text"
          width={"100%"}
          sx={{ fontSize: "1rem", background: "white" }}
        />
      }
      body={
        <>
          <Skeleton
            variant="rounded"
            height={100}
            sx={{ background: "white" }}
          />
          <br />

          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", background: "white" }}
          />
          <Skeleton
            variant="text"
            width={"40%"}
            sx={{ fontSize: "1rem", background: "white" }}
          />

          <Skeleton
            variant="rounded"
            width={"100%"}
            height={60}
            sx={{ background: "white" }}
          />
        </>
      }
    >
      <VuiBox mt={1}>
        <VuiBox
          mt={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <Grid container>
            <Grid item xs={6}>
              <VuiTypography variant="body2" color="white">
                Transparency
              </VuiTypography>
            </Grid>
            {/* <VuiProgress value="50"/> */}
            <Grid item xs={6}>
              <Slider
                defaultValue={30}
                aria-label="Default"
                valueLabelDisplay="auto"
              />
            </Grid>
          </Grid>
        </VuiBox>

        <Divider light />

        <VuiBox
          mt={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <VuiTypography variant="body2" color="white">
            Border
          </VuiTypography>
          <VuiBox
            mr={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <VuiSwitch
              color="info"
              checked={button1}
              onChange={handleSetButton1}
            />
            <VuiTypography variant="body2" color={button1 ? "white" : "text"}>
              &nbsp;&nbsp;&nbsp;{button1 ? "On" : "Off"}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <Divider light />
        <VuiBox
          mt={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <VuiTypography variant="body2" color="white">
            Title
          </VuiTypography>
          <VuiBox
            mr={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <VuiSwitch
              color="info"
              checked={button2}
              onChange={handleSetButton2}
            />
            <VuiTypography variant="body2" color={button2 ? "white" : "text"}>
              &nbsp;&nbsp;&nbsp;{button2 ? "On" : "Off"}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <Divider light />
        <VuiBox
          mt={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <VuiTypography variant="body2" color="white">
            Story Name
          </VuiTypography>
          <VuiBox
            mr={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <VuiSwitch
              color="info"
              checked={button3}
              onChange={handleSetButton3}
            />
            <VuiTypography variant="body2" color={button3 ? "white" : "text"}>
              &nbsp;&nbsp;&nbsp;{button3 ? "On" : "Off"}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Collapsed>
  );
}

function Carousel() {
  const [collapse, setCollapse] = useState(false);
  const [button1, setButton1] = useState(false);
  const handleSetButton1 = () => setButton1(!button1);
  const [button2, setButton2] = useState(false);
  const handleSetButton2 = () => setButton2(!button2);

  return (
    <Collapsed
      icon={<ContentCopyOutlined color="white" size="20px" />}
      title="Carousel"
      open={collapse === 1}
      onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
      width={700}
      titleskeleton={
        <Skeleton
          variant="text"
          width={"100%"}
          sx={{ fontSize: "10px", background: "white" }}
        />
      }
      body={
        <>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", background: "white" }}
          />

          <Box width="100%" mt={1}>
            <Box
              width="32.25%"
              display="inline-block"
              sx={{ marginRight: "10px" }}
            >
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", background: "white" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", background: "white" }}
              />
            </Box>
            <Box
              width="32.25%"
              display="inline-block"
              sx={{ marginRight: "10px" }}
            >
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", background: "white" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", background: "white", width: "40%" }}
              />
            </Box>
            <Box width="32.25%" display="inline-block">
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", background: "white" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", background: "white", width: "40%" }}
              />
            </Box>
          </Box>
        </>
      }
    >
      <VuiBox mt={1}>
        <VuiBox mt={2}>
          <VuiTypography variant="body2" color="white" fontWeight="bold">
            Layout
          </VuiTypography>
          <VuiBox
            mt={1}
            ml={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            lineHeight={1}
          >
            <Grid container>
              <Grid item xs={6}>
                <VuiTypography variant="body2" color="white">
                  Transparency
                </VuiTypography>
              </Grid>
              {/* <VuiProgress value="50"/> */}
              <Grid item xs={6}>
                <Slider
                  defaultValue={30}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
          </VuiBox>
        </VuiBox>
        <VuiBox mt={2}>
          <VuiTypography variant="body2" color="white" fontWeight="bold">
            Motion
          </VuiTypography>
          <VuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            lineHeight={1}
          >
            <Grid
              container
              mt={1}
              mb={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button>Static</Button>
                <Button>Dynamic</Button>
                <Button>Hover over</Button>
              </ButtonGroup>
            </Grid>
          </VuiBox>
        </VuiBox>
        <Divider light />
        <VuiBox
          mt={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <VuiTypography variant="body2" color="white">
            Border
          </VuiTypography>
          <VuiBox
            mr={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <VuiSwitch
              color="info"
              checked={button1}
              onChange={handleSetButton1}
            />
            <VuiTypography variant="body2" color={button1 ? "white" : "text"}>
              &nbsp;&nbsp;&nbsp;{button1 ? "On" : "Off"}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <Divider light />
        <VuiBox
          mt={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <VuiTypography variant="body2" color="white">
            Title
          </VuiTypography>
          <VuiBox
            mr={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <VuiSwitch
              color="info"
              checked={button2}
              onChange={handleSetButton2}
            />
            <VuiTypography variant="body2" color={button2 ? "white" : "text"}>
              &nbsp;&nbsp;&nbsp;{button2 ? "On" : "Off"}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <Divider light />
        <VuiBox mt={2}>
          <VuiTypography variant="body2" color="white" fontWeight="medium">
            Play Button
          </VuiTypography>
          <VuiBox
            mt={1}
            ml={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            lineHeight={1}
          >
            <Grid container>
              <Grid item xs={6}>
                <VuiTypography variant="body2" color="white">
                  Transparency
                </VuiTypography>
              </Grid>
              {/* <VuiProgress value="50"/> */}
              <Grid item xs={6}>
                <Slider
                  defaultValue={30}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Collapsed>
  );
}

function Widget() {
  const [collapse, setCollapse] = useState(false);
  const [button1, setButton1] = useState(false);
  const handleSetButton1 = () => setButton1(!button1);
  const [button2, setButton2] = useState(false);
  const handleSetButton2 = () => setButton2(!button2);

  return (
    <Collapsed
      icon={<WidgetsOutlined color="white" size="20px" />}
      title="Widget setting"
      open={collapse === 1}
      onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
      width={700}
      titleskeleton={
        <Skeleton
          variant="text"
          width={"100%"}
          sx={{ fontSize: "10px", background: "white" }}
        />
      }
      body={
        <>
          <Skeleton
            variant="rectangular"
            width={"90%"}
            height={180}
            sx={{ background: "white" }}
          />
        </>
      }
    >
      <VuiBox mt={1}>
        <VuiBox mt={2}>
          <VuiTypography variant="body2" color="white" fontWeight="bold">
            Layout
          </VuiTypography>
          <VuiBox ml={1}>
            <Grid
              container
              mt={3}
              mb={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <VuiTypography variant="body2" color="white">
                Shape
              </VuiTypography>

              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button>Rectangle</Button>
                <Button>Circle</Button>
              </ButtonGroup>
            </Grid>

            <VuiBox
              mt={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              lineHeight={1}
            >
              <VuiTypography variant="body2" color="white">
                Border
              </VuiTypography>
              <VuiBox
                mr={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <VuiSwitch
                  color="info"
                  checked={button1}
                  onChange={handleSetButton1}
                />
                <VuiTypography
                  variant="body2"
                  color={button1 ? "white" : "text"}
                >
                  &nbsp;&nbsp;&nbsp;{button1 ? "On" : "Off"}
                </VuiTypography>
              </VuiBox>
            </VuiBox>

            <Grid
              container
              mt={3}
              mb={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <VuiTypography variant="body2" color="white">
                Orientation
              </VuiTypography>

              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button>Vertical</Button>
                <Button>Horizontal</Button>
              </ButtonGroup>
            </Grid>

            <Grid
              container
              mt={3}
              mb={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <VuiTypography variant="body2" color="white">
                Position
              </VuiTypography>

              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button>Left</Button>
                <Button>Right</Button>
              </ButtonGroup>
            </Grid>
          </VuiBox>
        </VuiBox>
        <Divider light />

        <VuiBox mt={2}>
          <VuiTypography variant="body2" color="white" fontWeight="bold">
            Appearance
          </VuiTypography>
          <VuiBox ml={1}>
            <VuiBox
              mt={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              lineHeight={1}
            >
              <VuiTypography variant="body2" color="white">
                Border
              </VuiTypography>
              <VuiBox
                mr={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <VuiSwitch
                  color="info"
                  checked={button1}
                  onChange={handleSetButton1}
                />
                <VuiTypography
                  variant="body2"
                  color={button1 ? "white" : "text"}
                >
                  &nbsp;&nbsp;&nbsp;{button1 ? "On" : "Off"}
                </VuiTypography>
              </VuiBox>
            </VuiBox>

            <VuiBox
              mt={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              lineHeight={1}
            >
              <VuiTypography variant="body2" color="white">
                Caption
              </VuiTypography>
            </VuiBox>

            <VuiBox
              mt={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              lineHeight={1}
            >
              <VuiTypography variant="body2" color="white">
                Caption location
              </VuiTypography>
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button value="left" key="left">
                  <FormatAlignLeft />
                </Button>

                <Button value="center" key="center">
                  <FormatAlignCenter />
                </Button>

                <Button value="right" key="right">
                  <FormatAlignRight />
                </Button>
              </ButtonGroup>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Collapsed>
  );
}

function Thumbnail() {
  const [collapse, setCollapse] = useState(false);
  const theme = useTheme();
  const duration = 200; // seconds
  const [position, setPosition] = React.useState(32);
  const [paused, setPaused] = React.useState(false);
  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }
  // const mainIconColor = theme.palette.mode === 'light' ? '#fff' : '#000';
  // const lightIconColor =
  //   theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';

  return (
    <Collapsed
      icon={<GifBoxOutlined color="white" size="20px" />}
      title="Thumbnail"
      open={collapse === 1}
      onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
      width={700}
      height={400}
      body={
        <>
          <VuiInput placeholder="email@address.com" />
          <VuiBox width={250} mt={1}>
            <VuiInput placeholder="Subject" />
          </VuiBox>
        </>
      }
    >
      <VuiBox mt={2}>
        <VuiTypography variant="body2" color="white" fontWeight="medium">
          Appearance
        </VuiTypography>
        <VuiBox
          mt={1}
          ml={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <VuiBox sx={{ width: "100%", overflow: "hidden" }}>
            <Slider
              aria-label="time-indicator"
              size="small"
              value={position}
              min={0}
              step={1}
              max={duration}
              onChange={(_, value) => setPosition(value)}
              sx={{
                color:
                  theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
                height: 4,
                "& .MuiSlider-thumb": {
                  width: 8,
                  height: 8,
                  transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                  "&:before": {
                    boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                  },
                  "&:hover, &.Mui-focusVisible": {
                    boxShadow: `0px 0px 0px 8px ${
                      theme.palette.mode === "dark"
                        ? "rgb(255 255 255 / 16%)"
                        : "rgb(0 0 0 / 16%)"
                    }`,
                  },
                  "&.Mui-active": {
                    width: 20,
                    height: 20,
                  },
                },
                "& .MuiSlider-rail": {
                  opacity: 0.28,
                },
              }}
            />

            <VuiBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <VuiBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <VuiTypography variant="body2">Video Length:</VuiTypography>
                <VuiTypography variant="body2">
                  {formatDuration(duration)}
                </VuiTypography>
              </VuiBox>

              <VuiBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  aria-label={paused ? "play" : "pause"}
                  onClick={() => setPaused(!paused)}
                >
                  {paused ? (
                    <PlayArrowRounded
                      sx={{ fontSize: "3rem" }}
                      htmlColor={"white"}
                    />
                  ) : (
                    <PauseRounded
                      sx={{ fontSize: "3rem" }}
                      htmlColor={"white"}
                    />
                  )}
                </IconButton>
                <Box sx={{ p: 1, border: "1px solid", borderRadius: 2 }}>
                  {formatDuration(position)}
                </Box>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Box sx={{ p: 1, border: "1px solid", borderRadius: 2 }}>
                  {formatDuration(duration - position)}
                </Box>
              </VuiBox>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Collapsed>
  );
}

function Landing() {
  const [collapse, setCollapse] = useState(false);
  const [button1, setButton1] = useState(false);
  const handleSetButton1 = () => setButton1(!button1);

  return (
    <Collapsed
      icon={<DesktopWindowsOutlined color="white" size="20px" />}
      title="Landing Page"
      open={collapse === 1}
      onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
      width={700}
      height={400}
      body={
        <div
          style={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            marginTop: -25,
          }}
        >
          <VuiButton variant="contained" color="white">
            <Icon>share</Icon>&nbsp; Button
          </VuiButton>
          <VuiButton variant="gradient" color="info">
            Get Quickvideo for free
          </VuiButton>
        </div>
      }
    >
      <VuiBox mt={2}>
        <VuiTypography variant="body2" color="white" fontWeight="medium">
          Appearance
        </VuiTypography>
        <VuiBox
          mt={1}
          ml={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <VuiTypography variant="body2" color="white">
            Chat
          </VuiTypography>
          <VuiBox
            mr={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <VuiSwitch
              color="info"
              checked={button1}
              onChange={handleSetButton1}
            />
            <VuiTypography variant="body2" color={button1 ? "white" : "text"}>
              &nbsp;&nbsp;&nbsp;{button1 ? "On" : "Off"}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Collapsed>
  );
}

function Feed() {
  const [collapse, setCollapse] = useState(false);
  const [button1, setButton1] = useState(false);
  const handleSetButton1 = () => setButton1(!button1);
  const [button2, setButton2] = useState(false);
  const handleSetButton2 = () => setButton2(!button2);
  const [button3, setButton3] = useState(false);
  const handleSetButton3 = () => setButton3(!button3);

  return (
    <Collapsed
      icon={
        <LocalOfferOutlined
          color="white"
          size="22px"
          style={{ transform: "rotate(180deg)" }}
        />
      }
      title="Feed"
      open={collapse === 1}
      onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
      width={700}
      height={400}
      body={
        <>
          <VuiInput placeholder="email@address.com" />
          <VuiBox width={250} mt={1}>
            <VuiInput placeholder="Subject" />
          </VuiBox>
        </>
      }
    >
      <VuiBox mt={2}>
        <VuiTypography variant="body2" color="white" fontWeight="medium">
          Behaviour
        </VuiTypography>
        <VuiBox ml={2}>
          <VuiBox
            mt={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            lineHeight={1}
          >
            <VuiTypography variant="body2" color="white">
              View feed in fullscreen
            </VuiTypography>
            <VuiBox
              mr={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <VuiSwitch
                color="info"
                checked={button1}
                onChange={handleSetButton1}
              />
              <VuiTypography variant="body2" color={button1 ? "white" : "text"}>
                &nbsp;&nbsp;&nbsp;{button1 ? "On" : "Off"}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
          <VuiBox
            mt={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            lineHeight={1}
          >
            <VuiTypography variant="body2" color="white">
              Auto scroll to next video
            </VuiTypography>
            <VuiBox
              mr={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <VuiSwitch
                color="info"
                checked={button2}
                onChange={handleSetButton2}
              />
              <VuiTypography variant="body2" color={button2 ? "white" : "text"}>
                &nbsp;&nbsp;&nbsp;{button2 ? "On" : "Off"}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
          <VuiBox
            mt={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            lineHeight={1}
          >
            <VuiTypography variant="body2" color="white">
              Play unviewed videos first
            </VuiTypography>
            <VuiBox
              mr={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <VuiSwitch
                color="info"
                checked={button3}
                onChange={handleSetButton3}
              />
              <VuiTypography variant="body2" color={button3 ? "white" : "text"}>
                &nbsp;&nbsp;&nbsp;{button3 ? "On" : "Off"}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Collapsed>
  );
}

function Design() {
  return (
    // <VuiBox mt={2}>
    <Grid container spacing={1} paddingTop={2}>
      <Grid item xs={12}>
        <Stories />
      </Grid>
      <Grid item xs={12}>
        <Carousel />
      </Grid>
      <Grid item xs={12}>
        <Widget />
      </Grid>
      <Grid item xs={12}>
        <Thumbnail />
      </Grid>
      <Grid item xs={12}>
        <Landing />
      </Grid>
      <Grid item xs={12}>
        <Feed />
      </Grid>
    </Grid>
    // </VuiBox>
  );
}

export default Design;
