import { Card } from '@mui/material';
import VuiTypography from 'components/VuiTypography';
import React, { useEffect, useRef, useState } from 'react';
import BackupIcon from "@mui/icons-material/Backup";
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from "notistack";
import axios from 'axios';

const Upload = ({uploadFile, setUploadFile, setManualSubtitles, setLoading, timestamp, setTimeStamp}) => {
  const [fileId, setFileId] = useState(null);
  const [transcribeId, setTranscribleId] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const Authorization = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDI0NDYyNzEyLCJkYXRhIjp7ImVtYWlsIjoiWW9nZXNoQGtyaXNoa3dpcXJlcGx5Lm9ubWljcm9zb2Z0LmNvbSIsInJvbGUiOiJwcm92aWRlciIsImFwaWtleSI6IjJhNzllM2UyLTQ2ODUtNGY5Mi05ODBiLWNhMDU5ZDEyMjYxOCIsInJlZmVyZW5jZUtleSI6IjJhNzllM2UyLTQ2ODUtNGY5Mi05ODBiLWNhMDU5ZDEyMjYxOCIsInBsYW5UeXBlIjoiZGVmYXVsdCIsImNvdW50cnkiOm51bGwsIm1hcmtldHBsYWNlIjp0cnVlfSwiaWF0IjoxNzAyNDQ1OTExfQ.Tx1FRMp7qASAlIg79kFuZIMO8w-CYo2BpCvTXaWz6HQ';

  const removeFile = (event) => {
    event.stopPropagation();
    setUploadFile(null);
  }

  const isFileSizeValid = (file) => {
    const maxSize = 75 * 1024 * 1024;
    return file.size <= maxSize;
  };

  useEffect(() => {
    if (transcribeId) {
      pollTranscriptionStatus(transcribeId);
    }
  }, [transcribeId]);

  const pollTranscriptionStatus = async (transcribeId) => {
    // setPollingInProgress(true);

    const pollingInterval = 2000;
    const maxPollingAttempts = 10;

    let pollingAttempts = 0;
    const pollingTimer = setInterval(async () => {
      try {
        setLoading(true);
        const response = await fetchTranscriptionData(transcribeId);
        if (response.data.transcriptionStatus === 'Completed') {
          setManualSubtitles(response.data.transcripts);
          setTimeStamp(response.data.timestamp);
          setLoading(false);
          clearInterval(pollingTimer);
        } else if (pollingAttempts >= maxPollingAttempts) {
          setLoading(false);
          console.log('Transcription not ready within time limit');
          clearInterval(pollingTimer);
        }
        pollingAttempts++;
      } catch (error) {
        console.error('Error fetching transcription data:', error);
        clearInterval(pollingTimer);
      }
    }, pollingInterval);
  }

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!isFileSizeValid(file)) {
      enqueueSnackbar("File size exceeds the limit of 15MB", {
        variant: "Warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }

    setUploadFile(file);

    const formData = new FormData();
    formData.append('files', file);

    setLoading(true);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);

    // try {
    //   setLoading(true);
    //   const response = await axios.post('https://platform.neuralspace.ai/api/file/upload', formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //       'Authorization': Authorization
    //     },
    //   });

    //   const fileId = response.data.data.fileId;
    //   setFileId(fileId);
    //   transcribeFile(fileId);
    // } catch (error) {
    //   console.error('Error transcribing audio:', error);
    // }

  };

  const transcribeFile = async (fileId) => {
    const transcribeUrl = 'https://platform.neuralspace.ai/api/transcription/v1/file/transcribe';
    const language = 'en';
    const domain = 'general-v3-india-default';

    const transcriptionData = {
      fileId,
      language,
      domain
    };

    try {
      const response = await axios.post(transcribeUrl, transcriptionData, {
        headers: {
          'Authorization': Authorization,
          'Content-Type': 'application/json'
        }
      });
  
      setTranscribleId(response.data.data.transcribeId);
      pollTranscriptionStatus(response.data.data.transcribeId);
    } catch (error) {
      console.error('Error transcribing file:', error);
    }
  }

  const fetchTranscriptionData = async (transcribeId) => {
    const transcriptionApiUrl = `https://platform.neuralspace.ai/api/transcription/v1/single/transcription?transcribeId=${transcribeId}`;

    try {
      const response = await axios.get(transcriptionApiUrl, {
        headers: {
          'Authorization': Authorization
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching transcription data:', error);
      throw error;
    }
  }

  return (
    <>
      {uploadFile ? (
        <div sx={{boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)"}}>
          <ClearIcon sx={{color: 'whitesmoke', boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)", borderRadius: '100%', position: "absolute", right: 15, top: 15, cursor: 'pointer'}} onClick={removeFile} />
          <video controls style={{ width: "100%", height: "100%", marginTop: '20px' }}>
            <source src={URL.createObjectURL(uploadFile)} type="video/mp4" />
          </video>
          <VuiTypography textAlign='center'>{uploadFile.name}</VuiTypography>
        </div>
      ) : (
        <label htmlFor="video-file" style={{cursor: 'pointer'}}>
          <VuiTypography variant="h5" color="white" textAlign= 'center'>
            Upload the video to be personalized
          </VuiTypography>
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "fit-content",
              color: "whitesmoke",
              border: "1px dashed gray",
              cursor: "pointer",
              borderRadius: "15px",
              overflow: "hidden",
              padding: "40px",
              minWidth: "500px",
              maxWidth: "700px",
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '20px'
            }}
          >
            <input
              accept="video/mp4"
              style={{ display: 'none' }}
              type="file"
              id="video-file"
              name="video-file"
              value={uploadFile}
              onChange={handleFileUpload}
            />
            <BackupIcon sx={{ width: "3em", height: "3em" }} />
            <h3 style={{fontWeight: 300}}>Drag and drop files here</h3>
            <h4>or</h4>
            <h4
              style={{
                padding: "5px 15px",
                marginTop: "5px",
                border: "1px solid gray",
                borderRadius: "5px",
                fontWeight: 400
              }}
            >
              Browse Files
            </h4>
          </Card>
        </label>
      )}
    </>
  )
}

export default Upload;