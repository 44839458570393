import { Card } from '@mui/material';
import VuiBox from 'components/VuiBox';
import React, { useState } from 'react';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import ImageIcon from '@mui/icons-material/Image';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import MovieIcon from '@mui/icons-material/Movie';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import InterestsIcon from '@mui/icons-material/Interests';
import AnimationIcon from '@mui/icons-material/Animation';
import Template from './components/Template';
import Models from './components/Models';
import Text from './components/Text';
import Subtitle from './components/Subtitle';
import Image from './components/Image';
import Background from './components/Background';
import Video from './components/Video';
import Audio from './components/Audio';
import Shapes from './components/Shapes';
import Animation from './components/Animation';

const Sidediv = ({touched, setTouched, alignment, setAlignment, value, setValue, fonts, setFonts, selectedFont, setSelectedFont, selectedFontSize, setSelectedFontSize, fontSize, setFontSize, selectedFile, setSelectedFile, stockImages, setStockImages, imageQuery, setImageQuery, page, setPage, imageObserver, videoObserver, lastImageRef, loading, selectedBackground, setSelectedBackground, uploadedBackground, setUploadedBackground, backgroundTabValue, setBackgroundTabValue, uploadedVideo, setUploadedVideo, stockVideos, setStockVideos, videoQuery, setVideoQuery, videoPage, setVideoPage, lastVideoRef, uploadedAudio, setUploadedAudio, models, setModels, imageTotalPages, videoTotalPages, handleClick, selectedImage, setSelectedImage,  selectedAudio, setSelectedAudio, faceModels, scenes, updateSceneKey, currentSceneIndex, openSettings, setOpenSettings, setScenes, stockShapes, setStockShapes, shapesQuery, setShapesQuery, indexToStore, setIndexToStore, loadingModal, setLoadingModal, addScene}) => {

  return (
    <VuiBox sx={{display: 'flex', marginLeft: '15px'}}>
      <Card sx={{flex: '0.7', display: 'flex', flexDirection: 'column', maxHeight: '80vh', overflow: 'auto'}}>
        {/* {touched.template && <Template />} */}
        {touched.model && <Models 
          models={models}
          faceModels={faceModels}
          scenes={scenes}
          updateSceneKey={updateSceneKey}
          currentSceneIndex={currentSceneIndex}
          openSettings={openSettings}
          setOpenSettings={setOpenSettings}
        />}
        {touched.text && <Text 
          scenes={scenes} 
          setScenes={setScenes}
          updateSceneKey={updateSceneKey} 
          currentSceneIndex={currentSceneIndex}
          touched={touched}
          setTouched={setTouched}
          indexToStore={indexToStore}
          setIndexToStore={setIndexToStore}
          selectedFontSize={selectedFontSize}
          setSelectedFontSize={setSelectedFontSize}
          fontSize={fontSize}
          setFontSize={setFontSize}
        />}
        {touched.subtitle && <Subtitle
          alignment={alignment}
          setAlignment={setAlignment}
          value={value}
          setValue={setValue}
          fonts={fonts}
          setFonts={setFonts}
          selectedFont={selectedFont}
          setSelectedFont={setSelectedFont}
          selectedFontSize={selectedFontSize}
          setSelectedFontSize={setSelectedFontSize}
          fontSize={fontSize}
          setFontSize={setFontSize}
        />}
        {touched.image && <Image
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          stockImages={stockImages}
          setStockImages={setStockImages}
          imageQuery={imageQuery}
          setImageQuery={setImageQuery}
          page={page}
          setPage={setPage}
          imageObserver={imageObserver}
          lastImageRef={lastImageRef}
          loading={loading}
          imageTotalPages={imageTotalPages}
          scenes={scenes}
          setScenes={setScenes}
          updateSceneKey={updateSceneKey}
          currentSceneIndex={currentSceneIndex}
          openSettings={openSettings}
          setOpenSettings={setOpenSettings}
        />}
        {touched.background && <Background
          selectedBackground={selectedBackground}
          setSelectedBackground={setSelectedBackground}
          uploadedBackground={uploadedBackground}
          setUploadedBackground={setUploadedBackground}
          backgroundTabValue={backgroundTabValue}
          setBackgroundTabValue={setBackgroundTabValue}
          stockImages={stockImages}
          setStockImages={setStockImages}
          imageQuery={imageQuery}
          setImageQuery={setImageQuery}
          page={page}
          setPage={setPage}
          imageObserver={imageObserver}
          videoObserver={videoObserver}
          lastImageRef={lastImageRef}
          loading={loading}
          stockVideos={stockVideos}
          setStockVideos={setStockVideos}
          videoQuery={videoQuery}
          setVideoQuery={setVideoQuery}
          videoPage={videoPage}
          setVideoPage={setVideoPage}
          lastVideoRef={lastVideoRef}
          currentSceneIndex={currentSceneIndex}
          updateSceneKey={updateSceneKey}
        />}
        {touched.video && <Video
          uploadedVideo={uploadedVideo}
          setUploadedVideo={setUploadedVideo}
          stockVideos={stockVideos}
          setStockVideos={setStockVideos}
          videoQuery={videoQuery}
          setVideoQuery={setVideoQuery}
          videoPage={videoPage}
          videoTotalPages={videoTotalPages}
          setVideoPage={setVideoPage}
          lastVideoRef={lastVideoRef}
          loading={loading}
          videoObserver={videoObserver}
          scenes={scenes}
          setScenes={setScenes}
          updateSceneKey={updateSceneKey}
          currentSceneIndex={currentSceneIndex}
          openSettings={openSettings}
          setOpenSettings={setOpenSettings}
          setUploadedBackground={setUploadedBackground}
          loadingModal={loadingModal}
          setLoadingModal={setLoadingModal}
          addScene={addScene}
        />}
        {/* {touched.audio && <Audio
          uploadedAudio={uploadedAudio}
          setUploadedAudio={setUploadedAudio}
        />} */}
        {/* {touched.shapes && <Shapes
          scenes={scenes}
          setScenes={setScenes}
          updateSceneKey={updateSceneKey}
          currentSceneIndex={currentSceneIndex}
          openSettings={openSettings}
          setOpenSettings={setOpenSettings}
          stockShapes={stockShapes}
          setStockShapes={setStockShapes}
          shapesQuery={shapesQuery}
          setShapesQuery={setShapesQuery}
          loading={loading}
        />} */}
        {/* {touched.animation && <Animation />} */}
      </Card>
      <VuiBox sx={{flex: '0.3', display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '80vh', overflowY: 'auto', marginRight: '-20px', marginTop: '10px'}}>
        {/* <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '75px', marginBottom: '20px', cursor: 'pointer'}} onClick={() => handleClick('template')}>
          <ViewComfyIcon fontSize='medium' color={touched.template ? 'lightblue' : 'white'} />
          <h6 style={{color: touched.template ? 'lightblue' : 'whitesmoke'}}>Templates</h6>
        </VuiBox> */}
        <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '75px', marginBottom: '20px', cursor: 'pointer'}} onClick={() => handleClick('model')}>
          <AccountBoxIcon fontSize='medium' color={touched.model ? 'lightblue' : 'white'} />
          <h6 style={{color: touched.model ? 'lightblue' : 'whitesmoke'}}>Models</h6>
        </VuiBox>
        <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '75px', marginBottom: '20px', cursor: 'pointer'}} onClick={() => handleClick('text')}>
          <TextFieldsIcon fontSize='medium' color={touched.text ? 'lightblue' : 'white'} />
          <h6 style={{color: touched.text ? 'lightblue' : 'whitesmoke'}}>Text</h6>
        </VuiBox>
        {/* <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '75px', marginBottom: '20px', cursor: 'pointer'}} onClick={() => handleClick('subtitle')}>
          <SubtitlesIcon fontSize='medium' color={touched.subtitle ? 'lightblue' : 'white'} />
          <h6 style={{color: touched.subtitle ? 'lightblue' : 'whitesmoke'}}>Subtitle</h6>
        </VuiBox> */}
        <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '75px', marginBottom: '20px', cursor: 'pointer'}} onClick={() => handleClick('image')}>
          <ImageIcon fontSize='medium' color={touched.image ? 'lightblue' : 'white'} />
          <h6 style={{color: touched.image ? 'lightblue' : 'whitesmoke'}}>Image</h6>
        </VuiBox>
        <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '75px', marginBottom: '20px', cursor: 'pointer'}} onClick={() => handleClick('background')}>
          <WallpaperIcon fontSize='medium' color={touched.background ? 'lightblue' : 'white'} />
          <h6 style={{color: touched.background ? 'lightblue' : 'whitesmoke'}}>Background</h6>
        </VuiBox>
        <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '75px', marginBottom: '20px', cursor: 'pointer'}} onClick={() => handleClick('video')}>
          <MovieIcon fontSize='medium' color={touched.video ? 'lightblue' : 'white'} />
          <h6 style={{color: touched.video ? 'lightblue' : 'whitesmoke'}}>Video</h6>
        </VuiBox>
        {/* <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '75px', marginBottom: '20px', cursor: 'pointer'}} onClick={() => handleClick('audio')}>
          <LibraryMusicIcon fontSize='medium' color={touched.audio ? 'lightblue' : 'white'} />
          <h6 style={{color: touched.audio ? 'lightblue' : 'whitesmoke'}}>Audio</h6>
        </VuiBox> */}
        {/* <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '75px', marginBottom: '20px', cursor: 'pointer'}} onClick={() => handleClick('shapes')}>
          <InterestsIcon fontSize='medium' color={touched.shapes ? 'lightblue' : 'white'} />
          <h6 style={{color: touched.shapes ? 'lightblue' : 'whitesmoke'}}>Shapes</h6>
        </VuiBox> */}
        {/* <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '75px', marginBottom: '20px', cursor: 'pointer'}} onClick={() => handleClick('animation')}>
          <AnimationIcon fontSize='medium' color={touched.animation ? 'lightblue' : 'white'} />
          <h6 style={{color: touched.animation ? 'lightblue' : 'whitesmoke'}}>Animation</h6>
        </VuiBox> */}
      </VuiBox>
    </VuiBox>
  )
}

export default Sidediv