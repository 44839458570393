/*eslint-disable no-unused-vars */

import React from "react";
import QuickVideoIcon from "../QuickVideoIcon.png";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import VuiButton from "components/VuiButton";
import { useState } from "react";

function FinalStep() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [Size, setSize] = useState(0);

  const handleSize = (event, newSize) => {
    setSize(newSize);
  };
  const [Role, setRole] = useState(0);

  const handleRole = (event, newRole) => {
    setRole(newRole);
  };

  return (
    // <VuiBox sx={{ width: "65vw" }}>
    <Grid position={"relative"} sx={{ height: window.innerWidth < 768 ? "35vh" : "13vh",marginTop:"5%" }}>
      <VuiBox mt={2} style={{ display: "flex", justifyContent: "center", margin:"auto" }}>
        <VuiTypography
          style={{ display: "flex",flexDirection: window.innerWidth < 768 ? 'column' : '', justifyContent: "center", textAlign: 'center' }}
          variant={window.innerWidth < 768 ? "h5" : "h2"}
          color="light"
          textGradient
          verticalAlign="middle"
          fontWeight="medium"
        >
          Welcome to &nbsp;
          <img src={QuickVideoIcon} alt="quickvideoicon" style={{ width: window.innerWidth < 768 ? "150px" :"300px" }} />
        </VuiTypography>
      </VuiBox>
      <VuiBox mt={2} style={{ display: "flex", justifyContent: "center" }}>
        <VuiTypography
          variant="h5"
          color="info"
          textGradient
          verticalAlign="middle"
          fontWeight="regular"
        >
          Create your first video.
        </VuiTypography>
      </VuiBox>
    </Grid>
    // </VuiBox>
  );
}

export default FinalStep;