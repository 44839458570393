// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiDropzone from "components/VuiDropzone";
import VideoThumbnailCard from "../VideoThumbnailCard";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
//eslint-disable-next-line
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import VuiButton from "components/VuiButton";
import { Box } from "@mui/material";

function BrandingSettings(props) {
  console.log("ProfileSettings", props);
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <VuiBox mb="40px">
        <VuiTypography variant="h3" color="white" fontWeight="bold">
          Branding Settings
        </VuiTypography>
        <VuiTypography variant="subtitle1" color="white" fontSize="16px">
          Need Some Help? <a href="https://www.creative-tim.com/contact-us">Contact Us</a>
        </VuiTypography>
      </VuiBox>

      <Grid container spacing={3} direction="row">
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3} direction="column">
            <Grid item xs={12}>
              <VuiBox id="play-btn">
                <VuiTypography variant="h4" color="white" fontWeight="bold">
                  Play Button
                </VuiTypography>
                <VuiTypography variant="subtitle2" color="white" fontSize="15px">
                  Customize your play button to match your brand.
                </VuiTypography>
                <br/>
                <VuiBox component="play-btn-form">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormField label="Start Screen Text" placeholder="Begin an Interactive Conversation" type="text" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField label="Border Color" placeholder="Pick a Color" type="color" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField label="Background" placeholder="60%" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField label="Play Button Size" placeholder="Medium" type="select" />
                    </Grid>
                  </Grid>
                </VuiBox>
              </VuiBox>
            </Grid>
            <Grid item xs={12}>
              <VuiBox id="contact-form">
                <VuiTypography variant="h4" color="white" fontWeight="bold">
                  Contact Form
                </VuiTypography>
                <VuiTypography variant="subtitle2" color="white" fontSize="15px">
                  Customize your contact form to match your brand.
                </VuiTypography>
                <br/>
                <VuiBox component="play-btn-form">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormField label="CTA Text" placeholder="Leave us your details so we can get back to you" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField label="Background" placeholder="#FF005C" />
                    </Grid>
                  </Grid>
                </VuiBox>
              </VuiBox>
            </Grid>
            <Grid item xs={12}>
              <VuiBox id="Feed">
                <VuiTypography variant="h4" color="white" fontWeight="bold">
                  Feed
                </VuiTypography>
                <VuiTypography variant="subtitle2" color="white" fontSize="15px">
                  Customize your feed to match your brand.
                </VuiTypography>
                <br/>
                <VuiBox component="feed-form">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormField label="Button Color" placeholder="Pick a Color" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField label="Button Corner Radius" placeholder="Semi-Round" />
                    </Grid>
                  </Grid>
                </VuiBox>
              </VuiBox>
            </Grid>
            <Grid item xs={12}>
              <VuiBox id="Feed">
                <VuiTypography variant="h4" color="white" fontWeight="bold">
                  Player
                </VuiTypography>
                <VuiTypography variant="subtitle2" color="white" fontSize="15px">
                  Design a Player to match your brand.
                </VuiTypography>
                <br/>
                <VuiBox component="feed-form">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormField label="Brand Font" placeholder="Default" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField label="Brand Color" placeholder="Pick a Color" />
                    </Grid>
                  </Grid>
                </VuiBox>
              </VuiBox>
            </Grid>
            <Grid item xs={12}>
              <VuiBox id="Feed">
                <VuiTypography variant="h4" color="white" fontWeight="bold">
                  Logo
                </VuiTypography>
                <VuiTypography variant="subtitle2" color="white" fontSize="15px">
                  Upload your logo for use across QuickVideo's feed and landing page for a customized user experience.
                </VuiTypography>
                <br/>
                <VuiBox component="logo-form">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <VuiBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <VuiDropzone options={{ multiple: false, acceptedFiles: "image/*", addRemoveLinks: true }} />
                      </VuiBox>
                    </Grid>
                    <Grid item xs={12}>
                      <VuiTypography variant="subtitle2" color="white" fontSize="12px">
                        Max Size: 1MB | Max Resolution: 1536 x 1536 pixels
                      </VuiTypography>
                    </Grid>
                  </Grid>
                </VuiBox>
              </VuiBox>
            </Grid>
            <Grid item xs={12}>
              <VuiBox id="Feed">
                <VuiTypography variant="h4" color="white" fontWeight="bold">
                  Customer Notification Emails
                </VuiTypography>
                <VuiTypography variant="subtitle2" color="white" fontSize="15px">
                  Upload your logo for use on the email sent to recipients after answering your QuickVideo. 
                </VuiTypography>
                <br/>
                <VuiBox component="logo-form">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <VuiBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <VuiDropzone options={{ multiple: false, acceptedFiles: "image/*", addRemoveLinks: true }} />
                      </VuiBox>
                    </Grid>
                    <Grid item xs={12}>
                      <VuiTypography variant="subtitle2" color="white" fontSize="12px">
                        Max Size: 1MB | Max Resolution: 1536 x 1536 pixels
                      </VuiTypography>
                    </Grid>
                  </Grid>
                </VuiBox>
              </VuiBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <VideoThumbnailCard 
          title="slack bot"
          color="info"
          description="If everything I did failed - which it doesn't, I think that it actually succeeds."
          dateTime="02.03.22"
        thumbnail={"https://images.unsplash.com/photo-1670470076011-cd2f6e03ca40?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"} 
        />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <VuiButton variant="contained" color="primary" size="lg">
              Save Changes
            </VuiButton>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

export default BrandingSettings;
