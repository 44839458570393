import { AppBar, Button, ButtonGroup, Card, CircularProgress, Grid, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import React, { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import VuiSelect from 'components/VuiSelect';
import VuiInput from 'components/VuiInput';
import { useVisionUIController } from 'context';
import axios from 'axios';
import { IoMdColorWand } from "react-icons/io";
import BASE_URL from "context/api";

const Voice = ({selectedFile, setSelectedFile, audioResponse, setAudioResponse, availableVoiceSelect, setAvailableVoiceSelect}) => {
  const [cloneScriptTouched, setCloneScriptTouched] = useState(false);
  const [cloneNameTouched, setCloneNameTouched] = useState(false);
  const [allOptions, setAllOptions] = useState([]);
  const [modelSelection, setModelSelection] = useState('');
  const [cloneVoiceLoading, setCloneVoiceLoading] = useState(false);
  const [textLoading, setTextLoading] = useState(false);
  const [cloneVoiceToggle, setCloneVoiceToggle] = useState(false);
  const [cloneText, setCloneText] = useState('');
  const [availableVoiceText, setAvailableVoiceText] = useState("");
  const [cloneName, setCloneName] = useState("");
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const cloneScriptCharacterCount = cloneText.length;
  
  const [controller] = useVisionUIController();
  const { userDetails } = controller;
  const { user_id, access_token } = userDetails;

  const apiKey = '39ac8d95df8d5d45639e30ca0cbcdfbb';

  const fetchAvailableVoices = async () => {
    const url = 'https://api.elevenlabs.io/v1/voices';
    const headers = {
      Accept: 'application/json',
      'xi-api-key': apiKey,
    };

    try {
      const response = await axios.get(url, { headers });
      return response.data.voices;
    } catch (error) {
      console.error('Error fetching available voices:', error);
      return [];
    }
  };

  const fetchAllVoices = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/get_all_voices`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching all voices:', error);
      return [];
    }
  };

  const fetchData = async () => {
    const availableVoices = await fetchAvailableVoices();
    const allVoices = await fetchAllVoices();

    const premadeVoices = availableVoices.filter(option => option.category === 'premade');

    const combinedOptions = [
      ...allVoices.map(option => ({ id: "clone", value: option.voice_id, label: option.name, audioUrl: option.preview_url })),
      ...premadeVoices.map(option => ({ id: "premade", value: option.voice_id, label: option.name, audioUrl: option.preview_url })),
    ];

    setAllOptions(combinedOptions);
  };

  const handlePlayPauseClick = (voiceId, isPlaying) => {
    if (isPlaying) {
      setCurrentlyPlaying(voiceId);
    } else {
      setCurrentlyPlaying(null);
    }
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);

  const handleCloneScriptBlur = () => {
    setCloneScriptTouched(true);
  }

  const handleCloneNameBlur = () => {
    setCloneNameTouched(true);
  }

  const cloneError = cloneScriptTouched && cloneText === '';
  const cloneNameError = cloneNameTouched && cloneName === '';

  const handleCloneText = (e) => {
    setCloneText(e.target.value);
  }

  const handleCloneName = (e) => {
    setCloneName(e.target.value);
  }

  const handleAvailableVoiceSelection = (selectedOption) => {
    // if(selectedOption.id === "premade") {
    //   setAvailableVoiceSelect(selectedOption.value);
    //   setAudioResponse(null);
    // } else {
    //   setAudioResponse(selectedOption.value);
    //   setAvailableVoiceSelect('');
    // }
    setAudioResponse(selectedOption.value);
    setAvailableVoiceSelect('');
  }

  const handleModelSelection = (selectedOption) => {
    setModelSelection(selectedOption.value);
  }

  const handleVoiceCloneApi = () => {
    setCloneVoiceLoading(true);
    const formData = new FormData();
      selectedFile.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });
      // formData.append("file", selectedFile);
      formData.append("user_id", user_id);
      formData.append("name", cloneName);
      formData.append("description", cloneText);
      formData.append("model_id", modelSelection);
    try {
      axios.post(`${BASE_URL}/generate_voice`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const audioData = res.data.voice_id;
        setAudioResponse(audioData);
        setCloneVoiceToggle(true);
        setCloneVoiceLoading(false);
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        setCloneVoiceLoading(false);
      })
    } catch (err) {
      console.log(err);
      setCloneVoiceLoading(false);
    }
  }

  const handleChatgptText = async (field) => {
    let prompt;

    if (field === 2) {
      prompt = availableVoiceText;
    } else if (field === 3) {
      prompt = cloneText;
    }

    if (prompt) {
      try {
        const response = await makeAPIRequest(prompt);
        if(field === 2){
          setAvailableVoiceText(response);
        } else if(field === 3) {
          setCloneText(response);
        }
      } catch (error) {
        console.error('API request failed:', error);
      }
    } else {
      console.error('Input field must have input.');
    }
  }

  const makeAPIRequest = async (prompt) => {
    setTextLoading(true);
    try {
      const res = await axios.post(`${BASE_URL}/generate_ai_text`, {
        prompt_text: prompt
      })
      
      const newAvailableText = res.data.generated_text;
      setTextLoading(false);
      return newAvailableText.trim();
    } catch (err) {
      console.log(err);
    }
  }

  const removeFile = (event, index) => {
    event.stopPropagation();
    setSelectedFile((prevFiles) => prevFiles.filter((_, i) => i !== index));
  }

  const isFileSizeValid = (file) => {
    const maxSize = 15 * 1024 * 1024;
    return file.size <= maxSize;
  };

  const handleFileUpload = async (event) => {
    const newFiles = Array.from(event.target.files);
    setSelectedFile((prevFiles) => [...prevFiles, ...newFiles]);

    // if (!isFileSizeValid(files)) {
    //   enqueueSnackbar("File size exceeds the limit of 15MB", {
    //     variant: "Warning",
    //     autoHideDuration: 3000,
    //     anchorOrigin: {
    //       vertical: "top",
    //       horizontal: "right",
    //     },
    //   });
    //   return;
    // }
  };

  const [tabValue, setTabValue] = useState(0);

  const tabHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
      <AppBar
        position="static"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Tabs
          value={tabValue}
          onChange={tabHandler}
        >
          <Tab
            id="monthly"
            label={
              <VuiBox>
                <VuiTypography fontSize={12} color="white" fontWeight="bold">
                  Use available voice
                </VuiTypography>
              </VuiBox>
            }
          />
          <Tab
            id="annual"
            label={
              <VuiBox>
                <VuiTypography fontSize={12} color="white" fontWeight="bold">
                  Generate clone voice
                </VuiTypography>
              </VuiBox>
            }
          />
        </Tabs>
      </AppBar>
      <Grid sx={{ maxHeight: "1000px", marginTop: '30px', width: '100%'}}>
        <>
          {(cloneVoiceToggle && audioResponse !== '') ? (
            <div style={{marginTop: '40px', textAlign: 'center'}}>
              <h5 style={{color: 'gray', marginBottom: '40px', textAlign: 'center'}}>Voice Clone has been generated successfully. <br /> You can now click on submit button to start generating personalized videos.</h5>
            </div>
          ) : cloneVoiceLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
              <CircularProgress size={30} color="white" />
            </div> : 
            <>
            {tabValue === 0 &&  
            <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap', flexDirection: 'column', height: '35vh'}}>
              <VuiBox sx={{width: '300px', marginBottom: '20px'}}>
                <VuiTypography variant='h6'>Select voice</VuiTypography>
                <VuiSelect
                  placeholder="None"
                  options={allOptions}
                  onChange={handleAvailableVoiceSelection}
                  enableAudio={true}
                  currentlyPlaying={currentlyPlaying}
                  onPlayPauseClick={handlePlayPauseClick}
                />
              </VuiBox>
            </VuiBox>}
            {tabValue === 1 && <VuiBox sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
              <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-around',marginBottom: '25px', flexDirection: 'column'}}>
                <VuiBox sx={{width: '300px', marginBottom: '20px'}}>
                  <VuiTypography variant='h6'>Voice Name </VuiTypography>
                  <VuiInput
                    multiline
                    onChange={(e) => handleCloneName(e)}
                    value={cloneName}
                    onBlur={handleCloneNameBlur}
                    error={cloneNameError}
                    disabled={textLoading || cloneVoiceLoading}
                    placeholder="e.g, project1"
                  />
                </VuiBox>
                <VuiBox sx={{width: '300px', marginBottom: '20px'}}>
                  <VuiTypography variant='h6'>Select language</VuiTypography>
                  <VuiSelect
                    placeholder="None"
                    options={[
                      { value: "eleven_monolingual_v1", label: "English" },
                      { value: "eleven_multilingual_v1", label: "German" },
                      { value: "eleven_multilingual_v1", label: "Polish" },
                      { value: "eleven_multilingual_v1", label: "Spanish" },
                      { value: "eleven_multilingual_v1", label: "Italian" },
                      { value: "eleven_multilingual_v1", label: "French" },
                      { value: "eleven_multilingual_v1", label: "Portugeese" },
                      { value: "eleven_multilingual_v1", label: "Hindi" },
                    ]}
                    onChange={handleModelSelection}
                  />
                </VuiBox>
                <VuiBox sx={{width: '300px'}}>
                  <VuiTypography variant='h5'>Description</VuiTypography>
                  <VuiInput
                    placeholder={availableVoiceSelect === '' ? "How would you describe the voice? e.g. 'An old American make voice with a sight hoarseness in his throat. Perfect for news.'" : "Write your script here..."}
                    multiline
                    minRows={5}
                    maxRows={15}
                    onChange={(e) => handleCloneText(e)}
                    value={cloneText}
                    disabled={textLoading || cloneVoiceLoading}
                    onBlur={handleCloneScriptBlur}
                    error={cloneError}
                    sx={{marginTop: '5px'}}
                  />
                  <VuiBox
                    sx={{
                      width: "500px",
                      borderRadius: "5px",
                      marginTop: "2%",
                      height: "48px",
                      display: "flex",
                      overflow: "hidden",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title="Character count" placement="top">
                      <Button disabled={textLoading} sx={{border: '1px solid gray', color: 'whitesmoke', fontSize: '16px'}}>{cloneScriptCharacterCount}</Button>
                    </Tooltip>
                    <ButtonGroup variant="text" aria-label="text button group">
                      <Tooltip title="Generate text using AI" placement="top">
                        <IconButton aria-label="AI" color="light" onClick={()=>handleChatgptText(3)} disabled={cloneText === '' || textLoading}>
                          {textLoading ? <CircularProgress size={14} color="white" /> : <IoMdColorWand />}
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </VuiBox>
                </VuiBox>
              </VuiBox>
              <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-around',marginBottom: '25px', flexDirection: 'column'}}>
                <VuiBox sx={{width: '300px', marginTop: '20px'}}>
                  <>
                    <label htmlFor="audio-file-input" style={{cursor: 'pointer'}}>
                      <Card sx={{boxShadow: "0px 0px 50px 1px rgb(54, 68, 97)", color: 'whitesmoke', marginBottom: '10px', textAlign: 'center', display: 'flex', alignItems: 'center'}}>
                        <CloudUploadIcon />
                        <VuiTypography component='h6' sx={{color: 'gray !important', fontWeight: '300 !important'}}>
                          Upload voice sample here! (upto 25 files)
                          <input
                            accept="audio/mp3"
                            style={{ display: 'none' }}
                            type="file"
                            id="audio-file-input"
                            name="audio-file-input"
                            multiple
                            onChange={handleFileUpload}
                          />
                        </VuiTypography>
                      </Card>
                    </label>
                  </>
                  {selectedFile.length > 0 && <Card sx={{maxHeight: '250px', overflowY: 'auto'}}>
                  {selectedFile.map((item, index) => (
                    <VuiBox key={index} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <VuiTypography variant='h6'>{item.name.slice(0, 10)}..</VuiTypography>
                      <ClearIcon sx={{color: 'whitesmoke', boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)", borderRadius: '100%', cursor: 'pointer'}} onClick={(event)=>removeFile(event, index)} />
                      {/* <AudioPlayer audioSrc={URL.createObjectURL(selectedFile)} /> */}
                      {/* <audio controls style={{width: '100%', height: '40px'}}>
                        <source src={URL.createObjectURL(selectedFile)} type="audio/mpeg" />
                      </audio> */}
                    </VuiBox>
                  ))}
                  </Card>}
                </VuiBox>
                {selectedFile.length <= 0 && <VuiBox sx={{width: '300px', marginTop: '30px'}}>
                  {/* <VuiTypography variant='h5' sx={{marginTop: '25px', marginBottom: '5px'}}>Samples {selectedFile ? 1 : 0}/25 </VuiTypography> */}
                  <div style={{border: '1px dashed gray', height: '50px', padding: '5px 10px'}}>
                    <p style={{color: 'gray', fontSize: '12px'}}>Currently no files uploaded yet</p>
                  </div>
                </VuiBox>}
                <VuiBox sx={{width: '300px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '30px'}}>
                  <Button variant="contained" sx={{marginTop: '10px', color: 'whitesmoke', "&.Mui-disabled": { opacity: 0.5, cursor: "not-allowed", backgroundColor: "gray", color: 'whitesmoke' }}} onClick={handleVoiceCloneApi} disabled={cloneName === '' || cloneText === '' || selectedFile === null || modelSelection === ''}>Generate</Button>
                </VuiBox>
              </VuiBox>
            </VuiBox>}
            </>
          }
        </>
      </Grid>
    </VuiBox>
  )
}

export default Voice;