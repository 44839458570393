import { Card } from '@mui/material'
import VuiBox from 'components/VuiBox'
import VuiTypography from 'components/VuiTypography'
import Pagination from 'Pagination'
import React, { useState } from 'react'

const PersonalizationLogs = ({data}) => {
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = data?.slice(startIndex, endIndex);

  return (
    <Card>
      {displayedData?.map((item, index) => (
        <VuiBox key={index} sx={{border: '1px solid', '&:hover': {border: '1px solid skyblue'}, padding: '5px 20px', borderRadius: '10px', margin: '10px 0px'}}>
          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: window.innerWidth < 768 && 'column'}}>
            <VuiTypography color='white' variant='h5'>{item.video_name}</VuiTypography>
            <VuiTypography color='success' variant='h6'>{item.video_count}</VuiTypography>
          </VuiBox>
          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: window.innerWidth < 768 && 'column'}}>
            <VuiTypography color='info' variant='h6' sx={{fontSize: '12px'}}>{item.timestamp}</VuiTypography>
            {item.credits_used > 1 ? 
              <VuiTypography color='error' variant='h6'  sx={{fontSize: '14px'}}>- {item.credits_used} credits</VuiTypography>
              : <VuiTypography color='error' variant='h6'  sx={{fontSize: '14px'}}>- {item.credits_used} credit</VuiTypography>
            }
          </VuiBox>
        </VuiBox>
      ))}
      {data?.length > 5 && <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(data?.length / itemsPerPage)}
        onPageChange={handlePageChange}
      />}
    </Card>
  )
}

export default PersonalizationLogs