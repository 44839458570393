
import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";

// Vision UI Dashboard custom components
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background-cover-auth-signin-min.png";

import { Button, Icon } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useVisionUIController, login, googleLogin } from "context";
import { useSnackbar } from "notistack";
import CircularProgress from '@mui/material/CircularProgress';
//eslint-disable-next-line
import { setAuthLoading } from "context";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import axios from "axios";
import BASE_URL from "context/api";
import ReCAPTCHA from "react-google-recaptcha";
import '../../sign-up/basic/styles.css';
import GoogleLoginComponent from './GoogleLoginComponent';
import { decryptPassword } from "context";

function Basic() {
  const [controller, dispatch] = useVisionUIController();
  //eslint-disable-next-line
  const { isLoggedIn, userDetails, authLoading } = controller;
  const [loader, setLoader] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [email_id, setEmail] = useState('');
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState('');
  //eslint-disable-next-line
  const [passwordError, setPasswordError] = useState("");
  //eslint-disable-next-line
  const [error, setError] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resend, setResend] = useState(false);
  const [resendLoader, setResendLoader] = useState(false);

  const privateKeyPem = `-----BEGIN PRIVATE KEY-----
MIIEuwIBADANBgkqhkiG9w0BAQEFAASCBKUwggShAgEAAoIBAQCbtsSplQtFSkIh
TvJexUnSrJwIpsew+dRycZ2nuNTHN6gKbDKeAz52zEHyT2chJgWuTiz48Z8tg36p
we9uQFtMmg1n5EFEcx3k1sUdFUjKO/Ly3kKFUUaBIzYWnCelvRwY9QZX+aE533jP
zj18LrjasMCsyi26brvik/QHoUhucRwZg6dPLUeNMyYQutMrWcEK+gyGqoLR1rR0
Y5wbw8LkcOi1ex80YhvVlJHtEyeryJe3uBBBALDf0bVicfBMEMvMQY3xzSrboeWa
3fBnEeuM4zoBykL0jskswchmKdwn3fqtbhIVAVroXYUe/yDpAd2yeAZBnhZP+OgN
gslo/lhBAgMBAAECgf99+pzHS2TxE6qpSBfeHFZhdlQSUXNcQwAmD6eqsCpm5OvT
CmtSty8OdZxOfXbFS9CYFwHTG4rpOLXnGlqrBYR9z3ByjkghoIieFG/dvWmuNJYP
FWh/+27D/NcCCoGQs/bLSVONQw8epUTrzDt58Z5Rqq7saC3iPi0tjIYtpiG3c7U6
+L1AyXOblsOkgS27c3ZGVggJpSre+XcVlrhpRRyhuiIXZNGSwbHuVV+Bf9GRPkZ/
yjhJHUgsmKKCgX8Dw/c53ONmU0EjeukEPcTnLolBi9gcDvH01jXAN/1n9z+WD2Lu
+MhidakZIG7RAQsarrhwBHQP/Y4iyerewsI3GbECgYEA0o7y7dUvk/kjTfUO5zLl
SzfI7gOfS49gaNvrDaU6xiRV40Sg3q50cXgyYFb57dmNtrZvZxvs5NDQgIIoD9j/
ccOA/Ocr/OylAMiqRTFs5vxvWczC3quCSktUQMR9zSulbr4RwZoS7UaoSYcBu0XC
lFBWvdoj5rhNpWfga7QOIh0CgYEAvVG9xHfi8msBWhy4gLny4AZWCg49kOxY8UtD
No+O4mgcjD7Cu80yZ7ckFwdjRsDZbaWqXLWkJTQpuNpe9E8w25udBGSq1zPzdE/M
CS+rrEQne5H+494A4ARzmBc4hL33XNQ8BRRCyOqIObNxGeORXvlkmTpcDImAlpvH
woH/9XUCgYBdX8U9UUgbH+XXIXCteLWUaLWzT2np8Tg46CXR1E8/JUftVDbe6ZB7
7WixroCBSPP+beLzX7/rLOr5wj6P4JRPV/qwrLemHy+BLHvN2YBI4vNiaFqWsopB
9ivz2O9M+CkR9jobl/8CuE3S5YsQCHyklwgQg1debqcITguI171YxQKBgGd1EgUZ
ltXvD/AUnecKtuUh0UbxfUeDQR+rh3F7hhPH3UHu0jAa9J6lAH3tNZ1mqes3ix/a
y3bhSKOSAi30ULjSEz5hXNL5uuMm8FNcz632GLV4lRglK8k/5YOJvQMdZN4/xQTp
i42MTSFrJDWHnNuP4h+yOrdHNRQY0VNEA4I5AoGBAJV3w3PZs9Eahso0Xm+P0rNI
mXoA/gyDLMFyPjFQZAdJ4ED3SshFQ3SpT/y/UTNdDzV/NOeRMFqGUfXgDKZPO1xJ
EF/Xpjv27XaP25VZK0g2dDOjvA7EDPY1CLJEnmjVVytTcDHebZEf36t1/+1JuIHx
vygY44KGrebFwv7EpPU5
-----END PRIVATE KEY-----`;

// const privateKeyPem = process.env.REACT_APP_PRIVATE_KEY.replace(/\\n/g, '\n');

  useEffect(() => {
    // Fetch the stored email and password from the local storage
    const storedEmail = localStorage.getItem("rememberedEmail");
    const storedPassword = localStorage.getItem("rememberedPassword");
    // const decryptedPassword = decryptPassword(storedPassword, privateKeyPem);

    if (storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);

  const handleRecaptchaChange = (value) => {
    // setRecaptchaVerified(!!value);
    const recaptchaResponse = window.grecaptcha.getResponse();
    setRecaptchaVerified(!!recaptchaResponse);
  };

  const handleLogin = async () => {
    if (isRecaptchaVerified) {
      const recaptchaResponse = window.grecaptcha.getResponse();

      try {
        setLoader(true);
        await login(dispatch, { email_id, password, recaptchaResponse }, rememberMe);
        enqueueSnackbar('Signed in successfully!', { 
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      } catch (err) {
        setError(err.message);
        enqueueSnackbar(err.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      } finally {
        setLoader(false);
      }
    } else {
      console.error("reCAPTCHA verification failed!");
    }
  }  


  function handleEmailChange(event) {
    const value = event.target.value;
    setEmail(value);
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(value)) { // regex to test if input contains only letters
      setEmailError("Invalid! please enter a valid email.");
    } else {
      setEmailError("");
    }
  }
  function handlePasswordChange(event) {
    const value = event.target.value;
    setPassword(value);
    // const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}/;
    // if (!passwordRegex.test(value)) {
    //   setPasswordError("Please enter password as per instruction.");
    // } else {
    //   setPasswordError("");
    // }
  }

  const handleForgotPassword = () => {
    setForgotPassword(!forgotPassword);
  }

  const handleResendlink = () => {
    setResend(!resend);
  }

  const handleResend = () => {
    setResendLoader(true);
    try {
      axios.post(`${BASE_URL}/resend_link`, {
        email_id
      })
      .then((response) => {
        setResendLoader(false);
        enqueueSnackbar(response.data.message, { 
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        setResend(false);
      })
    } catch (error) {
      console.log(error.message);
      enqueueSnackbar(error.message, { 
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      setResendLoader(false);
    }
  }

  const handleChangePassword = async () => {
    setLoader(true);
    axios.post(`${BASE_URL}/forget_password`, {
      email_id
    })
    .then(function (response) {
      console.log(response);
      setLoader(false);
      enqueueSnackbar(response.data.message, { 
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    })
    .catch(function (error) {
      setLoader(false);
      enqueueSnackbar(error.response.data.error, {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }); 
  }

  const handleSuccess = async (response) => {
    await googleLogin(dispatch, response);
  };

  const handleFailure = (error) => {
    console.error(error);
  };

  return (
    <CoverLayout
      title={forgotPassword ? "Forgot password" : resend ? "Resend Link" : "Nice to see you!"}
      color="white"
      description={forgotPassword ? "Here you can easily retrieve a new password." : resend ? "Here you can resend email verification link" : "Enter your email and password to sign in"}
      image={bgImage}
      premotto={"INSPIRED BY THE FUTURE"}
      motto={"QUICKVIDEO.AI"}
      cardContent
    >
      <GradientBorder
        borderRadius={borders.borderRadius.form}
        minWidth="100%"
        maxWidth="100%"
      >

      <VuiBox component="form" role="form" borderRadius="inherit"
          p="30px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.focus,
            width: "300px"
          })}>
        {forgotPassword || resend ? (
          <VuiBox>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                Email
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              marginBottom="20px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              {/* <VuiInput
                id="uemail"
                type="email"
                placeholder="Your email..."
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              /> */}
              <VuiInput
                icon={{
                  component: (
                    <Icon>
                      <MailOutlineIcon />
                    </Icon>
                  ),
                  direction: "right",
                }}
                data-clarity-mask="true"
                placeholder="Enter your email"
                value={email_id}
                onChange = {handleEmailChange}
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm, color: 'whitesmoke !important'
                })}
                success={!emailError && email_id}
                error={emailError}
              />
            </GradientBorder>
            {emailError && <VuiTypography sx={{marginLeft: '10px', marginTop: '5px', marginBottom: '5px'}} variant="body2" color='error' className="error">{emailError}</VuiTypography>}
            <VuiButton 
              color="info" 
              fullWidth
              disabled={loader}
              onClick={forgotPassword ? handleChangePassword : handleResend}
            >
              {loader || resendLoader ? <CircularProgress size={24} color="white" /> : "SEND"}
            </VuiButton>
            <VuiBox textAlign="center">
              <VuiTypography variant="button" color="text" fontWeight="regular">
                or
              </VuiTypography>
            </VuiBox>
            <VuiBox textAlign="center">
              <VuiTypography variant="button" color="text" fontWeight="regular">
                <VuiTypography
                  // component={Link}
                  // to="/authentication/sign-in/basic"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  Sign in
                </VuiTypography>
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        ) : (
        <>
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                Email
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              {/* <VuiInput
                id="uemail"
                type="email"
                placeholder="Your email..."
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              /> */}
              <VuiInput
                icon={{
                  component: (
                    <Icon>
                      <MailOutlineIcon />
                    </Icon>
                  ),
                  direction: "right",
                }}
                data-clarity-mask="true"
                placeholder="Enter your email"
                value={email_id}
                onChange = {handleEmailChange}
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm, color: 'whitesmoke !important'
                })}
                success={!emailError && email_id}
                error={emailError}
              />
              </GradientBorder>
                  {emailError && <VuiTypography sx={{marginLeft: '10px', marginTop: '5px'}} variant="body2" color='error' className="error">{emailError}</VuiTypography>}
          </VuiBox>
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                Password
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                icon={{
                    component: (
                      <Icon onClick={handleTogglePasswordVisibility} sx={{cursor: "pointer"}}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </Icon>
                    ),
                    direction: "right",
                  }}
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  // type="password"
                  value={password}
                  onChange = {handlePasswordChange}
                  sx={({ typography: { size } }) => ({
                    fontSize: size.sm, color: 'whitesmoke !important'
                  })}
                  success={!passwordError && password}
                  error={passwordError}
                />
              </GradientBorder>
                {/* {passwordError && <VuiTypography sx={{marginLeft: '10px', marginTop: '5px'}} variant="body2" color='error' className="error">{passwordError}</VuiTypography>}
                {error && <VuiTypography sx={{marginLeft: '10px', marginTop: '5px'}} variant="body2" color='error' className="error">{error}</VuiTypography>} */}
          </VuiBox>
          <VuiBox display="flex" alignItems="center">
            <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
            <VuiTypography
              variant="caption"
              color="white"
              fontWeight="medium"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Remember me
            </VuiTypography>
          </VuiBox>
          <VuiBox mt={2}>
            <ReCAPTCHA
              sitekey="6LdM00opAAAAANnPGIFVh6_wW3NpPP4-Nnolm82S"
              onChange={handleRecaptchaChange}
              className="g-recaptcha-signin"
            />
          </VuiBox>
          <VuiBox mt={1} mb={1}>
            <VuiButton 
              color="info" 
              fullWidth
              disabled={loader || email_id === '' || password === '' || emailError !== '' || passwordError !== '' || !isRecaptchaVerified}
              onClick={handleLogin}
            >
              {loader ? <CircularProgress size={20} color="white" /> : "SIGN IN"}
            </VuiButton>
          </VuiBox>
          {/* <VuiBox mt={1} mb={1} mx='auto'>
            <GoogleLoginComponent onSuccess={handleSuccess} onFailure={handleFailure} />
          </VuiBox> */}
          <VuiBox textAlign="center" sx={{cursor: 'pointer'}} onClick={handleForgotPassword}>
            <VuiTypography
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Forgot password
            </VuiTypography>
          </VuiBox>
          <VuiBox textAlign="center">
            <VuiTypography variant="button" color="text" fontWeight="regular">
              Don&apos;t have an account?{" "}
              <VuiTypography
                component={Link}
                to="/signup"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                Sign up
              </VuiTypography>
            </VuiTypography>
          </VuiBox>
          <VuiBox textAlign="center" sx={{cursor: 'pointer'}} onClick={handleResendlink}>
            <VuiTypography
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Resend Verification Link ?
            </VuiTypography>
          </VuiBox>
        </>
        )}
      </VuiBox>
      </GradientBorder>
    </CoverLayout>
  );
}

export default Basic;
