import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useEffect, useState } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import PlaceholderCard from 'examples/Cards/PlaceholderCard';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import Steps from './steps/steps';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import folderIcon from './folder_5082687.png';
import Projects from './projects';
import axios from 'axios';
import { useVisionUIController } from "context";
import BASE_URL from 'context/api';
import Skeleton from '@mui/material/Skeleton';
import { useSnackbar } from "notistack";

const Personalization = () => {
  const [newProject, setNewProject] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [manualSubtitles, setManualSubtitles] = useState("Happy New Year 2024, let's welcome this year with Grace and love wishing you all a prosperous and joyous year ahead. Let's stay connected share more and this year a memorable journey together 2024.");
  const [timestamp, setTimeStamp] = useState([]);
  const [loading, setLoading] = useState('');
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [selectedWords, setSelectedWords] = useState([]);
  const [selectedVariablesName, setSelectedVariablesName] = useState([]);
  const [buttonCount, setButtonCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [projectLoading, setProjectLoading] = useState(false);

  const handleOpen = (url) => {
    setOpenModal(true);
    setSelectedUrl(url);
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedUrl(null);
  };

  const [project, setProject] = useState({
    name: '',
    use:'',
    description :'',
    // handler: '',
    variableType: null,
    personalizationVariableType: null,
    variableText: '',
  })
  const [sampleVideoPreview, setSampleVideoPreview] = useState(null);
  const [selectVideoPreview, setSelectVideoPreview] = useState(false);
  const [uploadCSV, setUploadCSV] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [audioResponse, setAudioResponse] = useState(null);
  const [allProjects, setAllProjects] = useState();
  const [detailView, setDetailView] = useState(false);
  const [projectDetails, setProjectDetails] = useState();
  const [controller] = useVisionUIController();
  const { userDetails } = controller;
  const { user_id, plan } = userDetails; 
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setProjectLoading(true);
    try {
      axios.get(`${BASE_URL}/project-info`, {
        params: {
          user_id
        },
      })
      .then(response =>
        {
          setAllProjects(response.data);
          setProjectLoading(false);
        }
      )
      .catch(err => {
        console.log(err);
        setProjectLoading(false);
      })
    } catch (error) {
      console.error('Error fetching data:', error);
      setProjectLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  const videoDetailsApi = async (selectedProject) => {
    try {
      await axios.get(`${BASE_URL}/video-details`, {
        params: {
          user_id,
          allProjects: selectedProject
        },
      })
      .then(response =>
        {
          setProjectDetails(response.data);
        }
      )
      .catch(err => {
        console.log(err);
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleClickOpen = () => {
    setNewProject(true);
  }

  const handleToggleProjectList = () => {
    setNewProject(false);
  }

  const handleProjectClick = async (data) => {
    await videoDetailsApi(data);
    setDetailView(data);
  }

  useEffect(() => {
    // plan === 'Trial' && setOpenPricingModal(dispatch, true);
    plan === 'Trial' && window.open('https://app.quickvideo.ai/gopremium', '_self');
    plan === 'Trial' && enqueueSnackbar('Upgrade your plan to access this feature.', { 
      variant: 'warning',
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {plan === 'Trial' ? <VuiTypography textAlign='center' variant='h4'>Kindly Upgrade your plan to access this feature</VuiTypography> : (!newProject && !detailView) && <VuiBox mt="0" mb="24px">
        <Grid container>
          <Grid item xs={12}>
            <VuiBox mb={1}>
              <VuiTypography variant="lg" color="white" fontWeight="bold">
                Video personalization
              </VuiTypography>
            </VuiBox>
            <VuiBox mb="40px">
              <VuiTypography
                fontSize={16}
                color="text"
                fontWeight="regular"
              >
                You can personalize your video in just few simple steps.
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3} sm={6} md={4} lg={3}>
            <PlaceholderCard
              title={{ variant: "h6", text: "Add Project" }}
              onClick={handleClickOpen}
            />
          </Grid>
          {allProjects && allProjects.map((v_data, index) => {
            return (
              projectLoading ? 
                <Skeleton
                  sx={{marginLeft: '20px', marginTop: '25px'}}
                  variant="square"
                  width={268}
                  height={160}
                /> : <Grid item xs={12} sm={6} md={4} lg={3} key={index} onClick={()=>handleProjectClick(v_data)}>
                <Card
                  sx={{cursor: "pointer", width: '268px', height: '180px'}}
                >
                  <img src={folderIcon} alt="folder" width='100%' />
                </Card>
                <Card
                  style={{
                    zIndex: "1",
                    marginTop: "-30px",
                    borderRadius: "0 0 20px 20px ",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: '268px',
                    cursor: 'pointer',
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: "1",
                    }}
                  >
                    <VuiTypography variant="body2" color="light">
                      {v_data.length > 15
                        ? v_data.slice(0, 15) + "..."
                        : v_data}
                    </VuiTypography>
                  </div>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </VuiBox>}

      {detailView && (
        <VuiBox>
          <ArrowBackIcon color="white" onClick={() => {setDetailView(false); setProjectDetails(null)}} sx={{cursor: 'pointer', marginBottom: '10px'}} />
          <Projects
            details={detailView}
            openModal={openModal}
            setOpenModal={setOpenModal}
            handleOpen={handleOpen}
            handleClose={handleClose}
            selectedUrl={selectedUrl}
            projectDetails={projectDetails}
          />
        </VuiBox>
      )}
      
      {newProject && (
        <>
          <ArrowBackIcon color="white" onClick={handleToggleProjectList} sx={{cursor: 'pointer', marginBottom: '10px'}} />
          <Steps
            setNewProject={setNewProject}
            uploadFile={uploadFile}
            setUploadFile={setUploadFile}
            manualSubtitles={manualSubtitles}
            setManualSubtitles={setManualSubtitles}
            timestamp={timestamp}
            setTimeStamp={setTimeStamp}
            loading={loading}
            setLoading={setLoading}
            selectedIndices={selectedIndices}
            setSelectedIndices={setSelectedIndices}
            selectedWords={selectedWords}
            setSelectedWords={setSelectedWords}
            selectedVariablesName={selectedVariablesName}
            setSelectedVariablesName={setSelectedVariablesName}
            project={project}
            setProject={setProject}
            buttonCount={buttonCount}
            setButtonCount={setButtonCount}
            uploadCSV={uploadCSV}
            setUploadCSV={setUploadCSV}
            parsedData={parsedData}
            setParsedData={setParsedData}
            tableRows={tableRows}
            setTableRows={setTableRows}
            values={values}
            setValues={setValues}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            audioResponse={audioResponse}
            setAudioResponse={setAudioResponse}
            sampleVideoPreview={sampleVideoPreview}
            setSampleVideoPreview={setSampleVideoPreview}
            selectVideoPreview={selectVideoPreview}
            setSelectVideoPreview={setSelectVideoPreview}
          />
        </>
      )}
    </DashboardLayout>
  )
}

export default Personalization