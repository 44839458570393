/*eslint-disable no-unused-vars */

import { useMemo, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MasterCard from "examples/Cards/MasterCard";
import MiniInfoCard from "examples/Cards/InfoCards/MiniInfoCard";
import Calendar from "examples/Calendar";
import InvoicesList from "examples/Lists/InvoicesList";

// Widgets page components
import Steps from "layouts/pages/widgets/components/Steps";
import FullBody from "layouts/pages/widgets/components/FullBody";
import MediaPlayer from "layouts/pages/widgets/components/MediaPlayer";
import OrdersOverview from "layouts/pages/widgets/components/OrdersOverview";
import UpcomingEvents from "layouts/pages/widgets/components/UpcomingEvents";
import Chart from "layouts/pages/widgets/components/Chart";

// Data
import {
  progressLineChartData,
  progressLineChartOptions,
} from "layouts/pages/widgets/data/progressLineChartData";
import calendarEventsData from "layouts/pages/widgets/data/calendarEventsData";
import invoicesListData from "layouts/pages/widgets/data/invoicesListData";
import {
  incomeLineChartData,
  incomeLineChartOptions,
} from "layouts/pages/widgets/data/incomeChartData";
import {
  lineChartDataCalories,
  lineChartOptionsCalories,
} from "layouts/pages/widgets/data/caloriesChartData";

// Icons
import { IoIosMusicalNotes } from "react-icons/io";
import { BsBatteryCharging } from "react-icons/bs";
import { AiFillCheckCircle } from "react-icons/ai";
import { FaPaypal, FaLightbulb } from "react-icons/fa";
import { IoWallet } from "react-icons/io5";
import { RiShareForwardFill } from "react-icons/ri";
import PluginCard from "./components/PluginCard";

import ShopifyIcon from "assets/images/logos/shopify.png";
import WixIcon from "assets/images/logos/Wix_com_logo_black.png";
import SynthesiaIcon from "assets/images/logos/synthesia.png";
import BigCommerceIcon from "assets/images/logos/bigcommerce.png";
import MondayIcon from "assets/images/logos/monday.png";
import IntercomIcon from "assets/images/logos/intercom.png";
import HubspotIcon from "assets/images/logos/hubspot.png";
import FreshsalesIcon from "assets/images/logos/freshsales.png";


function Plugins() {
  const pluginsData = [
    {
      plugin_icon : ShopifyIcon,
      p_title : "Shopify",
      description : "Add your QuickVideo to your Shopify store to address and solve the unique needs and challenges of your business. Embed your QuickVideo on any landing page or add as a widget.",
      connected : false,
      href : "https://www.shopify.com/"
    },
    {
      plugin_icon : WixIcon,
      p_title : "Wix",
      description : "Easily add your interactive QuickVideo to your site to draw viewers in and provide face to face interactions, even when you’re offline. Embed your QuickVideo on any landing page or add as a widget.",
      connected : true,
      href : "https://www.wix.com/"
    },
    {
      plugin_icon : SynthesiaIcon,
      p_title : "Synthesia",
      description : "Import your Synthesia videos into QuickVideo, with 1 click! Synthesia lets you create professional AI videos from text in 60+ languages. No need for cameras or actors. Just type and click.",
      connected : false,
      href : "https://www.synthesia.io/"
    },
    {
      plugin_icon : MondayIcon,
      p_title : "Monday.com",
      description : "Send QuickVideo responses straight to your monday.com board to save lead details, stay organized, and allow your team to come together and act quickly.",
      connected : false,
      href : "https://monday.com/"
    },
    {
      plugin_icon : IntercomIcon,
      p_title : "Intercom",
      description : "Instantly send videos inside Intercom messenger to resolve tickets faster and provide frictionless customer service. Get full visibility of viewer interactions directly inside Intercom.",
      connected : false,
      href : "https://www.intercom.com/"
    },
    {
      plugin_icon : HubspotIcon,
      p_title : "Synthesia",
      description : "Create new leads and attach text, video, and contact form responses in HubSpot directly from a QuickVideo.",
      connected : false,
      href : "https://www.synthesia.io/"
    },
    {
      plugin_icon : BigCommerceIcon,
      p_title : "BigCommerce",
      description : "Easily embed QuickVideo on your site or add as a pop up to draw viewers in and provide face to face interactions, anytime and anywhere.",
      connected : false,
      href : "https://www.bigcommerce.com/"
    },
    {
      plugin_icon : FreshsalesIcon,
      p_title : "Freshsales by zapier",
      description : "Create new contacts and leads directly from a QuickVideo, to level up your eCommerce marketing.",
      connected : false,
      href : "https://www.freshworks.com/"
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
       <Grid container spacing={3}>
       {
        pluginsData.map((plugin) => {
        return <Grid item xs={12} lg={4}>
          <PluginCard plugin_icon={plugin.plugin_icon} p_title={plugin.p_title} description={plugin.description} connected={plugin.connected} href={plugin.href} extra_badge={plugin.badge ? plugin.badge : false}/>
        </Grid>
       })}
      </Grid>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Plugins;
