let videoRef;
let peerConnection;
let streamId;
let sessionId;
let sessionClientAnswer;
let statsIntervalId;
let videoIsPlaying;
let lastBytesReceived;
let idleBotVideo;
let gender;
let createChatData;
let agent_id;

const RTCPeerConnection = (
  window.RTCPeerConnection
  ).bind(window);
  
const DID_API = {url: 'https://api.d-id.com', key: 'a3Jpc2hAa3dpcXJlcGx5Lmlv:qPAcbgap17hkT8L5BvbMf'}

export const initializeVideo = (videoRefs, video, voice) => {
  videoRef = videoRefs;
  idleBotVideo = video;
  gender= voice
  return (idleBotVideo, gender);
}

// const peerStatusLabel = document.getElementById('peer-status-label');
// const iceStatusLabel = document.getElementById('ice-status-label');
// const iceGatheringStatusLabel = document.getElementById('ice-gathering-status-label');
// const signalingStatusLabel = document.getElementById('signaling-status-label');
// const streamingStatusLabel = document.getElementById('streaming-status-label');

export const connect = async (url, user_id, channel, agentUse, agentId) => {
  agent_id = agentId;
  if (peerConnection && peerConnection.connectionState === 'connected') {
    return;
  }

  stopAllStreams();
  closePC();

  const sessionResponse = await fetchWithRetries(`https://quick.quickvideo.ai/v1/${channel}/streams`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${user_id}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      presenter_id: url,
    }),
  });

  const { id: newStreamId, offer, ice_servers: iceServers, session_id: newSessionId } = await sessionResponse.json();
  sessionStorage.setItem('streamId', newStreamId);
  sessionStorage.setItem('sessionId', newSessionId);
  streamId = newStreamId;
  sessionId = newSessionId;

  try {
    sessionClientAnswer = await createPeerConnection(offer, iceServers, user_id, channel);
  } catch (e) {
    // console.log('error during streaming setup', e);
    if (e.response && e.response.status === 429) {
      alert('You are making too many requests. Please try again later.');
    } else {
      console.log('Error during streaming setup', e);
    }
    stopAllStreams();
    closePC();
    return;
  }

  const sdpResponse = await fetch(`https://quick.quickvideo.ai/v1/${channel}/streams/${streamId}/sdp`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${user_id}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      answer: sessionClientAnswer,
      session_id: sessionId,
    }),
  });

  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${user_id}`,
    },
    body: JSON.stringify({
      agentId: agent_id,
    }),
  };
  
  fetch('https://quick.quickvideo.ai/v1/create_chat', options)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      return response.json();
    })
    .then(data => {
      createChatData = data;
    })
    .catch(err => console.error('Fetch error:', err));
};

export const talk = async (text, user_id, channel, agentUse) => {
  try {
    if(!agentUse) {
      if (peerConnection?.signalingState === 'stable' || peerConnection?.iceConnectionState === 'connected') {
          const talkResponse = await fetchWithRetries(`https://quick.quickvideo.ai/v1/${channel}/streams/${streamId}`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${user_id}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              script: {
                type: 'text',
                "provider": {
                  "type": "microsoft",
                  "voice_id": gender
                },
                "ssml": "false",
                "input": text
              },
              color: "#FFFFFF",
              driver_url: 'bank://lively/',
              config: {
                stitch: true,
              },
              session_id: sessionId,
            }),
          });
        }
    } else {
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          Authorization: `Bearer ${user_id}`,
        },
        body: JSON.stringify({
          messages: [
            {
              role: 'user',
              newKey: 'New Value',
              content: text,
              created_at: createChatData.created,
              'newKey-1': 'New Value',
            }
          ],
          streamId: sessionStorage.getItem('streamId'),
          sessionId: sessionStorage.getItem('sessionId'),
          newKey: 'New Value',
          chatId: createChatData.id,
          agentId: agent_id,
        })
      };
      
      fetch('https://quick.quickvideo.ai/v1/assistant_chat', options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));
    }
  } catch (e) {
    // console.log(e);
    if (e.response && e.response.status === 429) {
      alert('You are making too many requests. Please try again later.');
    } else {
      console.log('Error during streaming setup', e);
    }
  }
};

// const destroyButton = document.getElementById('destroy-button');
export const destroy = async (user_id) => {
  // await fetch(`${DID_API.url}/talks/streams/${streamId}`, {
  await fetch(`https://quick.quickvideo.ai/v1/clips/streams/${streamId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${user_id}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ session_id: sessionId }),
  });

  stopAllStreams();
  closePC();
};

// function onIceGatheringStateChange() {
//   iceGatheringStatusLabel.innerText = peerConnection.iceGatheringState;
//   iceGatheringStatusLabel.className = 'iceGatheringState-' + peerConnection.iceGatheringState;
// }
function onIceCandidate(event, user_id, channel) {
  if (event.candidate) {
    const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

    // fetch(`${DID_API.url}/talks/streams/${streamId}/ice`, {
    fetch(`https://quick.quickvideo.ai/v1/${channel}/streams/${streamId}/ice`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user_id}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        candidate,
        sdpMid,
        sdpMLineIndex,
        session_id: sessionId,
      }),
    });
  }
}
// function onIceConnectionStateChange() {
//   iceStatusLabel.innerText = peerConnection.iceConnectionState;
//   iceStatusLabel.className = 'iceConnectionState-' + peerConnection.iceConnectionState;
//   if (peerConnection.iceConnectionState === 'failed' || peerConnection.iceConnectionState === 'closed') {
//     stopAllStreams();
//     closePC();
//   }
// }
// function onConnectionStateChange() {
//   // not supported in firefox
//   peerStatusLabel.innerText = peerConnection.connectionState;
//   peerStatusLabel.className = 'peerConnectionState-' + peerConnection.connectionState;
// }
// function onSignalingStateChange() {
//   signalingStatusLabel.innerText = peerConnection.signalingState;
//   signalingStatusLabel.className = 'signalingState-' + peerConnection.signalingState;
// }

function onVideoStatusChange(videoIsPlaying, stream) {
  // let status;
  if (videoIsPlaying) {
    // status = 'streaming';
    const remoteStream = stream;
    setVideoElement(remoteStream);
  } else {
    // status = 'empty';
    playIdleVideo();
  }
  // streamingStatusLabel.innerText = status;
  // streamingStatusLabel.className = 'streamingState-' + status;
}

function onTrack(event) {
  if (!event.track) {
    console.warn('No track in the event');
    return;
  }

  if (!peerConnection) {
    console.error('peerConnection is null or undefined');
    return;
  }

  statsIntervalId = setInterval(async () => {
    try {
      const stats = await peerConnection.getStats(event.track);
      stats.forEach((report) => {
        if ((report.type === 'inbound-rtp' && report.mediaType === 'video') ||
            (report.type === 'inbound-rtp' && report.mediaType === 'audio')) {
          
          const bytesReceived = report.bytesReceived || 0;
          const isVideo = report.mediaType === 'video';
          
          if (isVideo) {
            const videoStatusChanged = videoIsPlaying !== (bytesReceived > lastBytesReceived);

            if (videoStatusChanged) {
              videoIsPlaying = bytesReceived > lastBytesReceived;
              onVideoStatusChange(videoIsPlaying, event.streams[0]);
            }
            lastBytesReceived = bytesReceived;
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, 500);
}

async function createPeerConnection(offer, iceServers, user_id, channel) {
  if (!peerConnection) {
    peerConnection = new RTCPeerConnection({ iceServers });
    // peerConnection.addEventListener('icegatheringstatechange', onIceGatheringStateChange, true);
    // peerConnection.addEventListener('icecandidate', onIceCandidate, true);
    peerConnection.addEventListener('icecandidate', (event) => {
      onIceCandidate(event, user_id, channel);
    });
    // peerConnection.addEventListener('iceconnectionstatechange', onIceConnectionStateChange, true);
    // peerConnection.addEventListener('connectionstatechange', onConnectionStateChange, true);
    // peerConnection.addEventListener('signalingstatechange', onSignalingStateChange, true);
    peerConnection.addEventListener('track', onTrack, true);
  }

  await peerConnection.setRemoteDescription(offer);
  // console.log('set remote sdp OK');

  const sessionClientAnswer = await peerConnection.createAnswer();
  // console.log('create local sdp OK');

  await peerConnection.setLocalDescription(sessionClientAnswer);
  // console.log('set local sdp OK');

  return sessionClientAnswer;
}

function setVideoElement(stream) {
  if (!stream) return;
  // talkVideo.srcObject = stream;
  // talkVideo.loop = false;

  // // safari hotfix
  // if (talkVideo.paused) {
  //   talkVideo
  //     .play()
  //     .then((_) => {})
  //     .catch((e) => {});
  // }
  if (videoRef && videoRef.current) {
    videoRef.current.srcObject = stream;
    videoRef.current.loop = false;

    // Safari hotfix
    if (videoRef.current.paused) {
      videoRef.current
        .play()
        .then((_) => {})
        .catch((e) => {});
    }
  } else {
    console.error('talkVideoRef is null or current is undefined');
  }
}

function playIdleVideo() {
  // talkVideo.srcObject = undefined;
  // talkVideo.src = './or_idle.mp4';
  // talkVideo.loop = true;
  if (videoRef && videoRef.current) {
    videoRef.current.srcObject = null;
    videoRef.current.src = idleBotVideo;
    videoRef.current.loop = true;
  } else {
    console.error('talkVideoRef is null or current is undefined');
  }
}

function stopAllStreams() {
  // if (talkVideo.srcObject) {
  //   console.log('stopping video streams');
  //   talkVideo.srcObject.getTracks().forEach((track) => track.stop());
  //   talkVideo.srcObject = null;
  // }
  if (videoRef  && videoRef.current && videoRef.current.srcObject) {
    videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    videoRef.current.srcObject = null;
  }
}

function closePC(pc = peerConnection) {
  if (!pc) return;
  pc.close();
  // pc.removeEventListener('icegatheringstatechange', onIceGatheringStateChange, true);
  pc.removeEventListener('icecandidate', onIceCandidate, true);
  // pc.removeEventListener('iceconnectionstatechange', onIceConnectionStateChange, true);
  // pc.removeEventListener('connectionstatechange', onConnectionStateChange, true);
  // pc.removeEventListener('signalingstatechange', onSignalingStateChange, true);
  pc.removeEventListener('track', onTrack, true);
  clearInterval(statsIntervalId);
  // iceGatheringStatusLabel.innerText = '';
  // signalingStatusLabel.innerText = '';
  // iceStatusLabel.innerText = '';
  // peerStatusLabel.innerText = '';
  if (pc === peerConnection) {
    peerConnection = null;
  }
}

const maxRetryCount = 3;
const maxDelaySec = 4;

async function fetchWithRetries(url, options, retries = 1) {
  try {
    return await fetch(url, options);
  } catch (err) {
    if (retries <= maxRetryCount) {
      const delay = Math.min(Math.pow(2, retries) / 4 + Math.random(), maxDelaySec) * 1000;

      await new Promise((resolve) => setTimeout(resolve, delay));

      console.log(`Request failed, retrying ${retries}/${maxRetryCount}. Error ${err}`);
      return fetchWithRetries(url, options, retries + 1);
    } else {
      throw new Error(`Max retries exceeded. error: ${err}`);
    }
  }
}