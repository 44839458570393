/*eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-unused-vars */

import { useEffect, useRef, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background-cover-auth-signin-min.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  useVisionUIController,
  signup,
} from "context";
import { useSnackbar } from "notistack";
import CircularProgress from '@mui/material/CircularProgress';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import QuickVideoIcon from '../../QuickVideoIcon.png';
import { Button } from "@mui/material";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import BASE_URL from 'context/api';
import BusinessIcon from '@mui/icons-material/Business';
import ReCAPTCHA from "react-google-recaptcha";
import './styles.css';
import { googleLogin } from "context";
import GoogleLoginComponent from "layouts/authentication/sign-in/basic/GoogleLoginComponent";

function SignIn() {
  const [loading, setLoading] = useState(false);
  const [first_name, setName] = useState("");
  const [business_name, setBusinessName] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [nameError, setNameError] = useState("");
  const [email_id, setEmail] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [verificationMessage, setVerificationMessage] = useState(false);
  const [timerToggle, setTimerToggle] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [controller, dispatch] = useVisionUIController();
  const { authLoading, authError } = controller;
  const { enqueueSnackbar } = useSnackbar();
  const initialTime = 120;
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [resendLoader, setResendLoader] = useState(false);
  const history = useHistory();
  let timeoutRef = useRef(null);

  useEffect(() => {
    let interval;
    if (verificationMessage) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [verificationMessage]);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimerToggle(true);
    }
  }, [timeLeft]);

  const handleResendlink = () => {
    setResendLoader(true);
    try {
      axios.post(`${BASE_URL}/resend_link`, {
        email_id
      })
      .then((response) => {
        setResendLoader(false);
        enqueueSnackbar(response.data.message, { 
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        setVerificationMessage(false);
        setTimerToggle(false);
        history.push('/signin');
      })
    } catch (error) {
      console.log(error.message);
      enqueueSnackbar(error.message, { 
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      setResendLoader(false);
    }
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return (
      <span>
        <span style={{ background: 'black', color: 'white', padding: '15px', borderRadius: '15px', marginRight: '5px' }}>
          {minutes.toString().padStart(2, '0')}
        </span>
        :
        <span style={{ background: 'black', color: 'white', padding: '15px', borderRadius: '15px', marginLeft: '5px' }}>
          {remainingSeconds.toString().padStart(2, '0')}
        </span>
      </span>
    );
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);

  const handleRecaptchaChange = (value) => {
    // setRecaptchaVerified(!!value);
    const recaptchaResponse = window.grecaptcha.getResponse();
    setRecaptchaVerified(!!recaptchaResponse);
  };

  const handleSignup = async () => {
    if (isRecaptchaVerified) {
      const recaptchaResponse = window.grecaptcha.getResponse();

      try {
        setLoading(true);
        await signup(dispatch, { first_name, email_id, password, mobile: mobilenumber, business_name, recaptchaResponse });
        setLoading(false);
        enqueueSnackbar('Please check email for verification.', { 
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        setVerificationMessage(true);
      } catch (err) {
        setError(true);
        enqueueSnackbar(err.message, {
          variant: "warning",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      } finally {
        setLoading(false);
      }
      setName("");
      // verificationMessage === false && setEmail("");
      setPassword("");
      setMobilenumber("");
    } else {
      console.error("reCAPTCHA verification failed!");
    }
  };


  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function handleNameChange(event) {
    const value = event.target.value;
    setName(value);
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!nameRegex.test(value)) {
      setNameError("Invalid! please enter a valid name.");
    } else {
      setNameError("");
    }
  }
  
  function handleBusinessNameChange(event) {
    const value = event.target.value;
    setBusinessName(value);
    // const nameRegex = /^[a-zA-Z\s]*$/;
    // if (!nameRegex.test(value)) {
    //   setBusinessNameError("Invalid! please enter a valid business name.");
    // } else {
    //   setBusinessNameError("");
    // }
  }

  function handleEmailChange(event) {
    const value = event.target.value.toLowerCase();
    setEmail(value);
    const emailRegex = /^\S+@\S+\.\S+$/;
    const blockedDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'rediff.com', 'rediffmail.com'];

    if (!emailRegex.test(value)) {
      setEmailError("Invalid! Please enter a valid email.");
    } else {
      setEmailError("");
    }
    // else {
    //   const [, domain] = value.split('@');
    //   if (blockedDomains.includes(domain)) {
    //     setEmailError("Invalid! Please use a different email domain.");
    //   } else {
    //     setEmailError("");
    //   }
    // }
  }
  
  function handlePasswordChange(event) {
    const value = event.target.value;
    setPassword(value);
    const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}/;
    if (!passwordRegex.test(value)) {
      setPasswordError("Please enter Valid password.");
    } else {
      setPasswordError("");
    }
  }

  const handlePhoneChange = (value, country) => {
    setMobilenumber(value);
  };

  const phoneInputStyle = {
    width: '262px',
    height: '38px',
    fontSize: '14px',
    borderRadius: '15px',
    backgroundColor: '#0f1535',
    border: 'none',
    color: 'whitesmoke'
  };

  const handleSuccess = async (response) => {
    await googleLogin(dispatch, response);
  };

  const handleFailure = (error) => {
    console.error(error);
  };


  return (
    verificationMessage === true ? 
      <div style={{display: 'flex', alignItems: 'center', justifyContent: window.innerWidth < 768 ? 'flex-start' : 'space-between', flexDirection: 'column', height: window.innerWidth < 768 ? '100vh' : '', marginTop: '10%'}}>
        <img src={QuickVideoIcon} height={window.innerWidth < 768 ? "30px" : "60px"} alt="Icon" />
        <VuiTypography variant={window.innerWidth < 768 ? 'h5' : 'h3'} color='white' style={{marginTop: '50px', display: 'flex', alignItems: 'center'}}>Please check email for verification <MarkEmailReadIcon sx={{marginLeft: '10px'}} /></VuiTypography>
        {!timerToggle && <div style={{marginTop: '20px', color: 'whitesmoke'}}>
          <h1>{formatTime(timeLeft)}</h1>
        </div>}
        {timerToggle && <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <h4 style={{color: 'whitesmoke', marginRight: '10px', fontWeight: 400}}>If didn't receive mail ?</h4>
            <Button variant="contained" sx={{background: 'transparent', color: 'whitesmoke', border: '1px solid whitesmoke', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 25px'}} onClick={handleResendlink}>
              {resendLoader ? <CircularProgress size={16} color="white" /> : "Resend Link"}
            </Button>
          </div>
        </div>}
        <Button component={Link} to="/authentication/sign-in/basic" variant="contained" sx={{marginTop: '20px', background: 'transparent', color: 'whitesmoke', border: '1px solid whitesmoke', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 25px'}}>Redirect to Sign in page</Button>
      </div> 
    : 
      <CoverLayout
        title="Welcome!"
        color="white"
        // description="Enter Details to sign up"
        image={bgImage}
        premotto={"INSPIRED BY THE FUTURE"}
        motto={"QUICKVIDEO.AI"}
        cardContent
      >
        <GradientBorder
          borderRadius={borders.borderRadius.form}
          minWidth="100%"
          maxWidth="100%"
        >
          <VuiBox
            component="form"
            role="form"
            borderRadius="inherit"
            p="18px"
            sx={({ palette: { secondary } }) => ({
              backgroundColor: secondary.focus,
              width: "300px"
            })}
          >
            {/* <VuiTypography
              color="white"
              fontWeight="bold"
              textAlign="center"
              mb="5px"
              sx={({ typography: { size } }) => ({
                fontSize: size.lg,
              })}
            >
              Register with
            </VuiTypography>
            <Stack
              mb="5px"
              justifyContent="center"
              alignItems="center"
              direction="row"
              spacing={1}
            >
              <GradientBorder borderRadius="xl">
                <a href="#">
                  <IconButton
                    transition="all .25s ease"
                    justify="center"
                    align="center"
                    bg="rgb(19,21,54)"
                    borderRadius="15px"
                    sx={({
                      palette: { secondary },
                      borders: { borderRadius },
                    }) => ({
                      borderRadius: borderRadius.xl,
                      padding: "15px",
                      backgroundColor: secondary.focus,
                      "&:hover": {
                        backgroundColor: rgba(secondary.focus, 0.9),
                      },
                    })}
                  >
                    <Icon
                      as={FaFacebook}
                      w="30px"
                      h="30px"
                      sx={({ palette: { white } }) => ({
                        color: white.focus,
                      })}
                    />
                  </IconButton>
                </a>
              </GradientBorder>
              <GradientBorder borderRadius="xl">
                <a href="#">
                  <IconButton
                    transition="all .25s ease"
                    justify="center"
                    align="center"
                    bg="rgb(19,21,54)"
                    borderRadius="15px"
                    sx={({
                      palette: { secondary },
                      borders: { borderRadius },
                    }) => ({
                      borderRadius: borderRadius.xl,
                      padding: "15px",
                      backgroundColor: secondary.focus,
                      "&:hover": {
                        backgroundColor: rgba(secondary.focus, 0.9),
                      },
                    })}
                  >
                    <Icon
                      as={FaApple}
                      w="30px"
                      h="30px"
                      sx={({ palette: { white } }) => ({
                        color: white.focus,
                      })}
                    />
                  </IconButton>
                </a>
              </GradientBorder>
              <GradientBorder borderRadius="xl">
                <a href="#">
                  <IconButton
                    transition="all .25s ease"
                    justify="center"
                    align="center"
                    bg="rgb(19,21,54)"
                    borderRadius="15px"
                    sx={({
                      palette: { secondary },
                      borders: { borderRadius },
                    }) => ({
                      borderRadius: borderRadius.xl,
                      padding: "15px",
                      backgroundColor: secondary.focus,
                      "&:hover": {
                        backgroundColor: rgba(secondary.focus, 0.9),
                      },
                    })}
                  >
                    <Icon
                      as={FaGoogle}
                      w="30px"
                      h="30px"
                      sx={({ palette: { white } }) => ({
                        color: white.focus,
                      })}
                    />
                  </IconButton>
                </a>
              </GradientBorder>
            </Stack>
            <VuiTypography
              color="text"
              fontWeight="bold"
              textAlign="center"
              sx={({ typography: { size } }) => ({ fontSize: size.lg })}
            >
              or
            </VuiTypography> */}
            <VuiBox mb={1} mt={1}>
              <VuiBox mb={0.5} ml={0.5}>
                <VuiTypography
                  component="label"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  Name
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                borderRadius={borders.borderRadius.lg}
                padding="1px"
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  icon={{
                    component: (
                      <Icon>
                        <PermIdentityIcon />
                      </Icon>
                    ),
                    direction: "right",
                  }}
                  data-clarity-mask="true"
                  type="text"
                  placeholder="Enter your name"
                  value={first_name}
                  onChange={handleNameChange}
                  sx={({ typography: { size } }) => ({
                    fontSize: size.sm, color: 'whitesmoke !important'
                  })}
                  success={!nameError && first_name}
                  error={nameError}
                />
              </GradientBorder>
              {nameError && (
                <VuiTypography variant="body2" color="error" className="error">
                  {nameError}
                </VuiTypography>
              )}
            </VuiBox>

            <VuiBox mb={1} mt={1}>
              <VuiBox mb={0.5} ml={0.5}>
                <VuiTypography
                  component="label"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  Business Name
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                borderRadius={borders.borderRadius.lg}
                padding="1px"
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  icon={{
                    component: (
                      <Icon>
                        <BusinessIcon />
                      </Icon>
                    ),
                    direction: "right",
                  }}
                  data-clarity-mask="true"
                  type="text"
                  placeholder="Enter your business name"
                  value={business_name}
                  onChange={handleBusinessNameChange}
                  sx={({ typography: { size } }) => ({
                    fontSize: size.sm, color: 'whitesmoke !important'
                  })}
                  success={!businessNameError && business_name}
                  error={businessNameError}
                />
              </GradientBorder>
              {/* {businessNameError && (
                <VuiTypography variant="body2" color="error" className="error">
                  {businessNameError}
                </VuiTypography>
              )} */}
            </VuiBox>

            <VuiBox mb={1}>
              <VuiBox mb={0.5} ml={0.5}>
                <VuiTypography
                  component="label"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  Email
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                padding="1px"
                borderRadius={borders.borderRadius.lg}
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  icon={{
                    component: (
                      <Icon>
                        <MailOutlineIcon />
                      </Icon>
                    ),
                    direction: "right",
                  }}
                  data-clarity-mask="true"
                  type="email"
                  placeholder="Enter your work email"
                  value={email_id}
                  onChange={handleEmailChange}
                  sx={({ typography: { size } }) => ({
                    fontSize: size.sm, color: 'whitesmoke !important'
                  })}
                  success={!emailError && email_id}
                  error={emailError}
                />
              </GradientBorder>
              {emailError && (
                <VuiTypography variant="body2" color="error" className="error">
                  {emailError}
                </VuiTypography>
              )}
            </VuiBox>

            <VuiBox mb={1}>
              <VuiBox mb={0.5} ml={0.5}>
                <VuiTypography
                  component="label"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  Password
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                borderRadius={borders.borderRadius.lg}
                padding="1px"
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  icon={{
                    component: (
                      <Icon onClick={handleTogglePasswordVisibility} sx={{cursor: "pointer"}}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </Icon>
                    ),
                    direction: "right",
                  }}
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  value={password}
                  onChange={handlePasswordChange}
                  sx={({ typography: { size } }) => ({
                    fontSize: size.sm, color: 'whitesmoke !important'
                  })}
                  success={!passwordError && password}
                  error={passwordError}
                />
              </GradientBorder>
              {passwordError && (
                <VuiTypography variant="body2" color="error" className="error">
                  {passwordError}
                </VuiTypography>
              )}
              {passwordError && <VuiTypography sx={{fontSize: '12px', fontWeight: 400}}>The password must contain at least one digit, one lowercase letter, one uppercase letter, and be at least 8 characters long.</VuiTypography>}
            </VuiBox>

            <VuiBox mb={1}>
              <VuiBox mb={0.5} ml={0.5}>
                <VuiTypography
                  component="label"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  Mobile number
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                padding="1px"
                borderRadius={borders.borderRadius.lg}
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <PhoneInput
                  country={'in'}
                  value={mobilenumber}
                  onChange={handlePhoneChange}
                  inputStyle={phoneInputStyle}
                  data-clarity-mask="true"
                />
                {/* <VuiInput
                  icon={{
                    component: (
                      <Icon>
                        <PhoneEnabledIcon />
                      </Icon>
                    ),
                    direction: "right",
                  }}
                  type="tel"
                  placeholder="Enter your mobile number"
                  value={email_id}
                  onChange={handleEmailChange}
                  sx={({ typography: { size } }) => ({
                    fontSize: size.sm, color: 'whitesmoke !important'
                  })}
                  success={!emailError && email_id}
                  error={emailError}
                /> */}
              </GradientBorder>
            </VuiBox>
            <VuiBox mt={2}>
              <ReCAPTCHA
                sitekey="6LdM00opAAAAANnPGIFVh6_wW3NpPP4-Nnolm82S"
                onChange={handleRecaptchaChange}
                className="g-recaptcha"
              />
            </VuiBox>
            <VuiBox mt={1} mb={1}>
              <VuiButton color="info" fullWidth disabled={loading || first_name === '' || nameError !== '' || email_id === '' || emailError !== '' || password === '' || passwordError !== '' || mobilenumber === '' || mobilenumber.length < 2 || business_name === '' || businessNameError !== '' || !isRecaptchaVerified} onClick={handleSignup}>
                {loading ? <CircularProgress size={20} color="white" /> : "SIGN UP"}
              </VuiButton>
            </VuiBox>
            {/* <VuiBox mt={1} mb={1} mx='auto'>
              <GoogleLoginComponent onSuccess={handleSuccess} onFailure={handleFailure} />
            </VuiBox> */}
            <VuiBox mt={2} textAlign="center">
              <VuiTypography variant="button" color="text" fontWeight="regular">
                Already have an account?{" "}
                <VuiTypography
                  component={Link}
                  to="/authentication/sign-in/basic"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  Sign in
                </VuiTypography>
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </GradientBorder>
      </CoverLayout>
  );
}

export default SignIn;
