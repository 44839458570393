/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React from "react";

// @mui components
import { Card, Grid, Stack } from "@mui/material";

// Vision UI Dashboard assets
import balance from "assets/images/billing-background-balance.png";
import Graph from "assets/images/shapes/graph-billing.svg";

import palette from "assets/theme/base/colors";

// Vision UI Dashboard components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// React icons
import { FaEllipsisH } from "react-icons/fa";
import { MdOutlineDomain } from "react-icons/md";

const CreditBalance = () => {
  return (
    <Card sx={{ padding: "30px" }}>
      <VuiBox display="flex" flexDirection="column">
        <VuiBox
          mb="32px"
          p="30px"
          display="flex"
          height="20vh"
          flexDirection="column"
          sx={{ backgroundImage: `url(${balance})`, backgroundSize: "cover", borderRadius: "18px" }}
        >
          <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
            <VuiTypography variant="caption" color="white" fontWeight="medium" mr="auto">
              Credit Balance
            </VuiTypography>
            <FaEllipsisH color="white" size="18px" sx={{ cursor: "pointer" }} />
          </VuiBox>
          <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
            <VuiTypography variant="h2" color="white" fontWeight="bold" mr="auto">
              - BALANCE -
            </VuiTypography>
            <VuiBox component="img" src={Graph} sx={{ width: "58px", height: "20px" }} />
          </VuiBox>
        </VuiBox>
        <VuiTypography color="text" variant="xxs" fontWeight="medium" mb="8px">
          NEWEST
        </VuiTypography>
        <Grid spacing={2} direction="column" container>
          <Grid item xs={12}>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing="10px" mr="auto">
              <VuiBox
                display="flex"
                mr="10px"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: "rgba(34, 41, 78, 0.7)",
                  borderRadius: "50%",
                  width: "42px",
                  height: "42px",
                }}
              >
                <MdOutlineDomain color={palette.success.main} size="20px" />
              </VuiBox>
              <VuiBox display="flex" flexDirection="column">
                <VuiTypography color="white" variant="button" fontWeight="medium">
                  Bill & Taxes
                </VuiTypography>
                <VuiTypography color="text" variant="button" fontWeight="medium">
                  Today, 16:36
                </VuiTypography>
              </VuiBox>
            </Stack>
            <VuiTypography variant="button" color="white" fontWeight="bold">
              -$154.50
            </VuiTypography>
          </VuiBox>
          </Grid>
          <Grid item xs={12}>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing="10px" mr="auto">
              <VuiBox
                display="flex"
                mr="10px"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: "rgba(34, 41, 78, 0.7)",
                  borderRadius: "50%",
                  width: "42px",
                  height: "42px",
                }}
              >
                <MdOutlineDomain color={palette.success.main} size="20px" />
              </VuiBox>
              <VuiBox display="flex" flexDirection="column">
                <VuiTypography color="white" variant="button" fontWeight="medium">
                  Bill & Taxes
                </VuiTypography>
                <VuiTypography color="text" variant="button" fontWeight="medium">
                  Today, 16:36
                </VuiTypography>
              </VuiBox>
            </Stack>
            <VuiTypography variant="button" color="white" fontWeight="bold">
              -$154.50
            </VuiTypography>
          </VuiBox>
          </Grid>
          <Grid item xs={12}>
          <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
            <Stack direction="row" spacing="10px" mr="auto">
              <VuiBox
                display="flex"
                mr="10px"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: "rgba(34, 41, 78, 0.7)",
                  borderRadius: "50%",
                  width: "42px",
                  height: "42px",
                }}
              >
                <MdOutlineDomain color={palette.success.main} size="20px" />
              </VuiBox>
              <VuiBox display="flex" flexDirection="column">
                <VuiTypography color="white" variant="button" fontWeight="medium">
                  Bill & Taxes
                </VuiTypography>
                <VuiTypography color="text" variant="button" fontWeight="medium">
                  Today, 16:36
                </VuiTypography>
              </VuiBox>
            </Stack>
            <VuiTypography variant="button" color="white" fontWeight="bold">
              -$154.50
            </VuiTypography>
          </VuiBox>
          </Grid>
          <Grid item xs={12}>
          <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
            <Stack direction="row" spacing="10px" mr="auto">
              <VuiBox
                display="flex"
                mr="10px"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: "rgba(34, 41, 78, 0.7)",
                  borderRadius: "50%",
                  width: "42px",
                  height: "42px",
                }}
              >
                <MdOutlineDomain color={palette.success.main} size="20px" />
              </VuiBox>
              <VuiBox display="flex" flexDirection="column">
                <VuiTypography color="white" variant="button" fontWeight="medium">
                  Bill & Taxes
                </VuiTypography>
                <VuiTypography color="text" variant="button" fontWeight="medium">
                  Today, 16:36
                </VuiTypography>
              </VuiBox>
            </Stack>
            <VuiTypography variant="button" color="white" fontWeight="bold">
              -$154.50
            </VuiTypography>
          </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>

    </Card>
  );
};

export default CreditBalance;
