import { Card, Tooltip } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import React, { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { useSnackbar } from "notistack";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VideoModal from './videoModal';

const Projects = ({projectDetails, details, handleOpen, handleClose, openModal, setOpenModal, selectedUrl}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyClick = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => {
        enqueueSnackbar('URL copied to clipboard', { 
          variant: "success",
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
      .catch((error) => {
        console.error('Failed to copy URL: ', error);
      });
  };

  const handleDownloadClick = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'video.mp4';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const rows = projectDetails && projectDetails.map((item, index) => ({
    id: index + 1, 
    name: item.substring(item.lastIndexOf('_') + 1),
    urls: item !== null && item.length > 30
      ? item.slice(0, 50) + "..."
      : item,
    // stamp: item.stamp,
    words: item.substring(item.lastIndexOf('_') + 1, item.lastIndexOf('.mp4')),
    options: (
      <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100px'}}>
        <Tooltip title='Preview video'  placement="top">
          <PlayArrowIcon size={22} sx={{marginRight: '10px', cursor: 'pointer'}} color='white' onClick={()=>handleOpen(item)} />
        </Tooltip>
        <Tooltip title='Copy video link'  placement="top">
          <CopyAllIcon size={22} sx={{marginRight: '10px', cursor: 'pointer'}} color='white' onClick={() => handleCopyClick(item)} />
        </Tooltip>
        <Tooltip title='Download video'  placement="top">
          <DownloadIcon size={22} sx={{marginRight: '10px', cursor: 'pointer'}} color='white' onClick={() => handleDownloadClick(item)} />
        </Tooltip>
        {openModal && <VideoModal openModal={openModal} handleClose={handleClose} url={selectedUrl} details={details} />}
      </VuiBox>
    )
  }));

  
  
  const dataTableData = {
    columns: [
      {Header: "Video ID", accessor: 'id', width: "10%"},
      {Header: "Video Name", accessor: 'name', width: "10%"},
      {Header: "Video URL", accessor: 'urls', width: "50%"},
      {Header: "Selected word", accessor: 'words', width: "10%"},
      {Header: "Options", accessor: 'options', width: "20%"},
    ],
    rows: rows
  }

  return (
    <DashboardLayout>
      <Card>
        <VuiBox p={3} pl={0} lineHeight={1}>
          <VuiTypography variant="h5" fontWeight="medium">
            Project:&nbsp; <span style={{color: 'lightgray'}}>{details}</span>
          </VuiTypography>
        </VuiBox>
        {projectDetails ? <DataTable table={dataTableData} canSearch /> : <VuiTypography>No videos created</VuiTypography>}
      </Card>
    </DashboardLayout>
  )
}

export default Projects