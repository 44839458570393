/*eslint-disable no-unused-vars */

import { useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import VuiButton from "components/VuiButton";
import { Box } from "@mui/material";

function ProfileSettings(props) {
  const [skills, setSkills] = useState(["react", "angular"]);
  console.log("ProfileSettings", props);
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <VuiBox mb="40px">
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Profile Settings
        </VuiTypography>
      </VuiBox>
      <VuiBox mb="40px">
        <Grid container spacing={3} justifyContent="center">
        <VuiAvatar src={props.user_data.avatar_url} alt="Avatar" size="xxl" bgColor="primary"/>
        <Grid item>
          </Grid>
          <Grid item>
            <VuiTypography variant="lg" color="white" fontWeight="bold">
            {props.user_data.u_name}
            </VuiTypography>
            <br/>
            <VuiTypography variant="text" fontSize="16px" color="white">
            {props.user_data.user_id}
            </VuiTypography>
          </Grid>
        </Grid>
      </VuiBox>
      <VuiBox component="form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField label="full name" placeholder={props.user_data.u_name} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="username" placeholder={props.user_data.user_id} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="email"
              placeholder={props.user_data.user_email}
              inputProps={{ type: "email" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="phone number"
              placeholder={props.user_data.user_phone}
              inputProps={{ type: "tel" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="API key"
              placeholder={props.user_data.user_api_key}
              inputProps={{ type: "text" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="API Secret"
              placeholder={props.user_data.user_api_secret}
              inputProps={{ type: "text" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <VuiButton variant="contained" color="primary" size="large">
                Save Changes
              </VuiButton>
            </Box>
          </Grid>
        </Grid>
      </VuiBox>
    </Card>
  );
}

export default ProfileSettings;
