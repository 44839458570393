import VuiBox from 'components/VuiBox'
import React, { useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Card, CircularProgress } from '@mui/material';
import { useVisionUIController } from 'context';

const Script = ({widgetDetails, agentId, loading}) => {
  const [copied, setCopied] = useState(false);
  const [controller] = useVisionUIController();
  const { plan } = controller;

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  const text =   
  `<script>
    var url = 'https://quickvideo.ai/chatwidget.js';
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = url;
    var options = {
      key: 'replace it with your key',
      selectedAvatar: '${widgetDetails.selectedAvatar}',
      selectedAvatarPreviewUrl: '${widgetDetails.selectedAvatarPreview}',
      welcomeMessage: '${widgetDetails.wMessage}',
      avatarName: '${widgetDetails.avatarName}',
      presenter_id: '${widgetDetails.presenterId}',
      agentId: '${agentId}',
      selectedVoice: '${widgetDetails.selectedVoice}',
      companyName: '${widgetDetails.companyName}',
    };
    s.onload = function() {
      if (typeof createVideoWidget === "function") {
          createVideoWidget(options);
      } else {
          alert("Something went wrong");
      }
    };
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  </script>`;

  const scriptLines = text.split('\n');
  const firstLineIndentation = scriptLines[1].match(/^\s*/)[0];

  return (
    <VuiBox sx={{display: 'flex', flexDirection: 'column'}}>
      {loading ? <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25%'}}><CircularProgress size={28} color='white' /></VuiBox> : 
        <>
          <ContentCopyIcon color='white' sx={{ cursor: 'pointer', marginLeft: 'auto', pointerEvents: (!agentId || plan === 'Trial') && 'none', opacity: (!agentId || plan === 'Trial') && '0.3' }} onClick={handleCopy} />
            {copied && <span>Copied!</span>}
          <Card sx={{color: 'whitesmoke', overflowY: 'scroll', maxHeight: '400px', pointerEvents: (!agentId || plan === 'Trial') && 'none', opacity: (!agentId || plan === 'Trial') && '0.3'}}>
            {scriptLines.map((line, index) => (
              <div key={index} style={{ whiteSpace: 'pre-wrap', fontSize: '18px', paddingLeft: line.startsWith(firstLineIndentation) ? firstLineIndentation.length * 4 : 0 }}>
                {line}
              </div>
            ))}
          </Card>
        </>
      }
    </VuiBox>
  )
}

export default Script