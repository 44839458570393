/*eslint-disable no-unused-vars */
/*eslint-disable array-callback-return */

import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, Grid } from "@mui/material";
import { MessageLeft, MessageRight } from "../conversation-tables/components/chat-window/Message.js";
import MessageData from "../conversation-tables/components/chat-window/MessageData.js";
import { IoMdPerson } from "react-icons/io";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: "100%",
      height: "calc(100vh - 50vh)",
      // height: "100%",
      // background: "linear-gradient(99.77deg, #0C0817 7.96%, #3135AB 93.95%)",
      // background: 'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 100%)',/
      overflowY: "scroll",
      paddingLeft: "5px",
      paddingRight: "5px",
      // display: "flex",
      // alignItems: "center",
      // justifyContent: "center",
    },
    scrollPane: {
      overflowY: "scroll",
    }

  })
);

export default function Chat(props) {
  const classes = useStyles();
  return (
    <Box className={classes.container} sx={{
      mb: 2,
      mt:2,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      overflowY: "scroll",
      borderBottomLeftRadius: "20px",
      borderBottomRightRadius: "20px",
     // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
    }}>
    <div style={{margin: "2%"}}>
    <Grid container spacing={2} direction="column">
          {
            props.conversation.map((message, index) => {
            if (message.msg_io === "incoming") {
              return <Grid item xs={12}>
                <IoMdPerson fontSize={"xx-large"} color="whitesmoke" style={{marginBottom: "-4%"}}  ></IoMdPerson>
                <MessageLeft
              message={message.msg_data}
              timestamp={message.ts}
              displayName={message.msg_from}
            /></Grid>;
            } else if (message.msg_io === "outgoing") {
              return <Grid item xs={12}>
                <Grid container direction="row-reverse">
                  <IoMdPerson fontSize={"xx-large"} color="whitesmoke"></IoMdPerson>
                  <MessageRight
                    message={message.msg_data}
                    timestamp={message.ts}
                    displayName={message.msg_from}
                    style={{ marginRight: '2%' }}
                  />

                  </Grid>
              </Grid>;
            }
          })
        }
        </Grid>
        </div>
      </Box>
  );
}
