import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Vision UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";

// Images
import bgPricingPage from "assets/images/pricing-page-background.png";

// palette colors
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import borders from "assets/theme/base/borders";

function Header({ tabValue, tabHandler }) {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const { borderRadius } = borders;

  return (
    <>
      <VuiBox
        position="relative"
        // height={{ xs: "55vh", lg: "55vh", xl: "55vh", xxl: "55vh" }}
        borderRadius="xl"
        overflow="hidden"
        // sx={{ background: `url(${bgPricingPage})`, backgroundSize: "cover", overflowX: "hidden" }}
        // m={1}
        // pt={5}
        pb={5}
      >
        {/* <VuiBox width="105rem" position="absolute" top={0} /> */}
        <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={10} lg={5}>
            <Grid container item xs={12} sm={10} md={8} lg={7} sx={{ mx: "auto" }}>
              <VuiBox
                width="100%"
                // mt={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <AppBar
                  position="static"
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                  <Tabs
                    value={tabValue}
                    onChange={tabHandler}
                    sx={{
                      maxWidth: "250px",
                      backgroundImage: linearGradient(
                        cardContent.main,
                        cardContent.state,
                        cardContent.deg
                      ),
                      borderRadius: borderRadius.lg,
                    }}
                  >
                    <Tab
                      id="Monthly"
                      label={
                        <VuiBox>
                          <VuiTypography fontSize={12} color="white" fontWeight="bold">
                            MONTHLY
                          </VuiTypography>
                        </VuiBox>
                      }
                      // sx={{ width: "50%" }}
                    />
                    <Tab
                      id="Annual"
                      label={
                        <VuiBox>
                          <VuiTypography fontSize={12} color="white" fontWeight="bold">
                            YEARLY
                          </VuiTypography>
                          <VuiTypography fontSize={10} color="lightgray" fontWeight="bold">
                            Save 20%
                          </VuiTypography>
                        </VuiBox>
                      }
                      // sx={{ width: "50%" }}
                    />
                  </Tabs>
                </AppBar>
              </VuiBox>
            </Grid>
          </Grid>
        </Grid>
      </VuiBox>
    </>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  tabValue: PropTypes.number.isRequired,
  tabHandler: PropTypes.func.isRequired,
};

export default Header;
