import { useState } from "react";

const VideoFooter = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleVideoTimeUpdate = (event) => {
    setCurrentTime(event.target.currentTime);
  };

  const handleVideoSeek = (event) => {
    const newCurrentTime = event.target.value;
    setCurrentTime(newCurrentTime);
    const video = document.querySelector('video');
    video.currentTime = newCurrentTime;
  };

  const handlePlayButtonClick = () => {
    const video = document.querySelector('video');
    isPlaying ? video.pause() : video.play();
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <button onClick={handlePlayButtonClick}>{isPlaying ? 'Pause' : 'Play'}</button>
      <div>
        <input
          type="range"
          min={0}
          max={duration}
          value={currentTime}
          onChange={handleVideoSeek}
        />
      </div>
      <div>
        <span>{currentTime}</span>
        <span> / </span>
        <span>{duration}</span>
      </div>
    </div>
  );
};

export default VideoFooter;
