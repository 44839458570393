import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

import { useEffect, useState } from "react";

// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";
// import VuiTypography from "components/VuiTypography";
// import VuiBadge from "components/VuiBadge";
import { CircularProgress, Container, Grid } from "@mui/material";
// import VuiButton from "components/VuiButton";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import BASE_URL from "context/api";
import { useVisionUIController } from "context";
import { useSnackbar } from "notistack";
import About from "layouts/applications/wizard/components/About";
import Header from "layouts/pages/pricing-page/components/Header";
import PricingCards from "layouts/pages/pricing-page/components/PricingCards";
import { setPlanName } from "context";
import mixpanel from "mixpanel-browser";

function Premium() {
  //eslint-disable-next-line
  const [controller] = useVisionUIController();
  const { userDetails } = controller;
  const { user_id, access_token } = userDetails;
  const [currentPlan, setCurrentPlan] = useState(false);
  const [personalPlan, setPersonalPlan] = useState(false);
  const [businessPlan, setBusinessPlan] = useState(false);
  const [currentPlanYearly, setCurrentPlanYearly] = useState(false);
  const [personalPlanYearly, setPersonalPlanYearly] = useState(false);
  const [businessPlanYearly, setBusinessPlanYearly] = useState(false);
  const [enterprisePlan, setEnterprisePlan] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [businessName, setBusinessSetName] = useState('');
  const [number, setNumber] = useState('');
  const [size, setSize] = useState('');
  const [role, setRole] = useState('');
  const [res, setRes] = useState('');
  const [resToggle, setResToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [innerPlanType, setInnerPlanType] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [currentCredit, setCurrentCredit] = useState(null);
  const [prices, setPrices] = useState([["30", "60", "90"], ["120", "180", "240"], ["360", "480", "720"], "Custom"]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "Annual") {
      setPrices([["24", "48", "72"], ["96", "144", "192"], ["288", "384", "576"], "Custom"]);
    } else {
      setPrices([["30", "60", "90"], ["120", "180", "240"], ["360", "480", "720"], "Custom"]);
    }
  };

  const planType = {
    // liteMon1: 'https://buy.stripe.com/test_7sI3etbEk2ZWbba3cg',
    // liteMon2: 'https://buy.stripe.com/test_8wM4ix8s8584bba28d',
    // liteMon3: 'https://buy.stripe.com/test_7sIcP3bEk1VS6UUcMS',
    // liteAnn1: 'https://buy.stripe.com/test_cN24ix9wcdEAgvu14b',
    // liteAnn2: 'https://buy.stripe.com/test_5kA5mB0ZG8kg7YYdQY',
    // liteAnn3: 'https://buy.stripe.com/test_28o5mBaAg2ZW5QQ14d',
    liteMon1: 'https://buy.stripe.com/cN2cQG4s18Yq02Q28g',
    liteMon2: 'https://buy.stripe.com/cN29EucYxb6y3f214d',
    liteMon3: 'https://buy.stripe.com/bIY9Eue2B5Me9Dq28i',
    liteAnn1: 'https://buy.stripe.com/dR68Aq4s1eiKcPC4gx',
    liteAnn2: 'https://buy.stripe.com/9AQ17Y1fP0rU6re7sK',
    liteAnn3: 'https://buy.stripe.com/00g03U3nX4Ia16UdR9',
    proMon1: 'https://buy.stripe.com/28o17Ye2B2A26refZ9',
    proMon2: 'https://buy.stripe.com/9AQ5oe0bL5MedTGeV6',
    proMon3: 'https://buy.stripe.com/28oeYO9Ml6Qi6re28l',
    proAnn1: 'https://buy.stripe.com/fZe5oe2jTgqS4j6fZi',
    proAnn2: 'https://buy.stripe.com/dR6g2S9Ml4Ia16UcN7',
    proAnn3: 'https://buy.stripe.com/eVa4ka5w5gqSbLy9AW',
    busiMon1: 'https://buy.stripe.com/9AQ8AqgaJb6ydTG3cq',
    busiMon2: 'https://buy.stripe.com/14k5oef6F5Me2aY9AP',
    busiMon3: 'https://buy.stripe.com/4gw2c2bUt8Yq16U3cs',
    busiAnn1: 'https://buy.stripe.com/5kA4ka3nX1vY6re5kH',
    busiAnn2: 'https://buy.stripe.com/dR65oe3nXa2u7vi28w',
    busiAnn3: 'https://buy.stripe.com/5kA4kagaJ2A25na9AZ',
  };
  const { enqueueSnackbar } = useSnackbar();

  const postDetails = async () => {
    setLoading(true);
    await axios.post(`${BASE_URL}/enterprise_leads`, {
      user_id: user_id,
      full_name: name,
      email_id: email,
      business_name: businessName,
      mobile: number,
      size: size,
      role: role,
    })
    .then((res) => {
      setRes(res.data.message);
      setResToggle(true);
      setLoading(false);
      mixpanel.track('Demo booked from lower plan', {
        'email_id': email,
        'mobile': number,
      });
      // setShowForm(false);
    })
    .catch(err => {
      console.log(err);
      setLoading(false);
    })
  }

  useEffect(()=>{
    setName(userDetails.first_name);
    setEmail(userDetails.email_id);

    resToggle && setTimeout(() => {
      setShowForm(false);
    }, 2000);

    // setTimeout(() => {
    //   setResToggle(false);
    //   setRes('');
    //   setName('');
    //   setEmail('');
    //   setBusinessSetName('');
    //   setNumber('');
    // }, 3000);
    //eslint-disable-next-line
  }, [res, resToggle])

  //eslint-disable-next-line
  const actionButtonStyles = {
    "& .material-icons-round": {
      transform: `translateX(0)`,
      transition: "all 200ms cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(4px)`,
    },
  };

  const handleInnerType = (type) => {
    setInnerPlanType(type);
  } 

  const handlePlan = async (plan) => {
    if(tabValue === 0) {
      if(plan === "Lite") {
        if(innerPlanType === 0) {
          window.open(planType.liteMon1, "_blank");
        } else if(innerPlanType === 1) {
          window.open(planType.liteMon2, "_blank");
        } else {
          window.open(planType.liteMon3, "_blank");
        }
      } else if(plan === 'Pro') {
        if(innerPlanType === 0) {
          window.open(planType.proMon1, "_blank");
        } else if(innerPlanType === 1) {
          window.open(planType.proMon2, "_blank");
        } else {
          window.open(planType.proMon3, "_blank");
        }
      } else {
        if(innerPlanType === 0) {
          window.open(planType.busiMon1, "_blank");
        } else if(innerPlanType === 1) {
          window.open(planType.busiMon2, "_blank");
        } else {
          // window.location.href = planType.busiMon3;
          window.open(planType.busiMon3, "_blank");
        }
      }
    } else {
      if(plan === "Lite") {
        if(innerPlanType === 0) {
          window.open(planType.liteAnn1, "_blank");
        } else if(innerPlanType === 1) {
          window.open(planType.liteAnn2, "_blank");
        } else {
          window.open(planType.liteAnn3, "_blank");
        }
      } else if(plan === 'Pro') {
        if(innerPlanType === 0) {
          window.open(planType.proAnn1, "_blank");
        } else if(innerPlanType === 1) {
          window.open(planType.proAnn2, "_blank");
        } else {
          window.open(planType.proAnn3, "_blank");
        }
      } else {
        if(innerPlanType === 0) {
          window.open(planType.busiAnn1, "_blank");
        } else if(innerPlanType === 1) {
          window.open(planType.busiAnn2, "_blank");
        } else {
          window.open(planType.busiAnn3, "_blank");
        }
      }
    }
  }

  const handleSelectLite = () => {
    handlePlan('Lite');
  }
  const handleSelectPersonal = () => {
    handlePlan('Pro');
  }
  const handleSelectBusiness = () => {
    handlePlan('Business');
  }
  const handleSelectEnterprise = () => {
    // handlePlan('Enterprise');
    setShowForm(true);
  }

  useEffect(() => {
    setLoading(true);
    try {
      axios.get(`${BASE_URL}/subscribe`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id,
        },
      })
      .then(response => {
        let planName = response.data.plan.name.split(' ');
        
        if(planName[2] === 'I' || planName[2] === 'IV') {
          setCurrentCredit(0);
        } else if(planName[2] === 'II'|| planName[2] === 'V') {
          setCurrentCredit(1);
        } else if(planName[2] === 'III'|| planName[2] === 'VI') {
          setCurrentCredit(2);
        } else {
          setCurrentCredit(0);
        }

        if(response.data.plan.type === 'Weekly') {
          switch (planName[1]) {
            case "Trial":
              setCurrentPlan(false);
              setPersonalPlan(false);
              setBusinessPlan(false);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(false);
              setEnterprisePlan(false);
              setLoading(false);
              return;
              
            case "Lite":
              setCurrentPlan(true);
              setPersonalPlan(false);
              setBusinessPlan(false);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(false);
              setEnterprisePlan(false);
              setLoading(false);
              return;
              
            case "Pro":
              setCurrentPlan(false);
              setPersonalPlan(true);
              setBusinessPlan(false);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(false);
              setEnterprisePlan(false);
              setLoading(false);
              return;
  
            case "Business":
              setCurrentPlan(false);
              setPersonalPlan(false);
              setBusinessPlan(true);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(false);
              setEnterprisePlan(false);
              setLoading(false);
              return; 
          
            case "Enterprise":
              setCurrentPlan(false);
              setPersonalPlan(false);
              setBusinessPlan(false);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(false);
              setEnterprisePlan(true);
              setLoading(false);
              return;
  
            default:
              break;
          }
        }

        if(response.data.plan.type === 'Monthly') {
          switch (planName[1]) {
            case "Trial":
              setCurrentPlan(false);
              setPersonalPlan(false);
              setBusinessPlan(false);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(false);
              setEnterprisePlan(false);
              setLoading(false);
              return;
              
            case "Lite":
              setCurrentPlan(true);
              setPersonalPlan(false);
              setBusinessPlan(false);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(false);
              setEnterprisePlan(false);
              setLoading(false);
              return;
              
            case "Pro":
              setCurrentPlan(false);
              setPersonalPlan(true);
              setBusinessPlan(false);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(false);
              setEnterprisePlan(false);
              setLoading(false);
              return;
  
            case "Business":
              setCurrentPlan(false);
              setPersonalPlan(false);
              setBusinessPlan(true);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(false);
              setEnterprisePlan(false);
              setLoading(false);
              return; 
          
            case "Enterprise":
              setCurrentPlan(false);
              setPersonalPlan(false);
              setBusinessPlan(false);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(false);
              setEnterprisePlan(true);
              setLoading(false);
              return;
  
            default:
              break;
          }
        }
        
        if(response.data.plan.type === 'Annual') {
          switch (planName[1]) {
            case "Lite":
              setCurrentPlan(false);
              setPersonalPlan(false);
              setBusinessPlan(false);
              setCurrentPlanYearly(true);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(false);
              setEnterprisePlan(false);
              setLoading(false);
              return;

            case "Pro":
              setCurrentPlan(false);
              setPersonalPlan(false);
              setBusinessPlan(false);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(true);
              setBusinessPlanYearly(false);
              setEnterprisePlan(false);
              setLoading(false);
              return;

            case "Business":
              setCurrentPlan(false);
              setPersonalPlan(false);
              setBusinessPlan(false);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(true);
              setEnterprisePlan(false);
              setLoading(false);
              return; 

            case "Enterprise":
              setCurrentPlan(false);
              setPersonalPlan(false);
              setBusinessPlan(false);
              setCurrentPlanYearly(false);
              setPersonalPlanYearly(false);
              setBusinessPlanYearly(false);
              setEnterprisePlan(true);
              setLoading(false);
              return;

            default:
              break;
          }
        }
      })
      .catch(err => {
        console.log(err); 
        setLoading(false);
        enqueueSnackbar("Can't fetch updated plan.", {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", marginTop: "25%" }}>
            <CircularProgress size={60} color="white" />
          </div>
        ) : (
          showForm ? 
          <About 
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            businessName={businessName}
            setBusinessSetName={setBusinessSetName}
            number={number}
            setNumber={setNumber}
            postDetails={postDetails}
            res={res}
            loader={loading}
            resToggle={resToggle} 
            size={size}
            setSize={setSize}
            role={role}
            setRole={setRole}
            setShowForm={setShowForm}
          />
            :
          <Card sx={{padding: "30px 10px"}}>
            <Header tabValue={tabValue} tabHandler={handleSetTabValue} />
            <Container>
              <PricingCards 
                prices={prices}
                handleSelectLite={handleSelectLite}
                handleSelectBusiness={handleSelectBusiness}
                handleSelectEnterprise={handleSelectEnterprise}
                handleSelectPersonal={handleSelectPersonal}
                currentPlan={currentPlan}
                currentPlanYearly={currentPlanYearly}
                personalPlan={personalPlan}
                personalPlanYearly={personalPlanYearly}
                businessPlan={businessPlan}
                businessPlanYearly={businessPlanYearly}
                enterprisePlan={enterprisePlan}
                tabValue={tabValue}
                handleInnerType={handleInnerType}
                currentCredit={currentCredit}
              />
            </Container>
          </Card>
        )}
      </Grid>
    </DashboardLayout>
  );
}

export default Premium;
