import { AppBar, Button, ButtonGroup, CircularProgress, Grid, Slider, Tab, Tabs, Typography } from '@mui/material'
import VuiBox from 'components/VuiBox'
import VuiInput from 'components/VuiInput'
import VuiTypography from 'components/VuiTypography'
import React, { useEffect, useState } from 'react'
import ImagesSettings from './ImagesSettings'

const Image = ({stockImages, setStockImages, imageQuery, setImageQuery, page, setPage, imageObserver, lastImageRef, loading, imageTotalPages, selectedImage, setSelectedImage, scenes, updateSceneKey, currentSceneIndex, openSettings, setOpenSettings, setScenes}) => {
  const [tabValue, setTabValue] = useState(0);
  const [length, setLength] = useState(0);
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [stockImages, setStockImages] = useState([]);
  // const [imageQuery, setImageQuery] = useState('');
  // const [page, setPage] = useState(1);
  // const [loading, setLoading] = useState(false);

  // const observer = useRef(null);
  // const lastImageRef = useRef(null);

  const handleSearchInputChange = (e) => {
    setStockImages([]);
    setImageQuery(e.target.value);
  };

  const filteredImages = stockImages && stockImages.filter((image) => image.alt.toLowerCase().includes(imageQuery.toLowerCase()));

  const tabHandler = (event, newValue) => {
    setTabValue(newValue);
    setOpenSettings({
      ...openSettings,
      image: false
    })
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setLength(prevLen => prevLen + 1);

    setOpenSettings({
      ...openSettings,
      image: false
    })
    
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageObject = {
          image: e.target.result,
          scale: 100,
          zIndex: 25 + length,
          opacity: 1,
          marginTop: 0,
          marginLeft: 0,
          x: 170,
          y: 20,
          size: {
            width: 300,
            height: 200,
          },
          handle: false,
          fullScreen: false,
        };

        updateSceneKey(currentSceneIndex, 'selectedImage', [...scenes[currentSceneIndex].selectedImage, newImageObject]);
        // setOpenImageSettings(newImageObject);
        setOpenSettings({
          ...openSettings,
          image: newImageObject
        })
      };
      reader.readAsDataURL(file);
    }
  };

 useEffect(() => {
  if (lastImageRef.current) {
    imageObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    });

    imageObserver.current.observe(lastImageRef.current);
  }

  return () => {
    if (imageObserver.current) {
      imageObserver.current.disconnect();
    }
  };
  //eslint-disable-next-line
}, [loading, imageTotalPages, page, stockImages]);

  const handleStockImageSelection = (image) => {
    setLength(prevLen => prevLen + 1);

    setOpenSettings({
      ...openSettings,
      image: false
    })
    // setSelectedImage(image.webformatURL);
    const newImageObject = {
      image: image.src.original,
      scale: 100,
      zIndex: 20 + length,
      opacity: 1,
      marginTop: 0,
      marginLeft: 0,
      x: 170,
      y: 20,
      size: {
        width: 300,
        height: 200,
      },
      handle: false,
      fullScreen: false,
    };

    updateSceneKey(currentSceneIndex, 'selectedImage', [...scenes[currentSceneIndex].selectedImage, newImageObject]);

    setOpenSettings({
      ...openSettings,
      image: newImageObject
    })
    
  }
  
  
  return (
    <VuiBox>
      <Grid container alignItems={"center"}>
        <AppBar
          position="static"
          sx={{ display: "flex", mb: 0, alignItems: "center" }}
        >
          <Tabs value={tabValue} onChange={tabHandler}>
            <Tab
              label={
                <VuiTypography color="light" variant="body2">
                  Upload
                </VuiTypography>
              }
            />
            <Tab
              label={
                <VuiTypography color="light" variant="body2">
                  Stock
                </VuiTypography>
              }
            />
          </Tabs>
        </AppBar>
      </Grid>

      {tabValue === 0 && 
        <VuiBox sx={{marginTop: '15px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          {openSettings.image === false ? <label htmlFor="image-file" style={{cursor: 'pointer'}}>
              <Typography variant='h6' sx={{color: '#000', background: 'lightgray', padding: '10px 25px', borderRadius: '15px'}}>
                Click here to Upload
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  type="file"
                  id="image-file"
                  name="image-file"
                  // value={openImageSettings}
                  onChange={handleFileUpload}
                />
              </Typography>
            </label>
           : 
            <ImagesSettings selectedImage={selectedImage} setSelectedImage={setSelectedImage} openSettings={openSettings} setOpenSettings={setOpenSettings} scenes={scenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} setScenes={setScenes} />
          }
        </VuiBox>
      }

    {tabValue === 1 && 
      <VuiBox sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-evenly', margin: '5px 0'}}>
        {openSettings.image === false ? 
          <>
            <VuiInput placeholder='Search here' value={imageQuery} onChange={handleSearchInputChange}  />
            {(filteredImages.length > 0 ? filteredImages : stockImages).map((image, index) => (
              <img 
              key={image.id} 
              src={image.src.large2x} 
              alt={image.alt} 
              width='130px' 
              style={{margin: '5px 0', cursor: 'pointer'}} 
              ref={index === ((filteredImages ? filteredImages.length - 1 : stockImages.length - 1)) ? lastImageRef : null} 
              onClick={() => handleStockImageSelection(image)} 
              />
            ))}
            {loading && <CircularProgress color='white' size={20} />}
          </> 
          :
          <ImagesSettings selectedImage={selectedImage} setSelectedImage={setSelectedImage} openSettings={openSettings} setOpenSettings={setOpenSettings} scenes={scenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} setScenes={setScenes} />
        }
      </VuiBox>
    }
    </VuiBox>
  )
}

export default Image