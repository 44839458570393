import { Button, Card } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import './tablestyle.css';

const Dataset = ({selectedWords, downloadCSV, buttonCount, uploadCSV, setUploadCSV, handleCSVUpload, parsedData, setParsedData, tableRows, setTableRows, values, setValues, project}) => {

  const removeFile = (event) => {
    event.stopPropagation();
    setUploadCSV(null);
    setParsedData([]);
    setTableRows([]);
    setValues([]);
  }

  return (
    <VuiBox sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'column'}}>
      <VuiBox sx={{marginBottom: '20px'}}>
        <VuiTypography>Download below CSV file to fill your dataset for selected variables</VuiTypography>
        {project.variableText && <Button onClick={downloadCSV} variant="contained" color="primary" style={{ marginTop: '10px', color: 'whitesmoke' }}>
          Download CSV
        </Button>}
      </VuiBox>
      {buttonCount === 1 && <VuiBox sx={{marginTop: '20px'}}>
        <VuiTypography>Once you are done with filling your dataset, please upload below</VuiTypography>
        {uploadCSV ? (
        <div sx={{boxShadow: "0px 0px 10px 2px rgb(54, 68, 97)"}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '20px'}}>
            <ClearIcon sx={{color: 'whitesmoke', cursor: 'pointer'}} onClick={removeFile} />
          </div>
          <table className='table_view'>
            <thead className='table_head'>
              <tr className='table_row'>
                {tableRows.map((rows, index) => {
                  return <th key={index} className='table_row'>{rows}</th>;
                })}
              </tr>
            </thead>
            <tbody className='table_body'>
              {values.map((value, index) => {
                return (
                  <tr key={index}>
                    {value.map((val, i) => {
                      return <td key={i} className='table_data'>{val}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <label htmlFor="csv-file" style={{cursor: 'pointer'}}>
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "50px",
              color: "whitesmoke",
              border: "1px dashed gray",
              cursor: "pointer",
              borderRadius: "15px",
              overflow: "hidden",
              padding: "40px",
              minWidth: "200px",
              maxWidth: "200px",
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '20px'
            }}
          >
            <input
              accept=".csv"
              style={{ display: 'none' }}
              type="file"
              id="csv-file"
              name="csv-file"
              onChange={handleCSVUpload}
            />
            <h5>Upload CSV</h5>
          </Card>
        </label>
      )}
      </VuiBox>}
    </VuiBox>
  )
}

export default Dataset