/*eslint-disable no-unused-vars*/

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import React, { useEffect, useState } from 'react';
import DashboardNavbar from '.';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
// import FormField from "layouts/pages/account/components/FormField";
import FormField from "layouts/applications/wizard/components/FormField";
import VuiButton from "components/VuiButton";
import { Box, CircularProgress } from "@mui/material";
import { useVisionUIController } from "context";
import axios from 'axios';
import BASE_URL from 'context/api';
import { useSnackbar } from "notistack";

function Profile() {
  const [controller, dispatch] = useVisionUIController();
  const { userDetails } = controller;
  const {user_id} = userDetails;
  const [name, setName] = useState(userDetails.first_name);
  const [number, setNumber] = useState(userDetails.mobile);
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    handleGetDetails();
    //eslint-disable-next-line
  }, [])

  const handleGetDetails = async () => {
    setLoader(true);
    await axios.get(`${BASE_URL}/update_contact_details`, {
      params: {
        user_id,
      }
    })
    .then((res) => {
      setLoader(false);
      setName(res.data.first_name);
      setNumber(res.data.mobile);
    })
    .catch((err) => {
      setLoader(false);
      enqueueSnackbar(err, { 
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    })
  }

  const handleChanges = async () => {
    setLoader(true);
    await axios.patch(`${BASE_URL}/update_contact_details`, {
      user_id,
      first_name: name,
      mobile: number,
    })
    .then((res) => {
      setLoader(false);
      enqueueSnackbar(res.data.detail, { 
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      handleGetDetails();
    })
    .catch((res) => {
      setLoader(false);
      enqueueSnackbar(res.data, { 
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    })
  }

  const handleNumber = (e) => {
    setNumber(e.target.value);
    const phoneRegex = /^[0-9]{10,}$/;
    if(!phoneRegex.test(e.target.value)) {
      setPhoneError("Enter valid phone number");
    } else {
      setPhoneError("");
    }
  }

  const handleName = (e) => {
    setName(e.target.value);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <VuiBox mb="40px">
          <VuiTypography variant="lg" color="white" fontWeight="bold">
            Profile Settings
          </VuiTypography>
        </VuiBox>
        <VuiBox mb="40px">
          <Grid container spacing={3} justifyContent="center">
          <VuiAvatar src="" alt="Avatar" size="xxl" bgColor="primary"/>
          <Grid item>
            </Grid>
            <Grid item>
              <VuiTypography variant="lg" color="white" fontWeight="bold">
                {userDetails.first_name}
              </VuiTypography>
              <br/>
              <VuiTypography variant="text" fontSize="16px" color="white">
                {userDetails.email_id}
              </VuiTypography>
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox component="form">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField 
                type="text" 
                label="full name" 
                placeholder="Name" 
                onChange={handleName} 
                value={name} 
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="email"
                placeholder="abc@gmail.com"
                inputProps={{ type: "email" }}
                value={userDetails.email_id}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="phone"
                label="phone number"
                placeholder="9876543210"
                onChange={handleNumber}
                value={number}
              />
              {(phoneError && number !== '') && <h6 style={{color: 'red'}}>{phoneError}</h6>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="plan"
                value={userDetails.plan}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <VuiButton variant="contained" color="primary" size="large" onClick={handleChanges}disabled={name === '' || number === '' || phoneError !== ''}>
                  {loader ? <CircularProgress size={12} color='white' /> : 'Save Changes'}
                </VuiButton>
              </Box>
            </Grid>
          </Grid>
        </VuiBox>
      </Card>
    </DashboardLayout>
  )
}

export default Profile;