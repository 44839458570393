/*eslint-disable no-unused-vars */

import React from "react";
import QuickVideoIcon from "../QuickVideoIcon.png";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import VuiButton from "components/VuiButton";
import { useState } from "react";

function SecondStep({value, setValue, size, setSize, role, setRole}) {

  const productMember = ["Alone", "Team"];
  const industry = ["Real Estate", "Education", "Financial", "Healthcare", "Hospitality", "Manufacturing", "Technology", "Media", "other"];
  const department = ["Administration", "Finances", "General management", "Human resources", "Marketing", "Sales", "Production", "Public relations", "Other"];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSize = (event, newSize) => {
    setSize(newSize);
  };

  const handleRole = (event, newRole) => {
    setRole(newRole);
  };

  return (
    <VuiBox>
      {/* <VuiBox style={{marginTop:'25px'}}>
								<VuiTypography
									variant='button'
									textGradient={true}
									color='logo'
									fontSize={14}
									letterSpacing={2}
									fontWeight='medium'
									
									>
									<img src={QuickVideoIcon} height="30px"/>
								</VuiTypography>
							</VuiBox> */}

      <Grid>
        {/* <VuiBox style={{ display: "flex", justifyContent: "center" }}> */}
          <VuiTypography
            style={{ display: "flex", justifyContent: "center", textAlign: 'center' }}
            variant="h5"
            color="light"
            textGradient
            verticalAlign="middle"
            fontWeight="regular"
          >
            Tell us more about your company to customize your experience.
          </VuiTypography>
        {/* </VuiBox> */}

        <VuiBox
          style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
        >
          <VuiTypography
            style={{ display: "block", justifyContent: "center" }}
            variant="h6"
            textGradient
            verticalAlign="middle"
            fontWeight="false"
            color="secondary"
          >
            Will you use the product alone or with a team?
          </VuiTypography>
        </VuiBox>
        <VuiBox
          style={{ display: "flex", justifyContent: window.innerWidth < 768 ? "flex-start" : "center", marginTop: "1%", overflowX: 'auto' }}
        >
          <Tabs value={value} onChange={handleChange} centered>
            {
              productMember.map((product, index) => (
                <Tab
                  key={index}
                  label={
                    <Typography color={"whitesmoke"} variant="body2">
                      {product}
                    </Typography>
                  }
                  value={product}
                />
              ))
            }
          </Tabs>
        </VuiBox>

        <VuiBox
          style={{ display: "flex", justifyContent: "center", marginTop: "2%" }}
        >
          <VuiTypography
            style={{ display: "block", justifyContent: "center" }}
            variant="h6"
            textGradient
            verticalAlign="middle"
            fontWeight="false"
            color="secondary"
          >
            Which Industry do you belong from?
          </VuiTypography>
        </VuiBox>
        <VuiBox
          style={{
            display: "flex",
            justifyContent: window.innerWidth < 768 ? "flex-start" : "center", 
            marginTop: "1%",
            overflowX: 'auto'
          }}
        >
          <Tabs
            value={size}
            onChange={handleSize}
            centered
            sx={{
              '& .MuiTab-root': {
                width: '100%',
                minWidth: 'auto',
                marginLeft: "10px",
                marginRight: "10px",
                paddingLeft: "15px !important",
                paddingRight: "15px !important"
              },
            }}
          >
            {
              industry.map((industry, index) => (
                <Tab
                  sx={{flex: 1}}
                  key={index}
                  label={
                    <Typography color={"whitesmoke"} variant="body2">
                      {industry}
                    </Typography>
                  }
                  value={industry}
                />
              ))
            }
          </Tabs>
        </VuiBox>
        <VuiBox
          style={{ display: "flex", justifyContent: "center", marginTop: "2%" }}
        >
          <VuiTypography
            style={{ display: "block", justifyContent: "center" }}
            variant="h6"
            textGradient
            verticalAlign="middle"
            fontWeight="false"
            color="secondary"
          >
            Which department do you belong to?
          </VuiTypography>
        </VuiBox>
        <VuiBox
          style={{
            display: "flex",
            justifyContent: window.innerWidth < 768 ? "flex-start" : "center", 
            marginTop: "1%",
            overflowX: 'auto'
          }}
        >
          <Tabs
            value={role}
            onChange={handleRole}
            centered
            sx={{
              '& .MuiTab-root': {
                width: '100%',
                minWidth: 'auto',
                marginLeft: "10px",
                marginRight: "10px",
                paddingLeft: "15px !important",
                paddingRight: "15px !important"
              },
            }}
          >
            {
              department.map((department, index) => (
                <Tab
                  sx={{ flex: 1 }}
                  key={index}
                  label={
                    <Typography color={"whitesmoke"} variant="body2">
                      {department}
                    </Typography>
                  }
                  value={department}
                />
              ))
            }
          </Tabs>
        </VuiBox>
      </Grid>
    </VuiBox>
  );
}

export default SecondStep;
