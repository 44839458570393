import VuiBox from 'components/VuiBox';
import React, { useState } from 'react';
import I1 from '../images/shapes/ico_bag.svg';
import I2 from '../images/shapes/ico_baseball.svg';
import I3 from '../images/shapes/ico_car.svg';
import I4 from '../images/shapes/ico_cart.svg';
import I5 from '../images/shapes/ico_chat.svg';
import I6 from '../images/shapes/ico_checkbox.svg';
import I7 from '../images/shapes/ico_checkbox_blank.svg';
import I8 from '../images/shapes/ico_circle.svg';
import I9 from '../images/shapes/ico_cloud.svg';
import I10 from '../images/shapes/ico_cloudy.svg';
import I11 from '../images/shapes/ico_cloudy_snowing.svg';
// import I12 from './../images/shapes/ico_expand_less.svg';
import I13 from '../images/shapes/ico_foggy.svg';
import I14 from '../images/shapes/ico_folder.svg';
import I15 from '../images/shapes/ico_folder_open.svg';
import I16 from '../images/shapes/ico_football.svg';
import I17 from '../images/shapes/ico_game.svg';
import I18 from '../images/shapes/ico_handshake.svg';
import I19 from '../images/shapes/ico_heart.svg';
import I20 from '../images/shapes/ico_heart_broken.svg';
import I21 from '../images/shapes/ico_hexagon.svg';
import I22 from '../images/shapes/ico_list.svg';
import I23 from '../images/shapes/ico_mail.svg';
import I24 from '../images/shapes/ico_moon.svg';
import I25 from '../images/shapes/ico_network.svg';
import I26 from '../images/shapes/ico_pentagon.svg';
import I27 from '../images/shapes/ico_person.svg';
import I28 from '../images/shapes/ico_phone.svg';
import I29 from '../images/shapes/ico_pin_drop.svg';
import I30 from '../images/shapes/ico_plain.svg';
import I31 from '../images/shapes/ico_rainy.svg';
import I32 from '../images/shapes/ico_rectangle.svg';
import I33 from '../images/shapes/ico_rectangle_rounded.svg';
import I34 from '../images/shapes/ico_rocket.svg';
import I35 from '../images/shapes/ico_scuba.svg';
import I36 from '../images/shapes/ico_soccer.svg';
import I37 from '../images/shapes/ico_square.svg';
import I38 from '../images/shapes/ico_square_rounded.svg';
import I39 from '../images/shapes/ico_star.svg';
import I40 from '../images/shapes/ico_tag.svg';
import I41 from '../images/shapes/ico_texture.svg';
import I42 from '../images/shapes/ico_thumb_down.svg';
import I43 from '../images/shapes/ico_thumb_up.svg';
import I44 from '../images/shapes/ico_thunderstorm.svg';
import I45 from '../images/shapes/ico_train.svg';
import I46 from '../images/shapes/ico_triangle.svg';
import I47 from '../images/shapes/ico_truck.svg';
import I48 from '../images/shapes/ico_volleyball.svg';
import { CircularProgress, Typography } from '@mui/material';
import VuiInput from 'components/VuiInput';
import ShapesSettings from './ShapesSettings';

const Shapes = ({scenes, setScenes, currentSceneIndex, updateSceneKey, openSettings, setOpenSettings, stockShapes, setStockShapes, shapesQuery, setShapesQuery, loading}) => {
  const shapes = [I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I13, I14, I15, I16, I17, I18, I19, I20, I21, I22, I23, I24, I25, I26, I27, I28, I29, I30, I31, I32, I33, I34, I35, I36, I37, I38, I39, I40, I41, I42, I43, I44, I45, I46, I47, I48];
  const [length, setLength] = useState(0);
  const [alternateQuery, setAlternateQuery] = useState('');

  const handleSelectedShape = (item) => {
    setLength(prevLen => prevLen + 1);

    setOpenSettings({
      ...openSettings,
      shape: false
    })
    
    const newShapeObject = {
      image: item.thumbnail_url,
      scale: 100,
      zIndex: 20 + length,
      opacity: 1,
      marginTop: 0,
      marginLeft: 0,
      x: 170,
      y: 20,
      size: {
        width: 100,
        height: 100,
      },
      handle: false,
    };

    updateSceneKey(currentSceneIndex, 'selectedShape', [...scenes[currentSceneIndex].selectedShape,newShapeObject])

    setOpenSettings({
      ...openSettings,
      shape: newShapeObject
    })
  }
  
  const handleSearch = (e) => {
    let value = e.target.value;
    setAlternateQuery(e.target.value);
    if(value === '') {
      setShapesQuery('default');
    } else {
      setShapesQuery(e.target.value);
    }
  }

  return (
    <VuiBox>
      <Typography>Shapes</Typography>
      <VuiBox sx={{display: 'flex', flexWrap: 'wrap', alignItem: 'center', justifyContent: 'space-evenly', color: 'white', marginTop: '10px'}}>
        {openSettings.shape === false ? 
          <> 
            <VuiInput placeholder='search here' value={alternateQuery} onChange={(e)=>handleSearch(e)}  />
            {stockShapes.map((item, index) => (
              <img key={index} src={item.thumbnail_url} alt='shapes' width={80} onClick={()=>handleSelectedShape(item)} style={{cursor: 'pointer', margin: '10px', background: 'whitesmoke', padding: '5px', borderRadius: '10px'}} />
            ))}
            {loading && <CircularProgress color='white' size={20} />}
          </>
          :
          <ShapesSettings setOpenSettings={setOpenSettings} openSettings={openSettings} scenes={scenes} setScenes={setScenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} />
        }
      </VuiBox>
    </VuiBox>
  )
}

export default Shapes