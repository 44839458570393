import React from 'react';
import Icon from 'assets/images/padlock_595586.png';
import VuiTypography from 'components/VuiTypography';
import VuiButton from 'components/VuiButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '@mui/material';
import bgLock from './assets/images/stock-vector-padlock-security-cyber-digital-concept-abstract-technology-background-protect-system-innovation-2075232532-transformed (1).jpeg'
import VuiBox from 'components/VuiBox';

const Lock = ({height, line}) => {
  const history = useHistory();
  
  const handleUpgradeClick = () => {
    history.push('/upgrade');
  };
  
  const handleCancel = () => {
    history.push('/create');
  };

  return (
    <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh',
    width: '90%',
    maxWidth: '600px',
    padding: '2rem',
    backdropFilter: 'blur(100px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '20px',
    margin: '0 auto',
  }}
>
  <img src={Icon} alt="lock" height={100} width={100} />
  <VuiTypography variant='h4' color='white' sx={{ marginTop: '15px', textAlign: 'center' }}>
    Kindly upgrade your plan to access {line}.
  </VuiTypography>
  <VuiBox
    sx={{
      marginTop: '30px',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
    }}
  >
    <Button
      variant='contained'
      sx={{
        color: 'whitesmoke',
        background: 'red',
        margin: '5px',
        '&:hover': { background: 'red' },
      }}
      onClick={handleCancel}
    >
      Cancel
    </Button>
    <Button
      variant='contained'
      sx={{
        color: 'whitesmoke',
        margin: '5px',
      }}
      onClick={handleUpgradeClick}
    >
      Change plan
    </Button>
  </VuiBox>
</div>

  )
}

export default Lock;