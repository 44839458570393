import { Typography } from '@mui/material';
import VuiBox from 'components/VuiBox';
import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextSettings from './textSettings';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const Text = ({scenes, setScenes, updateSceneKey, currentSceneIndex, touched, setTouched, indexToStore, setIndexToStore, fontSize, setFontSize, selectedFontSize, setSelectedFontSize}) => {

  const [fonts, setFonts] = useState(["None", "Times New Roman","Georgia","Garamond","Baskerville","Palatino","Arial","Helvetica","Calibri","Verdana","Futura","Courier New","Consolas","Inconsolata","Monaco","Liberation Mono","Brush Script","Lucida Calligraphy","Zapfino","Pacifico","Snell Roundhand","Comic Sans MS","Papyrus","Jokerman","Curlz MT","Chiller","Century Gothic","Avant Garde Gothic","Didot","Bodoni","Rockwell","D'Nealian","Schoolbell","Comic Neue","Quicksand","Architect's Daughter","Edwardian Script","Brush Script MT","Snell Roundhand","French Script","Palace Script MT","Impact","Stencil", "Playbill", "Bauhaus 93", "Broadway", "Rockwell", "Courier", "Egyptienne", "Clarendon", "Archer"]);    

  const handleToggle = (prop) => {
    const updatedTouched = {};
    let newFontSize;

    for (const key in scenes[currentSceneIndex].textToggle) {
      updatedTouched[key] = false;
    }

    updatedTouched[prop] = scenes[currentSceneIndex].textArrays[prop];
    updateSceneKey(currentSceneIndex, 'textToggle', updatedTouched);

    setIndexToStore((prevIndexes) => ({
      ...prevIndexes,
      [prop]: prevIndexes[prop] + 1,
    }));
    
    const newTextArrays = { ...scenes[currentSceneIndex].textArrays };

    switch (prop) {
      case 'header':
        newFontSize = 40;
        break;

      case 'title':
        newFontSize = 34;
        break;

      case 'main_text':
        newFontSize = 26;
        break;

      case 'caption':
        newFontSize = 18;
        break;
    
      default:
        newFontSize = 40;
        break;
    }

    const newTextObject = {
      text: prop,
      font: "",
      fontSize: newFontSize,
      color: "#000000",
      background: "",
      // stroke: "",
      // border: 0,
      // borderColor: "",
      // padding: 0,
      // align: "",
      // scale: 100,
      zIndex: 40,
      opacity: 1,
      // marginTop: 0,
      // marginLeft: 0,
      x: 170,
      y: 20,
      size: {
        width: 100,
        height: 100,
      },
      handle: false,
    };

    if (newTextArrays[prop]) {
      newTextArrays[prop].push(newTextObject);
      updateSceneKey(currentSceneIndex, 'textArrays', newTextArrays);
    } else {
      console.error(`Array "${prop}" does not exist in textArrays.`);
    }
  }
  
  const handleToggleClose = (prop) => {
    const updatedTouched = {};

    for (const key in scenes[currentSceneIndex].textToggle) {
      updatedTouched[key] = false;
    }
    updatedTouched[prop] = false;
    // setTextToggle(updatedTouched);
    updateSceneKey(currentSceneIndex, 'textToggle', updatedTouched);
    // setIndexToStore((prevIndex) => prevIndex + 1);
    // setIndexToStore((prevIndexes) => ({
    //   ...prevIndexes,
    //   [prop]: prevIndexes[prop] + 1,
    // }));
    
    // const indexToIncrement = indexToStore[textVariant];
    // setIndexToStore((prevIndexes) => ({
    //   ...prevIndexes,
    //   [textVariant]: indexToIncrement + 1,
    // }));
  }

  const handleDelete = (innerCategory) => {
    setScenes((prevScenes) => {
      return prevScenes.map((scene, index) => {
        if (index === currentSceneIndex) {
          return {
            ...scene,
            textArrays: {
              ...scene.textArrays,
              [innerCategory]: scene.textArrays[innerCategory].filter((text, index) => {
                  
                if (Array.isArray(scenes[currentSceneIndex].textToggle[innerCategory])) {
                  return text.text !== scenes[currentSceneIndex].textToggle[innerCategory][index]?.text;
                } 

                if (typeof scenes[currentSceneIndex].textToggle[innerCategory] === 'object') {
                  return text.text !== scenes[currentSceneIndex].textToggle[innerCategory].text;
                } 
              })
            },
          };
        }
        return scene;
      });
    });

    handleToggleClose(innerCategory);

    const updatedTouched = {};
    for (const key in touched) {
      updatedTouched[key] = false;
    }
    updatedTouched['model'] = true;
    setTouched(updatedTouched);
  }

  return (
    <VuiBox sx={{display: 'flex', flexDirection: 'column'}}>
      {scenes[currentSceneIndex].textToggle.header && <VuiBox sx={{display: 'flex', flexDirection: 'column'}}>
        <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
            <ArrowBackIcon color="white" onClick={() => handleToggleClose('header')} sx={{cursor: 'pointer', marginRight: '10px'}} />
            <Typography>header text</Typography>
          </VuiBox>
          <DeleteOutlineIcon color='white' sx={{cursor: 'pointer'}} onClick={()=>handleDelete('header')} />
        </VuiBox>
        <TextSettings fonts={fonts} textVariant='header' indexToStore={indexToStore} setIndexToStore={setIndexToStore} scenes={scenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} selectedFontSize={selectedFontSize} setSelectedFontSize={setSelectedFontSize} fontSize={fontSize} setFontSize={setFontSize} />
      </VuiBox>}

      {scenes[currentSceneIndex].textToggle.title && <VuiBox sx={{display: 'flex', flexDirection: 'column'}}>
        <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
            <ArrowBackIcon color="white" onClick={() => handleToggleClose('title')} sx={{cursor: 'pointer', marginRight: '10px'}} />
            <Typography>title text</Typography>
          </VuiBox>
          <DeleteOutlineIcon color='white' sx={{cursor: 'pointer'}} onClick={()=>handleDelete('title')} />
        </VuiBox>
        <TextSettings fonts={fonts} textVariant='title' indexToStore={indexToStore} setIndexToStore={setIndexToStore} scenes={scenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} selectedFontSize={selectedFontSize} setSelectedFontSize={setSelectedFontSize} fontSize={fontSize} setFontSize={setFontSize} />
      </VuiBox>}

      {scenes[currentSceneIndex].textToggle.main_text && <VuiBox sx={{display: 'flex', flexDirection: 'column'}}>
        <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
            <ArrowBackIcon color="white" onClick={() => handleToggleClose('main_text')} sx={{cursor: 'pointer', marginRight: '10px'}} />
            <Typography>main text</Typography>
          </VuiBox>
          <DeleteOutlineIcon color='white' sx={{cursor: 'pointer'}} onClick={()=>handleDelete('main_text')} />
        </VuiBox>
        <TextSettings fonts={fonts} textVariant='main_text' indexToStore={indexToStore} setIndexToStore={setIndexToStore} scenes={scenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} selectedFontSize={selectedFontSize} setSelectedFontSize={setSelectedFontSize} fontSize={fontSize} setFontSize={setFontSize} />
      </VuiBox>}

      {scenes[currentSceneIndex].textToggle.caption && <VuiBox sx={{display: 'flex', flexDirection: 'column'}}>
        <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
            <ArrowBackIcon color="white" onClick={() => handleToggleClose('caption')} sx={{cursor: 'pointer', marginRight: '10px'}} />
            <Typography>caption text</Typography>
          </VuiBox>
          <DeleteOutlineIcon color='white' sx={{cursor: 'pointer'}} onClick={()=>handleDelete('caption')} />
        </VuiBox>
        <TextSettings fonts={fonts} textVariant='caption' indexToStore={indexToStore} setIndexToStore={setIndexToStore} scenes={scenes} updateSceneKey={updateSceneKey} currentSceneIndex={currentSceneIndex} selectedFontSize={selectedFontSize} setSelectedFontSize={setSelectedFontSize} fontSize={fontSize} setFontSize={setFontSize} />
      </VuiBox>}

      {(!scenes[currentSceneIndex].textToggle.header && !scenes[currentSceneIndex].textToggle.title && !scenes[currentSceneIndex].textToggle.main_text && !scenes[currentSceneIndex].textToggle.caption) && 
      <>
        <Typography textAlign='left'>Text</Typography>
        <VuiBox sx={{textAlign: 'center', background: 'lightgray', marginTop: '20px', cursor: 'pointer'}} onClick={() => handleToggle('header')}>
          <h1>Header</h1>
        </VuiBox>
        <VuiBox sx={{textAlign: 'center', background: 'lightgray', marginTop: '20px', cursor: 'pointer'}} onClick={() => handleToggle('title')}>
          <h2>Title</h2>
        </VuiBox>
        <VuiBox sx={{textAlign: 'center', background: 'lightgray', marginTop: '20px', cursor: 'pointer'}} onClick={() => handleToggle('main_text')}>
          <h3>Main Text</h3>
        </VuiBox>
        <VuiBox sx={{textAlign: 'center', background: 'lightgray', marginTop: '20px', cursor: 'pointer'}} onClick={() => handleToggle('caption')}>
          <h4>Caption</h4>
        </VuiBox>
      </>
      }
    </VuiBox>
  )
}

export default Text