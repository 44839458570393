import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageLightBlue: {
      position: "relative",
      minWidth:"23%",
      maxWidth: "47.5%",
      padding: "10px",
      backgroundColor: "#0175FF",
      // width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "200 .8em 'Plus Jakarta Display', sans-serif",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #0175FF",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "-1px",
        left: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #0175FF",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px",
      },
    },
    messageDeepBlue: {
      // right: "3%",
      position: "relative",
      alignSelf: "right",
      maxWidth: "47.5%",
      padding: "10px",
      backgroundColor: "#070D29",
      //height: "50px",
      textAlign: "left",
      font: "200 .8em 'Plus Jakarta Display', sans-serif",
      border: "1px solid #070D29",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #070D29",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #070D29",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px",
      },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      color: "#fff",
      font: "Plus Jakarta Display",
    },
    messageTimeStampLeft: {
      fontSize: "0.75em",
      textAlign: "left",
      paddingTop: "5px",
      color: "#bfbfbf",
    },
    messageTimeStampRight: {
      fontSize: "0.75em",
      color: "#bfbfbf",
      textAlign: "right",
      paddingTop: "5px",
    },
    deepBlue: {
      color: "#FFF",
      width: "150%",
    },
    lightBlue: {
      color: "#FFF",
      width: "150%",
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

//avatarが左にあるメッセージ（他人）
export const MessageLeft = (props) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  //eslint-disable-next-line
  const displayName = props.displayName ? props.displayName : "名無しさん";
  const classes = useStyles();
  return (
    <div className={classes.messageRow}>
      <div>
        <Grid container>
          <div style={{marginLeft:"7%",}} className={classes.messageLightBlue}>
            <div>
              <p className={classes.messageContent}>{message}</p>
              <div className={classes.messageTimeStampLeft}>{timestamp}</div>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export const MessageRight = (props) => {
  const classes = useStyles();
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  return (
    <div style={{marginRight:"2%",marginTop:"1%"}} className={classes.messageDeepBlue}>
      <p className={classes.messageContent}>{message}</p>
      <div className={classes.messageTimeStampRight}>{timestamp}</div>
    </div>
  );
};
