import React from "react";
import { Typography } from "@mui/material";

import Grid from "@mui/material/Grid";


function Lib(){


return(
    <Grid style={{width: 700, textAlign: 'center'}}>
        <Typography style={{ fontWeightMedium: 500,fontSizeMD: 16, bottom:"50px", color: "whitesmoke"}}> 
            You haven't Uploaded any videos yet!
        </Typography>

    </Grid>
);
}
export default Lib;